import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import LeagueSummary from './../league/LeagueSummary'
import NewDivisionDialog from './../league/NewDivisionDialog'
import DivisionTable from './../league/DivisionTable'

import { leagueSlice } from 'league/redux'

class Teams extends React.Component {
  render() {
    const { openAddDivisionModal } = this.props
    return (
      <div>
        <NewDivisionDialog />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={8} style={{ textAlign: 'center', width: '100%' }}>
            <DivisionTable />
            <Button
              style={{ margin: 10, textAlign: 'right' }}
              onClick={openAddDivisionModal}
              className="save-btn"
            >
              Add age group
            </Button>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', width: '100%' }}>
            <LeagueSummary hide_broadcast />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddDivisionModal: () =>
    dispatch(leagueSlice.actions.updateNewDialog({ key: 'open', value: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
