import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { updateCustomerEmail } from './../actions'
import { actions } from './../redux'
import { openConfirmModal } from 'common/actions'

class EditEmailForm extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Edit customer email
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => this.props.handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  id="standard-adornment-amount"
                  value={this.props.email}
                  onChange={e => this.props.editEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.updateCustomerEmail()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.customer.edit_email_modal_open,
  email: state.customer.creation.email,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  editEmail: email =>
    dispatch(actions.changeNewCustomerDetails({ key: 'email', value: email })),
  handleClose: () => dispatch(actions.closeEditEmailModal()),
  updateCustomerEmail: () => {
    dispatch(
      openConfirmModal(
        'Customer',
        `edit this customer's email (you have to merge the according hubspot profiles from hubspot)`,
        updateCustomerEmail
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailForm)
