import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import ContractDetails from 'contract/components/ContractDetails'
import Menu from 'contract/components/Menu'
import EventsTable from 'contract/components/EventsTable'
import CreateEventDialog from 'contract/components/CreateEventDialog'
import EventsEditDialog from 'contract/components/EventsEditDialog'
import FeeUpdateDialog from 'contract/components/FeeUpdateDialog'
import PtypeUpdateDialog from 'contract/components/PtypeUpdateDialog'
import Invoice from 'invoice/components/Invoice'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import HubspotListDialog from 'common/components/HubspotListDialog'

import { getContract } from 'contract/actions'
import { getInvoiceByContractID } from 'invoice/actions'
import { actions } from 'contract/redux'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Manage extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getContract(id)
    this.props.getInvoice(id)
  }

  render() {
    const { contract, alert_open, alert_message, closeAlert } = this.props
    return (
      <div>
        <Snackbar
          open={alert_open}
          autoHideDuration={3000}
          onClose={() => closeAlert()}
        >
          <Alert severity="success" onClose={() => closeAlert()}>
            {alert_message}
          </Alert>
        </Snackbar>
        <CreateEventDialog />
        <EventsEditDialog />
        <FeeUpdateDialog />
        <PtypeUpdateDialog />
        <HubspotListDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          {contract && (
            <Grid item xs={4}>
              <ContractDetails />
            </Grid>
          )}
          {contract && (
            <Grid item xs={8}>
              <Menu />
              {this.props.tab === 'events' && <EventsTable />}
              {this.props.tab === 'invoice' && <Invoice is_contract />}
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  contract: state.contract.contract,
  tab: state.contract.tab,
  alert_open: state.contract.alert_message_open,
  alert_message: state.contract.alert_message,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getContract: id => dispatch(getContract(id)),
  getInvoice: id => dispatch(getInvoiceByContractID(id)),
  closeAlert: message => dispatch(actions.closeAlert()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
