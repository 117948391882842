import React from 'react'
import { connect } from 'react-redux'

import { Grid, TextField, Button } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'

import { loginUser } from './../actions'
import { actions } from './../redux'

const styles = theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
})

class LoginForm extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <RadioGroup
            aria-label="position"
            name="position"
            row
            value={this.props.user_type}
            onChange={(a, b) => this.props.updateLoginField('user_type', b)}
          >
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  value="admin"
                  control={<Radio color="primary" />}
                  label="Admin"
                  labelPlacement="bottom"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="manager"
                  control={<Radio color="primary" />}
                  label="Manager"
                  labelPlacement="bottom"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="mod"
                  control={<Radio color="primary" />}
                  label="MOD"
                  labelPlacement="bottom"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="referee"
                  control={<Radio color="primary" />}
                  label="Referee"
                  labelPlacement="bottom"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={this.props.email}
          onChange={e => this.props.updateLoginField('email', e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={this.props.password}
          onChange={e =>
            this.props.updateLoginField('password', e.target.value)
          }
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            this.props.loginUser()
          }}
        >
          <b>Sign In</b>
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          {/*<Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>*/}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.user.form,
})

const mapDispatchToProps = dispatch => ({
  updateLoginField: (k, v) =>
    dispatch(actions.updateFormField({ key: k, value: v })),
  loginUser: () => dispatch(loginUser()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginForm))
