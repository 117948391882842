import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { updateTeamName } from 'teams/actions'
import { actions } from 'teams/redux'

class EditTeamNameDialog extends React.Component {
  render() {
    const {
      change_name_dialog_open,
      team_form,
      handleClose,
      changeTeamDetails,
      updateTeamName,
    } = this.props
    return (
      <Dialog
        open={change_name_dialog_open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Edit Team Name
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <TextField
              label="Name *"
              fullWidth
              variant="outlined"
              value={team_form.name}
              onChange={e => changeTeamDetails('name', e.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateTeamName()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.teams,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.toggleChangeTeamNameModal({ open: false })),
  updateTeamName: () => dispatch(updateTeamName()),
  changeTeamDetails: (k, v) =>
    dispatch(actions.editTeamDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamNameDialog)
