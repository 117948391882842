import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filters'
import SalesReport from './../reports/Sales'
import InvoicesReport from './../reports/Invoices'

class Report extends React.Component {
  render() {
    const { report_type } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Filters report_dropdown />
        </Grid>
        <Grid item xs={12}>
          {report_type === 'cash' && <SalesReport />}
          {report_type === 'invoice' && <InvoicesReport />}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  report_type: state.reporting.report_type,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Report)
