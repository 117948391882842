import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'

import { updateInvoiceItemFee } from './../actions'
import { actions } from './../redux'

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.floatValue,
        },
      })
    }}
    thousandSeparator
    isNumericString
    prefix="$"
  />
)

const EditCreditDialog = ({
  open,
  handleClose,
  price,
  updatePrice,
  updateInvoiceItemFee,
}) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="sm"
    fullWidth
    aria-labelledby="confirmation-dialog-title"
    open={open}
  >
    <DialogTitle id="confirmation-dialog-title">Edit fee</DialogTitle>
    <DialogContent>
      <TextField
        id="standard-adornment-amount"
        value={price}
        onChange={e => updatePrice(e.target.value)}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        fullWidth
        label="Hourly fee amount"
      />
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleClose()
          updateInvoiceItemFee()
        }}
        color="primary"
      >
        Save
      </Button>
    </DialogActions>
  </Dialog>
)

const mapStateToProps = state => ({
  ...state.invoice.update_fee,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.updateFee({ open: false })),
  updatePrice: price => dispatch(actions.updateFee({ price })),
  updateInvoiceItemFee: () => dispatch(updateInvoiceItemFee()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCreditDialog)
