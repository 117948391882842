import React from 'react'
import { connect } from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'

import Copyright from './../components/Copyright'
import ForgotForm from './../components/ForgotForm'
import ErrorDialog from './../../common/components/ErrorDialog'
import Loader from './../../common/components/Loader'

import { parseSignupData } from './../actions'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://scoreplay-scoreboards.s3.eu-west-3.amazonaws.com/bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
})

class Signup extends React.Component {
  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    if (params.token) {
      this.props.parseSignupData(params.token)
    }
  }
  render() {
    const { classes } = this.props
    return (
      <div>
        <ErrorDialog />
        <Loader />
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Password forgotten
              </Typography>
              <br />
              <Typography variant="body1">
                Please enter your email to receive a recover link in your email.
              </Typography>
              <form className={classes.form} noValidate>
                <ForgotForm />
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  parseSignupData: data => dispatch(parseSignupData(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Signup))
