import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CreateLeagueForm from './forms/CreateLeague'

import { createLeague, editLeague, toggleModal } from './../actions'

class LeagueDialog extends React.Component {
  render() {
    const { open, edit, is_tournament, handleClose, createLeague, editLeague } =
        this.props,
      cb = edit ? editLeague : createLeague
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit' : 'Create'} new{' '}
          {is_tournament ? 'tournament' : 'league'}
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateLeagueForm is_tournament={is_tournament} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              cb()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.league.dialog_open,
  edit: state.league.dialog_edit,
  is_tournament: state.league.create_league.tournament,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleModal(false)),
  createLeague: () => dispatch(createLeague()),
  editLeague: () => dispatch(editLeague()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueDialog)
