import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Select from 'react-select'
import {
  TimePickerComponent,
  DatePickerComponent,
} from '@syncfusion/ej2-react-calendars'
import Grid from '@material-ui/core/Grid'

import { actions } from './../../redux'
import { getFields } from './../../../calendar/actions'
import {
  momentDateTimeToUnix,
  momentDateToUnix,
  unixToDateTime,
  unixToDate,
} from './../../../helper'
import { day_of_week } from './../../../consts'

class SlotForm extends React.Component {
  componentDidMount() {
    if (this.props.field) {
      this.props.getFields(this.props.facility_id)
    }
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Select
            options={this.props.facilities}
            placeholder="Select facility *"
            value={
              this.props.facility &&
              this.props.facilities.find(
                f => f.value === this.props.facility.ID
              )
            }
            onChange={e => {
              this.props.changeSlotDetails('facility', e.facility)
              this.props.getFields(e.value)
            }}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            options={this.props.fields}
            placeholder="Select fields *"
            value={
              this.props.sel_fields &&
              this.props.fields.filter(
                day => this.props.sel_fields.indexOf(day.value) !== -1
              )
            }
            onChange={e =>
              this.props.changeSlotDetails(
                'sel_fields',
                e.map(v => v.value)
              )
            }
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isMulti
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            options={day_of_week}
            placeholder="Select days *"
            value={
              this.props.sel_days_of_week &&
              day_of_week.filter(
                day => this.props.sel_days_of_week.indexOf(day.value) !== -1
              )
            }
            onChange={e =>
              this.props.changeSlotDetails(
                'sel_days_of_week',
                e.map(v => v.value)
              )
            }
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isMulti
          />
        </Grid>
        <Grid item xs={6}>
          <TimePickerComponent
            id="league_start_date"
            placeholder="Slot start time *"
            value={this.props.start_time}
            onChange={e =>
              this.props.changeSlotDetails(
                'start_time',
                momentDateTimeToUnix(moment(e.value))
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TimePickerComponent
            id="league_end_date"
            placeholder="Slot end time *"
            value={this.props.end_time}
            onChange={e =>
              this.props.changeSlotDetails(
                'end_time',
                momentDateTimeToUnix(moment(e.value))
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePickerComponent
            id="start_date"
            placeholder="Start date"
            value={this.props.start_date}
            step={15}
            onChange={e =>
              this.props.changeSlotDetails(
                'start_date',
                momentDateToUnix(moment(e.value))
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePickerComponent
            id="end_date"
            placeholder="End date"
            value={this.props.end_date}
            step={15}
            onChange={e =>
              this.props.changeSlotDetails(
                'end_date',
                momentDateToUnix(moment(e.value))
              )
            }
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.soccernaut.create_slot,
  facilities: state.common.facilities
    .filter(f => f.ID < 997)
    .map(f => ({
      label: f.name,
      value: f.ID,
      facility: f,
    })),
  fields: state.calendar.fields.map(f => ({
    label: f.name,
    value: f.ID,
    field: f,
  })),
  start_time: unixToDateTime(state.soccernaut.create_slot.start_time).toDate(),
  end_time: unixToDateTime(state.soccernaut.create_slot.end_time).toDate(),
  start_date: unixToDate(state.soccernaut.create_slot.start_date).toDate(),
  end_date: unixToDate(state.soccernaut.create_slot.end_date).toDate(),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeSlotDetails: (k, v) =>
    dispatch(actions.changeSlotDetails({ key: k, value: v })),
  getFields: facility_id => dispatch(getFields(facility_id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SlotForm)
