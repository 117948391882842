import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ProductFieldTimeForm from './../../../calendar/components/forms/ProductFieldTimeSection'

import {
  toggleAddGameModal,
  updateGameDetails,
  createManualMatch,
  changeScheduleDetails,
} from 'league/actions'
import { GAME_TYPES } from 'consts'

class AddGameDialog extends React.Component {
  render() {
    const { open, handleClose, createManualMatch } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
          Add manual game
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProductFieldTimeForm hide_comment hide_ptype hide_end_date />
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={3}
            style={{ paddingTop: 20, textAlign: 'center' }}
          >
            <Grid item xs={6} style={{ textAlign: 'center', width: '100%' }}>
              <TextField
                fullWidth
                label="Match duration (in minutes)"
                onChange={e =>
                  this.props.changeScheduleDetails('duration', e.target.value)
                }
                value={this.props.duration}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={GAME_TYPES}
                placeholder="Game type*"
                value={GAME_TYPES.find(g => g.value === this.props.game_type)}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                onChange={e =>
                  this.props.updateGameDetails('game_type', e.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={[{ label: 'TBD', value: 0 }].concat(this.props.teams)}
                placeholder="Home team*"
                value={this.props.teams.find(
                  t => t.value.toString() === this.props.home_team_id
                )}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                onChange={e =>
                  this.props.updateGameDetails('home_team_id', e.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={[{ label: 'TBD', value: 0 }].concat(this.props.teams)}
                placeholder="Away team*"
                value={this.props.teams.find(
                  t => t.value.toString() === this.props.away_team_id
                )}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                onChange={e =>
                  this.props.updateGameDetails('away_team_id', e.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              createManualMatch()
              handleClose()
            }}
            color="primary"
          >
            Check & Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.league.new_game.modal_open,
  teams: state.league.league
    ? state.league.league.teams.map(t => ({
        label: t.team.name,
        value: t.team.ID,
      }))
    : [],
  ...state.league.new_game,
  duration: state.league.scheduler.duration,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleAddGameModal(false)),
  updateGameDetails: (k, v) => dispatch(updateGameDetails(k, v)),
  changeScheduleDetails: (k, v) => dispatch(changeScheduleDetails(k, v)),
  createManualMatch: () => dispatch(createManualMatch()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddGameDialog)
