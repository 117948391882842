import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { changeLeagueDetails, changeScheduleDetails } from 'league/actions'
import { unixToDate, momentDateToUnix } from 'helper'

import { leagueSlice } from 'league/redux'

class Scheduler extends React.Component {
  componentDidMount() {
    this.props.changeScheduleDetails('duration', this.props.match_duration)
  }
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={7} style={{ textAlign: 'center' }}>
          {this.props.is_tournament ? 'Day' : 'League start'}
          <DatePickerComponent
            id="league_start_date"
            placeholder="League start date *"
            value={unixToDate(this.props.league_start).toDate()}
            onChange={e => {
              const unix_time = momentDateToUnix(moment(e.value))
              this.props.updateLeague('start_date', unix_time)
            }}
          />
        </Grid>
        {!this.props.is_tournament && (
          <Grid item xs={7} style={{ textAlign: 'center', width: '100%' }}>
            <TextField
              fullWidth
              label="Number of games guaranteed per team including playoffs (7 or 10)"
              onChange={e =>
                this.props.changeScheduleDetails('games', e.target.value)
              }
              value={this.props.games}
            />
          </Grid>
        )}
        <Grid item xs={7} style={{ textAlign: 'center', width: '100%' }}>
          <TextField
            fullWidth
            label="Match duration (in minutes)"
            onChange={e =>
              this.props.changeScheduleDetails('duration', e.target.value)
            }
            value={this.props.duration}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.scheduler,
  league_start: state.league.league.start_date,
  is_tournament: state.league.league.is_tournament,
  match_duration: state.league.league.duration,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLeagueDetails: (k, v) => dispatch(changeLeagueDetails(k, v)),
  changeScheduleDetails: (k, v) => dispatch(changeScheduleDetails(k, v)),
  updateLeague: (k, v) =>
    dispatch(leagueSlice.actions.updateLeague({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler)
