import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Select from 'react-select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import {
  getSoccernautParticipants,
  getCoaches,
  updateSoccernautCoach,
} from './../../actions'
import { actions as commonActions } from 'common/redux'
import { openInNewTab } from 'helper'

class SoccernautSection extends React.Component {
  componentDidMount() {
    this.props.getSoccernautParticipants()
    this.props.getCoaches(this.props.facility_id)
  }
  copyToClipboard() {
    const event_id = this.props.event_details.ID,
      is_adult =
        this.props.event_details.comment.indexOf('Adult') !== -1 ? '1' : '0',
      copyText = `https://webapp.sofive.com/soccernaut_participants.html?event_id=${event_id}&is_adult=${is_adult}`
    navigator.clipboard.writeText(copyText)
    this.props.copySuccess()
  }
  openWebapp() {
    const event_id = this.props.event_details.ID,
      is_adult =
        this.props.event_details.comment.indexOf('Adult') !== -1 ? '1' : '0',
      copyText = `https://webapp.sofive.com/soccernaut_participants.html?event_id=${event_id}&is_adult=${is_adult}`
    openInNewTab(copyText)
  }
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center', marginTop: 20 }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Button className="save-btn" onClick={() => this.copyToClipboard()}>
            Copy add participant link
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button className="save-btn" onClick={() => this.openWebapp()}>
            Add participant
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    First Name
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Last Name
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Contact</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Waiver signed
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.participants.map((participant, i) => (
                  <TableRow key={`${participant.ID}`}>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.first_name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.last_name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.email}
                      {participant.is_kid && (
                        <div>
                          <br />
                          (parent)
                        </div>
                      )}
                      <Link
                        href={`/customer/${participant.ID}`}
                        target="_blank"
                      >
                        <IconButton aria-label="delete">
                          <ExitToAppIcon fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.waiver_signed ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Select
            options={this.props.customers}
            placeholder="Select coach..."
            value={
              this.props.coach_id &&
              this.props.customers.find(c => c.ID === this.props.coach_id)
            }
            formatOptionLabel={option => (
              <p>
                {option.first_name} {option.last_name} <br />
                <span>
                  email=
                  {option.email}
                </span>
                {option.phone && (
                  <span>
                    <br />
                    phone=
                    {option.phone}
                  </span>
                )}
              </p>
            )}
            onChange={e => this.props.updateSoccernautCoach(e.value)}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: base => ({ ...base, height: '80px', minHeight: '80px' }),
              valueContainer: base => ({
                ...base,
                height: '80px',
                minHeight: '80px',
              }),
            }}
            menuPortalTarget={document.body}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  participants: state.calendar.soccernaut_participants,
  customers: state.calendar.customers.map(coach => ({
    ...coach,
    label: coach.first_name + coach.last_name + coach.email, // for searching
    value: coach.ID,
  })),
  facility_id: state.calendar.event_details.field.facility_id,
  coach_id: state.calendar.event_details.coach_id,
  event_details: state.calendar.event_details,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSoccernautParticipants: () => dispatch(getSoccernautParticipants()),
  getCoaches: facility_id => dispatch(getCoaches(facility_id)),
  updateSoccernautCoach: user_id => dispatch(updateSoccernautCoach(user_id)),
  copySuccess: () =>
    dispatch(commonActions.openInfoModal({ message: 'Copied to clipboard' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SoccernautSection)
