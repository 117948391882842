import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filters'
import DiscountTable from './../components/DiscountTable'
import RuleTable from './../components/RuleTable'

import { getDiscounts, getDiscountRules } from './../actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getDiscounts()
    this.props.getDiscountRules()
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            {this.props.tab === 'discounts' && <DiscountTable />}
            {this.props.tab === 'rules' && <RuleTable />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.discount.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getDiscounts: () => dispatch(getDiscounts()),
  getDiscountRules: () => dispatch(getDiscountRules()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
