import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { applyFilter } from './../../helper'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class GLTable extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '33%' }}
                  className="table-header"
                >
                  Code
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '33%' }}
                  className="table-header"
                >
                  Product
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '33%' }}
                  className="table-header"
                >
                  Season
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {this.props.gls.map(gl => (
                  <TableRow key={gl.ID} hover style={{ cursor: 'pointer' }}>
                    <TableCell style={{ textAlign: 'center', width: '33%' }}>
                      {gl.code}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '33%' }}>
                      {gl.product_type.name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '33%' }}>
                      {gl.season.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  gls: state.product.gls.filter(g => applyFilter(g, state.product.query)),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GLTable))
