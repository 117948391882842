import { unixToDate } from 'helper'

const getSeason = unix_date => {
  const d = unixToDate(unix_date).toDate()
  const seasonArray = [
    {
      name: 'Spring',
      date: new Date(
        d.getFullYear(),
        2,
        d.getFullYear() % 4 === 0 ? 19 : 20
      ).getTime(),
    },
    {
      name: 'Summer',
      date: new Date(
        d.getFullYear(),
        5,
        d.getFullYear() % 4 === 0 ? 20 : 21
      ).getTime(),
    },
    {
      name: 'Fall',
      date: new Date(
        d.getFullYear(),
        8,
        d.getFullYear() % 4 === 0 ? 22 : 23
      ).getTime(),
    },
    {
      name: 'Winter',
      date: new Date(
        d.getFullYear(),
        11,
        d.getFullYear() % 4 === 0 ? 20 : 21
      ).getTime(),
    },
  ]
  return seasonArray.filter(({ date }) => date <= d).slice(-1)[0]
}

export const buildTags = (facility, event) => {
  let tags = [],
    details = {}
  tags.push(facility.name)
  const product_type = event.product_type.reporting
  let spl = product_type.split(' ')
  if (spl.length > 0) {
    tags.push(spl.slice(1).join(' '))
  } else {
    tags.push(product_type)
  }
  if (product_type.toLowerCase().indexOf('adult') !== -1) {
    tags.push('Adult')
    details.age_group = 'Adult'
  } else {
    tags.push('Youth')
    details.age_group = 'Youth'
  }
  const season = getSeason(event.start_date)
  if (season) {
    tags.push(season.name)
    details.season = season.name
  }
  tags.push('photo')
  tags.push('field')
  return [tags, details]
}

export const filterPhotos = (filters, photos) => {
  return photos.filter(photo => {
    if (filters.facility_id && photo.facility.ID !== filters.facility_id) {
      return false
    }
    if (
      filters.product &&
      photo.event.product_type.reporting !== filters.product
    ) {
      return false
    }
    if (
      filters.season &&
      photo.details.season &&
      photo.details.season !== filters.season
    ) {
      return false
    }
    if (
      filters.age_group &&
      photo.details.age_group &&
      photo.details.age_group !== filters.age_group
    ) {
      return false
    }
    if (filters.deleted && filters.deleted === 1 && photo.to_keep) {
      return false
    }
    if (filters.deleted && filters.deleted === 2 && !photo.to_keep) {
      return false
    }
    return true
  })
}
