import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TeamIcon from '@material-ui/icons/PersonPin'
import CalendarIcon from '@material-ui/icons/DateRange'
import InvoicesIcon from '@material-ui/icons/Receipt'

import { actions } from 'teams/redux'

class TeamMenu extends React.Component {
  render() {
    return (
      <Paper square elevation={0}>
        <Tabs
          value={this.props.team_tab}
          onChange={(a, b) => this.props.changeTeamTab(b)}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          <Tab icon={<TeamIcon />} label="Players" value="players" />
          {this.props.league && (
            <Tab
              icon={<CalendarIcon />}
              label="Schedule & Standings"
              value="standings"
            />
          )}
          <Tab icon={<InvoicesIcon />} label="Invoices" value="invoices" />
        </Tabs>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  team_tab: state.teams.team_tab,
  league: state.teams.team.current_league,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTeamTab: tab => dispatch(actions.changeTab({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamMenu)
