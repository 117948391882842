import React, { useState } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'

import { getClasses } from './../actions'
import { actions } from './../redux'
import { unixToDate, unixToDateTime, momentDateTimeSecondsToUnix } from 'helper'
import { colors } from 'consts'

const ResourceDropdown = ({
  query,
  changeFilterDetails,
  start_date_parsed,
  end_date_parsed,
  start_date,
  end_date,
  getPickupPlayers,
  facilities,
  facility_id,
  toggleCreationModal,
  is_admin,
  getClasses,
}) => {
  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4} style={{ width: '100%' }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Filter classes..."
            value={query}
            onChange={e => changeFilterDetails('query', e.target.value)}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={facilities}
            placeholder="Facility"
            onChange={v => changeFilterDetails('facility_id', v)}
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isMulti
          />
        </Grid>
        <Grid item xs={3}>
          <DateRangePicker
            small
            isOutsideRange={date => false}
            startDate={start_date ? unixToDateTime(start_date) : null}
            startDateId="your_unique_start_date_id"
            endDate={end_date ? unixToDateTime(end_date) : null}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) => {
              if (!startDate && !endDate) {
                // dates cleared
                changeFilterDetails('start_date', null)
                changeFilterDetails('end_date', null)
              }
              if (startDate) {
                changeFilterDetails(
                  'start_date',
                  momentDateTimeSecondsToUnix(
                    startDate.hour(0).minute(0).second(0)
                  )
                )
              }
              if (endDate) {
                changeFilterDetails(
                  'end_date',
                  momentDateTimeSecondsToUnix(
                    endDate.hours(23).minutes(59).seconds(59)
                  )
                )
              }
            }}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            showClearDates={true}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            style={{
              color: colors.blue_strong,
              backgroundColor: colors.blue_mild,
            }}
            component="span"
            onClick={getClasses}
          >
            Search
          </Button>
          {is_admin && (
            <Button
              component="span"
              variant="outlined"
              color="secondary"
              onClick={() => toggleCreationModal()}
            >
              Create
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.classes.filters,
  facilities: state.common.filter_facilities,
  start_date_parsed: unixToDate(state.pickup.filter.start_date),
  end_date_parsed: unixToDate(state.pickup.filter.end_date),
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilterDetails: (k, v) =>
    dispatch(actions.changeFilterDetails({ key: k, value: v })),
  toggleCreationModal: () =>
    dispatch(actions.toggleCreationModal({ open: true })),
  getClasses: () => dispatch(getClasses()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
