import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ProductFieldTimeForm from 'calendar/components/forms/ProductFieldTimeSection'
import RepeatForm from 'calendar/components/forms/RepeatSection'

import { addClassEvent } from 'classes/actions'
import { getFields } from 'calendar/actions'
import { actions } from 'classes/redux'

const ManualClassDialog = ({
  facility_id,
  open,
  handleClose,
  handleSubmit,
  getFields,
}) => {
  useEffect(() => {
    getFields(facility_id)
  })
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        Add manual class
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ProductFieldTimeForm hide_comment hide_ptype />
        <RepeatForm />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Check & Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  open: state.classes.class_event_modal,
  facility_id: state.classes.classInfo.facility_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleClassEventModal(false)),
  handleSubmit: () => dispatch(addClassEvent()),
  getFields: id => dispatch(getFields(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManualClassDialog)
