import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import FacilitiesManage from './FacilityManage'
import FieldsManage from './FieldManage'
import ProductsManage from 'product/screens/NewManage'
import TaxesManage from './TaxManage'
import FeeManage from './FeeManage'
import AgeGroupManage from './AgeGroupManage'
import NewTaxDialog from 'resources/components/NewTaxDialog'
import GLManage from './GLManage'
import FieldTypeManage from './FieldTypeManage'

const ResourceManager = () => {
  const url = useSelector(state => state.router.location.pathname),
    [tab, setTab] = useState('field'),
    qParam = useSelector(state => state.router.location.query)
  useEffect(() => qParam?.tab && setTab(qParam?.tab), [qParam])
  return (
    <div>
      <NewTaxDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <ToggleButtonGroup value={tab} exclusive aria-label="text alignment">
            <ToggleButton
              value="facility"
              aria-label="left aligned"
              onClick={() => (window.location = `${url}?tab=facility`)}
            >
              Facilities
            </ToggleButton>
            <ToggleButton
              value="field"
              aria-label="left aligned"
              onClick={() => (window.location = `${url}?tab=field`)}
            >
              Fields
            </ToggleButton>
            <ToggleButton
              value="gl"
              aria-label="centered"
              onClick={() => (window.location = `${url}?tab=gl`)}
            >
              GLs
            </ToggleButton>
            <ToggleButton
              value="product"
              aria-label="centered"
              onClick={() => (window.location = `${url}?tab=product`)}
            >
              Products
            </ToggleButton>
            <ToggleButton
              value="tax"
              aria-label="centered"
              onClick={() => (window.location = `${url}?tab=tax`)}
            >
              Taxes
            </ToggleButton>
            <ToggleButton
              value="fee"
              aria-label="centered"
              onClick={() => (window.location = `${url}?tab=fee`)}
            >
              Fees
            </ToggleButton>
            <ToggleButton
              value="agegroup"
              aria-label="centered"
              onClick={() => (window.location = `${url}?tab=agegroup`)}
            >
              Age Groups
            </ToggleButton>
            <ToggleButton
              value="fieldtype"
              aria-label="centered"
              onClick={() => (window.location = `${url}?tab=fieldtype`)}
            >
              Field Types
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {tab === 'facility' && <FacilitiesManage />}
          {tab === 'field' && <FieldsManage />}
          {tab === 'gl' && <GLManage />}
          {tab === 'product' && <ProductsManage />}
          {tab === 'tax' && <TaxesManage />}
          {tab === 'fee' && <FeeManage />}
          {tab === 'agegroup' && <AgeGroupManage />}
          {tab === 'fieldtype' && <FieldTypeManage />}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)
