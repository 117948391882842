import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'

import { confirmUser } from './../actions'
import { actions } from './../redux'

const styles = theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
})

class SignupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
    }
  }

  handleClickShowPassword() {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  }

  handleMouseDownPassword(event) {
    event.preventDefault()
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <TextField
            label="First name"
            fullWidth
            value={this.props.first_name}
            onChange={e =>
              this.props.updateLoginField('first_name', e.target.value)
            }
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Last name"
            fullWidth
            value={this.props.last_name}
            onChange={e =>
              this.props.updateLoginField('last_name', e.target.value)
            }
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            fullWidth
            value={this.props.email}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone"
            fullWidth
            value={this.props.phone}
            onChange={e => this.props.updateLoginField('phone', e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              fullWidth
              name="password"
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={this.props.password}
              onChange={e =>
                this.props.updateLoginField('password', e.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword.bind(this)}
                    onMouseDown={this.handleMouseDownPassword.bind(this)}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
            <OutlinedInput
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type={this.state.showPassword ? 'text' : 'password'}
              id="confirm_password"
              autoComplete="current-confirm_password"
              value={this.props.confirm_password}
              onChange={e =>
                this.props.updateLoginField('confirm_password', e.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => {
              this.props.confirmUser()
            }}
            className="save-btn"
          >
            <b>Save</b>
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  ...state.user.form,
})

const mapDispatchToProps = dispatch => ({
  updateLoginField: (k, v) =>
    dispatch(actions.updateFormField({ key: k, value: v })),
  confirmUser: () => dispatch(confirmUser()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignupForm))
