import React from 'react'
import { connect } from 'react-redux'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Scheduler from './../scheduler/Wizard'
import LeagueMatchesTable from './../league/LeagueMatches'
import ELeagueMatchesTable from './../eleague/LeagueMatches'
import TeamGamesTable from './../league/TeamGames'

import { getMatches, toggleAddGameModal, deleteGames } from 'league/actions'
import { openDeleteModal } from 'common/actions'

class Schedule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hide_column: false,
    }
  }
  componentDidMount() {
    this.props.getMatches()
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            onClick={() => this.props.addManualGame()}
            className="save-btn"
          >
            Add manual game
          </Button>
          {this.props.is_admin_or_manager && (
            <Button
              onClick={() => this.props.deleteGames()}
              className="del-btn"
              style={{ marginLeft: 30 }}
            >
              Delete all games
            </Button>
          )}
          <ReactToPrint
            content={() => this.componentRef}
            onBeforeGetContent={() => this.setState({ hide_column: true })}
            onAfterPrint={() => this.setState({ hide_column: false })}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <Button
                  style={{ marginLeft: 30 }}
                  color="secondary"
                  onClick={handlePrint}
                >
                  Print Schedule
                </Button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          {this.props.matches.length === 0 ? <Scheduler /> : null}
          {this.props.matches.length && !this.props.is_e_league ? (
            <LeagueMatchesTable
              setRef={el => (this.componentRef = el)}
              hide_column={this.state.hide_column}
            />
          ) : null}
          {this.props.matches.length && this.props.is_e_league ? (
            <ELeagueMatchesTable
              setRef={el => (this.componentRef = el)}
              hide_column={this.state.hide_column}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          {!this.props.is_e_league && this.props.matches.length ? (
            <TeamGamesTable
              teams={this.props.teams}
              matches={this.props.matches}
            />
          ) : null}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  teams: state.league.league.teams.map(t => t.team),
  matches: state.league.league_matches,
  is_e_league: state.league.league.is_e_league,
  is_admin_or_manager: state.user.is_admin_or_manager,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMatches: () => dispatch(getMatches()),
  addManualGame: () => dispatch(toggleAddGameModal(true)),
  deleteGames: id =>
    dispatch(openDeleteModal('game list', () => deleteGames())),
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
