import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { actions } from './../redux'
import { updateExpiry } from './../actions'
import { openConfirmModal } from 'common/actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class DiscountList extends React.Component {
  render() {
    const { classes, expired, updateExpired, expireDiscount } = this.props

    return (
      <div>
        Show expired?{' '}
        <Checkbox
          value={expired}
          onChange={e => updateExpired(e.target.checked)}
        />
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Type
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {this.props.discounts.map(discount => (
                  <TableRow
                    key={discount.ID}
                    hover
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      {discount.name}
                      {discount.expired ? (
                        <span style={{ color: 'red' }}> - expired</span>
                      ) : null}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {discount.is_percentage ? 'Percentage' : 'Fixed amount'}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {!discount.is_percentage && '$'}
                      {discount.value}
                      {discount.is_percentage && '%'}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {!discount.expired && (
                        <IconButton
                          className={classes.root}
                          onClick={() => expireDiscount(discount.ID)}
                        >
                          <DeleteIcon
                            color="secondary"
                            fontSize="small"
                            className="del-btn"
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  discounts: state.discount.discounts.filter(d => {
    if (state.discount.show_expired_discounts) return true
    return !d.expired
  }),
  expired: state.discount.show_expired_discounts,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateExpired: expired => dispatch(actions.updateExpired({ expired })),
  expireDiscount: id => {
    dispatch(
      openConfirmModal('Discount', `expire this discount`, () =>
        updateExpiry(id)
      )
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DiscountList))
