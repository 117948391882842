import React from 'react'
import { connect } from 'react-redux'

import memoize from 'memoize-one'
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import EventIcon from '@material-ui/icons/Event'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'

import { colors } from 'consts'
import { openDeleteModal } from 'common/actions'
import { unixToDateTime } from 'helper'
import { openEventEditModal, deleteEvent } from './../actions'
import { getFields } from 'calendar/actions'

const sortIcon = <ArrowDownward />

const columns = memoize(({ editEvent, deleteEvent, classes }) => [
  {
    name: 'Start',
    selector: 'Start',
    center: true,
    grow: 1,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {unixToDateTime(row.start_date).format('M/D/YYYY h:mmA')}
      </div>
    ),
  },
  {
    name: 'Duration',
    selector: 'Duration',
    center: true,
    grow: 1,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {(row.end_date - row.start_date) / 3600} hour(s)
      </div>
    ),
  },
  {
    name: 'Action',
    selector: 'Action',
    center: true,
    grow: 1,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        <IconButton className={classes.root} onClick={() => editEvent(row)}>
          <EditIcon style={{ color: colors.blue_strong }} fontSize="small" />
        </IconButton>
        <Link href={`/calendar/event/${row.ID}`} target="_blank">
          <IconButton style={{ padding: 5 }}>
            <EventIcon
              fontSize="small"
              style={{
                color: colors.blue_strong,
              }}
            />
          </IconButton>
        </Link>
        <IconButton className={classes.root} onClick={() => deleteEvent(row)}>
          <DeleteIcon style={{ color: colors.red_strong }} fontSize="small" />
        </IconButton>
      </div>
    ),
  },
])

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const EventsTable = ({
  classes,
  deleteEvent,
  editEvent,
  events,
  facility_id,
}) => (
  <div>
    <Paper elevation={0}>
      <DataTable
        columns={columns({ editEvent, deleteEvent, classes })}
        data={events}
        highlightOnHover
        defaultSortField="date"
        defaultSortAsc={false}
        sortIcon={sortIcon}
        pagination
        striped
        fixedHeader
        paginationPerPage={50}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Paper>
  </div>
)
const mapStateToProps = (state, ownProps) => ({
  events: state.classes.classInfo.events,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteEvent: event =>
    dispatch(openDeleteModal('event', () => deleteEvent(event))),
  editEvent: event => {
    dispatch(getFields(event.facility_id))
    dispatch(openEventEditModal(event))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventsTable))
