import React from 'react'

import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { unixToDateTime } from './../../../helper'
import { colors } from './../../../consts'

const RepeatTableRow = ({
  i,
  event,
  fields,
  is_edit,
  deleteEvent,
  checkFunc,
  setEdit,
}) => (
  <TableRow
    key={`${event.ID || 0}_${event.field_id || 0}_${event.start_date}`}
    style={{
      backgroundColor: event.is_ok ? colors.green_mild : colors.red_mild,
    }}
  >
    <TableCell style={{ textAlign: 'center', width: '25%' }}>
      {event.field_id
        ? fields.find(f => f.value === event.field_id).label
        : 'Select field...'}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '25%' }}>
      {unixToDateTime(event?.start_date).format('M/D/YYYY HH:mm')}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '25%' }}>
      {unixToDateTime(event?.end_date).format('M/D/YYYY HH:mm')}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '10%' }}>
      {event.is_ok ? 'OK' : 'Taken'}
      {event.to_check && (
        <div>
          <br />
          <Button onClick={() => checkFunc(event.ID)} className="save-btn">
            Check {is_edit && ' & Save'}
          </Button>
        </div>
      )}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '15%' }}>
      <IconButton onClick={() => setEdit(i)}>
        <EditIcon color="primary" fontSize="small" />
      </IconButton>
      <IconButton onClick={() => deleteEvent(i)}>
        <DeleteIcon style={{ color: colors.red_strong }} fontSize="small" />
      </IconButton>
    </TableCell>
  </TableRow>
)

export default RepeatTableRow
