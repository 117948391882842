import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { assignReferee, toggleModal, handleRefereeChange } from './../actions'

class AssignRefereeDialog extends React.Component {
  render() {
    const {
      referee,
      referees,
      open,
      handleClose,
      handleRefereeChange,
      assignReferee,
    } = this.props

    return (
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Assign Referee
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={referee}
                  onChange={e => handleRefereeChange(e.target.value)}
                >
                  <MenuItem value={0}>
                    <em>Select Referee</em>
                  </MenuItem>
                  {referees.map(
                    ({ ID, first_name, last_name, email, phone, facility }) => (
                      <MenuItem spacing={1} key={ID} value={ID}>
                        {`${first_name} ${last_name} (${facility.name})`}
                        <br /> {`Email: ${email}`}
                        <br /> {`Phone: ${phone}`}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              assignReferee()
              handleClose()
            }}
            variant="outlined"
            color="primary"
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  referee: state.referee.referee,
  referees: state.referee.referees,
  open: state.referee.referee_modal_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleModal(false)),
  assignReferee: () => dispatch(assignReferee()),
  handleRefereeChange: referee => dispatch(handleRefereeChange(referee)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignRefereeDialog)
