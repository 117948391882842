import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ProductSelect from './forms/ProductSelect'
import CreateEventForm from './forms/CreateEvent'

import { saveEvent, deleteEvent, updateEvent } from './../actions'
import { openDeleteModal } from './../../common/actions'
import { actions } from './../redux'
import { colors } from './../../consts'
const { toggleEventModal } = actions

class EventDialog extends React.Component {
  render() {
    const {
      step,
      edit,
      open,
      handleClose,
      saveEvent,
      deleteEvent,
      updateEvent,
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit' : 'Add'} event
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!edit && step === 1 && <ProductSelect />}
          {(edit || step === 2) && <CreateEventForm />}
        </DialogContent>
        <DialogActions>
          {!edit && step === 2 && (
            <Button
              color="primary"
              onClick={() => {
                saveEvent()
                handleClose()
              }}
            >
              Save
            </Button>
          )}
          {edit && (
            <Button
              onClick={() => {
                deleteEvent()
                handleClose()
              }}
              style={{ color: colors.red_strong }}
            >
              Delete
            </Button>
          )}
          {edit && (
            <Button
              onClick={() => {
                updateEvent()
                handleClose()
              }}
              className="save-btn"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.calendar.event_details_modal_open,
  step: state.calendar.step,
  edit: state.calendar.event_details.edit,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleEventModal(false)),
  saveEvent: () => dispatch(saveEvent()),
  updateEvent: () => dispatch(updateEvent()),
  deleteEvent: () => dispatch(openDeleteModal('event', deleteEvent)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventDialog)
