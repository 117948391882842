import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Select from 'react-select'

import ProductFieldTimeForm from 'calendar/components/forms/ProductFieldTimeSection'
import RepeatForm from 'calendar/components/forms/RepeatSection'

import { createSession } from 'pickup/actions'
import { actions } from 'pickup/redux'
import { getFields } from 'calendar/actions'
import { actions as calendarActions } from 'calendar/redux'
import { getFees } from 'contract/actions'

class CreateContractDialog extends React.Component {
  componentDidMount() {
    this.props.changeEventDetails('product_type', 'Adult Pick Up')
    this.props.changeEventDetails('product_type_id', 5)
    if (this.props.facility_id) {
      this.props.getFees(this.props.facility_id)
      this.props.changeEventDetails('facility_id', this.props.facility_id)
      this.props.getFields(this.props.facility_id)
    }
  }

  render() {
    const { open, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Create pickup session
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProductFieldTimeForm
            show_facility={this.props.is_admin}
            hide_comment
            onChangeFacility={this.props.getFees}
          />
          <br />
          <Select
            label="Product fee"
            variant="outlined"
            fullWidth
            options={this.props.fees}
            placeholder="Select fee"
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            onChange={e => {
              this.props.updateFee(e.value)
            }}
            menuPortalTarget={document.body}
          />
          <br />
          <RepeatForm />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.createSession()}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.pickup.creation_modal_open,
  facility_id: state.user.facility_id,
  fees: state.contract.fees.map(fee => ({
    label: `${fee.product.name} - ${fee.text}`,
    value: fee.id,
  })),
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleCreationModal({ open: false })),
  createSession: () => dispatch(createSession()),
  changeEventDetails: (k, v) =>
    dispatch(calendarActions.changeEventDetails({ field: k, value: v })),
  getFields: facility_id => dispatch(getFields(facility_id)),
  getFees: facility_id => dispatch(getFees(5, facility_id)),
  updateFee: fee_id => dispatch(actions.updateFee({ fee_id })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContractDialog)
