import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ProductForm from './ProductForm'
import { actions } from 'product/redux'

const NewProductDialog = () => {
  const dispatch = useDispatch(),
    open = useSelector(state => state.product.new_product_details.dialog_open),
    handleClose = () =>
      dispatch(actions.updateProduct({ key: 'dialog_open', value: false }))
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create base product
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 50 }}>
        <ProductForm />
      </DialogContent>
    </Dialog>
  )
}

export default NewProductDialog
