import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import { actions } from 'contract/redux'

class PtypeForm extends React.Component {
  render() {
    return (
      <>
        <Grid item xs={12}>
          <Select
            label="Product fee"
            variant="outlined"
            fullWidth
            options={this.props.product_types}
            placeholder="Select fee"
            value={
              this.props.product_type_id &&
              this.props.product_types.find(
                p => p.value === this.props.product_type_id
              )
            }
            onChange={e => {
              this.props.changeContractDetails('product_type_id', e.value)
            }}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  product_types: state.common.product_types.map(p => ({
    label: p.name,
    value: p.ID,
  })),
  product_type_id: state.contract.new_contract.product_type_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeContractDetails: (k, v) =>
    dispatch(actions.changeContractDetails({ field: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(PtypeForm)
