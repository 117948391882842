import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import MatchesTable from './MatchesTable'
import TeamGamesTable from './../league/TeamGames'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class BuiltSchedule extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12}>
          Match schedule
        </Grid>
        <Grid item xs={12}>
          <MatchesTable />
        </Grid>
        <Grid item xs={12}>
          League details
        </Grid>
        <Grid item xs={12}>
          <TeamGamesTable
            teams={this.props.teams}
            matches={this.props.scheduler_matches}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  teams: state.league.league.teams.map(t => t.team),
  scheduler_matches: state.league.scheduler_matches.map(m => ({
    ...m,
    home_team_id: parseInt(m.home),
    away_team_id: parseInt(m.away),
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BuiltSchedule))
