import moment from 'moment'
import { momentDateTimeToUnix } from 'helper'

export const addFacilityIdToQuery = ({ facility_id, user_type }) => {
  const query = {}
  if (user_type !== 'admin') {
    query.facility_id = facility_id
  }
  return query
}

export const momentToUnix = ({ date, end }) => {
  let hour = 0,
    minute = 0,
    second = 0
  if (end) {
    hour = 23
    minute = 59
    second = 59
  }
  if (!date) date = moment()
  return momentDateTimeToUnix(date.hours(hour).minutes(minute).seconds(second))
}
