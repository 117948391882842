import React, { useEffect, useState } from 'react'
import { payments } from '@square/web-sdk'

import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from 'consts'
import { Button } from '@material-ui/core'

const SquarePayment = ({ onTokenObtained }) => {
  const [card, setCard] = useState(null)
  const [err, setErr] = useState(null)

  useEffect(() => {
    payments(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID).then(res => {
      res.card().then(card => {
        card.attach('#card-container').then(() => setCard(card))
      })
    })
  }, [])

  const pay = () => {
    if (!card) return
    card.tokenize().then(resp => {
      if (resp.status === 'OK') return onTokenObtained(resp.token)
      let message = `Tokenization failed with status: ${result.status}`
      if (result?.errors) {
        message += ` and errors: ${JSON.stringify(result?.errors)}`
      }
      setErr(message)
    })
  }

  return (
    <>
      <div id="card-container" style={{ minHeight: 89 }}></div>
      {!!err && (
        <div id="card-container-error" style={{ color: 'red' }}>
          {err}
        </div>
      )}
      <Button onClick={pay} className="save-btn" style={{ marginBottom: 20 }}>
        PAY
      </Button>
    </>
  )
}

export default SquarePayment
