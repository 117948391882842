import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import AccountProfile from './../components/Profile'
import Menu from './../components/Menu'
import AddCreditDialog from './../components/AddCreditDialog'
import EditEmailDialog from './../components/EditEmailDialog'
import ProfilePage from './../components/pages/Profile'
import HistoryPage from './../components/pages/History'
import ContractsPage from './../components/pages/Contracts'
import MergeDialog from 'customer/components/MergeDialog'

import { getCustomer } from './../actions'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Manage extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getCustomer(id)
  }

  render() {
    const { customer, snackbar_open } = this.props
    return (
      <div>
        <AddCreditDialog />
        <EditEmailDialog />
        <MergeDialog />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbar_open}
        >
          <Alert severity="success">This is a success message!</Alert>
        </Snackbar>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          {customer && (
            <Grid item xs={4}>
              <AccountProfile />
            </Grid>
          )}
          {customer && (
            <Grid item xs={8}>
              <Menu />
              {this.props.tab === 'profile' && <ProfilePage />}
              {this.props.tab === 'history' && <HistoryPage />}
              {this.props.tab === 'contracts' && <ContractsPage />}
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  customer: state.customer.customer,
  snackbar_open: state.customer.snackbar_open,
  tab: state.customer.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCustomer: customer_id => dispatch(getCustomer(customer_id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
