import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import App from './App'
import { rootReducer } from './reducers'
import { colors } from './consts'

Sentry.init({
  dsn: 'https://9f09b1cca8a04688932718369011012e@o210655.ingest.sentry.io/5582416',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment:
    process.env.REACT_APP_ENV === 'staging' ? 'staging' : 'production',
})

// Configure Redux devtools.
// Complicated due to sagaMiddleware which requires composeEnhancers.

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})

const store = configureStore({
  reducer: rootReducer(history),
  middleware: [...getDefaultMiddleware()], // thunk
})

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      light: colors.blue_mild,
      main: colors.blue_strong,
    },
    secondary: {
      light: colors.green_mild,
      main: colors.green_strong,
    },
    // type: 'dark'
  },
})

const render = Component => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}
