import React, { useState } from 'react'
import { connect } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import RepeatTableEdit from './RepeatTableEdit'
import RepeatTableRow from './RepeatTableRow'

import { runNewCheck, runSingleCheckAndSave } from './../../actions'
import { actions } from './../../redux'
const { updateRecurringEvent, deleteRecurringEvent } = actions

const RepeatTable = props => {
  const [editing, setEdit] = useState(null)
  const { is_edit, runSingleCheckAndSave, runNewCheck, repeat_events } = props

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            style={{ textAlign: 'center', width: '25%' }}
            className="table-header"
          >
            Field
          </TableCell>
          <TableCell
            style={{ textAlign: 'center', width: '25%' }}
            className="table-header"
          >
            Start
          </TableCell>
          <TableCell
            style={{ textAlign: 'center', width: '25%' }}
            className="table-header"
          >
            End
          </TableCell>
          <TableCell
            style={{ textAlign: 'center', width: '10%' }}
            className="table-header"
          >
            Status
          </TableCell>
          <TableCell
            style={{ textAlign: 'center', width: '15%' }}
            className="table-header"
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {repeat_events.map((event, i) =>
          editing === i ? (
            <RepeatTableEdit
              key={`${event.ID || 0}_${event.field_id || 0}_${
                event.start_date
              }_${i}`}
              event={event}
              {...props}
              i={i}
              checkFunc={
                is_edit ? runSingleCheckAndSave : () => runNewCheck(setEdit)
              }
              setEdit={setEdit}
            />
          ) : (
            <RepeatTableRow
              key={`${event.ID || 0}_${event.field_id || 0}_${
                event.start_date
              }_${i}`}
              event={event}
              {...props}
              i={i}
              checkFunc={is_edit ? runSingleCheckAndSave : runNewCheck}
              setEdit={setEdit}
            />
          )
        )}
      </TableBody>
    </Table>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.calendar.event_details,
  fields: state.calendar.fields.map(field => ({
    label: `${field.number} - ${field.name}`,
    value: field.ID,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateRecurringEvent: (i, k, v) =>
    dispatch(updateRecurringEvent({ i, field: k, value: v })),
  runNewCheck: setEdit => dispatch(runNewCheck(setEdit)),
  runSingleCheckAndSave: id => dispatch(runSingleCheckAndSave(id)),
  deleteEvent: i => dispatch(deleteRecurringEvent({ i })),
})

export default connect(mapStateToProps, mapDispatchToProps)(RepeatTable)
