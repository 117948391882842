import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Chip from '@material-ui/core/Chip'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { getTeamsReport } from './../actions'
import { colors } from 'consts'
import { niceNumber, exportToCSV } from 'helper'
import { actions } from './../redux'

const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'League',
    selector: 'league',
    sortable: true,
    center: true,
    wrap: true,
    cell: row =>
      row.hide_league ? null : (
        <Link href={`/league/${row.league_id}`} target="_blank">
          {row.league}
        </Link>
      ),
  },
  {
    name: 'Facility',
    selector: 'facility',
    sortable: true,
    center: true,
  },
  {
    name: 'League end date',
    selector: 'end',
    sortable: true,
    center: true,
  },
  {
    name: 'Team',
    selector: 'team',
    sortable: true,
    center: true,
    cell: row => (
      <Link href={`/team/${row.team_id}`} target="_blank">
        {row.team}
      </Link>
    ),
  },
  {
    name: 'Invoiced',
    selector: 'invoiced',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoiced)}`,
    sortFunction: (rowA, rowB) => rowA.invoiced - rowB.invoiced,
  },
  {
    name: 'Paid',
    selector: 'paid',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.paid)}`,
    sortFunction: (rowA, rowB) => rowA.paid - rowB.paid,
  },
  {
    name: 'Discounted',
    selector: 'discounted',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.discounted)}`,
    sortFunction: (rowA, rowB) => rowA.discounted - rowB.discounted,
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    center: true,
    cell: row => (
      <Chip
        size="small"
        label={<b>${niceNumber(row.balance)}</b>}
        style={{
          color: row.balance > 0 ? colors.red_strong : colors.green_strong,
          backgroundColor:
            row.balance > 0 ? colors.red_mild : colors.green_mild,
          margin: 5,
        }}
      />
    ),
    sortFunction: (rowA, rowB) => rowA.balance - rowB.balance,
  },
])

const customStyles = {
  rows: {
    style: {
      minHeight: '100px', // override the row height
    },
  },
}

class TeamsReport extends React.Component {
  componentDidMount() {
    this.props.getTeamsReport()
    this.props.changeReportType('teams')
  }

  render() {
    const { teams, totals } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          Teams: <b>{teams.filter(t => t.team_id).length}</b>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          Invoiced: <b>${niceNumber(totals.invoiced)}</b>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          Paid: <b>${niceNumber(totals.paid)}</b>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          Discounted: <b>${niceNumber(totals.discounted)}</b>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          Balance:{' '}
          <Chip
            size="small"
            label={<b>${niceNumber(totals.balance)}</b>}
            style={{
              color:
                totals.balance > 0 ? colors.red_strong : colors.green_strong,
              backgroundColor:
                totals.balance > 0 ? colors.red_mild : colors.green_mild,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Teams</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportToCSV(teams, 'Teams')}
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={teams}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              customStyles={customStyles}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.reporting.filter,
  teams: state.reporting.teams,
  totals: state.reporting.teams_total,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeReportType: report => dispatch(actions.changeReportType(report)),
  getTeamsReport: p => dispatch(getTeamsReport(p)),
  updateFilter: (k, v) =>
    dispatch(actions.updateFilter({ field: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsReport)
