import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import TextField from 'common/components/FastTextField'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import RepeatTable from './RepeatTable'

import { calculateEventRepeat, runNewCheck } from './../../actions'
import { actions } from './../../redux'
const { changeEventDetails, updateRecurringEvent, notifyRepeatChange } =
    actions,
  repeat_options = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
  ],
  end_options = [
    { label: 'Until date', value: 'until' },
    { label: 'Count', value: 'count' },
  ]

class RepeatForm extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <div
            style={{ float: 'left', cursor: 'pointer' }}
            onClick={() =>
              this.props.changeEventDetails('repeat', !this.props.repeat)
            }
          >
            Repeat event
            <Checkbox
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              checked={this.props.repeat}
            />
          </div>
        </Grid>
        {this.props.repeat ? (
          <>
            <Grid item xs={6}>
              <Select
                options={repeat_options}
                placeholder="Repeat frequency"
                value={
                  this.props.repeat_method &&
                  repeat_options.find(f => f.value === this.props.repeat_method)
                }
                onChange={e =>
                  this.props.changeEventDetails('repeat_method', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={6}>
              {this.props.repeat_method && (
                <TextField
                  id="standard-multiline-static"
                  label={`Repeat every x ${
                    this.props.repeat_method === 'daily' ? 'days' : 'weeks'
                  }`}
                  fullWidth
                  value={this.props.repeat_frequency}
                  type="number"
                  onChange={e =>
                    this.props.changeEventDetails(
                      'repeat_frequency',
                      e.target.value
                    )
                  }
                />
              )}
            </Grid>
            {this.props.repeat_method === 'weekly' && (
              <Grid item xs={12}>
                <ToggleButtonGroup
                  value={this.props.repeat_days}
                  onChange={
                    (a, b) =>
                      this.props.changeEventDetails('repeat_days', [
                        b[b.length - 1],
                      ]) // only the last one
                  }
                  aria-label="text formatting"
                >
                  <ToggleButton value="1">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      M
                    </Avatar>
                  </ToggleButton>
                  <ToggleButton value="2">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      T
                    </Avatar>
                  </ToggleButton>
                  <ToggleButton value="3">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      W
                    </Avatar>
                  </ToggleButton>
                  <ToggleButton value="4">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      T
                    </Avatar>
                  </ToggleButton>
                  <ToggleButton value="5">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      F
                    </Avatar>
                  </ToggleButton>
                  <ToggleButton value="6">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      S
                    </Avatar>
                  </ToggleButton>
                  <ToggleButton value="0">
                    <Avatar
                      style={{ backgroundColor: 'transparent', color: 'black' }}
                    >
                      S
                    </Avatar>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            )}
            <Grid item xs={6}>
              <Select
                options={end_options}
                placeholder="End type"
                value={
                  this.props.end_method &&
                  end_options.find(f => f.value === this.props.end_method)
                }
                onChange={e =>
                  this.props.changeEventDetails('end_method', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={6}>
              {this.props.end_method === 'count' && (
                <TextField
                  id="standard-multiline-static"
                  label={`Run for x ${
                    this.props.repeat_method === 'daily' ? 'days' : 'weeks'
                  }`}
                  fullWidth
                  value={this.props.end_count}
                  type="number"
                  onChange={e =>
                    this.props.changeEventDetails('end_count', e.target.value)
                  }
                />
              )}
              {this.props.end_method === 'until' && (
                <DatePickerComponent
                  id="end_date"
                  placeholder="End date"
                  value={this.props.end_date}
                  onChange={e =>
                    this.props.changeEventDetails('end_date', e.value)
                  }
                />
              )}
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <RepeatTable />
        </Grid>
        {this.props.repeat_change && (
          <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
            <Button
              className="save-btn"
              onClick={() => this.props.calculateEventRepeat()}
            >
              <b>Check availability</b>
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.calendar.event_details,
  fields: state.calendar.fields.map(field => ({
    label: `${field.number} - ${field.name}`,
    value: field.ID,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeEventDetails: (k, v) => {
    dispatch(changeEventDetails({ field: k, value: v }))
    dispatch(notifyRepeatChange(true))
  },
  calculateEventRepeat: () => dispatch(calculateEventRepeat()),
  updateRecurringEvent: (i, k, v) =>
    dispatch(updateRecurringEvent({ i, field: k, value: v })),
  runNewCheck: () => dispatch(runNewCheck()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RepeatForm)
