import React from 'react'
import { connect } from 'react-redux'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'

import { actions } from 'resources/redux'
import { getFieldTypeNameById } from 'resources/helper'

const styles = theme => ({
    maxheight: {
      maxHeight: 650,
      overflow: 'scroll',
    },
    root: {
      padding: 2,
    },
  }),
  getAmPm = t => `${t}${t <= 12 ? 'am' : 'pm'}`

class StaffTable extends React.Component {
  render() {
    const { fields, setField, field_types } = this.props

    return (
      <div>
        close time = <b>24 is midnight</b> / close time ={' '}
        <b>0 means field closed</b>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Number
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Type
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Weekday open times
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Weekend open times
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Weekday Peak times
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Weekend Peak times
              </TableCell>
              <TableCell
                style={{ textAlign: 'center' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map(field => (
              <TableRow key={field.ID} hover style={{ cursor: 'pointer' }}>
                <TableCell style={{ textAlign: 'center' }}>
                  {field.number}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {field.name}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {getFieldTypeNameById(field_types, field.new_field_type_id)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {getAmPm(field.weekday_open_time)} -{' '}
                  {getAmPm(field.weekday_close_time)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {getAmPm(field.weekend_open_time)} -{' '}
                  {getAmPm(field.weekend_close_time)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {getAmPm(field.weekday_peak_time_start)} -{' '}
                  {getAmPm(field.weekday_peak_time_end)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {getAmPm(field.weekend_peak_time_start)} -{' '}
                  {getAmPm(field.weekend_peak_time_end)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton onClick={() => setField(field)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  fields: state.resources.fields,
  field_types: state.resources.field_types,
})

const mapDispatchToProps = dispatch => ({
  setField: f => {
    dispatch(actions.updateField({ key: 'id', value: f.ID }))
    dispatch(actions.updateField({ key: 'number', value: f.number }))
    dispatch(actions.updateField({ key: 'name', value: f.name }))
    dispatch(
      actions.updateField({
        key: 'new_field_type_id',
        value: f.new_field_type_id,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekday_open_time',
        value: f.weekday_open_time,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekday_close_time',
        value: f.weekday_close_time,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekend_open_time',
        value: f.weekend_open_time,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekend_close_time',
        value: f.weekend_close_time,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekday_peak_time_start',
        value: f.weekday_peak_time_start,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekday_peak_time_end',
        value: f.weekday_peak_time_end,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekend_peak_time_start',
        value: f.weekend_peak_time_start,
      })
    )
    dispatch(
      actions.updateField({
        key: 'weekend_peak_time_end',
        value: f.weekend_peak_time_end,
      })
    )
    dispatch(actions.updateField({ key: 'open', value: true }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StaffTable))
