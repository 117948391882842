import React, { useState } from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'

import EditScoreForm from './EditScoreForm'
import LeagueScoresRow from './LeagueScoresRow'

import {
  updateMatchScore,
  changeMatchPage,
  deleteMatch,
  updateMatchEvent,
  updateMatchDetails,
} from 'league/actions'
import { openDeleteModal } from './../../../common/actions'
import { leagueSlice } from 'league/redux'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const LeagueMatchesTable = props => {
  const { setRef, hide_column, matches, league_page, changeMatchPage } = props
  const [editing, setEdit] = useState(null)
  return (
    <Paper elevation={0}>
      <Table ref={setRef}>
        <TableHead>
          <TableRow>
            {!hide_column && (
              <TableCell
                style={{ textAlign: 'center', width: '5%' }}
                className="table-header"
              >
                Week
              </TableCell>
            )}
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Field
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Date
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Time
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '15%' }}
              className="table-header"
            >
              Home
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '15%' }}
              className="table-header"
            >
              Score
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '15%' }}
              className="table-header"
            >
              Away
            </TableCell>
            {!hide_column && (
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map(match =>
            editing === match.ID ? (
              <EditScoreForm
                setEdit={setEdit}
                key={match.ID}
                match={match}
                {...props}
              />
            ) : (
              <LeagueScoresRow
                setEdit={setEdit}
                key={match.ID}
                match={match}
                {...props}
              />
            )
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[50]}
        component="div"
        count={matches.length}
        rowsPerPage={50}
        page={league_page}
        onChangePage={(a, b) => changeMatchPage(b)}
      />
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  matches: state.league.league_matches,
  league_page: state.league.league_page,
  teams: state.league.league.teams.map(t => ({
    label: t.team.name,
    value: t.team.ID,
  })),
  fields: state.calendar.fields.map(f => ({
    value: f.ID,
    label: f.number.toString() + ' - ' + f.name,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMatchScore: (id, side, score) =>
    dispatch(updateMatchScore(id, side, score)),
  changeMatchPage: numb => dispatch(changeMatchPage(numb)),
  deleteMatch: id => dispatch(openDeleteModal('match', () => deleteMatch(id))),
  updateMatch: (id, key, value) =>
    dispatch(leagueSlice.actions.updateMatch({ id, key, value })),
  updateMatchDetails: (id, key, value) =>
    dispatch(updateMatchDetails(id, key, value)),
  updateMatchEvent: match => dispatch(updateMatchEvent(match)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LeagueMatchesTable))
