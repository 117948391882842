import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Select from 'react-select'
import {
  DatePickerComponent,
  TimePickerComponent,
} from '@syncfusion/ej2-react-calendars'

import {
  changePlayoffDetails,
  buildPlayoffs,
  runPlayoffCheck,
  editPlayoff,
  createMatchEvents,
} from './../../actions'
import { colors } from './../../../consts'

const rounds = [16, 8, 4, 2].map(r => ({ label: `Round of ${r}`, value: r }))

class PlayoffSchedule extends React.Component {
  render() {
    return (
      <div>
        {this.props.built_playoffs.length === 0 && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={4}>
              <TextField
                label="Match duration *"
                fullWidth
                variant="outlined"
                value={this.props.duration}
                onChange={e =>
                  this.props.changePlayoffDetails('duration', e.target.value)
                }
                type="number"
              />
            </Grid>
            <Grid item xs={8}>
              <Select
                options={rounds}
                placeholder="Select facility *"
                value={rounds.find(r => r.value === this.props.round)}
                onChange={e =>
                  this.props.changePlayoffDetails('round', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            {this.props.round_settings.map((r, i) => (
              <Grid key={i} item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    Round of <b>{r.level}</b>
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      options={this.props.fields}
                      placeholder="Field*"
                      value={this.props.field}
                      onChange={e =>
                        this.props.changePlayoffDetails('field', e, i)
                      }
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePickerComponent
                      id="league_start_date"
                      placeholder="Day *"
                      value={this.props.start_date}
                      onChange={e =>
                        this.props.changePlayoffDetails(
                          'start_date',
                          e.value,
                          i
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePickerComponent
                      id="start_time"
                      placeholder="Start time*"
                      value={this.props.start_time}
                      step={15}
                      onChange={e =>
                        this.props.changePlayoffDetails(
                          'start_time',
                          e.value,
                          i
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                className="save-btn"
                onClick={() => this.props.buildPlayoffs()}
              >
                Pre-Generate playoffs
              </Button>
            </Grid>
          </Grid>
        )}
        {this.props.built_playoffs.length ? (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      Round
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      Field
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      Date
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      Time
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      Home
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      Away
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.built_playoffs.map((match, i) => (
                    <TableRow
                      key={match.ID}
                      hover
                      style={{
                        cursor: 'pointer',
                        backgroundColor: match.is_ok ? '' : colors.red_mild,
                      }}
                    >
                      <TableCell style={{ textAlign: 'center', width: '20%' }}>
                        {match.level}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', width: '20% !important' }}
                      >
                        <Select
                          options={this.props.fields}
                          placeholder="Field*"
                          value={this.props.fields.find(
                            f => f.value === match.field_id
                          )}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          onChange={e =>
                            this.props.editPlayoff('field_id', e.value, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', width: '10% !important' }}
                      >
                        <DatePickerComponent
                          id="start_date"
                          placeholder="Start date *"
                          value={match.date_parsed}
                          onChange={e =>
                            this.props.editPlayoff('date_parsed', e.value, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', width: '10% !important' }}
                      >
                        <TimePickerComponent
                          id="start_time"
                          placeholder="Start time*"
                          value={match.time_parsed}
                          step={15}
                          onChange={e =>
                            this.props.editPlayoff('time_parsed', e.value, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', width: '20% !important' }}
                      >
                        <Select
                          options={this.props.teams}
                          placeholder="Home team*"
                          value={this.props.teams.find(
                            t => t.value === match.home_team_id
                          )}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          onChange={e =>
                            this.props.editPlayoff('home_team_id', e.value, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', width: '20% !important' }}
                      >
                        <Select
                          options={this.props.teams}
                          placeholder="Away team*"
                          value={this.props.teams.find(
                            t => t.value === match.away_team_id
                          )}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          onChange={e =>
                            this.props.editPlayoff('away_team_id', e.value, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', width: '20% !important' }}
                      >
                        {match.is_ok ? 'OK' : 'Taken'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            {!this.props.to_check ? (
              <Grid item xs={12}>
                <Button
                  className="save-btn"
                  onClick={() => this.props.createMatchEvents()}
                >
                  Save
                </Button>
              </Grid>
            ) : null}
            {this.props.to_check ? (
              <Button
                className="save-btn"
                onClick={() => this.props.runPlayoffCheck()}
              >
                Check availability
              </Button>
            ) : null}
          </Grid>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.new_playoffs,
  fields: state.calendar.fields.map(f => ({
    value: f.ID,
    label: f.number.toString() + ' - ' + f.name,
  })),
  teams: state.league.league.teams
    .map(t => ({
      value: t.team.ID,
      label: t.team.name,
    }))
    .concat({
      value: -1,
      label: 'Bye',
    }),
  built_playoffs: state.league.built_playoffs,
  to_check: state.league.built_playoffs.filter(m => m.to_check).length !== 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changePlayoffDetails: (key, value, i) =>
    dispatch(changePlayoffDetails(key, value, i)),
  editPlayoff: (key, value, i) => dispatch(editPlayoff(key, value, i)),
  buildPlayoffs: () => dispatch(buildPlayoffs()),
  runPlayoffCheck: () => dispatch(runPlayoffCheck()),
  createMatchEvents: () => dispatch(createMatchEvents()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayoffSchedule)
