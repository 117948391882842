import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CalendarIcon from '@material-ui/icons/DateRange'
import ReceiptIcon from '@material-ui/icons/Receipt'

import { actions } from 'contract/redux'

class Details extends React.Component {
  render() {
    return (
      <Paper square style={{ marginBottom: '2em' }} elevation={0}>
        <Tabs
          value={this.props.tab}
          onChange={(a, b) => this.props.changeTab(b)}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          <Tab icon={<CalendarIcon />} label="Events" value="events" />
          <Tab icon={<ReceiptIcon />} label="Invoice" value="invoice" />
        </Tabs>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.contract.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTab: tab => dispatch(actions.changeTab({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
