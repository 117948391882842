import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'

import { updateMatchIds } from '../actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class GameList extends React.Component {
  render() {
    const { classes, matches, matchIds, updateMatchIds } = this.props

    const isSelected = id => matchIds.indexOf(id) !== -1

    return (
      <div>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '5%' }}
                  className="table-header"
                >
                  Select
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Facility
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  League
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '25%' }}
                  className="table-header"
                >
                  Match
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Referee
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {matches
                  .sort((a, b) => a.event.start_date - b.event.start_date)
                  .map((match, index) => {
                    const isItemSelected = isSelected(match.ID)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        onClick={() =>
                          updateMatchIds(
                            match.ID,
                            match?.event?.field?.facility?.ID
                          )
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={match.ID}
                        selected={isItemSelected}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell style={{ width: '5%' }} padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', width: '20%' }}
                        >
                          {match?.event?.field?.number}-
                          {match?.event?.field?.name}
                          <br />
                          <small>{match?.event?.field?.facility?.name}</small>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', width: '20%' }}
                        >
                          <Link
                            href={`/league/${match.league.ID}`}
                            target="_blank"
                          >
                            {match.league.name}
                          </Link>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', width: '25%' }}
                        >{`${match.home_team.name} - ${match.away_team.name}`}</TableCell>
                        <TableCell
                          style={{ textAlign: 'center', width: '20%' }}
                        >
                          {`${match.referee.first_name} ${match.referee.last_name}`}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', width: '10%' }}
                        >
                          <b>{match.time_parsed}</b>
                          <br />
                          {match.date_parsed}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  matches: state.referee.matches.filter(match => {
    let ok = true
    if (state.referee.search.field_number) {
      if (match.event.field.number !== state.referee.search.field_number) {
        ok = false
      }
    }
    if (state.referee.search.assigned_type) {
      if (state.referee.search.assigned_type === 'no') {
        return ok && !match.referee.ID
      }
      return ok && match.referee.ID
    }
    return ok
  }),
  matchIds: state.referee.matchIds,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMatchIds: (id, facility_id) =>
    dispatch(updateMatchIds(id, facility_id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GameList))
