import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/Description'

import { niceNumber, exportToCSV, unixToDate } from 'helper'
import { colors } from 'consts'
import { changeLoading } from 'common/actions'
import { buildCustomerHistory } from 'customer/helper'

const History = props => {
  const { invoices, product_stats, can_export } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      {can_export ? (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            className="save-btn"
            style={{ marginLeft: 10 }}
            onClick={() => {
              changeLoading(true)
              buildCustomerHistory(invoices)
                .then(history_items => {
                  exportToCSV(history_items, 'History')
                  changeLoading(false)
                })
                .catch(err => {
                  console.error(err)
                  changeLoading(false)
                })
            }}
          >
            Export customer history
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            {product_stats.map((product, index) => (
              <Grid item xs={3} style={{ textAlign: 'center' }} key={index}>
                <Typography variant="h6" gutterBottom>
                  <b style={{ color: colors.purple_strong }}>{product.value}</b>{' '}
                  {product.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Product
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Details
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Invoiced
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Balance
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map(invoice => (
                <TableRow key={invoice.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'center' }}
                  >
                    <b>{invoice.product_type.name}</b>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {invoice.date_parsed}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {invoice.comment}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    ${niceNumber(invoice.invoiced)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    ${niceNumber(invoice.balance)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {invoice.contract.ID ? (
                      <Link
                        href={`/contract/${invoice.contract.ID}`}
                        target="_blank"
                      >
                        <IconButton style={{ padding: 1 }}>
                          <FileCopyIcon
                            fontSize="small"
                            style={{
                              color: colors.blue_strong,
                            }}
                          />
                        </IconButton>
                      </Link>
                    ) : (
                      <Link href={`/invoice/${invoice.ID}`} target="_blank">
                        <IconButton style={{ padding: 1 }}>
                          <FileCopyIcon
                            fontSize="small"
                            style={{
                              color: colors.blue_strong,
                            }}
                          />
                        </IconButton>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  let p_map = {}
  const invoices = state.customer.invoices.map(invoice => {
    const p_type = invoice.product_type.name
    if (!(p_type in p_map)) {
      p_map[p_type] = 0
    }
    p_map[p_type] += 1
    return {
      ...invoice,
      date_parsed: unixToDate(invoice.date).format('MM/DD/YYYY'),
    }
  })
  const product_stats = Object.keys(p_map).map(key => ({
    name: key,
    value: p_map[key],
  }))
  return {
    invoices,
    product_stats,
    can_export:
      state.user.user_type === 'admin' || state.user.user_type === 'manager',
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLoading: loading => dispatch(dispatch(changeLoading(loading))),
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
