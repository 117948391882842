import { SCOREBOARDS_SERVER_URL } from './../consts'
import axios from 'axios'

export const filterScoreboards = (scoreboards, filters) => {
  return scoreboards
    .filter(scoreboard => {
      if ((filters.facilities || []).length === 0) return scoreboard
      let ok = false
      filters.facilities.forEach(facility => {
        if (scoreboard.facility.ID === facility.value) {
          ok = true
        }
      })
      return ok
    })
    .filter(scoreboard => {
      if (!filters.status) return scoreboard
      return scoreboard.connected === filters.status.value
    })
}

export const sendCommand = (scoreboard_port, command) => {
  return axios.post(SCOREBOARDS_SERVER_URL, {
    id: scoreboard_port.toString(),
    action: command,
    origin: 'scoreboard',
  })
}
