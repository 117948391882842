import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/Description'
import { openDeleteModal } from './../../common/actions'

import { colors } from 'consts'
import { getSlots } from 'pickup/actions'
import { deleteContract } from 'contract/actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class SessionsTable extends React.Component {
  componentDidMount() {
    this.props.getSlots()
  }

  render() {
    const { classes, sessions } = this.props

    return (
      <div>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '25%' }}
                  className="table-header"
                >
                  No
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Slots
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '30%' }}
                  className="table-header"
                >
                  Details
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {sessions.map(session => (
                  <TableRow key={session.ID}>
                    <TableCell style={{ textAlign: 'center', width: '25%' }}>
                      {session.ID}
                      <br />
                      <small>{session.facility.name}</small>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {session.events.length}
                    </TableCell>
                    {session.slot ? (
                      <TableCell style={{ textAlign: 'center', width: '30%' }}>
                        {session.slot.details}
                        <br />
                        <small>{session.slot.field}</small>
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      <Link href={`/contract/${session.ID}`}>
                        <IconButton style={{ padding: 5 }}>
                          <FileCopyIcon
                            fontSize="small"
                            style={{
                              color: colors.blue_strong,
                            }}
                          />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  sessions: state.pickup.sessions
    .filter(s => {
      if (state.pickup.filter.facility_id) {
        return s.facility_id === state.pickup.filter.facility_id
      }
      return true
    })
    .filter(s => {
      if (state.pickup.filter.day_of_week) {
        return s.slot.day === state.pickup.filter.day_of_week
      }
      return true
    }),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteSession: id =>
    dispatch(
      openDeleteModal('contract (and all the events it may include)', () =>
        deleteContract(id)
      )
    ),
  getSlots: () => dispatch(getSlots()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SessionsTable))
