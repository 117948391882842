import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import TextField from 'common/components/FastTextField'
import Grid from '@material-ui/core/Grid'
import { CirclePicker } from 'react-color'

import { actions } from 'teams/redux'

class LeagueForm extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        {!this.props.hide_facility && (
          <Grid item xs={12}>
            <Select
              options={this.props.facilities}
              placeholder="Select facility *"
              value={
                this.props.facility_id &&
                this.props.facilities.find(
                  f => f.value === this.props.facility_id
                )
              }
              onChange={e =>
                this.props.changeTeamDetails('facility_id', e.value)
              }
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label="Name *"
            fullWidth
            variant="outlined"
            value={this.props.name}
            onChange={e => this.props.changeTeamDetails('name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CirclePicker
            colors={[
              '#f44336',
              '#9c27b0',
              '#2196f3',
              '#8bc34a',
              '#ff9800',
              '#ffeb3b',
              '#607d8b',
              '#000000',
              '#FBF2FC',
            ]}
            width="100%"
            color={this.props.color}
            onChange={c => this.props.changeTeamDetails('color', c.hex)}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.teams.team_form,
  facilities: state.common.facilities.map(f => ({
    label: f.name,
    value: f.ID,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTeamDetails: (k, v) =>
    dispatch(actions.editTeamDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueForm)
