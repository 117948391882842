import moment from 'moment'
import api from 'api'

import { changeLoading, dispatchError } from 'common/actions'
import { actions } from 'pickup/redux'
import { unixToDateTime, momentDateTimeToUnix, momentDateToUnix } from 'helper'

const _getSlots = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.pickup
    .get()
    .then(sessions => {
      dispatch(actions.getSlots({ sessions }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getSlots = () => (dispatch, getState) => dispatch(_getSlots())

export const createSession = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const event_details = getState().calendar.event_details,
    { fee_id } = getState().pickup
  if (!fee_id)
    return dispatch(dispatchError({ message: 'Please select a valid fee' }))
  return createContract(event_details.facility_id, fee_id)
    .then(contract_id => {
      return saveEvents(event_details, contract_id).then(() => {
        return createInvoice(contract_id, event_details.facility_id)
      })
    })
    .then(() => {
      dispatch(_getSlots())
      dispatch(actions.toggleCreationModal({ open: false }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const createContract = (facility_id, fee_id) => {
  const spl = fee_id.split(';'),
    product_id = parseInt(spl[0]),
    fee = parseFloat(spl[1])
  let body = {
    facility_id,
    name: `Pickup session @${facility_id}`,
    product_type_id: 5,
    product_id,
    fee,
    deposit: 0.0,
  }
  return api.contract.create(body).then(contract => contract.ID)
}

const saveEvents = (event_details, contract_id) => {
  const moment_start = unixToDateTime(event_details.start_time),
    moment_end = unixToDateTime(event_details.end_time),
    comment = `Pickup session #${contract_id}`
  let bodies = []
  if (!event_details.repeat_events.length) {
    // Single event, otherwise it's already included in the repeat events
    bodies = [
      {
        field_id: event_details.field_id,
        product_type_id: event_details.product_type_id,
        start_date: momentDateTimeToUnix(moment_start),
        end_date: momentDateTimeToUnix(moment_end),
        comment,
        contract_id,
      },
    ]
  }
  if (event_details.repeat_events.filter(e => !e.is_ok).length > 0) {
    return Promise.reject('Please check that all events have free spots')
  }
  event_details.repeat_events.forEach(event => {
    bodies.push({
      field_id: event.field_id,
      product_type_id: event.product_type_id,
      start_date: event.start_date,
      end_date: event.end_date,
      comment,
      contract_id,
    })
  })
  return Promise.all(
    bodies.map(body => {
      return api.event.create(body)
    })
  )
}

const createInvoice = (contract_id, facility_id) => {
  const body = {
    date: momentDateToUnix(moment()),
    customer_id: 0,
    invoice_type_id: 1,
    facility_id,
    contract_id,
    comment: `pickup_${contract_id}`,
    product_type_id: 5,
  }
  return api.invoice.create(body)
}

export const getPickupPlayers =
  (start_date, end_date) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    let facility = 0
    const { facility_id, user_type } = getState().user
    if (user_type !== 'admin') {
      facility = facility_id
    }
    return api.pickup
      .getPlayers(facility, start_date, end_date || start_date + 86400)
      .then(players => {
        dispatch(actions.setPickupPlayers({ players }))
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const checkIn = (id, checked_in) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.event
    .checkInPickupPlayers(id, checked_in)
    .then(pickup_players => {
      dispatch(changeLoading(false))
      dispatch(actions.checkIn({ id, checked_in }))
    })
    .catch(e => dispatch(dispatchError(e)))
}
