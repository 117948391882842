import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

import { closeDeleteModal } from './../actions'

class DeleteDialog extends Component {
  render() {
    const { open, handleClose, deleteAndClose, name } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999 }}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this {name} ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => deleteAndClose(this.props.handleDelete)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  ...state.common.delete_dialog,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(closeDeleteModal()),
  deleteAndClose: deleteFunc => {
    dispatch(deleteFunc())
    dispatch(closeDeleteModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog)
