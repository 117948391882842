import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import GroupIcon from '@material-ui/icons/Group'
import Chip from '@material-ui/core/Chip'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Checkbox from '@material-ui/core/Checkbox'
import { Button } from '@material-ui/core'

import HubspotListDialog from 'common/components/HubspotListDialog'

import { applyFilter } from './../../helper'
import { colors } from './../../consts'
import { openHubspotList } from 'common/actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const TeamsTable = ({ classes, openHubspotList, teams }) => {
  const [checked, setChecked] = React.useState({})
  return (
    <div>
      <HubspotListDialog />
      <Button
        onClick={() =>
          openHubspotList(Object.keys(checked).filter(k => checked[k]))
        }
      >
        Export hubspot
      </Button>
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Captain
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Age group
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Facility
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                # of players
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div className={classes.maxheight}>
          <Table aria-label="simple table">
            <TableBody>
              {teams.map(team => (
                <TableRow key={team.ID} hover style={{ cursor: 'pointer' }}>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Checkbox
                      checked={checked[team.ID]}
                      onChange={e => {
                        if (e.target.checked) {
                          setChecked({ ...checked, [team.ID]: true })
                        } else {
                          setChecked({ ...checked, [team.ID]: false })
                        }
                      }}
                      name="hubspot"
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    {team.name}
                    <br />
                    <small>{team.current_league?.name}</small>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    {team.captain && (
                      <div>
                        {team.captain.first_name} {team.captain.last_name}
                        <Link
                          href={`/customer/${team.captain.ID}`}
                          target="_blank"
                        >
                          <OpenInNewIcon style={{ fontSize: 15 }} />
                        </Link>
                        <br />
                        <small>{team.captain.email}</small>
                      </div>
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    {team.age_group}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    <Chip
                      size="small"
                      label={<b>{team.facility.name}</b>}
                      style={{
                        color: colors.purple_strong,
                        backgroundColor: colors.purple_mild,
                        margin: 5,
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    {team.roster.length}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    <Link href={`/team/${team.ID}`} target="_blank">
                      <IconButton className={classes.root}>
                        <GroupIcon
                          fontSize="small"
                          style={{
                            color: colors.blue_strong,
                          }}
                        />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  teams: state.teams.teams
    .filter(t => applyFilter(t, state.teams.filter.query, ['name']))
    .filter(t => {
      if (
        state.teams.filter.facility_id &&
        t.facility_id !== state.teams.filter.facility_id
      ) {
        return false
      }
      return true
    })
    .filter(t => {
      if (
        state.teams.filter.age_group &&
        t.age_group !== state.teams.filter.age_group
      ) {
        return false
      }
      return true
    }),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoTeam: id => dispatch(push('/team/' + id)),
  openHubspotList: team_ids => dispatch(openHubspotList({ team_ids })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamsTable))
