import React from 'react'
import { connect } from 'react-redux'

import { IconButton, Link, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { colors } from 'consts'
import { openDeleteModal } from 'common/actions'
import { deleteInvoiceItem } from 'invoice/actions'
import { actions } from 'invoice/redux'

const InvoiceItemDetails = ({
  deleteInvoiceItem,
  has_payments,
  invoice_item,
  invoice,
  is_admin,
  is_contract,
  is_manager,
  read_only,
  show_event,
  updateFee,
}) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <div>
            {invoice_item.new_product_id
              ? `${invoice_item.new_product.name}`
              : `Manual Fee`}
          </div>
          {invoice.team_id ? (
            <>
              <br />
              <small>
                Team{' '}
                <Link href={`/team/${invoice.team.ID}`} target="_blank">
                  {invoice.team.name}
                </Link>
              </small>
            </>
          ) : null}
          {show_event && invoice_item.event_id ? (
            <div>
              <br />
              <Link
                href={`/calendar/event/${invoice_item.event_id}`}
                target="_blank"
              >
                event {invoice_item.event_id}
              </Link>
            </div>
          ) : null}
        </TableCell>
        <TableCell align="center">{invoice_item.quantity}</TableCell>
        <TableCell align="center">
          ${invoice_item.price}{' '}
          {(is_admin || is_manager) && (
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => updateFee(invoice_item.ID, invoice_item.price)}
            >
              edit
            </u>
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'center', width: '5% !important' }}>
          {read_only || is_contract ? null : (
            <div>
              {is_admin && !has_payments ? (
                <IconButton
                  onClick={() => deleteInvoiceItem(invoice_item.ID)}
                  style={{ padding: 5 }}
                >
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      color: colors.red_strong,
                    }}
                  />
                </IconButton>
              ) : null}
            </div>
          )}
        </TableCell>
      </TableRow>
      {invoice_item.fees?.map((fee, index) => (
        <TableRow key={index}>
          <TableCell />
          <TableCell colSpan={1}>Included Fees</TableCell>
          <TableCell align="center" colSpan={1}>
            {fee.value}%
          </TableCell>
          <TableCell />
        </TableRow>
      ))}
      {invoice_item.taxes?.map((tax, index) => (
        <TableRow key={index}>
          <TableCell />
          <TableCell colSpan={1}>Taxes</TableCell>
          <TableCell align="center" colSpan={1}>
            {tax.value}%
          </TableCell>
          <TableCell />
        </TableRow>
      ))}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  invoice: state.invoice.invoice,
  is_admin: state.user.is_admin,
  is_manager: state.user.user_type === 'manager',
  has_payments:
    state.invoice.invoice && state.invoice.invoice.payments.length > 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteInvoiceItem: id =>
    dispatch(openDeleteModal('invoice item', () => deleteInvoiceItem(id))),
  updateFee: (invoice_item_id, price) =>
    dispatch(actions.updateFee({ open: true, invoice_item_id, price })),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceItemDetails)
