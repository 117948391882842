import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import FastTextField from 'common/components/FastTextField'

import { saveField } from 'resources/actions'
import { actions } from 'resources/redux'
import { getFieldTypeNameById } from 'resources/helper'

const NewProductDialog = () => {
  const {
      open,
      number,
      name,
      new_field_type_id,
      weekday_open_time,
      weekday_close_time,
      weekend_open_time,
      weekend_close_time,
      weekday_peak_time_start,
      weekday_peak_time_end,
      weekend_peak_time_start,
      weekend_peak_time_end,
    } = useSelector(state => state.resources.field),
    dispatch = useDispatch(),
    updateField = (k, v) => dispatch(actions.updateField({ key: k, value: v })),
    handleClose = () => updateField('open', false),
    _saveField = () => dispatch(saveField()),
    field_types = useSelector(state => state.resources.field_types)
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create field
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={6}>
            <FastTextField
              label="Number *"
              variant="outlined"
              value={number}
              onChange={e => updateField('number', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Name *"
              variant="outlined"
              value={name}
              onChange={e => updateField('name', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={field_types.map(field_type => ({
                value: field_type.ID,
                label: field_type.name,
              }))}
              placeholder="Field type"
              value={{
                value: new_field_type_id,
                label: getFieldTypeNameById(field_types, new_field_type_id),
              }}
              onChange={e => updateField('new_field_type_id', e.value)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekday open time *"
              variant="outlined"
              value={weekday_open_time}
              onChange={e => updateField('weekday_open_time', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekday close time *"
              variant="outlined"
              value={weekday_close_time}
              onChange={e => updateField('weekday_close_time', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekend open time *"
              variant="outlined"
              value={weekend_open_time}
              onChange={e => updateField('weekend_open_time', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekend close time *"
              variant="outlined"
              value={weekend_close_time}
              onChange={e => updateField('weekend_close_time', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekday peak time start *"
              variant="outlined"
              value={weekday_peak_time_start}
              onChange={e =>
                updateField('weekday_peak_time_start', e.target.value)
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekday peak time end *"
              variant="outlined"
              value={weekday_peak_time_end}
              onChange={e =>
                updateField('weekday_peak_time_end', e.target.value)
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekend peak time start *"
              variant="outlined"
              value={weekend_peak_time_start}
              onChange={e =>
                updateField('weekend_peak_time_start', e.target.value)
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FastTextField
              label="Weekend peak time end *"
              variant="outlined"
              value={weekend_peak_time_end}
              onChange={e =>
                updateField('weekend_peak_time_end', e.target.value)
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => _saveField()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewProductDialog
