import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import LocationIcon from '@material-ui/icons/LocationOn'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import TeamMenu from './../components/TeamMenu'
import PlayersPage from './../components/details/Players'
import StandingsPage from './../components/standings/StandingsPage'
import InvoiceList from './../components/InvoiceTable'
import AddPlayerDialog from './../components/forms/AddPlayer'
import EditTeamNameDialog from './../components/forms/EditTeamNameDialog'
import ChangeLeagueDialog from './../components/ChangeLeagueDialog'
import ChangeTeamDialog from './../components/ChangeTeamDialog'
import BroadcastMessage from './../components/BroadcastMessage'

import { colors } from 'consts'
import { getTeamDetails } from './../actions'
import { actions } from 'teams/redux'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class Details extends React.Component {
  componentDidMount() {
    this.props.getTeamDetails(this.props.match.params.id)
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <AddPlayerDialog />
        <ChangeLeagueDialog />
        <EditTeamNameDialog />
        <ChangeTeamDialog />
        <BroadcastMessage />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '1%' }}
          spacing={4}
        >
          {this.props.team && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h3">
                {this.props.team.name}
                {this.props.is_admin_or_manager && (
                  <IconButton
                    className={classes.root}
                    onClick={() =>
                      this.props.toggleChangeTeamNameModal(this.props.team.name)
                    }
                  >
                    <EditIcon
                      style={{ color: colors.blue_strong }}
                      fontSize="small"
                    />
                  </IconButton>
                )}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <LocationIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.team.facility.name}
              </Typography>
              <Typography>
                <Link
                  href={`https://webapp.sofive.com/team.html?team_id=${this.props.team.ID}`}
                  target="_blank"
                >
                  <Button style={{ margin: 10 }} className="save-btn">
                    View Team
                  </Button>
                </Link>
              </Typography>
            </Grid>
          )}
          {this.props.team && (
            <Grid item xs={12}>
              <TeamMenu />
            </Grid>
          )}
          {this.props.team && (
            <Grid item xs={12}>
              {this.props.team_tab === 'players' && <PlayersPage />}
              {this.props.team_tab === 'standings' && <StandingsPage />}
              {this.props.team_tab === 'invoices' && <InvoiceList />}
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  team: state.teams.team,
  team_tab: state.teams.team_tab,
  is_admin_or_manager: state.user.is_admin_or_manager,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTeamDetails: id => dispatch(getTeamDetails(id)),
  toggleChangeTeamNameModal: name =>
    dispatch(actions.toggleChangeTeamNameModal({ name, open: true })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Details))
