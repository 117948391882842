import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from 'react-select'

import { actions } from './../redux'

const ResourceDropdown = ({
  facilities,
  facility_id,
  changeFacility,
  openDialog,
}) => {
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={facilities}
            placeholder="Facility"
            value={facility_id && facilities.find(p => p.value === facility_id)}
            onChange={v => changeFacility(v.value)}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  admin: state.user.admin,
  facilities: state.common.filter_facilities,
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFacility: facility_id =>
    dispatch(
      actions.updateState({
        key: 'facility_id',
        value: facility_id,
      })
    ),
  openDialog: resource_type =>
    dispatch(
      actions.updateField({
        key: 'open',
        value: true,
        reset: true,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
