import React from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { unixToDate, unixToTime } from '../../../helper'
import { colors } from '../../../consts'

const LeagueScoresRow = ({
  match,
  hide_column,
  updateMatchEvent,
  deleteMatch,
  setEdit,
}) => {
  return (
    <TableRow
      hover
      style={{
        cursor: 'pointer',
        backgroundColor:
          match.match_type === 'friendly'
            ? 'honeydew'
            : match.changed
            ? colors.yellow_mild
            : null,
      }}
    >
      {!hide_column && (
        <TableCell style={{ textAlign: 'center', width: '5% !important' }}>
          {match.round ? match.round : match.week}
          {match.match_type === 'friendly' && (
            <>
              <br />
              Friendly
            </>
          )}
        </TableCell>
      )}
      <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
        {!match.is_bye &&
          `${match.event?.field?.number} - ${match.event?.field?.name}`}
      </TableCell>
      <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
        {match.is_bye
          ? 'BYE'
          : unixToDate(match.event?.start_date).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
        {match.is_bye
          ? null
          : unixToTime(match.event?.start_date).format('h:mma')}
      </TableCell>
      <TableCell style={{ textAlign: 'center', width: '15% !important' }}>
        {match.home_team.name || 'TBD'}
      </TableCell>
      <TableCell style={{ textAlign: 'center', width: '15% !important' }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={2}
        >
          <Grid item xs={5} style={{ textAlign: 'center' }}>
            {match.home_score}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'center' }}>
            -
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'center' }}>
            {match.away_score}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {match.match_type}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell style={{ textAlign: 'center', width: '15% !important' }}>
        {match.away_team.name || 'TBD'}
      </TableCell>
      {!hide_column && (
        <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
          {match.changed ? (
            <Button
              className="save-btn"
              onClick={() => updateMatchEvent(match)}
            >
              Check & Save
            </Button>
          ) : (
            <>
              <IconButton onClick={() => setEdit(match.ID)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              <IconButton onClick={() => deleteMatch(match.ID)}>
                <DeleteIcon
                  color="secondary"
                  fontSize="small"
                  style={{ color: colors.red_strong }}
                />
              </IconButton>
            </>
          )}
        </TableCell>
      )}
    </TableRow>
  )
}

export default LeagueScoresRow
