import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import { colors } from './../../../consts'
import { leagueSlice } from 'league/redux'
import { openHubspotList } from 'common/actions'

class LeagueSummary extends React.Component {
  render() {
    return (
      <div>
        <Paper elevation={0} style={{ padding: '4%' }}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="h5">Summary</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total due:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                <Chip
                  size="small"
                  label={
                    <b>${Math.round(this.props.league.balance * 100) / 100}</b>
                  }
                  style={{
                    color:
                      this.props.league.balance > 0
                        ? colors.red_strong
                        : colors.green_strong,
                    backgroundColor:
                      this.props.league.balance > 0
                        ? colors.red_mild
                        : colors.green_mild,
                    margin: 5,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Teams:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {this.props.league.is_e_league
                  ? this.props.league.e_players.length
                  : this.props.league.teams.length}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total expected:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                ${this.props.league.invoiced}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total paid:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                ${this.props.league.paid}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {!this.props.hide_broadcast && (
          <Button
            className="save-btn"
            style={{ marginTop: 20 }}
            onClick={() => this.props.openBroadcast()}
          >
            Broadcast message
          </Button>
        )}
        <Grid item xs={12}>
          <Button
            className="save-btn"
            style={{ marginTop: 20 }}
            onClick={() => this.props.openHubspotList(this.props.league.ID)}
          >
            Create hubspot list
          </Button>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  league: state.league.league,
  teams: state.league.league.teams,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openBroadcast: () =>
    dispatch(
      leagueSlice.actions.updateBroadcastMessage({ key: 'open', value: true })
    ),
  openHubspotList: league_id =>
    dispatch(openHubspotList({ league_ids: [league_id] })),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueSummary)
