import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class TeamGamesTable extends React.Component {
  render() {
    const { matches, teams } = this.props
    let team_map = {}
    teams.forEach(t => {
      team_map[t.ID] = t.name
    })
    let teamStatsMap = {},
      played_weeks = {}
    for (let i = 0; i < matches.length; i++) {
      const homeTeam = matches[i].home_team_id,
        awayTeam = matches[i].away_team_id,
        week = matches[i].week
      played_weeks[week] = true
      if (!(homeTeam in teamStatsMap)) {
        teamStatsMap[homeTeam] = {
          team_id: homeTeam,
          games: 0,
          home: 0,
          away: 0,
          week_map: {},
          game_map: {},
        }
      }
      if (!(awayTeam in teamStatsMap)) {
        teamStatsMap[awayTeam] = {
          team_id: awayTeam,
          games: 0,
          home: 0,
          away: 0,
          week_map: {},
          game_map: {},
        }
      }
      teamStatsMap[homeTeam].games += 1
      teamStatsMap[homeTeam].home += 1
      if (!(week in teamStatsMap[homeTeam].week_map)) {
        teamStatsMap[homeTeam].week_map[week] = 0
      }
      teamStatsMap[homeTeam].week_map[week] += 1
      if (!(awayTeam in teamStatsMap[homeTeam].game_map)) {
        teamStatsMap[homeTeam].game_map[awayTeam] = 0
      }
      teamStatsMap[homeTeam].game_map[awayTeam] += 1
      teamStatsMap[awayTeam].games += 1
      teamStatsMap[awayTeam].away += 1
      if (!(week in teamStatsMap[awayTeam].week_map)) {
        teamStatsMap[awayTeam].week_map[week] = 0
      }
      teamStatsMap[awayTeam].week_map[week] += 1
      if (!(homeTeam in teamStatsMap[awayTeam].game_map)) {
        teamStatsMap[awayTeam].game_map[homeTeam] = 0
      }
      teamStatsMap[awayTeam].game_map[homeTeam] += 1
    }
    let team_stats = []
    Object.keys(teamStatsMap).forEach(team => {
      const team_details = teamStatsMap[team]
      let no_play_weeks = 0,
        double_play_weeks = 0
      Object.keys(played_weeks).forEach(week => {
        if (!(week in team_details.week_map)) {
          no_play_weeks += 1
        } else {
          if (team_details.week_map[week] > 1) {
            double_play_weeks += 1
          }
        }
      })
      team_stats.push({
        ...team_details,
        no_play_weeks,
        double_play_weeks,
        team: team_map[team],
      })
    })
    const team_header = Object.keys(teamStatsMap)
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={6}>
          <Paper elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ textAlign: 'center', width: '10%' }}
                    className="table-header"
                  >
                    Team
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20%' }}
                    className="table-header"
                  >
                    Byes
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '10%' }}
                    className="table-header"
                  >
                    DH
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20%' }}
                    className="table-header"
                  >
                    Home
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20%' }}
                    className="table-header"
                  >
                    Away
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20%' }}
                    className="table-header"
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {team_stats.map(team => (
                  <TableRow
                    key={JSON.stringify(team)}
                    hover
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {team.team}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {team.no_play_weeks}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {team.double_play_weeks}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {team.home}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {team.away}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {team.games}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          {!this.props.is_e_league && (
            <Paper elevation={0} style={{ overflow: 'scroll' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ textAlign: 'center' }}
                      className="table-header"
                    >
                      <b># games</b>
                    </TableCell>
                    {team_header.map((team, i) => (
                      <TableCell key={i} style={{ textAlign: 'center' }}>
                        {team_map[team]}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {team_header.map((team, i) => (
                    <TableRow key={i} style={{ textAlign: 'center' }}>
                      <TableCell style={{ textAlign: 'center' }}>
                        {team_map[team]}
                      </TableCell>
                      {team_header.map((vs_team, i) => (
                        <TableCell key={i} style={{ textAlign: 'center' }}>
                          {teamStatsMap[team].game_map[vs_team]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    is_e_league: state.league.league.is_e_league,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamGamesTable))
