import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CustomerSection from 'customer/components/CustomerSection'

import { addPlayer } from './../../actions'
import { searchCustomers } from './../../../calendar/actions'
import { actions } from 'teams/redux'

class TeamDialog extends React.Component {
  render() {
    const { open, handleClose, addPlayer } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
          New Player
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the customer you wish to add to the team.
          </DialogContentText>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <CustomerSection />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              addPlayer()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.teams.new_player_dialog_open,
  customers: state.calendar.customers,
  new_player: state.teams.team_form.new_player,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.togglePlayerModal({ open: false })),
  addPlayer: () => dispatch(addPlayer()),
  searchCustomers: q => dispatch(searchCustomers(q)),
  changeTeamDetails: (k, v) =>
    dispatch(actions.editTeamDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamDialog)
