import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'

import { updateMatchScore, changeMatchPage } from './../../actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class ELeagueMatchesTable extends React.Component {
  buildMatchURL(id, token, isHome) {
    let h = 0
    if (isHome) {
      h = 1
    }
    return `https://events.sofive.com/fifa/match?match_id=${id}&token=${token}&h=${h}`
  }

  render() {
    return (
      <Paper elevation={2}>
        <Table ref={this.props.setRef}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Round
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Date
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Time
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                Home
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                Score
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                Away
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.matches.map(match => (
              <TableRow key={match.ID} hover style={{ cursor: 'pointer' }}>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {match.level
                    ? 'Round of ' + match.level.toString()
                    : 'Championship'}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {match.e_league_date.format('M/D/YYYY')}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {match.e_league_time.format('h:mA')}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {match.home_team
                    ? match.home_team.name
                    : match.home_player.console_id}
                  <IconButton
                    onClick={() => {
                      const url = this.buildMatchURL(
                        match.ID,
                        match.token,
                        true
                      )
                      navigator.clipboard.writeText(url)
                    }}
                  >
                    <SportsEsportsIcon color="primary" />
                  </IconButton>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ textAlign: 'center' }}
                    spacing={4}
                  >
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <TextField
                        label="HScore"
                        fullWidth
                        dense
                        value={match.home_score}
                        onChange={e =>
                          this.props.updateMatchScore(
                            match.ID,
                            'home_score',
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <TextField
                        label="VScore"
                        fullWidth
                        dense
                        value={match.away_score}
                        onChange={e =>
                          this.props.updateMatchScore(
                            match.ID,
                            'away_score',
                            e.target.value
                          )
                        }
                        onBlur={console.log}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {match.away_team
                    ? match.away_team.name
                    : match.away_player.console_id}
                  <IconButton
                    onClick={() => {
                      const url = this.buildMatchURL(
                        match.ID,
                        match.token,
                        false
                      )
                      navigator.clipboard.writeText(url)
                    }}
                  >
                    <SportsEsportsIcon color="secondary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50]}
          component="div"
          count={1040}
          rowsPerPage={50}
          page={this.props.league_page}
          onChangePage={(a, b) => this.props.changeMatchPage(b)}
        />
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  matches: state.league.player_matches_dialog_open
    ? state.league.player_matches
    : state.league.league_matches,
  league_page: state.league.league_page,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMatchScore: (id, side, score) =>
    dispatch(updateMatchScore(id, side, score)),
  changeMatchPage: numb => dispatch(changeMatchPage(numb)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ELeagueMatchesTable))
