import axios from 'axios'
import api from 'api'

import { ERP_BACKEND_URL, ERP_BACKEND_NO_USER_URL } from './../consts'
import { actions } from './redux'
import { changeLoading, dispatchError, getFacilities } from 'common/actions'

const updateFacilityID = id => dispatch => {
  localStorage.setItem('facility_id', id)
  dispatch(actions.changeFacility({ facility_id: id }))
}

export const checkLogin = () => dispatch => {
  return axios
    .get(ERP_BACKEND_URL + `/check`)
    .then(() => {
      let facility_id = localStorage.getItem('facility_id') || 1
      dispatch(updateFacilityID(parseInt(facility_id)))
      const user_type = localStorage.getItem('user_type')
      dispatch(actions.changeUserType({ user_type }))
      api.setUserDetails(user_type, parseInt(facility_id))
      dispatch(actions.changeAppState({ ready: true }))
      dispatch(getFacilities())
    })
    .catch(e => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.indexOf('code=401') !== -1
      ) {
        return (window.location = '/login')
      }
      return dispatch(dispatchError(e))
    })
}

export const loginUser = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const details = getState().user.form,
    url = ERP_BACKEND_NO_USER_URL + `/user/login`,
    body = {
      email: details.email,
      password: details.password,
      user_type: details.user_type,
    }
  return axios
    .post(url, body)
    .then(resp => {
      if (resp.data.errors) {
        return Promise.reject({ message: resp.data.errors.msg })
      }
      localStorage.setItem('auth_token', resp.data.token)
      localStorage.setItem('user_type', resp.data.type)
      localStorage.setItem('facility_id', resp.data.facility_id || 1)
      window.location = '/'
    })
    .catch(e =>
      dispatch(dispatchError({ message: 'Wrong email/password combination' }))
    )
}

export const confirmUser = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const details = getState().user.form
  if (
    !details.first_name ||
    !details.last_name ||
    !details.phone ||
    !details.password ||
    !details.confirm_password
  ) {
    return dispatch(
      dispatchError({ message: 'Please fill out all the fields' })
    )
  }
  if (details.password !== details.confirm_password) {
    return dispatch(dispatchError({ message: 'Passwords do not match' }))
  }
  const url = ERP_BACKEND_NO_USER_URL + `/user/confirm`,
    body = {
      id: details.user_id,
      token: details.token,
      first_name: details.first_name,
      last_name: details.last_name,
      phone: details.phone,
      password: details.password,
    }
  return axios
    .post(url, body)
    .then(resp => {
      if (resp.data.errors) {
        return Promise.reject({ message: resp.data.errors.msg })
      }
      if (!resp.data.token) {
        return dispatch(dispatchError({ message: 'Error signing up' }))
      }
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + resp.data.token
      localStorage.setItem('auth_token', resp.data.token)
      localStorage.setItem('user_type', resp.data.type)
      if (resp.data.facility_id) {
        localStorage.setItem('facility_id', parseInt(resp.data.facility_id))
      }
      window.location = '/'
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const resetPassword = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const details = getState().user.form
  if (!details.email || details.email === '') {
    return dispatch(dispatchError({ message: 'Please fill out your email' }))
  }
  return axios
    .post(ERP_BACKEND_NO_USER_URL + `/user/reset?email=${details.email}`)
    .then(resp => {
      dispatch(changeLoading(false))
      alert('Please check your email for a link to reset your password')
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const logout = () => () => {
  localStorage.removeItem('auth_token')
  window.location = '/login'
}

export const parseSignupData = data => dispatch => {
  const base64decoded = atob(data),
    parsed = JSON.parse(base64decoded)
  if (!parsed.ID) {
    return dispatch(dispatchError({ message: 'Wrong signup link' }))
  }
  dispatch(actions.updateFormField({ key: 'user_id', value: parsed.ID }))
  if (!parsed.token) {
    return dispatch(dispatchError({ message: 'Wrong signup link' }))
  }
  dispatch(actions.updateFormField({ key: 'token', value: parsed.token }))
  if (parsed.first_name) {
    dispatch(
      actions.updateFormField({ key: 'first_name', value: parsed.first_name })
    )
  }
  if (parsed.first_name) {
    dispatch(
      actions.updateFormField({ key: 'first_name', value: parsed.first_name })
    )
  }
  if (parsed.last_name) {
    dispatch(
      actions.updateFormField({ key: 'last_name', value: parsed.last_name })
    )
  }
  if (parsed.email) {
    dispatch(actions.updateFormField({ key: 'email', value: parsed.email }))
  }
  if (parsed.phone) {
    dispatch(actions.updateFormField({ key: 'phone', value: parsed.phone }))
  }
}
