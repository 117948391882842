import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import SalesReportWidget from './../components/SalesReportWidget'

import { getPaymentsReport } from './../actions'
import { buildPaymentData } from './../helper'
import { niceNumber, exportToCSV } from './../../helper'
import { colors } from 'consts'

const sortIcon = <ArrowDownward />

const customStyles = {
  rows: {
    style: {
      backgroundColor: colors.red_mild,
    },
  },
}

const columns = memoize(() => [
  {
    name: 'Date',
    selector: 'created_at',
    sortable: true,
    center: true,
  },
  {
    name: 'Location',
    selector: 'location',
    sortable: true,
    grow: 1,
    center: true,
  },
  {
    name: 'Source',
    selector: 'source',
    sortable: true,
    grow: 1,
    center: true,
    cell: row => {
      return (
        <p style={{ textAlign: 'center' }}>
          {row.source}
          <br />
          <small>
            <Link
              href={`https://squareup.com/dashboard/sales/transactions/${row.square_id}`}
              target="_blank"
            >
              {row.square_id}
            </Link>
          </small>
        </p>
      )
    },
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    grow: 1,
    center: true,
    format: row => `$${niceNumber(row.amount)}`,
    sortFunction: (rowA, rowB) => rowA.amount - rowB.amount,
  },
  {
    name: 'Event/Invoice',
    selector: 'event_id',
    sortable: true,
    center: true,
    cell: row => {
      let link = `/calendar/event/${row.event_id}`
      if (row.invoice_id) {
        link = `/invoice/${row.invoice_id}`
      }
      return (
        <p style={{ textAlign: 'center' }}>
          {row.event_type}
          <br />
          <small>
            <Link href={link} target="_blank">
              {row.invoice_id ? row.invoice_id : row.event_id}
            </Link>
          </small>
        </p>
      )
    },
    sortFunction: (rowA, rowB) => rowA.event_id - rowB.event_id,
  },
])

class ReconciliationReport extends React.Component {
  render() {
    const { payment_data: payments, details: payments_by_type } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={4}>
          <SalesReportWidget
            data={payments_by_type}
            title="By source"
            hide_wow={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Payments</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        exportToCSV(buildPaymentData(payments), 'Payments')
                      }
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={payments}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              fixedHeader
              paginationPerPage={100}
              paginationRowsPerPageOptions={[100, 250, 500]}
              customStyles={customStyles}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_admin: state.user.user_type === 'admin',
  ...state.reporting.reconciliation,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPaymentsReport: () => dispatch(getPaymentsReport()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReconciliationReport)
