import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'

import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'product/redux'
import { saveFeeTax as _saveFeeTax } from 'product/actions'
import { Grid } from '@material-ui/core'

const FeeTaxDialog = () => {
  const { type, open, name, value } = useSelector(
      state => state.product.fee_tax_modal
    ),
    dispatch = useDispatch(),
    handleClose = () =>
      dispatch(actions.updateFeeTax({ key: 'open', value: false })),
    update = (k, v) => dispatch(actions.updateFeeTax({ key: k, value: v })),
    saveFeeTax = () => dispatch(_saveFeeTax())
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create new {type === 'tax' ? 'tax' : 'fee'}
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              label="Name *"
              variant="outlined"
              value={name}
              onChange={e => update('name', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Value * (in %)"
              variant="outlined"
              value={value}
              onChange={e => update('value', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            saveFeeTax()
            handleClose()
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeeTaxDialog
