import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import { colors } from 'consts'

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '100%',
  },
}))

export default function RecipeReviewCard({ deleted, tags, src, onClick }) {
  const classes = useStyles()
  return (
    <Card className={classes.root} elevation={0} onClick={onClick}>
      <CardMedia className={classes.media} image={src} title="Sofive" />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {tags.map(tag => (
            <Chip variant="outlined" size="small" label={tag} key={tag} />
          ))}
          {deleted && (
            <Chip
              size="small"
              label="deleted"
              key="deleted"
              style={{
                color: colors.red_strong,
                backgroundColor: colors.red_mild,
              }}
            />
          )}
        </Typography>
      </CardContent>
    </Card>
  )
}
