import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  classes: [],
  classInfo: null,
  filters: {
    query: '',
    facility_id: [],
    start_date: null,
    end_date: null,
  },
  form: {
    name: '',
    description: '',
    start_date: null,
    end_date: null,
    registration_start_date: null,
    registration_end_date: null,
    available_online: true,
    max_kids: 8,
    fee: '',
    facility_id: null,
    is_camp: false,
  },
  fee_dialog_open: false,
  tab: 'events',
  alert_message_open: false,
  alert_message: null,
  broadcast_message_open: false,
  new_event_modal_open: false,
  new_event_modal_edit: false,
  fees: [],
  class_event_modal: false,
  creation_modal_open: false,
  participant_modal: false,
  quota_edit_open: false,
  change_class: {
    open: false,
    new_class_id: null,
    attendee_id: null,
  },
}

const classesSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
      getClasses: (state, { payload }) => {
        state.classes = payload.classes
      },
      getClass: (state, { payload }) => {
        state.classInfo = {
          ...payload.classInfo,
          attendees: payload.classInfo.attendees.map(attendee => {
            let details = {}
            try {
              details = JSON.parse(attendee.customer.details)
            } catch (e) {
              console.error(e)
            }
            return {
              ...attendee,
              details,
            }
          }),
        }
        state.form = {
          ...state.form,
          ...state.classInfo,
        }
      },
      changeFilterDetails: (state, { payload }) => {
        state.filters = {
          ...state.filters,
          [payload.key]: payload.value,
        }
      },
      toggleCreationModal: (state, { payload }) => {
        state.creation_modal_open = payload.open
      },
      updateForm: (state, { payload }) => {
        state.form = {
          ...state.form,
          [payload.key]: payload.value,
        }
      },
      changeContractDetails: (state, { payload }) => {
        state.new_contract = {
          ...state.new_contract,
          [payload.field]: payload.value,
        }
      },
      toggleFeeModal: (state, { payload }) => {
        state.fee_dialog_open = payload.open
      },
      toggleEventEditModal: (state, { payload }) => {
        state.new_event_modal_edit = true
        state.new_event_modal_open = payload.open
      },
      toggleEventCreationModal: (state, { payload }) => {
        state.new_event_modal_edit = false
        state.new_event_modal_open = payload.open
      },
      changeTab: (state, { payload }) => {
        state.tab = payload.tab
      },
      openAlert: (state, { payload }) => {
        state.alert_message_open = true
        state.alert_message = payload.message
      },
      closeAlert: (state, { payload }) => {
        state.alert_message_open = false
        state.alert_message = null
      },
      updateBroadcastMessage: (state, { payload }) => {
        state.broadcast_message_open = payload.open
      },
      deleteEvent: (state, { payload }) => {
        state.classInfo = {
          ...state.classInfo,
          events: state.classInfo.events.filter(e => e.ID !== payload.id),
        }
      },
      getFees: (state, { payload }) => {
        state.fees = payload.fees
      },
      toggleClassEventModal: (state, { payload }) => {
        state.class_event_modal = payload
      },
      toggleAddParticipantModal: (state, { payload }) => {
        state.participant_modal = payload
      },
      toggleQuotaEdit: (state, { payload }) => {
        state.quota_edit_open = payload.open
        state.form = {
          ...state.form,
          quota: payload.quota || 0,
        }
      },
      editChangeClass: (state, { payload }) => {
        state.change_class = {
          ...state.change_class,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = classesSlice.actions

export { classesSlice, actions }
