import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  facilities: [],
  filter_facilities: [], // to be used in dropdowns (sorted, don't include 'Any', label-val)
  product_types: [],
  delete_dialog: {
    open: false,
    handleDelete: null,
    name: '',
  },
  error_modal_open: false,
  error_message: null,
  confirm_dialog: {
    open: false,
    handleConfirm: null,
    subject: null,
    message: null,
  },
  info_dialog: {
    open: false,
    message: null,
  },
  logs: {},
  hubspot: {
    payload: null,
    open: false,
    list_id: null,
  },
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
      openDeleteModal: (state, { payload }) => {
        state.delete_dialog = {
          ...state.delete_dialog,
          open: true,
          handleDelete: payload.handleDelete,
          name: payload.name,
        }
      },
      closeDeleteModal: (state, { payload }) => {
        state.delete_dialog = {
          ...state.delete_dialog,
          open: false,
          handleDelete: null,
          name: '',
        }
      },
      openConfirmModal: (state, { payload }) => {
        state.confirm_dialog = {
          ...state.confirm_dialog,
          open: true,
          handleConfirm: payload.handleConfirm,
          subject: payload.subject,
          message: payload.message,
        }
      },
      closeConfirmModal: (state, { payload }) => {
        state.confirm_dialog = {
          ...state.confirm_dialog,
          open: false,
          handleDelete: null,
          subject: null,
          message: null,
        }
      },
      getFacilities: (state, { payload }) => {
        state.facilities = payload.facilities
        state.filter_facilities = state.facilities
          .filter(f => f.ID < 997)
          .filter(f => f.nickname !== '')
          .filter(f => {
            if (payload.is_admin) return true
            return f.ID === payload.user_facility_id
          })
          .map(f => ({
            label: `${f.nickname}`,
            value: f.ID,
            name: f.name,
          }))
          .sort((a, b) => a.value - b.value)
      },
      getProductTypes: (state, { payload }) => {
        state.product_types = payload.product_types
      },
      changeLoading: (state, { payload }) => {
        state.loading = payload.loading
      },
      dispatchError: (state, { payload }) => {
        if (payload.open) {
          state.error_modal_open = true
          state.error_message = payload.err
          state.loading = false
          return
        }
        state.error_modal_open = false
        state.error_message = null
      },
      openInfoModal: (state, { payload }) => {
        state.info_dialog = {
          ...state.info_dialog,
          open: true,
          message: payload.message,
        }
      },
      closeInfoModal: (state, { payload }) => {
        state.info_dialog = {
          ...state.info_dialog,
          open: false,
          message: null,
        }
      },
      addLogs: (state, { payload }) => {
        state.logs = {
          ...state.logs,
          [payload.log_type]: payload.logs,
        }
      },
      updateHubspot: (state, { payload }) => {
        state.hubspot = {
          ...state.hubspot,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = commonSlice.actions

export { commonSlice, actions }
