import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import UploadImageButton from './UploadImageButton'

import { actions } from './../redux'

const status = [
  { label: 'Online', value: true },
  { label: 'Offline', value: false },
]

class Filters extends React.Component {
  render() {
    const { facilities } = this.props

    return (
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        {this.props.is_admin && (
          <Grid item xs={2}>
            <Select
              isMulti
              options={facilities}
              placeholder="Filter center..."
              value={this.props.filters.facilities}
              onChange={v => this.props.changeFilter('facilities', v)}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <Select
            options={status}
            placeholder="Filter status..."
            value={this.props.filters.status}
            onChange={v => this.props.changeFilter('status', v)}
            isClearable
          />
        </Grid>
        <Grid item xs={2}>
          <UploadImageButton />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_admin: state.user.user_type === 'admin',
  facilities: state.common.filter_facilities,
  filters: state.scoreboards.filters,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilter: (k, v) => dispatch(actions.editFilter({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
