import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ClassDetails from 'classes/components/forms/ClassDetails'

import { createClass } from 'classes/actions'
import { actions } from 'classes/redux'

const CreateClassDialog = ({ createClass, handleClose, open }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      disableBackdropClick
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create class
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ClassDetails />
      </DialogContent>
      <DialogActions>
        <Button onClick={createClass}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  open: state.classes.creation_modal_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleCreationModal({ open: false })),
  createClass: () => dispatch(createClass()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateClassDialog)
