import React from 'react'
import { connect } from 'react-redux'
import { actions } from './../redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

const seasons = ['Spring', 'Summer', 'Fall', 'Winter'].map(season => ({
  label: season,
  value: season,
}))
const age_groups = ['Adult', 'Youth'].map(age_group => ({
  label: age_group,
  value: age_group,
}))
const days = ['Yesterday', 'Last 3 days', 'Last 7 days', 'Last 30 days'].map(
  day => ({ label: day, value: day })
)
const yes_no = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 2 },
]

const Filters = ({ facilities, products, filters, updateFilter }) => {
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={days}
            placeholder="Date range"
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={facilities}
            value={
              filters.facility_id &&
              facilities.find(p => p.value === filters.facility_id)
            }
            onChange={e => updateFilter('facility_id', e?.value)}
            placeholder="Facility"
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={products}
            value={
              filters.product && products.find(p => p.value === filters.product)
            }
            onChange={e => updateFilter('product', e?.value)}
            placeholder="Product"
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={seasons}
            value={
              filters.season && seasons.find(p => p.value === filters.season)
            }
            onChange={e => updateFilter('season', e?.value)}
            placeholder="Season"
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={age_groups}
            value={
              filters.age_group &&
              age_groups.find(p => p.value === filters.age_group)
            }
            onChange={e => updateFilter('age_group', e?.value)}
            placeholder="Age group"
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={yes_no}
            value={
              filters.deleted && yes_no.find(p => p.value === filters.deleted)
            }
            onChange={e => updateFilter('deleted', e?.value)}
            placeholder="Deleted"
            isClearable
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  facilities: state.common.filter_facilities,
  products: state.common.product_types.map(ptype => ({
    label: ptype.reporting,
    value: ptype.reporting,
  })),
  filters: state.media.filters,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateFilter: (key, value) => dispatch(actions.updateFilter({ key, value })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
