import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { actions } from './../redux'
import { downloadURI } from 'helper'

const PhotoDetails = ({ photo, open, toggleDetails }) => {
  return (
    <Drawer anchor="right" open={open} onClose={() => toggleDetails(false)}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ width: 350, textAlign: 'center', paddingTop: '2%' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography variant="h4">Details</Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 50, paddingTop: 0 }}>
          <img
            alt="photo_details"
            src={photo?.thumbnail}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className="save-btn"
            onClick={() => downloadURI(photo?.s3_url)}
          >
            Download
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  )
}

const mapStateToProps = (state, ownProps) => ({
  photo: state.media.selected_media,
  open: state.media.details_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleDetails: open => dispatch(actions.toggleDetails({ open })),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoDetails)
