import React from 'react'
import { connect } from 'react-redux'

import { unixToDateTime } from 'helper'

const DailyReport = ({
  Center,
  Date,
  AdultBookings,
  YouthBookings,
  AdultBookingsLastWeek,
  YouthBookingsLastWeek,
  PickPlayers,
  PickPlayersLastWeek,
  YouthContracts,
  YouthContractsLastWeek,
  AdultGames,
  AdultGamesLastWeek,
  YouthGames,
  YouthGamesLastWeek,
  SoccerSales,
  SoccerSalesNoYR,
  SoccerSalesCash,
  BarIncomeTotal,
  BarIncomeCash,
  BarOfSoccer,
  HealthChecks,
  HealthChecksPerEvent,
  Occupancy,
  Hours,
  WeekStart,
  WeekEnd,
  start_daily,
  end_daily,
}) => {
  return (
    <div>
      <h2>
        <b>Daily report for {Center}</b>
      </h2>
      <div>
        <b>Activity for {Date}</b>
      </div>
      <div>
        <ul>
          <li>
            Adult bookings (hrs): <b>{AdultBookings}</b> today |{' '}
            <b>{AdultBookingsLastWeek}</b> this week
          </li>
          <li>
            Youth bookings (hrs): <b>{YouthBookings}</b> today |{' '}
            <b>{YouthBookingsLastWeek}</b> this week
          </li>
          <li>
            Pickup players: <b>{PickPlayers}</b> today |{' '}
            <b>{PickPlayersLastWeek}</b> this week
          </li>
          <li>
            Youth contracts (hrs): <b>{YouthContracts}</b> today |{' '}
            <b>{YouthContractsLastWeek}</b> this week
          </li>
          <li>
            Adult games: <b>{AdultGames}</b> today | <b>{AdultGamesLastWeek}</b>{' '}
            this week
            <br />
          </li>
          <li>
            Youth games: <b>{YouthGames}</b> today | <b>{YouthGamesLastWeek}</b>{' '}
            this week
            <br />
          </li>
        </ul>
        <div>
          <b>Sales and KPIs for {Date}</b>
        </div>
        <div>
          <ul>
            <li>
              Soccer Net Sales Total <b>${SoccerSales}</b>, ${SoccerSalesNoYR}{' '}
              excl. YR & Event Sales ($
              <b>{SoccerSalesCash}</b> in cash)
            </li>
            <li>
              Bar net sales: <b>${BarIncomeTotal}</b> ($
              <b>{BarIncomeCash}</b> cash), <b>${BarOfSoccer}</b> per event
            </li>
            <li>
              Check-ins: <b>{HealthChecks}</b> ({HealthChecksPerEvent} per
              event)
            </li>
            <li>
              Occupancy: <b>{Occupancy}%</b> (total hours: <b>{Hours}</b>)
            </li>
          </ul>
          <div>
            <b>Notes</b>
          </div>
        </div>
        <div>
          <ul>
            <li>
              Day = {unixToDateTime(start_daily).format('MM/DD ha')} to{' '}
              {unixToDateTime(end_daily).format('MM/DD ha')}
            </li>
            <li>
              Week = {WeekStart} 4am to {WeekEnd} 4am
            </li>
          </ul>
          <div>Signature: [insert name]</div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.reporting.report,
  ...state.reporting.filter,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DailyReport)
