import React from 'react'
import { connect } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { leagueSlice } from './../../redux'

class TeamOrderTable extends React.Component {
  render() {
    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Order
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '70%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...this.props.teams]
              .sort((a, b) => a.order - b.order)
              .map((team, i) => (
                <TableRow key={team.order} hover style={{ cursor: 'pointer' }}>
                  <TableCell
                    style={{ textAlign: 'center', width: '10% !important' }}
                  >
                    <b>{team.order + 1}</b>
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '70% !important' }}
                  >
                    {team.team.name}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    {i !== 0 && (
                      <KeyboardArrowUpIcon
                        color="secondary"
                        fontSize="small"
                        onClick={() => this.props.changeTeamOrder(team.ID, -1)}
                      />
                    )}
                    {i !== this.props.teams.length - 1 && (
                      <KeyboardArrowDownIcon
                        color="secondary"
                        fontSize="small"
                        onClick={() => this.props.changeTeamOrder(team.ID, 1)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  teams: state.league.league.teams,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTeamOrder: (id, delta) =>
    dispatch(leagueSlice.actions.changeTeamOrder({ id, delta })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamOrderTable)
