import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import ResourceDropdown from '../components/ResourceDropdown'
import FieldsTable from '../components/FieldsTable'
import NewFieldDialog from '../components/NewFieldDialog'

import { getFieldTypes, getResource } from '../actions'
import { actions } from '../redux'

const ResourceManager = ({
  resource_type,
  facility_id,
  getResource,
  getFieldTypes,
}) => {
  const qParam = useSelector(state => state.router.location.query),
    dispatch = useDispatch()
  useEffect(() => {
    qParam?.facility_id &&
      dispatch(
        actions.updateState({
          key: 'facility_id',
          value: parseInt(qParam?.facility_id),
        })
      )
  }, [qParam])
  useEffect(() => {
    getResource()
    getFieldTypes()
  }, [resource_type, facility_id])
  return (
    <div>
      <NewFieldDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ paddingTop: '2%' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <ResourceDropdown />
        </Grid>
        <Grid item xs={12}>
          <FieldsTable />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getResource: () => dispatch(getResource()),
  getFieldTypes: () => dispatch(getFieldTypes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)
