import { ERP_BACKEND_URL, SCOREBOARDS_SOCKET_URL } from './../consts'
import axios from 'axios'
import { sendCommand } from './helper'
import { v4 as uuidv4 } from 'uuid'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'

export const getScoreboards = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let url = `/scoreboard`
  const { facility_id, user_type } = getState().user
  if (user_type !== 'admin') {
    url = url + '?facility_id=' + facility_id
  }
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(actions.getAll({ scoreboards: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _setupWebsocket = () => (dispatch, getState) => {
  console.log('Setting up websocket')
  let ws = new WebSocket(SCOREBOARDS_SOCKET_URL)
  ws.onopen = evt => {
    console.log('[SOCKET] Connection successfull')
    ws.send(
      JSON.stringify({ id: uuidv4(), origin: 'admin', action: 'register' })
    )
  }
  ws.onclose = evt => {
    console.log('[SOCKET] Closed')
    ws = null
    setTimeout(_setupWebsocket, 1000)
  }
  ws.onmessage = evt => {
    const scoreboard_data = JSON.parse(evt.data)
    console.log('[SOCKET] Got scoreboard data', scoreboard_data)
    if (!scoreboard_data.action) {
      dispatch(actions.addScoreboardSocket({ scoreboard: scoreboard_data }))
      return
    }
    if (scoreboard_data.action && scoreboard_data.action === 'set') {
      const details = JSON.parse(scoreboard_data.details),
        game = {
          home: details.home_team,
          away: details.away_team,
          home_score: 0,
          away_score: 0,
          timer: 0,
        }
      return dispatch(actions.setMatchSocket({ game, id: scoreboard_data.id }))
    }
    if (scoreboard_data.action && scoreboard_data.action === 'update') {
      const details = JSON.parse(scoreboard_data.details),
        game = {
          home_score: details.home_score,
          away_score: details.away_score,
          timer: details.timer,
        }
      return dispatch(actions.setMatchSocket({ game, id: scoreboard_data.id }))
    }
    if (scoreboard_data.action && scoreboard_data.action === 'reset') {
      return dispatch(
        actions.setMatchSocket({ game: null, id: scoreboard_data.id })
      )
    }
  }
  ws.onerror = evt => {
    console.log('[SOCKET] Error ' + evt.data)
    dispatch(dispatchError(JSON.stringify(evt.data)))
    ws.close()
  }
}

export const setupWebsocket = () => (dispatch, getState) =>
  dispatch(_setupWebsocket())

export const resetScoreboard = scoreboard_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return sendCommand(scoreboard_id, 'reset')
    .then(() => dispatch(changeLoading(false)))
    .catch(e => dispatch(dispatchError(e)))
}
