import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

import { closeConfirmModal } from './../actions'

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  confirmAndClose,
  subject,
  message,
}) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="sm"
    aria-labelledby="confirmation-dialog-title"
    open={open}
  >
    <DialogTitle id="confirmation-dialog-title">
      {subject} confirmation
    </DialogTitle>
    <DialogContent>Are you sure you want to {message} ?</DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={() => confirmAndClose(handleConfirm)} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

const mapStateToProps = state => ({
  ...state.common.confirm_dialog,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(closeConfirmModal()),
  confirmAndClose: handleConfirm => {
    dispatch(handleConfirm())
    dispatch(closeConfirmModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog)
