import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { momentDateTimeSecondsToUnix } from 'helper'

const initialState = {
  slots: [],
  create_slot: {
    facility: null,
    sel_fields: [],
    sel_days_of_week: [],
    start_time: null,
    end_time: null,
    dialog_open: false,
    start_date: null,
    end_date: null,
  },
  filter: {
    facility_id: null,
    day_of_week: null,
    start_date: momentDateTimeSecondsToUnix(
      moment().hours(0).minutes(0).seconds(0)
    ),
    end_date: momentDateTimeSecondsToUnix(
      moment().hours(23).minutes(59).seconds(59)
    ),
  },
  tab: 'assign',
  events: [],
  assign: {
    coach_id: null,
    dialog_open: false,
  },
}

const soccernautSlice = createSlice({
    name: 'soccernaut',
    initialState,
    reducers: {
      getAll: (state, { payload }) => {
        let slot_map = {}
        payload.slots.forEach(slot => {
          if (!(slot.group_id in slot_map)) {
            slot_map[slot.group_id] = []
          }
          slot_map[slot.group_id].push(slot)
        })
        state.slots = Object.keys(slot_map).map(group_id => {
          const sub_slots = slot_map[group_id]
          let field_ids = {}
          sub_slots.forEach(slot => {
            field_ids[slot.field.ID] = slot.field
          })
          return {
            ...sub_slots[0],
            sel_fields: Object.keys(field_ids).map(parseInt),
            fields: Object.keys(field_ids).map(
              id => `${field_ids[id].number} - ${field_ids[id].name}`
            ),
            sel_days_of_week: [
              ...new Set(sub_slots.map(slot => slot.day_of_week)),
            ].sort(),
          }
        })
      },
      addSlot: (state, { payload }) => {
        state.slots = state.slots.concat(payload.slot)
      },

      deleteSlot: (state, { payload }) => {
        state.slots = state.slots.filter(s => s.group_id !== payload.id)
      },
      toggleCreateSlotModal: (state, { payload }) => {
        state.create_slot = {
          ...state.create_slot,
          dialog_open: payload.open,
        }
      },
      changeSlotDetails: (state, { payload }) => {
        state.create_slot = {
          ...state.create_slot,
          [payload.key]: payload.value,
        }
      },
      setSlotDetails: (state, { payload }) => {
        state.create_slot = {
          ...state.create_slot,
          ...payload,
        }
      },
      updateTab: (state, { payload }) => {
        state.tab = payload.tab
      },
      getEvents: (state, { payload }) => {
        state.events = payload.events.map(ev => ({ ...ev, selected: false }))
      },
      setSelected: (state, { payload }) => {
        if (!payload.selected.length) {
          state.events = state.events.map(ev => ({ ...ev, selected: false }))
          return
        }
        state.events = state.events.map(ev => ({
          ...ev,
          selected: payload.selected.indexOf(ev.ID) !== -1,
        }))
      },
      toggleAssignModal: (state, { payload }) => {
        state.assign = {
          ...state.assign,
          dialog_open: payload.open,
        }
      },
      updateAssignedCoach: (state, { payload }) => {
        state.assign = {
          ...state.assign,
          coach_id: payload.coach_id,
        }
      },
      removeSelectedEvents: (state, { payload }) => {
        state.events = state.events.filter(ev => !ev.selected)
      },
      changeFilterDetails: (state, { payload }) => {
        state.filter = {
          ...state.filter,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = soccernautSlice.actions

export { soccernautSlice, actions }
