import React, { Component } from 'react'
import { connect } from 'react-redux'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AssignmentIcon from '@material-ui/icons/AssignmentInd'
import BlurCircularIcon from '@material-ui/icons/BlurCircular'
// import ScoreboardsIcon from '@material-ui/icons/SportsSoccer'
// import ClubhouseIcon from '@material-ui/icons/LiveTv'
import ResourcesIcon from '@material-ui/icons/HowToReg'
import AdminIcon from '@material-ui/icons/Settings'
import CalendarIcon from '@material-ui/icons/Event'
import FileCopyIcon from '@material-ui/icons/Description'
import Divider from '@material-ui/core/Divider'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import EventNoteIcon from '@material-ui/icons/EventNote'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid'
import SportsIcon from '@material-ui/icons/Sports'
import ListAltIcon from '@material-ui/icons/ListAlt'

import { colors } from './../../consts'

const _MenuItem = ({ path_name, label, target, Icon }) => (
  <ListItem
    button
    key="customers"
    onClick={() => (window.location = target)}
    style={{
      color: path_name === target ? colors.green_strong : null,
      textDecoration: path_name === target ? 'underline' : null,
    }}
  >
    <ListItemIcon style={{ minWidth: '40px' }}>
      <Icon
        style={{ color: path_name === target ? colors.green_strong : null }}
      />
    </ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
)
const MenuItem = connect(
  state => ({
    path_name: state.router.location.pathname,
  }),
  {}
)(_MenuItem)

class Menu extends Component {
  render() {
    if (this.props.is_not_hydra) {
      return (
        <List>
          <MenuItem label="Schedule" target="/schedule" Icon={CalendarIcon} />
        </List>
      )
    }
    return (
      <List>
        {/*<ListItem
          button
          key="ref_assignment"
          onClick={() => (window.location = '/')}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <AssignmentIcon style={{ color: colors.green_strong }} />
          </ListItemIcon>
          <ListItemText primary="Matches" />
        </ListItem>*/}
        <MenuItem label="Calendar" target="/calendar" Icon={CalendarIcon} />
        <MenuItem label="Contracts" target="/contract" Icon={EventNoteIcon} />
        <MenuItem
          label={
            <span>
              Leagues &<br />
              tournaments
            </span>
          }
          target="/leagues"
          Icon={GroupWorkIcon}
        />
        <MenuItem
          label="Soccernaut"
          target="/soccernaut"
          Icon={BlurCircularIcon}
        />
        <MenuItem
          label="Pickup"
          target="/pickup"
          Icon={FlipCameraAndroidIcon}
        />
        <MenuItem label="Classes" target="/classes" Icon={ListAltIcon} />
        <Divider />
        {/*<ListItem
          button
          key="scoreboards"
          onClick={() => (window.location = '/scoreboards')}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <ScoreboardsIcon style={{ color: colors.green_strong }} />
          </ListItemIcon>
          <ListItemText primary="Scoreboards" />
        </ListItem>*/}
        {/*<ListItem
          button
          key="clubhouse"
          onClick={() => (window.location = '/clubhouse')}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <ClubhouseIcon style={{ color: colors.green_strong }} />
          </ListItemIcon>
          <ListItemText primary="Clubhouse" />
        </ListItem>
        <Divider />*/}
        <MenuItem label="Customers" target="/customer" Icon={ResourcesIcon} />
        <MenuItem label="Teams" target="/teams" Icon={AssignmentIcon} />
        {this.props.is_admin && (
          <MenuItem label="Invoices" target="/invoice" Icon={FileCopyIcon} />
        )}
        <MenuItem label="Reporting" target="/reporting" Icon={EqualizerIcon} />
        <MenuItem label="Referee" target="/referee" Icon={SportsIcon} />
        <MenuItem label="Admin" target="/admin" Icon={AdminIcon} />
      </List>
    )
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  is_admin: state.user.user_type === 'admin',
  is_not_hydra:
    state.user.user_type === 'coach' || state.user.user_type === 'referee',
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
