import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import TablePagination from '@material-ui/core/TablePagination'

import { colors } from 'consts'

const ContractsTable = ({ data, _contracts }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const handleChangePage = (event, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }
  const contracts = data ? data : _contracts,
    show_cust = data === undefined
  return (
    <Paper elevation={0}>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={contracts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              No
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Name
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '20%' }}
              className="table-header"
            >
              Product
            </TableCell>
            {show_cust && (
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Customer
              </TableCell>
            )}
            {show_cust && (
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Comment
              </TableCell>
            )}
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Balance
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', width: '10%' }}
              className="table-header"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <TableBody>
        {contracts
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(contract => (
            <TableRow key={contract.ID}>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                {contract.ID}
                <br />
                <small>{contract.facility.name}</small>
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                {contract.name}
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                {contract.product_type.name}
              </TableCell>
              {show_cust && (
                <TableCell style={{ textAlign: 'center', width: '20%' }}>
                  {contract.customer && (
                    <div>
                      <Link
                        href={`/customer/${contract.customer.ID}`}
                        target="_blank"
                      >
                        {contract.customer.first_name}{' '}
                        {contract.customer.last_name}
                      </Link>
                      <br />
                      {contract.customer.email}
                      {contract.customer.organization && (
                        <Chip
                          size="small"
                          label={<b>org</b>}
                          style={{
                            color: colors.purple_strong,
                            backgroundColor: colors.purple_mild,
                            margin: 5,
                          }}
                        />
                      )}
                    </div>
                  )}
                </TableCell>
              )}
              {show_cust && (
                <TableCell style={{ textAlign: 'center', width: '20%' }}>
                  {contract.comment}
                </TableCell>
              )}
              {contract.invoice ? (
                <TableCell style={{ textAlign: 'center', width: '10%' }}>
                  <Chip
                    size="small"
                    label={<b>${contract.invoice.balance}</b>}
                    style={{
                      color:
                        contract.invoice.balance > 0
                          ? colors.red_strong
                          : colors.green_strong,
                      backgroundColor:
                        contract.invoice.balance > 0
                          ? colors.red_mild
                          : colors.green_mild,
                      margin: 5,
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                <Link href={`/contract/${contract.ID}`} target="_blank">
                  <IconButton style={{ padding: 5 }}>
                    <OpenInNewIcon
                      fontSize="small"
                      style={{
                        color: colors.blue_strong,
                      }}
                    />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => {
  const hide_paid = state.contract.search.hide_paid
  let contracts = state.contract.contracts
  if (hide_paid) {
    contracts = contracts.filter(c => c.invoice?.balance !== 0)
  }
  return { _contracts: contracts }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ContractsTable)
