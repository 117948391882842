import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import { dispatchError } from './../../common/actions'
import { searchCustomers } from './../../calendar/actions'
import { createInvoice } from './../actions'
import { actions } from './../redux'

const products = [
  { label: '[MANUAL] Youth Rental Contract', value: 36, ptype_id: 7 },
  { label: '[MANUAL] Youth League', value: 38, ptype_id: 8 },
  { label: '[MANUAL] Adult league', value: 39, ptype_id: 3 },
  { label: '[MANUAL] Adult Tournament', value: 40, ptype_id: 4 },
  { label: '[MANUAL] Youth Tournament', value: 41, ptype_id: 9 },
]

class NewInvoiceForm extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Add new invoice
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => this.props.handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Select
                options={this.props.facilities}
                placeholder="Facility"
                value={
                  this.props.facility_id &&
                  this.props.facilities.find(
                    f => f.value === this.props.facility_id
                  )
                }
                onChange={v =>
                  this.props.updateInvoice('facility_id', v && v.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={this.props.customers}
                getOptionLabel={option =>
                  `${option.first_name} ${option.last_name}`
                }
                renderOption={option => (
                  <p>
                    {option.first_name} {option.last_name}{' '}
                    {option.hubspot && option.hubspot.gender && (
                      <span>({option.hubspot.gender})</span>
                    )}
                    <br />
                    <span>
                      email=
                      {option.email}
                    </span>
                    {option.hubspot && option.hubspot.phone && (
                      <span>
                        <br />
                        phone=
                        {option.hubspot.phone}
                      </span>
                    )}
                    {option.hubspot && option.hubspot.state && (
                      <span>
                        <br />
                        state=
                        {option.hubspot.state}
                      </span>
                    )}
                  </p>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Customer"
                    variant="outlined"
                    fullWidth
                    onChange={e => this.props.searchCustomers(e.target.value)}
                  />
                )}
                value={this.props.customer}
                onChange={(e, c) => this.props.updateInvoice('customer', c)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DatePickerComponent
                id="invoice_date"
                placeholder="Invoice date *"
                value={this.props.date}
                onChange={e => this.props.updateInvoice('date', e.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                placeholder="Product"
                label="Product type"
                variant="outlined"
                fullWidth
                options={products}
                value={
                  this.props.product_id &&
                  products.find(f => f.value === this.props.product_id)
                }
                onChange={e => {
                  this.props.updateInvoice('product_id', e.value)
                  this.props.updateInvoice('ptype_id', e.ptype_id)
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="amount"
                label="Amount"
                fullWidth
                variant="outlined"
                value={this.props.amount}
                onChange={e =>
                  this.props.updateInvoice('amount', e.target.value)
                }
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-multiline-static"
                label="Comment"
                multiline
                rows="3"
                fullWidth
                variant="outlined"
                value={this.props.comment}
                onChange={e =>
                  this.props.updateInvoice('comment', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.createInvoice()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.invoice.invoice_form,
  open: state.invoice.invoice_modal_open,
  customers: state.calendar.customers,
  facilities: state.common.facilities.map(f => ({
    label: f.name,
    value: f.ID,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleInvoiceModal({ open: false })),
  dispatchError: e => dispatch(dispatchError(e)),
  searchCustomers: q => dispatch(searchCustomers(q)),
  updateInvoice: (k, v) =>
    dispatch(actions.updateInvoice({ key: k, value: v })),
  createInvoice: () => dispatch(createInvoice()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoiceForm)
