import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Select from 'react-select'

import { updateSoccernautCoach } from './../actions'
import { getCoaches } from './../../calendar/actions'
import { actions } from './../redux'

class AssignDialog extends React.Component {
  render() {
    const { open, handleClose, selected_events, updateSoccernautCoach } =
      this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        onEntered={() =>
          this.props.getCoaches(selected_events[0].field.facility_id)
        }
      >
        <DialogTitle id="form-dialog-title">
          Assign soccernaut event
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Select
            options={this.props.customers}
            placeholder="Select coach..."
            value={
              this.props.coach_id &&
              this.props.customers.find(c => c.ID === this.props.coach_id)
            }
            formatOptionLabel={option => (
              <p>
                {option.first_name} {option.last_name} <br />
                <span>
                  email=
                  {option.email}
                </span>
                {option.phone && (
                  <span>
                    <br />
                    phone=
                    {option.phone}
                  </span>
                )}
              </p>
            )}
            onChange={e => this.props.updateAssignedCoach(e.value)}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: base => ({ ...base, height: '80px', minHeight: '80px' }),
              valueContainer: base => ({
                ...base,
                height: '80px',
                minHeight: '80px',
              }),
            }}
            menuPortalTarget={document.body}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateSoccernautCoach()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.soccernaut.assign.dialog_open,
  customers: state.calendar.customers.map(coach => ({
    ...coach,
    label: coach.first_name + coach.last_name + coach.email, // for searching
    value: coach.ID,
  })),
  selected_events: state.soccernaut.events.filter(ev => ev.selected),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleAssignModal({ open: false })),
  updateSoccernautCoach: () => dispatch(updateSoccernautCoach()),
  getCoaches: facility_id => dispatch(getCoaches(facility_id)),
  updateAssignedCoach: coach_id =>
    dispatch(actions.updateAssignedCoach({ coach_id })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignDialog)
