import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { momentDateTimeSecondsToUnix } from './../helper'

const initialState = {
  data: null,
  sales: [],
  payments_by_type: [],
  payments_by_product: [],
  payments_by_location: [],
  invoices: [],
  events: [],
  filter: {
    start_date: momentDateTimeSecondsToUnix(
      moment().hours(0).minutes(0).subtract(6, 'd')
    ),
    end_date: momentDateTimeSecondsToUnix(moment().hours(23).minutes(59)),
    facility_id: [],
    product_type_id: [],
    payment_type: [],
    invoiced_paid: true,
    uninvoiced: true,
    invoiced_unpaid: true,
    active: 0,
    league_id: [],
    exclude_tax: false,
    start_daily: momentDateTimeSecondsToUnix(
      moment().hours(4).minutes(0).subtract(1, 'd')
    ),
    end_daily: momentDateTimeSecondsToUnix(moment().hours(4).minutes(0)),
  },
  report_type: 'cash',
  payments: [],
  health_checks: [],
  teams: [],
  teams_total: {},
  teams_leagues: {},
  reconciliation: {
    total_map: {},
    payment_data: [],
  },
  outstanding_customers_array: [],
  report: null,
  cameras: null,
}

const reportingSlice = createSlice({
    name: 'reporting',
    initialState,
    reducers: {
      getData: (state, { payload }) => {
        let final_data = {}
        Object.keys(payload.data).forEach(reporting_type => {
          final_data[reporting_type] = {}
          Object.keys(payload.data[reporting_type]).forEach(product_type => {
            const pdata = payload.data[reporting_type][product_type]
            final_data[reporting_type][product_type] = {
              today: pdata.Today,
              wow:
                Math.round(
                  ((pdata.Today - pdata.LastToday) /
                    (pdata.LastToday + 0.0001)) *
                    1000
                ) / 10,
              wtd: pdata.Wtd,
              wtd_wow:
                Math.round(
                  ((pdata.Wtd - pdata.LastWtd) / (pdata.LastWtd + 0.0001)) *
                    1000
                ) / 10,
            }
            final_data[reporting_type][product_type].wow_sign =
              final_data[reporting_type][product_type].wow > 0 ? '+' : ''
            final_data[reporting_type][product_type].wtd_wow_sign =
              final_data[reporting_type][product_type].wtd_wow > 0 ? '+' : ''
          })
        })
        state.data = final_data
      },
      getSalesReport: (state, { payload }) => {
        state.sales = payload.data
        let payments_by_type_map = {},
          payments_by_product_map = {},
          payments_by_location_map = {}
        payload.data.payments.forEach(payment => {
          if (!(payment.type in payments_by_type_map)) {
            payments_by_type_map[payment.type] = {
              quantity: 0,
              amount: 0,
            }
          }
          payments_by_type_map[payment.type].quantity += 1
          payments_by_type_map[payment.type].amount += payment.amount
          // total_quantity += 1
          // total_amount += payment.amount
          const product_name = payment.product_type.name
          if (!(product_name in payments_by_product_map)) {
            payments_by_product_map[product_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          payments_by_product_map[product_name].quantity += 1
          payments_by_product_map[product_name].amount += payment.amount
          const location_name = payment.facility.name
          if (!(location_name in payments_by_location_map)) {
            payments_by_location_map[location_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          payments_by_location_map[location_name].quantity += 1
          payments_by_location_map[location_name].amount += payment.amount
        })
        state.payments_by_type = [
          {
            description: 'Cash',
            key: 'cash',
            quantity: payments_by_type_map.cash
              ? payments_by_type_map.cash.quantity
              : 0,
            amount: payments_by_type_map.cash
              ? payments_by_type_map.cash.amount
              : 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Check',
            key: 'check',
            quantity: payments_by_type_map.check
              ? payments_by_type_map.check.quantity
              : 0,
            amount: payments_by_type_map.check
              ? payments_by_type_map.check.amount
              : 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Wire Transfer / ACH',
            key: 'wire',
            quantity: payments_by_type_map.wire
              ? payments_by_type_map.wire.quantity
              : 0,
            amount: payments_by_type_map.wire
              ? payments_by_type_map.wire.amount
              : 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Credit card',
            key: 'card',
            quantity: payments_by_type_map.card
              ? payments_by_type_map.card.quantity
              : 0,
            amount: payments_by_type_map.card
              ? payments_by_type_map.card.amount
              : 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Customer credit',
            key: 'credit',
            quantity: payments_by_type_map.credit
              ? payments_by_type_map.credit.quantity
              : 0,
            amount: payments_by_type_map.credit
              ? payments_by_type_map.credit.amount
              : 0,
            wow_count: 0,
            wow_amount: 0,
          },
          // {
          //   description: 'Total',
          //   key: 'total',
          //   quantity: total_quantity,
          //   amount: total_amount,
          //   wow_count: 0,
          //   wow_amount: 0,
          // },
        ]
        state.payments_by_product = Object.keys(payments_by_product_map).map(
          product_name => ({
            description: product_name,
            quantity: payments_by_product_map[product_name].quantity,
            amount: payments_by_product_map[product_name].amount,
            wow_count: 0,
            wow_amount: 0,
          })
        )
        state.payments_by_location = Object.keys(payments_by_location_map).map(
          location_name => ({
            description: location_name,
            quantity: payments_by_location_map[location_name].quantity,
            amount: payments_by_location_map[location_name].amount,
            wow_count: 0,
            wow_amount: 0,
          })
        )
      },
      getSalesReportWow: (state, { payload }) => {
        let payments_by_type_map = {},
          total_amount = 0,
          total_quantity = 0,
          payments_by_product_map = {},
          payments_by_location_map = {}
        payload.data.payments.forEach(payment => {
          if (!(payment.type in payments_by_type_map)) {
            payments_by_type_map[payment.type] = {
              quantity: 0,
              amount: 0,
            }
          }
          payments_by_type_map[payment.type].quantity += 1
          payments_by_type_map[payment.type].amount += payment.amount
          total_quantity += 1
          total_amount += payment.amount
          const product_name = payment.product_type.name
          if (!(product_name in payments_by_product_map)) {
            payments_by_product_map[product_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          payments_by_product_map[product_name].quantity += 1
          payments_by_product_map[product_name].amount += payment.amount
          const location_name = payment.facility.name
          if (!(location_name in payments_by_location_map)) {
            payments_by_location_map[location_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          payments_by_location_map[location_name].quantity += 1
          payments_by_location_map[location_name].amount += payment.amount
        })
        state.payments_by_type = state.payments_by_type.map(payment => {
          let new_count = payment.quantity,
            new_amount = payment.amount,
            prev_count = null,
            prev_amount = null
          if (payment.key === 'total') {
            prev_count = total_quantity
            prev_amount = total_amount
          } else {
            prev_count = payments_by_type_map[payment.key]
              ? payments_by_type_map[payment.key].quantity
              : 0
            prev_amount = payments_by_type_map[payment.key]
              ? payments_by_type_map[payment.key].amount
              : 0
          }
          let count_delta = 0
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }

          let amount_delta = 0
          if (prev_amount > 0) {
            amount_delta = ((new_amount - prev_amount) / prev_amount) * 100
          }
          return {
            ...payment,
            wow_count: count_delta,
            wow_amount: amount_delta,
          }
        })
        state.payments_by_product = state.payments_by_product.map(item => {
          let new_count = item.quantity,
            new_amount = item.amount,
            prev_count = null,
            prev_amount = null
          if (item.description === 'total') {
            prev_count = total_quantity
            prev_amount = total_amount
          } else {
            prev_count = payments_by_product_map[item.description]
              ? payments_by_product_map[item.description].quantity
              : 0
            prev_amount = payments_by_product_map[item.description]
              ? payments_by_product_map[item.description].amount
              : 0
          }
          let count_delta = 0
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }

          let amount_delta = 0
          if (prev_amount > 0) {
            amount_delta = ((new_amount - prev_amount) / prev_amount) * 100
          }
          return {
            ...item,
            wow_count: count_delta,
            wow_amount: amount_delta,
          }
        })
        state.payments_by_location = state.payments_by_location.map(item => {
          let new_count = item.quantity,
            new_amount = item.amount,
            prev_count = null,
            prev_amount = null
          if (item.description === 'total') {
            prev_count = total_quantity
            prev_amount = total_amount
          } else {
            prev_count = payments_by_location_map[item.description]
              ? payments_by_location_map[item.description].quantity
              : 0
            prev_amount = payments_by_location_map[item.description]
              ? payments_by_location_map[item.description].amount
              : 0
          }
          let count_delta = null
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }

          let amount_delta = null
          if (prev_amount > 0) {
            amount_delta = ((new_amount - prev_amount) / prev_amount) * 100
          }
          return {
            ...item,
            wow_count: count_delta,
            wow_amount: amount_delta,
          }
        })
      },
      getInvoiceReport: (state, { payload }) => {
        const data = payload.data.map(invoice => ({
          ...invoice,
          product: invoice.product_type.name,
          facility: invoice.facility.name,
        }))
        state.invoices = data
        console.log('Got', state.invoices.length)
        let invoices_status_map = {
            invoiced: 0,
            discounted: 0,
            paid: 0,
            balance: 0,
          },
          total_quantity = 0,
          payments_by_product_map = {},
          payments_by_location_map = {},
          event_map = {},
          outstanding_customers_map = {}
        data.forEach(invoice => {
          invoices_status_map.invoiced += invoice.invoiced
          invoices_status_map.discounted += invoice.discounted
          invoices_status_map.paid += invoice.paid
          invoices_status_map.balance += invoice.balance
          const product_name = invoice.product
          if (!(product_name in payments_by_product_map)) {
            payments_by_product_map[product_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          let key = `p_${invoice.event_id}`
          if (!(key in event_map)) {
            payments_by_product_map[product_name].quantity += 1
            event_map[key] = 1
          }
          payments_by_product_map[product_name].amount += invoice.invoiced
          const location_name = invoice.facility
          if (!(location_name in payments_by_location_map)) {
            payments_by_location_map[location_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          key = `l_${invoice.event_id}`
          if (!(key in event_map)) {
            payments_by_location_map[location_name].quantity += 1
            event_map[key] = 1
          }
          payments_by_location_map[location_name].amount += invoice.invoiced
          outstanding_customers_map[invoice.customer_id] = {
            ID: invoice.ID,
            CreatedAt: invoice.CreatedAt,
            customer: {
              ID: invoice.customer?.ID,
              first_name:
                invoice.customer?.first_name ||
                invoice.contract?.customer?.first_name,
              last_name:
                invoice.customer?.last_name ||
                invoice.contract?.customer?.last_name,
            },
            facility: invoice.facility,
            balance: outstanding_customers_map[invoice.customer_id]?.balance
              ? outstanding_customers_map[invoice.customer_id]['balance'] +
                invoice.balance
              : invoice.balance,
            total_invoices: outstanding_customers_map[invoice.customer_id]
              ?.total_invoices
              ? [
                  ...outstanding_customers_map[invoice.customer_id]
                    .total_invoices,
                  invoice.ID,
                ]
              : [invoice.ID],
          }
        })
        const outstanding_customers_array = []
        for (const property in outstanding_customers_map) {
          if (outstanding_customers_map[property]['balance'] > 0)
            outstanding_customers_array.push(
              outstanding_customers_map[property]
            )
        }
        state.outstanding_customers_array = outstanding_customers_array
        state.payments_by_type = [
          {
            description: 'Invoiced',
            key: 'invoiced',
            quantity: total_quantity,
            amount: invoices_status_map.invoiced,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Discounted',
            key: 'discounted',
            quantity: total_quantity,
            amount: invoices_status_map.discounted,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Amount',
            key: 'amount',
            quantity: total_quantity,
            amount:
              invoices_status_map.invoiced - invoices_status_map.discounted,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Paid',
            key: 'paid',
            quantity: total_quantity,
            amount: invoices_status_map.paid,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Balance',
            key: 'balance',
            quantity: total_quantity,
            amount: invoices_status_map.balance,
            wow_count: 0,
            wow_amount: 0,
          },
        ]
        state.payments_by_product = Object.keys(payments_by_product_map).map(
          product_name => ({
            description: product_name,
            quantity: payments_by_product_map[product_name].quantity,
            amount: payments_by_product_map[product_name].amount,
            wow_count: 0,
            wow_amount: 0,
          })
        )
        state.payments_by_location = Object.keys(payments_by_location_map).map(
          location_name => ({
            description: location_name,
            quantity: payments_by_location_map[location_name].quantity,
            amount: payments_by_location_map[location_name].amount,
            wow_count: 0,
            wow_amount: 0,
          })
        )
      },
      getInvoiceReportWow: (state, { payload }) => {
        const data = payload.data.map(invoice => ({
          ...invoice,
          product: invoice.product_type.name,
          facility: invoice.facility.name,
          event_id: invoice.invoice_items[0].event.ID,
        }))
        let invoices_status_map = {
            invoiced: 0,
            discounted: 0,
            paid: 0,
            balance: 0,
            amount: 0,
          },
          payments_by_product_map = {},
          payments_by_location_map = {},
          event_map = {}
        data.forEach(invoice => {
          invoices_status_map.invoiced += invoice.invoiced
          invoices_status_map.discounted += invoice.discounted
          invoices_status_map.paid += invoice.paid
          invoices_status_map.balance += invoice.balance
          const product_name = invoice.product
          if (!(product_name in payments_by_product_map)) {
            payments_by_product_map[product_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          let key = `p_${invoice.event_id}`
          if (!(key in event_map)) {
            payments_by_product_map[product_name].quantity += 1
            event_map[key] = 1
          }
          payments_by_product_map[product_name].amount += invoice.invoiced
          const location_name = invoice.facility
          if (!(location_name in payments_by_location_map)) {
            payments_by_location_map[location_name] = {
              quantity: 0,
              amount: 0,
            }
          }
          key = `l_${invoice.event_id}`
          if (!(key in event_map)) {
            payments_by_location_map[location_name].quantity += 1
            event_map[key] = 1
          }
          payments_by_location_map[location_name].amount += invoice.invoiced
        })
        invoices_status_map.amount =
          invoices_status_map.invoiced - invoices_status_map.discounted
        state.payments_by_type = state.payments_by_type.map(payment => {
          let new_amount = payment.amount,
            prev_amount = invoices_status_map[payment.key]
          let amount_delta = 0
          if (prev_amount > 0) {
            amount_delta = ((new_amount - prev_amount) / prev_amount) * 100
          }
          return {
            ...payment,
            wow_count: 0,
            wow_amount: amount_delta,
          }
        })
        state.payments_by_product = state.payments_by_product.map(item => {
          let new_count = item.quantity,
            new_amount = item.amount,
            prev_count = payments_by_product_map[item.description]
              ? payments_by_product_map[item.description].quantity
              : 0,
            prev_amount = payments_by_product_map[item.description]
              ? payments_by_product_map[item.description].amount
              : 0
          let count_delta = 0
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }

          let amount_delta = 0
          if (prev_amount > 0) {
            amount_delta = ((new_amount - prev_amount) / prev_amount) * 100
          }
          return {
            ...item,
            wow_count: count_delta,
            wow_amount: amount_delta,
          }
        })
        state.payments_by_location = state.payments_by_location.map(item => {
          let new_count = item.quantity,
            new_amount = item.amount,
            prev_count = payments_by_location_map[item.description]
              ? payments_by_location_map[item.description].quantity
              : 0,
            prev_amount = payments_by_location_map[item.description]
              ? payments_by_location_map[item.description].amount
              : 0
          let count_delta = null
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }

          let amount_delta = null
          if (prev_amount > 0) {
            amount_delta = ((new_amount - prev_amount) / prev_amount) * 100
          }
          return {
            ...item,
            wow_count: count_delta,
            wow_amount: amount_delta,
          }
        })
      },
      getEventsReport: (state, { payload }) => {
        let invoiced_paid = 0,
          invoiced_unpaid = 0,
          uninvoiced = 0,
          product_map = {},
          events_by_location_map = {}
        console.log(payload)
        console.log('Got', payload.data.length, 'events')
        state.events = payload.data
          .filter(ev => ev.product_type.ID !== 13) // Remove blocks
          .filter(ev => {
            if (!payload.invoiced_paid) {
              return !(
                ev.invoice &&
                ev.invoice.invoice_items.length &&
                ev.invoice.balance === 0
              )
            }
            return true
          })
          .filter(ev => {
            if (!payload.invoiced_unpaid) {
              return !(
                ev.invoice &&
                ev.invoice.invoice_items.length &&
                ev.invoice.balance > 0
              )
            }
            return true
          })
          .filter(ev => {
            if (!payload.uninvoiced) {
              return ev.invoice && ev.invoice.invoice_items.length
            }
            return true
          })
          .map(ev => {
            const product_name = ev.product_type.name
            if (!(product_name in product_map)) {
              product_map[product_name] = {
                quantity: 0,
                amount: 0,
              }
            }
            product_map[product_name].quantity += 1
            const location_name = ev.field.facility.name
            if (!(location_name in events_by_location_map)) {
              events_by_location_map[location_name] = {
                quantity: 0,
                amount: 0,
              }
            }
            events_by_location_map[location_name].quantity += 1
            if (!ev.invoice) {
              uninvoiced += 1
              return {
                ...ev,
                invoice: { exists: false, amount: 0, paid: 0, balance: 0 },
              }
            }
            if (ev.invoice.invoice_items) {
              if (ev.invoice.balance === 0) {
                invoiced_paid += 1
              } else {
                invoiced_unpaid += 1
              }
            } else {
              uninvoiced += 1
            }
            return {
              ...ev,
              invoice: {
                exists: true,
                ...ev.invoice,
                amount: ev.invoice.invoiced - ev.invoice.discounted,
              },
            }
          })
        state.payments_by_type = [
          {
            description: 'Invoiced paid',
            key: 'invoiced_paid',
            quantity: invoiced_paid,
            amount: 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Invoiced unpaid',
            key: 'invoiced_unpaid',
            quantity: invoiced_unpaid,
            amount: 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Uninvoiced',
            key: 'uninvoiced',
            quantity: uninvoiced,
            amount: 0,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Total',
            key: 'total',
            quantity: invoiced_paid + invoiced_unpaid + uninvoiced,
            amount: 0,
            wow_count: 0,
            wow_amount: 0,
          },
        ]
        state.payments_by_product = Object.keys(product_map).map(
          product_name => ({
            description: product_name,
            quantity: product_map[product_name].quantity,
            amount: product_map[product_name].amount,
            wow_count: 0,
            wow_amount: 0,
          })
        )
        state.payments_by_location = Object.keys(events_by_location_map).map(
          location_name => ({
            description: location_name,
            quantity: events_by_location_map[location_name].quantity,
            amount: events_by_location_map[location_name].amount,
            wow_count: 0,
            wow_amount: 0,
          })
        )
      },
      getEventsReportWow: (state, { payload }) => {
        let invoiced_paid = 0,
          invoiced_unpaid = 0,
          uninvoiced = 0,
          product_map = {},
          events_by_location_map = {}
        payload.data
          .filter(ev => ev.product_type.ID !== 13)
          .forEach(ev => {
            const product_name = ev.product_type.name
            if (!(product_name in product_map)) {
              product_map[product_name] = {
                quantity: 0,
                amount: 0,
              }
            }
            product_map[product_name].quantity += 1
            const location_name = ev.field.facility.name
            if (!(location_name in events_by_location_map)) {
              events_by_location_map[location_name] = {
                quantity: 0,
                amount: 0,
              }
            }
            events_by_location_map[location_name].quantity += 1
            if (!ev.invoice) {
              uninvoiced += 1
            } else {
              if (ev.invoice.invoice_items) {
                if (ev.invoice.balance === 0) {
                  invoiced_paid += 1
                } else {
                  invoiced_unpaid += 1
                }
              } else {
                uninvoiced += 1
              }
            }
          })
        const total = invoiced_paid + invoiced_unpaid + uninvoiced,
          status_map = { invoiced_paid, invoiced_unpaid, uninvoiced, total }
        state.payments_by_type = state.payments_by_type.map(payment => {
          let new_count = payment.quantity,
            prev_count = status_map[payment.key]
          let count_delta = 0
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }
          return {
            ...payment,
            wow_count: count_delta,
            wow_amount: 0,
          }
        })
        state.payments_by_product = state.payments_by_product.map(item => {
          let new_count = item.quantity,
            prev_count = product_map[item.description]
              ? product_map[item.description].quantity
              : 0
          let count_delta = 0
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }
          return {
            ...item,
            wow_count: count_delta,
          }
        })
        state.payments_by_location = state.payments_by_location.map(item => {
          let new_count = item.quantity,
            prev_count = events_by_location_map[item.description]
              ? events_by_location_map[item.description].quantity
              : 0
          let count_delta = null
          if (prev_count > 0) {
            count_delta = ((new_count - prev_count) / prev_count) * 100
          }
          return {
            ...item,
            wow_count: count_delta,
          }
        })
      },
      updateFilter: (state, { payload }) => {
        state.filter = {
          ...state.filter,
          [payload.field]: payload.value,
        }
      },
      changeReportType: (state, { payload }) => {
        state.report_type = payload
      },
      getPaymentsReport: (state, { payload }) => {
        let actions_per_second = {},
          payment_type_total = {
            online: 0,
            hydra: 0,
            square: 0,
            hydra_total: 0,
          }
        payload.payments.forEach(p => {
          const trunc = p.date.slice(0, 16) + p.location
          if (!(trunc in actions_per_second)) {
            actions_per_second[trunc] = 0
          }
          actions_per_second[trunc] += 1
          if (p.origin === 'online') {
            payment_type_total.online += p.amount
            payment_type_total.hydra_total += p.amount
          }
          if (p.origin === 'hydra') {
            payment_type_total.hydra += p.amount
            payment_type_total.hydra_total += p.amount
          }
          if (p.origin === 'square') {
            payment_type_total.square += p.amount
          }
        })
        state.payments = payload.payments.map(p => {
          const trunc = p.date.slice(0, 16) + p.location
          return { ...p, count: actions_per_second[trunc] }
        })
        state.payments_by_type = [
          {
            description: 'Online',
            key: 'online',
            quantity: 0,
            amount: payment_type_total.online,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Hydra',
            key: 'hydra',
            quantity: 0,
            amount: payment_type_total.hydra,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Total',
            key: 'thydra',
            quantity: 0,
            amount: payment_type_total.hydra_total,
            wow_count: 0,
            wow_amount: 0,
          },
          {
            description: 'Square',
            key: 'square',
            quantity: 0,
            amount: payment_type_total.square,
            wow_count: 0,
            wow_amount: 0,
          },
        ]
      },
      getHealthReport: (state, { payload }) => {
        state.health_checks = payload.health_checks
      },
      getTeamsReport: (state, { payload }) => {
        state.teams = payload.data
        state.teams_total = payload.totals
        state.teams_leagues = Object.keys(payload.leagues).map(id => ({
          label: payload.leagues[id],
          value: id,
        }))
      },
      getReconciliations: (state, { payload }) => {
        state.reconciliation = {
          ...payload,
        }
      },
      updateState: (state, { payload }) => {
        state[payload.key] = payload.value
      },
    },
  }),
  actions = reportingSlice.actions

export { reportingSlice, actions }
