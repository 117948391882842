import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { updateCustomerCredits } from './../actions'
import { actions } from './../redux'

class AddCreditForm extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Add credit to customer
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => this.props.handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Amount
                </InputLabel>
                <Input
                  id="standard-adornment-amount"
                  value={this.props.amount}
                  onChange={e => this.props.editAddCreditAmount(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.updateCustomerCredits()
              this.props.handleClose()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.customer.add_credit_modal_open,
  amount: state.customer.add_credit_amount,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  editAddCreditAmount: amount =>
    dispatch(actions.editAddCreditAmount({ amount })),
  handleClose: () => dispatch(actions.toggleAddCreditModal({ open: false })),
  updateCustomerCredits: () => dispatch(updateCustomerCredits()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditForm)
