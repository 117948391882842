import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'

import { actions } from './../redux'
import { getEventsToAssign } from './../actions'
import { colors, day_of_week } from './../../consts'
import { unixToDateTime, momentDateTimeSecondsToUnix } from '../../helper'

class SlotFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { focusedInput: null }
  }
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
          style={{ padding: 10 }}
        >
          <Grid item xs={2} style={{ width: '100%' }}>
            <ToggleButtonGroup
              value={this.props.tab}
              exclusive
              aria-label="text alignment"
            >
              <ToggleButton
                value="slot"
                aria-label="left aligned"
                onClick={() => this.props.updateTab('slot')}
              >
                Slot
              </ToggleButton>
              <ToggleButton
                value="assign"
                aria-label="centered"
                onClick={() => this.props.updateTab('assign')}
              >
                Assign
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={this.props.facilities}
              placeholder="Facility"
              value={
                this.props.facility_id &&
                this.props.facilities.find(
                  p => p.value === this.props.facility_id
                )
              }
              onChange={v =>
                this.props.changeFilterDetails('facility_id', v && v.value)
              }
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          {this.props.is_slot && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Select
                options={day_of_week}
                placeholder="Day of week"
                value={
                  this.props.day_of_week &&
                  day_of_week.find(p => p.value === this.props.day_of_week)
                }
                onChange={v =>
                  this.props.changeFilterDetails('day_of_week', v && v.value)
                }
                isClearable
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <DateRangePicker
              small
              isOutsideRange={() => false}
              startDate={
                this.props.start_date
                  ? unixToDateTime(this.props.start_date)
                  : null
              }
              startDateId="your_unique_start_date_id"
              endDate={
                this.props.end_date ? unixToDateTime(this.props.end_date) : null
              }
              endDateId="your_unique_end_date_id"
              onDatesChange={({ startDate, endDate }) => {
                if (!startDate && !endDate) {
                  // dates cleared
                  this.props.changeFilterDetails('start_date', null)
                  this.props.changeFilterDetails('end_date', null)
                }
                if (startDate) {
                  this.props.changeFilterDetails(
                    'start_date',
                    momentDateTimeSecondsToUnix(startDate)
                  )
                }
                if (endDate) {
                  this.props.changeFilterDetails(
                    'end_date',
                    momentDateTimeSecondsToUnix(
                      endDate.hours(23).minutes(59).seconds(59)
                    )
                  )
                }
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              minimumNights={0}
              showClearDates={true}
            />
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Button
              style={{
                color: colors.blue_strong,
                backgroundColor: colors.blue_mild,
              }}
              component="span"
              onClick={() => this.props.getEventsToAssign()}
            >
              Search
            </Button>
          </Grid>
          {this.props.is_slot && this.props.is_admin && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
                component="span"
                onClick={() => this.props.toggleCreateSlotModal()}
              >
                Add new
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.soccernaut.filter,
  tab: state.soccernaut.tab,
  facilities: state.common.filter_facilities,
  is_slot: state.soccernaut.tab === 'slot',
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleCreateSlotModal: () =>
    dispatch(actions.toggleCreateSlotModal({ open: true })),
  updateTab: t => dispatch(actions.updateTab({ tab: t })),
  changeFilterDetails: (k, v) =>
    dispatch(actions.changeFilterDetails({ key: k, value: v })),
  getEventsToAssign: () => dispatch(getEventsToAssign()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SlotFilter)
