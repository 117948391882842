import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import TextField from 'common/components/FastTextField'
import Grid from '@material-ui/core/Grid'

import { actions } from './../../redux'
import { user_types } from './../../../consts'

class StaffForm extends React.Component {
  componentDidMount() {
    this.props.changeStaffDetails('facility_id', this.props.user_facility_id)
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={6}>
          <Select
            options={this.props.facilities}
            placeholder="Facility"
            value={
              this.props.facility_id &&
              this.props.facilities.find(
                p => p.value === this.props.facility_id
              )
            }
            onChange={v =>
              this.props.changeStaffDetails('facility_id', v && v.value)
            }
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            options={user_types.filter(u => {
              if (this.props.is_admin) return true
              return u.value !== 'admin' && u.value !== 'manager'
            })}
            placeholder="User type"
            value={
              this.props.user_type &&
              user_types.find(p => p.value === this.props.user_type)
            }
            onChange={v =>
              this.props.changeStaffDetails('user_type', v && v.value)
            }
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="First name"
            fullWidth
            value={this.props.first_name}
            onChange={e =>
              this.props.changeStaffDetails('first_name', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last name"
            fullWidth
            value={this.props.last_name}
            onChange={e =>
              this.props.changeStaffDetails('last_name', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            fullWidth
            value={this.props.email}
            onChange={e =>
              this.props.changeStaffDetails('email', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone"
            fullWidth
            value={this.props.phone}
            onChange={e =>
              this.props.changeStaffDetails('phone', e.target.value)
            }
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.staff.create_staff,
  facilities: state.common.filter_facilities,
  is_admin: state.user.user_type === 'admin',
  user_facility_id: state.user.facility_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeStaffDetails: (k, v) =>
    dispatch(actions.changeStaffDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(StaffForm)
