import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'

const FastTextField = props => {
  const [txt, setTxt] = useState(props.value)
  return (
    <TextField
      {...props}
      value={txt}
      onChange={e => setTxt(e.target.value)}
      onBlur={e => props.onChange(e)}
    />
  )
}

export default FastTextField
