import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { Button } from '@material-ui/core'

import { actions } from 'resources/redux'
import { getAgeGroups } from 'resources/actions'
import NewAgeGroupDialog from 'resources/components/NewAgeGroupDialog'

const sortIcon = <ArrowDownward />

const columns = setAgeGroup =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: age_group => age_group.ID,
    },
    {
      name: 'Name',
      selector: 'name',
      center: true,
      cell: age_group => age_group.name,
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: age_group => (
        <>
          <IconButton onClick={() => setAgeGroup(age_group)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = () => {
  const age_groups = useSelector(state => state.resources.age_groups),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(
        actions.updateAgeGroup({ key: 'open', value: true, reset: true })
      ),
    setAgeGroup = f => {
      dispatch(actions.updateAgeGroup({ key: 'id', value: f.ID }))
      dispatch(actions.updateAgeGroup({ key: 'name', value: f.name }))
      dispatch(actions.updateAgeGroup({ key: 'open', value: true }))
    }
  useEffect(() => {
    dispatch(getAgeGroups())
  }, [])
  return (
    <div>
      <NewAgeGroupDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setAgeGroup)()}
              data={age_groups}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)
