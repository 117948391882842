import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { updateSearchQuery, searchCustomers } from './../actions'
import { actions } from './../redux'
import { colors } from './../../consts'

class SearchBar extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={8} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search customer..."
              onChange={e => this.props.updateSearchQuery(e.target.value)}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  this.props.searchCustomers()
                }
              }}
            />
          </Grid>
          {!this.props.noCreate && (
            <Grid item xs={2} style={{ width: '100%' }}>
              <Button
                color="primary"
                component="span"
                style={{
                  width: '100%',
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
                onClick={() => this.props.openCreationModal()}
              >
                <b>New customer</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSearchQuery: q => dispatch(updateSearchQuery(q)),
  searchCustomers: () => dispatch(searchCustomers()),
  openCreationModal: () =>
    dispatch(actions.toggleCustomerCreationModal({ open: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
