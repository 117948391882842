import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import EditPlayer from './forms/EditPlayer'

import { togglePlayerModal, updateEPlayer } from './../actions'

class PlayerDialog extends React.Component {
  render() {
    const { open, handleClose, updateEPlayer } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Edit Player
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <EditPlayer />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateEPlayer()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.league.player_profile_dialog_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(togglePlayerModal(false)),
  updateEPlayer: () => dispatch(updateEPlayer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDialog)
