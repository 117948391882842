import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'
import { unixToDateTime } from './../helper'
import api from 'api'

const _getSlots = () => (dispatch, getState) => {
  let url = `/slot`
  const { facility_id, user_type } = getState().user
  if (user_type !== 'admin') {
    url = url + '?facility_id=' + facility_id
  }
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(
        actions.getAll({
          slots: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getSlots = () => (dispatch, getState) => dispatch(_getSlots())

const getUTCMinutes = d => {
  const p = unixToDateTime(d),
    hours = parseInt(p.format('HH')),
    minutes = parseInt(p.format('mm'))
  return hours * 3600 + minutes * 60
}

const _createSlot = body => {
  return axios.post(ERP_BACKEND_URL + `/slot`, body)
}

export const createSlot = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const create_slot = getState().soccernaut.create_slot
  let slots = [],
    group_id = `${create_slot.facility.ID}`
  for (let i = 0; i < create_slot.sel_fields.length; i++) {
    const field = create_slot.sel_fields[i]
    for (let j = 0; j < create_slot.sel_days_of_week.length; j++) {
      const day_of_week = create_slot.sel_days_of_week[j]
      slots.push({
        facility_id: create_slot.facility.ID,
        field_id: field,
        day_of_week: day_of_week,
        start_time: getUTCMinutes(create_slot.start_time),
        end_time: getUTCMinutes(create_slot.end_time),
        start_date: create_slot.start_date,
        end_date: create_slot.end_date,
        product_type_id: 14, // soccernaut
      })
      group_id += `_${field}_${day_of_week}`
    }
  }
  return Promise.all(slots.map(slot => _createSlot({ ...slot, group_id })))
    .then(resp => {
      dispatch(_getSlots())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteSlot = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/slot?group_id=${id}`)
    .then(resp => {
      dispatch(actions.deleteSlot({ id }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getEventsToAssign = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let url = `/event/soccernaut?`
  const { facility_id, user_type } = getState().user
  const { start_date, end_date } = getState().soccernaut.filter
  if (user_type !== 'admin') {
    url = `${url}facility_id=${facility_id}&`
  }
  if (start_date) {
    url = `${url}start_date=${start_date}&`
  }
  if (end_date) {
    url = `${url}end_date=${end_date}`
  }
  return api.soccernaut
    .get(url)
    .then(events => {
      dispatch(actions.getEvents({ events }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _updateSoccernautCoach = (event_id, coach_id) => {
  return axios.put(
    ERP_BACKEND_URL + `/event/coach/${event_id}?coach_id=${coach_id}`
  )
}

export const updateSoccernautCoach = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { events, assign } = getState().soccernaut,
    p = events
      .filter(ev => ev.selected)
      .map(ev => _updateSoccernautCoach(ev.ID, assign.coach_id))
  return Promise.all(p)
    .then(() => {
      dispatch(actions.removeSelectedEvents())
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}
