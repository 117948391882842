import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'

import { generateHubspotList } from 'common/actions'
import { actions } from 'common/redux'

const HubspotListDialog = () => {
  const { open, list_id } = useSelector(state => state.common.hubspot),
    dispatch = useDispatch(),
    handleClose = () => {
      dispatch(actions.updateHubspot({ key: 'open', value: false }))
      dispatch(actions.updateHubspot({ key: 'payload', value: null }))
      dispatch(actions.updateHubspot({ key: 'list_id', value: null }))
    },
    _generateHubspotList = () => dispatch(generateHubspotList())
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Create new hubspot list
      </DialogTitle>
      <DialogContent>
        {list_id ? (
          <Typography variant="body1">
            Property <i>custom_list__hydra_</i> : <b>{list_id}</b>
            <br />
            <Link
              href="https://app.hubspot.com/contacts/4549148/objectLists/create"
              target="_blank"
            >
              Create list
            </Link>
          </Typography>
        ) : (
          <Button autoFocus onClick={_generateHubspotList} className="save-btn">
            Generate
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {list_id ? 'Close' : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HubspotListDialog
