import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ProfileIcon from '@material-ui/icons/AssignmentInd'
import ScheduleIcon from '@material-ui/icons/Assignment'

import PlayerMatchesDialog from './PlayerMatchesDialog'

import { deletePlayerSignup } from './../../actions'
import { openDeleteModal } from './../../../common/actions'

import {
  getPlayerMatchesModal,
  getEPlayerDetails,
  togglePlayerModal,
} from './../../actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class PlayerTable extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <PlayerMatchesDialog />
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '25%' }}
                  className="table-header"
                >
                  Console ID
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Discord Name
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '25%' }}
                  className="table-header"
                >
                  Email
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.players.map(({ ID, player }) => (
                <TableRow key={player.ID} hover style={{ cursor: 'pointer' }}>
                  <TableCell
                    style={{ textAlign: 'center', width: '15% !important' }}
                  >
                    {player.customer.first_name} {player.customer.last_name}
                    {this.props.no_show_map[player.ID] && (
                      <span style={{ color: 'red' }}>
                        {' '}
                        ({this.props.no_show_map[player.ID]})
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '25% !important' }}
                  >
                    {player.console_id.slice(0, 15)}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '15% !important' }}
                  >
                    {player.discord_username.slice(
                      0,
                      player.discord_username.indexOf('#') > 0
                        ? player.discord_username.indexOf('#')
                        : player.discord_username.length
                    )}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '25% !important' }}
                  >
                    {player.customer.email.slice(
                      0,
                      player.customer.email.indexOf('#') > 0
                        ? player.customer.email.indexOf('#')
                        : player.customer.email.length
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    <IconButton
                      className={classes.root}
                      onClick={() =>
                        this.props.getPlayerProfileModal(player.ID)
                      }
                    >
                      <ProfileIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={classes.root}
                      onClick={() =>
                        this.props.getPlayerMatchesModal(player.ID)
                      }
                    >
                      <ScheduleIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={classes.root}
                      onClick={() => this.props.deletePlayer(ID)}
                    >
                      <DeleteIcon color="secondary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let no_show_map = {}
  state.league.league_matches.forEach(m => {
    if (
      m.home_checked_in &&
      !m.away_checked_in &&
      m.home_score === '3' &&
      m.away_score === '0'
    ) {
      if (!(m.away_player_id in no_show_map)) {
        no_show_map[m.away_player_id] = 0
      }
      no_show_map[m.away_player_id] += 1
    }
    if (
      m.away_checked_in &&
      !m.home_checked_in &&
      m.away_score === '3' &&
      m.home_score === '0'
    ) {
      if (!(m.home_player_id in no_show_map)) {
        no_show_map[m.home_player_id] = 0
      }
      no_show_map[m.home_player_id] += 1
    }
  })
  return {
    players: state.league.league.e_players,
    no_show_map,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPlayerMatchesModal: id => dispatch(getPlayerMatchesModal(id)),
  getPlayerProfileModal: id => {
    dispatch(getEPlayerDetails(id))
    dispatch(togglePlayerModal(true))
  },
  deletePlayer: id =>
    dispatch(openDeleteModal('player', () => deletePlayerSignup(id))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlayerTable))
