import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import HelpIcon from '@material-ui/icons/Help'

const LogIcon = ({ log_action }) => {
  if (log_action === 'create') {
    return (
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
    )
  }
  if (log_action === 'update') {
    return (
      <ListItemIcon>
        <CreateIcon />
      </ListItemIcon>
    )
  }
  if (log_action === 'delete') {
    return (
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
    )
  }
  return (
    <ListItemIcon>
      <HelpIcon />
    </ListItemIcon>
  )
}

const LogList = ({ log_type, logs, is_admin_or_manager }) => {
  if (!is_admin_or_manager) return null
  return (
    <Paper elevation={0} style={{ marginTop: 20 }}>
      <List
        component="nav"
        aria-label="object changes"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Changes made
          </ListSubheader>
        }
        dense
      >
        {logs[log_type] &&
          logs[log_type].map(log => (
            <ListItem key={log.log.ID}>
              <LogIcon log_action={log.log.action} />
              <ListItemText
                primary={
                  <div>
                    {log.changes.map((change, index) => (
                      <span key={index}>
                        {change}
                        <br />
                      </span>
                    ))}
                  </div>
                }
                secondary={
                  <div>
                    <b>
                      {log.log.user.first_name} {log.log.user.last_name}
                    </b>{' '}
                    on {log.log.date}
                  </div>
                }
              />
            </ListItem>
          ))}
      </List>
    </Paper>
  )
}

const mapStateToProps = state => ({
  logs: state.common.logs,
  is_admin_or_manager: state.user.is_admin_or_manager,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LogList)
