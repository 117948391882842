import api from 'api'
import { changeLoading, dispatchError } from '../common/actions'
import { actions } from './redux'
import { addFacilityIdToQuery } from './helpers'

export const getMatches = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const search_details = getState().referee.search
  let url = `/ref/matches`

  if (!search_details.start_date || !search_details.end_date) {
    return dispatch(
      dispatchError({ message: 'Please enter a start and an end date' })
    )
  }
  url = `${url}?start_date=${search_details.start_date}&end_date=${search_details.end_date}`

  const { facility_id, user_type } = getState().user
  if (user_type !== 'admin') {
    url = `${url}&facility_id=${facility_id}`
  } else {
    if (search_details.facility_id) {
      url = `${url}&facility_id=${search_details.facility_id}`
    }
  }

  return api.referee
    .getMatches(url)
    .then(matches => {
      dispatch(actions.getMatches({ matches }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateSearch = (field, value) => (dispatch, getState) => {
  dispatch(
    actions.updateSearch({
      field,
      value,
    })
  )
}

export const updateMatchIds = (id, facility_id) => (dispatch, getState) => {
  dispatch(actions.updateMatchIds({ id, facility_id }))
}

export const toggleModal = open => (dispatch, getState) => {
  if (open) {
    const { match_facility } = getState().referee
    const facilities_id = new Set([...Object.values(match_facility)])
    if (facilities_id.size > 1) {
      return dispatch(
        dispatchError({
          message: 'Please select matches from the same facility',
        })
      )
    }
  }
  dispatch(actions.toggleModal({ open }))
}

export const handleRefereeChange = referee => (dispatch, getState) => {
  dispatch(actions.updateReferee({ referee }))
}
export const assignReferee = () => (dispatch, getState) => {
  const { referee: referee_id, matchIds: match_ids } = getState().referee
  if (!match_ids.length || !referee_id) {
    return dispatch(
      dispatchError({
        message: 'Please select valid matches and a referee',
      })
    )
  }
  dispatch(changeLoading(true))
  const payload = { referee_id, match_ids }

  return api.referee
    .assignReferee(payload)
    .then(matches => {
      dispatch(getMatches())
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getReferees = () => (dispatch, getState) => {
  const query = addFacilityIdToQuery(getState().user)
  const { match_facility } = getState().referee
  query.facility_id = query.facility_id || [...Object.values(match_facility)][0]

  return api.referee
    .getReferees(query)
    .then(referees => {
      dispatch(actions.getReferees({ referees }))
    })
    .catch(e => dispatch(dispatchError(e)))
}
