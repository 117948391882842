import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import NewFacilityDialog from 'resources/components/NewFacilityDialog'
import { Button } from '@material-ui/core'

import { actions } from 'resources/redux'
import { getFees } from 'product/actions'
import NewTaxFeeDialog from 'resources/components/NewTaxDialog'

const sortIcon = <ArrowDownward />

const columns = setTax =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: tax => tax.ID,
    },
    {
      name: 'Name',
      selector: 'name',
      center: true,
      cell: tax => tax.name,
    },
    {
      name: 'Value',
      selector: 'value',
      center: true,
      cell: tax => `${tax.value}%`,
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: tax => (
        <>
          <IconButton onClick={() => setTax(tax)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = () => {
  const taxes = useSelector(state => state.product.fees),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(actions.updateTax({ key: 'open', value: true, reset: true })),
    setTax = f => {
      dispatch(actions.updateTax({ key: 'id', value: f.ID }))
      dispatch(actions.updateTax({ key: 'name', value: f.name }))
      dispatch(actions.updateTax({ key: 'value', value: f.value }))
      dispatch(actions.updateTax({ key: 'open', value: true }))
    }
  useEffect(() => {
    dispatch(getFees())
  }, [])
  return (
    <div>
      <NewTaxFeeDialog type="fee" />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setTax)()}
              data={taxes}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)
