import { unixToDateTime } from './../helper'

export const parseEventLogs = logs => {
  return logs
    .map((log, i) => {
      let changes = []
      Object.keys(logs[i]).forEach(key => {
        if (
          key !== 'log' &&
          logs[i - 1] &&
          logs[i - 1][key] &&
          logs[i - 1][key] !== logs[i][key]
        ) {
          let initial_value = logs[i - 1][key],
            final_value = logs[i][key]
          if (key === 'start') {
            initial_value =
              unixToDateTime(initial_value).format('DD/MM/YYYY h:mmA')
            final_value = unixToDateTime(final_value).format('DD/MM/YYYY h:mmA')
          }
          if (key === 'end') {
            initial_value =
              unixToDateTime(initial_value).format('DD/MM/YYYY h:mmA')
            final_value = unixToDateTime(final_value).format('DD/MM/YYYY h:mmA')
          }
          changes.push(`${key}: ${initial_value} -> ${final_value}`)
        }
      })
      return { ...log, changes }
    })
    .reverse()
}

export const parseInvoiceLogs = logs => {
  return logs
    .map((log, i) => {
      let changes = [],
        action = 'create'
      if (log.log.action === 'create_payment') {
        changes = [`Payment amount=$${log.amount}, type=${log.ptype}`]
      }
      if (log.log.action === 'delete_payment') {
        action = 'delete'
        changes = [
          `Payment deleted, amount=$${log.amount}, type=${log.ptype}, refund? ${log.refunded}`,
        ]
      }
      if (log.log.action === 'create_invoice_item') {
        changes = [`New invoice item amount=$${log.price}`]
      }
      if (log.log.action === 'update_invoice_item') {
        changes = [`Updated fee amount=$${log.price}`]
      }
      return { ...log, log: { ...log.log, action }, changes }
    })
    .reverse()
}
