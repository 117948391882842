import React from 'react'
import { connect } from 'react-redux'

import TextField from 'common/components/FastTextField'
import Grid from '@material-ui/core/Grid'

class MatchSection extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <TextField
            label="League"
            fullWidth
            value={this.props.match.league.name}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Home team"
            fullWidth
            value={this.props.match.home_team.name}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Away team"
            fullWidth
            value={this.props.match.away_team.name}
            disabled
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.calendar.event_details,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MatchSection)
