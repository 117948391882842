import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import NewFacilityDialog from 'resources/components/NewFacilityDialog'
import { Button } from '@material-ui/core'

import { actions } from 'resources/redux'

const sortIcon = <ArrowDownward />

const columns = setFacility =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: facility => facility.ID,
    },
    {
      name: 'Name',
      selector: 'name',
      center: true,
      cell: facility => facility.name,
    },
    {
      name: 'Nickname',
      selector: 'nickname',
      center: true,
      cell: facility => facility.nickname,
    },
    {
      name: 'Address',
      selector: 'address',
      center: true,
      cell: facility => facility.address,
    },
    {
      name: 'Tax rate',
      selector: 'tax_rate',
      center: true,
      cell: facility => `${facility.tax_rate * 100}%`,
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: facility => (
        <>
          <IconButton onClick={() => setFacility(facility)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = () => {
  const facilities = useSelector(state => state.common.facilities),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(
        actions.updateFacility({ key: 'open', value: true, reset: true })
      ),
    setFacility = f => {
      dispatch(actions.updateFacility({ key: 'id', value: f.ID }))
      dispatch(actions.updateFacility({ key: 'name', value: f.name }))
      dispatch(actions.updateFacility({ key: 'nickname', value: f.nickname }))
      dispatch(actions.updateFacility({ key: 'address', value: f.address }))
      dispatch(
        actions.updateFacility({
          key: 'tax_rate',
          value: `${f.tax_rate * 100}`,
        })
      )
      dispatch(actions.updateFacility({ key: 'open', value: true }))
    }
  return (
    <div>
      <NewFacilityDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setFacility)()}
              data={facilities}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)
