const Entities = require('html-entities').AllHtmlEntities
const entities = new Entities()

export const cleanString = s => {
  return entities.decode(s).trim()
}

export const cleanFieldName = name => {
  if (name.indexOf('-') === -1) {
    return name.trim()
  }
  const spl = name.split('-'),
    number = spl[0].trim().toLowerCase(),
    field = spl[1].trim().toLowerCase()
  // return number and field with first letter capitalized
  return (
    number +
    ' - ' +
    field
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  )
}

export const filterMatches = (matches, filters) => {
  let filtered_matches = matches
  if (filters.centers && filters.centers.length) {
    const array_val = filters.centers.map(c => c.value)
    filtered_matches = filtered_matches.filter(
      m => array_val.indexOf(m.center) !== -1
    )
  }
  if (filters.events && filters.events.length) {
    const array_val = filters.events.map(c => c.value)
    filtered_matches = filtered_matches.filter(
      m => array_val.indexOf(m.event) !== -1
    )
  }
  if (filters.fields && filters.fields.length) {
    const array_val = filters.fields.map(c => c.value)
    filtered_matches = filtered_matches.filter(
      m => array_val.indexOf(m.fieldname) !== -1
    )
  }
  if (filters.refs && filters.refs.length) {
    const array_val = filters.refs.map(c => c.value)
    filtered_matches = filtered_matches.filter(
      m => array_val.indexOf(m.refid) !== -1
    )
  }
  return filtered_matches
}
