import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import LineTo from 'react-lineto'
import Link from '@material-ui/core/Link'

import { getPlayoffMatches } from './../../actions'
import { openInNewTab } from './../../../helper'

class EPlayOffsTable extends React.Component {
  componentDidMount() {
    this.props.getPlayoffMatches()
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
        >
          {this.props.levels.map((level, index) => (
            <Grid
              item
              xs={2}
              style={{ width: '100%', height: '100%' }}
              key={index}
            >
              <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12}>
                  Round of {level}
                </Grid>
                {this.props.matches
                  .filter(m => m.level === level)
                  .map(m => (
                    <Grid
                      item
                      xs={12}
                      style={{ width: '100%', border: '1px solid black' }}
                      className={m.index.toString()}
                      key={m.ID}
                    >
                      <div>
                        <Link
                          onClick={() =>
                            openInNewTab(
                              '/customer/' +
                                m.home_player.customer.ID.toString()
                            )
                          }
                        >
                          {m.home_player.console_id}
                        </Link>
                        : {m.home_score}
                        <br />
                        <Link
                          onClick={() =>
                            openInNewTab(
                              '/customer/' +
                                m.away_player.customer.ID.toString()
                            )
                          }
                        >
                          {m.away_player.console_id}
                        </Link>
                        : {m.away_score}
                        <LineTo
                          from={m.index.toString()}
                          to={(
                            Math.round(m.index / 2) +
                            Math.round(this.props.maxLevel / 2)
                          ).toString()}
                          fromAnchor="right"
                          toAnchor="left"
                        />
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const levels = [...new Set(state.league.playoff_matches.map(m => m.level))],
    maxLevel = Math.max.apply(Math, levels)
  return {
    matches: state.league.playoff_matches,
    levels,
    maxLevel,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPlayoffMatches: () => dispatch(getPlayoffMatches()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EPlayOffsTable)
