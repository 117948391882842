import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import CustomerDetails from './../forms/CustomerDetails'
import CustomerFamily from './../forms/CustomerFamily'
import CustomerEPlayer from './../forms/CustomerEPlayer'

const Profile = props => {
  const { customer } = props

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12}>
        <CustomerDetails />
      </Grid>
      <Grid item xs={12}>
        <CustomerFamily />
      </Grid>
      {customer.eplayer && (
        <Grid item xs={12}>
          <CustomerEPlayer />
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  customer: state.customer.customer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
