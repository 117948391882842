import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import LogList from 'common/components/LogTable'

import { getEventLogs } from 'common/actions'

const InvoiceLogs = ({ invoice, getInvoiceLogs }) => {
  useEffect(() => {
    getInvoiceLogs(invoice?.ID)
  }, [invoice, getInvoiceLogs])

  return <LogList log_type="invoice" />
}

const mapStateToProps = (state, ownProps) => ({
  invoice: state.invoice.invoice,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInvoiceLogs: id => dispatch(getEventLogs('invoice', id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceLogs)
