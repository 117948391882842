import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { getHealthChecks } from 'calendar/actions'

class HealthCheckSection extends React.Component {
  componentDidMount() {
    this.props.getHealthChecks()
  }
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center', marginTop: 20 }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    First Name
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Last Name
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Email</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.participants.map((participant, i) => (
                  <TableRow key={`${participant.ID}_${i}`}>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.customer.first_name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.customer.last_name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.customer.email}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Link
                        href={`/customer/${participant.customer.ID}`}
                        target="_blank"
                      >
                        <IconButton aria-label="delete">
                          <ExitToAppIcon fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  participants: state.calendar.health_checks,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getHealthChecks: () => dispatch(getHealthChecks()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HealthCheckSection)
