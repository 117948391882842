import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

import { updateMatchScore } from 'league/actions'

class LeagueSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      home_score: null,
      away_score: null,
    }
  }

  componentDidMount() {
    this.setState({
      home_score: this.props.match?.home_score,
      away_score: this.props.match?.away_score,
    })
  }

  render() {
    const { match } = this.props
    return (
      <Paper elevation={0}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center', marginTop: 20 }}
          spacing={4}
        >
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Link href={`/league/${match.league_id}`} target="_blank">
              <Button className="save-btn">Goto league</Button>
            </Link>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <Link href={`/team/${match?.home_team.ID}`} target="_blank">
              {match?.home_team.name}
            </Link>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <TextField
              label="HScore"
              fullWidth
              dense
              value={this.state.home_score}
              onChange={e => this.setState({ home_score: e.target.value })}
              onBlur={() =>
                this.props.updateMatchScore(
                  match.ID,
                  'home_score',
                  this.state.home_score
                )
              }
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <TextField
              label="VScore"
              fullWidth
              dense
              value={this.state.away_score}
              onChange={e => this.setState({ away_score: e.target.value })}
              onBlur={() =>
                this.props.updateMatchScore(
                  match.ID,
                  'away_score',
                  this.state.away_score
                )
              }
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <Link href={`/team/${match?.away_team.ID}`} target="_blank">
              {match?.away_team.name}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  match: state.calendar.event_details?.match,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMatchScore: (id, side, score) =>
    dispatch(updateMatchScore(id, side, score)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueSection)
