import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

class AdminRoute extends React.Component {
  render() {
    const Component = this.props.component
    return this.props.is_admin ||
      (this.props.allow_managers && this.props.is_manager) ? (
      <Component />
    ) : (
      <Redirect to={{ pathname: '/' }} />
    )
  }
}

const mapStateToProps = state => ({
  is_admin: state.user.user_type === 'admin',
  is_manager: state.user.user_type === 'manager',
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute)
