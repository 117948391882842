import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import { red, green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'

import { resetScoreboard } from './../actions'

const styles = theme => ({
  card: {
    maxWidth: 345,
  },
  avatar_red: {
    backgroundColor: red[500],
  },
  avatar_green: {
    backgroundColor: green[500],
  },
})

var QRCode = require('qrcode.react')

class Scoreboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_qr: false,
    }
  }

  _downloadQR(facility, field, element_id) {
    const canvas = document.getElementById(element_id)
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${facility}_${field}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  downloadQR(facility, field, element_id) {
    this.setState({ show_qr: true }, () => {
      this._downloadQR(facility, field, element_id)
      this.setState({ show_qr: false })
    })
  }

  render() {
    const { classes, data } = this.props

    return (
      <Card className={classes.card} elevation={0}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={
                data.connected ? classes.avatar_green : classes.avatar_red
              }
            >
              {data.connected ? 'I' : 'O'}
            </Avatar>
          }
          title={
            <div>
              {data.field.number} - {data.field.name} <small>{data.port}</small>
            </div>
          }
          subheader={data.facility.name}
        />
        {data.game && (
          <CardContent>
            {data.game.home || 'Home team'}: {data.game.home_score}
            <br />
            {data.game.away || 'Away team'}: {data.game.away_score}
            <br />
            Start:{' '}
            {data.game.timer &&
              moment().subtract(data.game.timer, 's').format('h:mm a')}
          </CardContent>
        )}
        {this.state.show_qr && (
          <QRCode
            id={data.port.toString()}
            value={JSON.stringify({ port: data.port })}
            size={290}
            level={'H'}
            includeMargin={true}
          />
        )}
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => this.props.resetScoreboard(data.port)}
          >
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            onClick={() =>
              this.downloadQR(
                data.facility.name,
                data.field.name,
                data.port.toString()
              )
            }
          >
            Download QR
          </Button>
        </CardActions>
      </Card>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetScoreboard: id => dispatch(resetScoreboard(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Scoreboard))
