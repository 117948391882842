import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  scoreboards: [],
  filters: {
    facilities: [],
    action: null,
    status: null,
  },
}

const scoreboardSlice = createSlice({
    name: 'scoreboard',
    initialState,
    reducers: {
      getAll: (state, { payload }) => {
        state.scoreboards = payload.scoreboards
          .map(s => ({ ...s, connected: false }))
          .sort((a, b) => a.ID - b.ID)
      },
      editFilter: (state, { payload }) => {
        state.filters = {
          ...state.filters,
          [payload.key]: payload.value,
        }
      },
      addScoreboardSocket: (state, { payload }) => {
        state.scoreboards = state.scoreboards.map(s => {
          if (
            payload.scoreboard.origin === 'scoreboard' &&
            s.port.toString() === payload.scoreboard.id
          ) {
            return { ...s, connected: payload.scoreboard.connected }
          }
          return s
        })
      },
      setMatchSocket: (state, { payload }) => {
        state.scoreboards = state.scoreboards.map(s => {
          if (s.port.toString() === payload.id) {
            let game = s.game || {}
            if (payload.game) {
              game = {
                ...game,
                ...payload.game,
              }
            } else {
              game = null
            }
            return { ...s, game }
          }
          return s
        })
      },
    },
  }),
  actions = scoreboardSlice.actions

export { scoreboardSlice, actions }
