import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import StandingsTable from 'league/components/pages/Standings'
import Schedule from './Schedule'

import { getMatches } from 'league/actions'

class Standings extends React.Component {
  componentDidMount() {
    this.props.getMatches()
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ paddingTop: '1%' }}
        spacing={4}
      >
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Schedule />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <StandingsTable current_team_id={this.props.team_id} />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  team_id: state.teams.team.ID,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMatches: () => dispatch(getMatches()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Standings)
