import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

const DownloadRefApp = () => {
  return (
    <Grid
      container
      spacing={3}
      alignContent="center"
      justify="center"
      style={{ height: '100vh' }}
    >
      <Grid item md={6}>
        <Grid container justify="center" alignContent="center" spacing={2}>
          <Grid
            item
            md={6}
            sm={12}
            justify="center"
            style={{ textAlign: 'center' }}
          >
            <a
              href="https://apps.apple.com/us/app/sofive-ref/id1550367094"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button size="large" variant="contained" color="primary">
                Download app on the appstore
              </Button>
            </a>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            justify="center"
            style={{ textAlign: 'center' }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.sofive.refapp"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button size="large" variant="contained" color="secondary">
                Download app on the playstore
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DownloadRefApp
