import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import Settings from './Settings'
import Confirm from './Confirm'
import BuiltSchedule from './BuiltSchedule'

import { createMatches, createEMatches } from './../../actions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps() {
  return ['Schedule settings', 'Confirmation', 'Scheduled matches']
}

const SchedulerWizard = ({
  is_e_league,
  createMatches,
  createEMatches,
  create_ok,
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper style={{ padding: '2%' }} elevation={0}>
        <Divider style={{ marginBottom: '2%' }} />
        {activeStep === 0 && <Settings />}
        {activeStep === 1 && <Confirm />}
        {activeStep === 2 && <BuiltSchedule />}
        <div style={{ paddingTop: '3%', float: 'right' }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          {activeStep === 0 && (
            <Button onClick={handleNext} className="save-btn">
              Next
            </Button>
          )}
          {activeStep === 1 && (
            <Button onClick={handleNext} className="save-btn">
              Build Schedule
            </Button>
          )}
          {activeStep === 2 && create_ok && (
            <Button
              onClick={() => {
                if (is_e_league) {
                  return createEMatches()
                }
                createMatches()
              }}
              className="save-btn"
            >
              Save matches
            </Button>
          )}
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  is_e_league: state.league.league.is_e_league,
  create_ok:
    state.league.scheduler_matches.filter(m => m.to_check).length === 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  createMatches: () => dispatch(createMatches()),
  createEMatches: () => dispatch(createEMatches()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerWizard)
