import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

const TextEdit = ({ origName, onSave }) => {
  const [name, changeName] = useState(origName),
    [edit, changeEdit] = useState(false)
  if (!edit) {
    return (
      <div>
        {name}
        <IconButton onClick={() => changeEdit(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </div>
    )
  }
  return (
    <div>
      <TextField
        id="standard-adornment-amount"
        value={name}
        onChange={e => changeName(e.target.value)}
        fullWidth
        label="Name"
        onBlur={() => {
          onSave(name)
          changeEdit(false)
        }}
        autoFocus
      />
    </div>
  )
}

export default TextEdit
