import api from 'api'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'
import { buildTags } from './helper'

export const getMedias = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.media
    .get()
    .then(medias => {
      dispatch(
        actions.getMedias({
          medias: medias.map(media => {
            const [tags, details] = buildTags(media.facility, media.event)
            return {
              ...media,
              tags,
              details,
            }
          }),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}
