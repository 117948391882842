import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import NumberFormat from 'react-number-format'

import { actions } from 'contract/redux'

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.floatValue,
        },
      })
    }}
    thousandSeparator
    isNumericString
    prefix="$"
  />
)

class FeeForm extends React.Component {
  render() {
    return (
      <>
        <Grid item xs={12}>
          <Select
            label="Product fee"
            variant="outlined"
            fullWidth
            options={this.props.fees}
            placeholder="Select fee"
            value={
              this.props.fee_id &&
              this.props.fees.find(p => p.value === this.props.fee_id)
            }
            onChange={e => {
              this.props.changeContractDetails('fee_id', e.value)
            }}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </Grid>
        {this.props.fee_id === -1 && (
          <Grid item xs={12}>
            <TextField
              id="standard-adornment-amount"
              value={this.props.fee}
              onChange={e =>
                this.props.changeContractDetails('fee', e.target.value)
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              fullWidth
              label="Hourly fee amount"
            />
          </Grid>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.contract.new_contract,
  fees: [{ label: 'Manual fee', value: -1 }],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeContractDetails: (k, v) =>
    dispatch(actions.changeContractDetails({ field: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeForm)
