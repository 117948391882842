import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import ELeagueMatchesTable from './LeagueMatches'

import { updateMatchScore, togglePlayerMatchModal } from './../../actions'

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class PlayerMatchesDialog extends React.Component {
  render() {
    const { open, handleClose, classes } = this.props
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <ELeagueMatchesTable />
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.league.player_matches_dialog_open,
  matches: state.league.player_matches,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(togglePlayerMatchModal(false)),
  updateMatchScore: (id, side, score) =>
    dispatch(updateMatchScore(id, side, score)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlayerMatchesDialog))
