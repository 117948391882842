import { createSlice } from '@reduxjs/toolkit'
import { unixToDate, unixToDateTime } from './../helper'
import { momentToUnix } from './helpers'

const initialState = {
  referees: [],
  referee: 0,
  matches: [],
  matchIds: [],
  match_facility: {},
  referee_modal_open: false,
  search: {
    start_date: momentToUnix({ date: null }),
    end_date: momentToUnix({ date: null, end: true }),
    facility_id: null,
    assigned_type: 'no',
    field_number: null,
  },
}

const refereeSlice = createSlice({
    name: 'referee',
    initialState,
    reducers: {
      getAllreferees: (state, { payload }) => {
        state.referees = payload.referees
      },
      getreferee: (state, { payload }) => {
        state.referee = payload.referee
      },
      getMatches: (state, { payload }) => {
        state.matchIds = []
        state.match_facility = {}
        state.matches = payload.matches
          .map(l => ({
            ...l,
            date_parsed: unixToDate(l.event.start_date).format('M/D/YYYY'),
            time_parsed: unixToDateTime(l.event.start_date).format('h:mmA'),
          }))
          .sort((a, b) => {
            if (a.ID > b.ID) return 1
            if (a.ID < b.ID) return -1
            return 0
          })
      },
      updateSearch: (state, { payload }) => {
        state.search = {
          ...state.search,
          [payload.field]: payload.value,
        }
      },
      updateReferee: (state, { payload }) => {
        state.referee = payload.referee
      },
      toggleModal: (state, { payload }) => {
        state.referee_modal_open = payload.open
      },
      updateMatchIds: (state, { payload }) => {
        if (typeof state.match_facility[payload.id] !== 'undefined') {
          delete state.match_facility[payload.id]
        } else {
          state.match_facility[payload.id] = payload.facility_id
        }

        state.matchIds = Object.keys(state.match_facility).map(fac_id =>
          Number(fac_id)
        )
      },
      getReferees: (state, { payload }) => {
        state.referees = payload.referees
      },
    },
  }),
  actions = refereeSlice.actions

export { refereeSlice, actions }
