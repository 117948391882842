import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { getStandings } from './../../actions'

const styles = theme => ({
    maxheight: {
      maxHeight: 650,
      overflow: 'scroll',
    },
    root: {
      padding: 2,
    },
  }),
  group_values = [
    { label: 'All', value: 0 },
    { label: 'Group 1', value: 1 },
    { label: 'Group 2', value: 2 },
  ]

class StandingsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      group: 0,
    }
  }

  changeGroup(e) {
    this.setState({ group: e.value })
  }

  componentDidMount() {
    this.props.getStandings()
  }

  render() {
    const { standings, is_tournament, show_groups } = this.props
    return (
      <div>
        {is_tournament && show_groups ? (
          <div style={{ width: '30%', marginBottom: 20 }}>
            <Select
              options={group_values}
              placeholder="Select group *"
              value={
                this.state.group &&
                group_values.find(g => g.value === this.state.group)
              }
              onChange={this.changeGroup}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </div>
        ) : null}
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  No.
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Team
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Points
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Games
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  W
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  D
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  L
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5%' }}
                  className="table-header"
                >
                  GF
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5%' }}
                  className="table-header"
                >
                  GA
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5%' }}
                  className="table-header"
                >
                  GD
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {standings
                .filter(s => {
                  if (is_tournament && show_groups && this.state.group > 0) {
                    return s.group === this.state.group
                  }
                  return true
                })
                .map((standing, i) => (
                  <TableRow
                    key={standing.id}
                    hover
                    style={
                      standing.id === this.props.current_team_id
                        ? { backgroundColor: '#0080002e' }
                        : {}
                    }
                  >
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '20% !important' }}
                    >
                      {standing.team}{' '}
                      {is_tournament ? (
                        <small>
                          {' '}
                          - Group <b>{standing.group}</b>
                        </small>
                      ) : null}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15% !important' }}
                    >
                      {standing.points}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15% !important' }}
                    >
                      {standing.games}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {standing.won}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {standing.draw}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10% !important' }}
                    >
                      {standing.lost}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '5% !important' }}
                    >
                      {standing.gf}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '5% !important' }}
                    >
                      {standing.ga}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '5% !important' }}
                    >
                      {standing.gd}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  standings: state.league.league_standings,
  is_tournament: state.league.league?.is_tournament,
  show_groups: state.league.league?.teams.length > 5,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getStandings: () => dispatch(getStandings()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StandingsTable))
