import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Select from 'react-select'
import {
  DatePickerComponent,
  TimePickerComponent,
} from '@syncfusion/ej2-react-calendars'
import Grid from '@material-ui/core/Grid'
import TextField from 'common/components/FastTextField'

import { unixToDateTime, momentDateTimeToUnix } from './../../../helper'
import { getFields } from './../../actions'
import { actions } from './../../redux'
const { changeEventDetails, notifyRepeatChange } = actions

class ProductFieldTimeForm extends React.Component {
  componentDidMount() {
    if (
      this.props.field_id &&
      this.props.field &&
      this.props.field.facility_id
    ) {
      this.props.getFields(this.props.field.facility_id)
    }
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        {this.props.show_facility && (
          <Grid item xs={12}>
            <Select
              options={this.props.facilities}
              placeholder="Select facility..."
              value={
                this.props.facility_id &&
                this.props.facilities.find(
                  f => f.value === this.props.facility_id
                )
              }
              onChange={e => {
                this.props.changeEventDetails('facility_id', e.value)
                this.props.getFields(e.value)
                this.props.onChangeFacility &&
                  this.props.onChangeFacility(e.value)
              }}
              variant="outlined"
              isDisabled={this.props.disabled_facilities}
              fullWidth
            />
          </Grid>
        )}
        {!this.props.hide_ptype && (
          <Grid item xs={12}>
            <Select
              label="Product type"
              variant="outlined"
              fullWidth
              isDisabled
              value={{ label: this.props.product_type }}
            />
          </Grid>
        )}
        {!this.props.hide_fields && (
          <Grid item xs={12}>
            <Select
              options={this.props.fields}
              placeholder="Select field..."
              value={
                this.props.field_id &&
                this.props.fields.find(f => f.value === this.props.field_id)
              }
              onChange={e => this.props.changeEventDetails('field_id', e.value)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
        )}
        {!this.props.hide_start_date && (
          <Grid item xs={3}>
            <DatePickerComponent
              id="start_date"
              placeholder="Start date"
              value={this.props.start_time}
              step={15}
              onChange={e => {
                this.props.changeEventDetails(
                  'start_time',
                  momentDateTimeToUnix(moment(e.value))
                )
              }}
            />
          </Grid>
        )}
        {!this.props.hide_start_date && (
          <Grid item xs={3}>
            <TimePickerComponent
              id="start_time"
              placeholder="Start time"
              value={this.props.start_time}
              step={15}
              onChange={e =>
                this.props.changeEventDetails(
                  'start_time',
                  momentDateTimeToUnix(moment(e.value))
                )
              }
            />
          </Grid>
        )}
        {!this.props.hide_end_date && (
          <Grid item xs={3}>
            <DatePickerComponent
              id="end_date"
              placeholder="End date"
              value={this.props.end_time}
              step={15}
              onChange={e =>
                this.props.changeEventDetails(
                  'end_time',
                  momentDateTimeToUnix(moment(e.value))
                )
              }
            />
          </Grid>
        )}
        {!this.props.hide_end_date && (
          <Grid item xs={3}>
            <TimePickerComponent
              id="end_time"
              placeholder="End time"
              value={this.props.end_time}
              step={15}
              onChange={e =>
                this.props.changeEventDetails(
                  'end_time',
                  momentDateTimeToUnix(moment(e.value))
                )
              }
            />
          </Grid>
        )}
        {[3, 8].indexOf(this.props.product_type_id) > -1 && (
          <>
            <Grid item xs={6}>
              <TextField
                id="team_a"
                label="Team A"
                fullWidth
                variant="outlined"
                value={this.props.team_a}
                onChange={e =>
                  this.props.changeEventDetails('team_a', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="team_b"
                label="Team B"
                fullWidth
                variant="outlined"
                value={this.props.team_b}
                onChange={e =>
                  this.props.changeEventDetails('team_b', e.target.value)
                }
              />
            </Grid>
          </>
        )}
        {!this.props.hide_comment && (
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-static"
              label="Comment"
              multiline
              rows="3"
              fullWidth
              variant="outlined"
              value={this.props.comment}
              onChange={e =>
                this.props.changeEventDetails('comment', e.target.value)
              }
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  fields: state.calendar.fields.map(f => ({
    value: f.ID,
    label: f.number.toString() + ' - ' + f.name,
  })),
  facilities: state.common.filter_facilities,
  ...state.calendar.event_details,
  start_time: unixToDateTime(state.calendar.event_details.start_time).toDate(),
  end_time: unixToDateTime(state.calendar.event_details.end_time).toDate(),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeEventDetails: (k, v) => {
    dispatch(changeEventDetails({ field: k, value: v }))
    dispatch(notifyRepeatChange(true))
  },
  getFields: facility_id => dispatch(getFields(facility_id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFieldTimeForm)
