import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Filters from './../components/Filters'
import StaffTable from './../components/StaffTable'
import CreateStaffDialog from './../components/CreateStaffDialog'

import { getStaff } from './../actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getStaff()
  }

  render() {
    return (
      <Paper elevation={0} style={{ padding: 10 }}>
        <CreateStaffDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            <StaffTable />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getStaff: () => dispatch(getStaff()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
