import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filters'
import InvoiceTable from './../components/InvoiceTable'
import EventSelectionDialog from './../components/EventSelectionDialog'
import NewInvoiceDialog from './../components/NewInvoiceDialog'

import { searchInvoices } from './../actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.searchInvoices()
  }

  render() {
    return (
      <div>
        <EventSelectionDialog />
        <NewInvoiceDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            <InvoiceTable />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchInvoices: () => dispatch(searchInvoices()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
