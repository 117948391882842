import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

import DeletePaymentDialog from './ConfirmPaymentDelete'
import EditCreditDialog from './ConfirmAddCredit'
import InvoiceItemDetails from './InvoiceItemDetails'
import PaymentDetails from './PaymentDetails'
import UpdateFeeDialog from './UpdateFeeDialog'

import { colors } from 'consts'
import { openDeleteModal, openConfirmModal } from 'common/actions'
import { capitalizeFirst } from 'helper'
import { deleteDiscount, revertPayment } from 'invoice/actions'
import { actions } from 'invoice/redux'

const InvoiceDetails = ({
  is_admin,
  invoice,
  revertPayment,
  deleteDiscount,
  show_event,
  read_only,
  is_contract,
  refundPayment,
}) => {
  if (!invoice) {
    return (
      <TableContainer component={Paper} elevation={0} style={{ marginTop: 10 }}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className="table-header">Desc</TableCell>
              <TableCell align="center" className="table-header">
                Qty.
              </TableCell>
              <TableCell align="center" className="table-header">
                Amount
              </TableCell>
              <TableCell align="center" className="table-header">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell colSpan={1}>
                Total
                <br />
                <small>with tax</small>
              </TableCell>
              <TableCell align="center" colSpan={1}>
                $0
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell colSpan={1}>Paid</TableCell>
              <TableCell align="center" colSpan={1}>
                $0
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell colSpan={1}>Balance</TableCell>
              <TableCell align="center" colSpan={1}>
                <Chip
                  size="small"
                  label={<b>$0</b>}
                  style={{
                    color: colors.green_strong,
                    backgroundColor: colors.green_mild,
                    margin: 5,
                  }}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  return (
    <div>
      <EditCreditDialog />
      <UpdateFeeDialog />
      <DeletePaymentDialog />
      <TableContainer component={Paper} elevation={0} style={{ marginTop: 10 }}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className="table-header">Desc</TableCell>
              <TableCell align="center" className="table-header">
                Qty.
              </TableCell>
              <TableCell align="center" className="table-header">
                Amount
              </TableCell>
              <TableCell align="center" className="table-header">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.invoice_items.map(invoice_item => (
              <InvoiceItemDetails
                key={invoice_item.ID}
                invoice_item={invoice_item}
                is_contract={is_contract}
                read_only={read_only}
                show_event={show_event}
              />
            ))}
            {invoice.discounts.map(discount => (
              <TableRow key={discount.ID}>
                <TableCell>
                  {discount.discount_id
                    ? discount.discount.name
                    : `Custom discount (${discount.comment})`}
                  <br />
                  <small>(discount)</small>
                </TableCell>
                <TableCell align="center">1</TableCell>
                <TableCell align="center">
                  -
                  {discount.discount_id
                    ? discount.discount.value
                    : discount.amount}
                  {discount.discount.is_percentage ? '%' : '$'}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5% !important' }}
                >
                  {is_admin && (
                    <IconButton onClick={() => deleteDiscount(discount.ID)}>
                      <DeleteIcon
                        fontSize="small"
                        style={{
                          color: colors.red_strong,
                        }}
                      />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {invoice.payments.map(payment => (
              <TableRow key={payment.ID}>
                <TableCell>
                  {payment.reversal ? '[REVERSAL] ' : ''}
                  {payment.reversed ? '[REVERSED] ' : ''}
                  {payment.refunded ? '[REFUNDED] ' : ''}
                  {payment.refund ? '[REFUND] ' : ''}
                  {capitalizeFirst(payment.type)} payment <br />
                  <PaymentDetails payment={payment} />
                  {payment.player_customer_id ? (
                    <>
                      {!payment.details && <br />}
                      <small>
                        Paid by{' '}
                        {payment.customer?.ID ? (
                          <Link
                            href={`/customer/${payment.customer.ID}`}
                            target="_blank"
                          >
                            {payment.customer.first_name}{' '}
                            {payment.customer.last_name}
                          </Link>
                        ) : (
                          <Link
                            href={`/customer/${payment.player.ID}`}
                            target="_blank"
                          >
                            {payment.player.first_name}{' '}
                            {payment.player.last_name}
                          </Link>
                        )}
                      </small>
                    </>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  {payment.reversal || payment.refund ? '-' : ''}1
                </TableCell>
                <TableCell align="center">
                  ${Math.abs(payment.amount)}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '5% !important' }}
                >
                  {is_admin &&
                  !payment.reversed &&
                  !payment.reversal &&
                  !payment.refunded &&
                  !payment.refund ? (
                    <div>
                      <Button
                        disableElevation
                        variant="contained"
                        color="secondary"
                        startIcon={<RotateLeftIcon />}
                        onClick={() =>
                          revertPayment(payment.ID, payment.amount)
                        }
                        style={{
                          backgroundColor: colors.yellow_mild,
                          color: colors.yellow_strong,
                        }}
                      >
                        Revert
                      </Button>
                      <br />
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<MonetizationOnIcon />}
                        onClick={() =>
                          refundPayment(payment.ID, payment.amount)
                        }
                        style={{
                          backgroundColor: colors.red_mild,
                          color: colors.red_strong,
                          marginTop: 10,
                        }}
                      >
                        Refund
                      </Button>
                    </div>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
            {invoice.taxes > 0 && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={1}>Taxes</TableCell>
                <TableCell align="center" colSpan={1}>
                  ${invoice.taxes}
                </TableCell>
                <TableCell />
              </TableRow>
            )}
            {invoice.fees > 0 && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={1}>
                  Processing fees
                  <br />
                  <small>
                    ({invoice.facility.processing_fee * 100}
                    %)
                  </small>
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  ${invoice.fees}
                </TableCell>
                <TableCell />
              </TableRow>
            )}
            <TableRow>
              <TableCell />
              <TableCell colSpan={1}>
                Total
                <br />
                <small>with tax</small>
              </TableCell>
              <TableCell align="center" colSpan={1}>
                ${invoice.invoiced}
              </TableCell>
              <TableCell />
            </TableRow>
            {invoice.discounted > 0 && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={1}>Discount</TableCell>
                <TableCell align="center" colSpan={1}>
                  ${invoice.discounted}
                </TableCell>
                <TableCell />
              </TableRow>
            )}
            <TableRow>
              <TableCell />
              <TableCell colSpan={1}>Paid</TableCell>
              <TableCell align="center" colSpan={1}>
                ${invoice.paid}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell colSpan={1}>Balance</TableCell>
              <TableCell align="center" colSpan={1}>
                <Chip
                  size="small"
                  label={<b>${invoice.balance}</b>}
                  style={{
                    color:
                      invoice.balance <= 0
                        ? colors.green_strong
                        : colors.red_strong,
                    backgroundColor:
                      invoice.balance <= 0
                        ? colors.green_mild
                        : colors.red_mild,
                    margin: 5,
                  }}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  invoice: state.invoice.invoice,
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  revertPayment: (id, amount) =>
    dispatch(
      openConfirmModal(
        'Payment reversal',
        `revert this payment of $${amount}`,
        () => revertPayment(id, amount)
      )
    ),
  deleteDiscount: id =>
    dispatch(openDeleteModal('discount', () => deleteDiscount(id))),
  refundPayment: (id, amount) =>
    dispatch(
      actions.toggleDeletePaymentModal({
        open: true,
        del: { id, amount },
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
