import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { buildInvoiceData } from './../helper'
import { niceNumber, exportToCSV } from './../../helper'

const sortIcon = <ArrowDownward />

const conditionalRowStyles = [
  {
    when: row => row.balance > 0,
    style: {
      backgroundColor: '#FFE2E5',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#FFE2E5',
      },
    },
  },
  {
    when: row => row.balance < 0,
    style: {
      backgroundColor: '#C9F7F5',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#C9F7F5',
      },
    },
  },
  {
    when: row => row.balance > 0,
    style: {
      backgroundColor: '#FFE2E5',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#FFE2E5',
      },
    },
  },
  {
    when: row => row.total_invoices.length > 6,
    style: {
      minHeight: '100px',
      backgroundColor: '#FFE2E5',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#FFE2E5',
      },
    },
  },
]

const columns = memoize(() => [
  {
    name: 'Invoice',
    selector: 'invoice',
    center: true,
    grow: 3,
    cell: row => {
      const total = row.total_invoices.length
      const invoices = row.total_invoices.map((invoiceID, i) => (
        <span key={i}>
          <Link href={`/invoice/${invoiceID}`} target="_blank">
            {invoiceID}
          </Link>
          {total > 1 && row.total_invoices[i + 1] && ', '}
        </span>
      ))
      return <Grid>{invoices}</Grid>
    },
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    grow: 2,
    center: true,
    format: row => moment(row.CreatedAt).format('MM/DD/YYYY'),
    sortFunction: (rowA, rowB) => rowA.CreatedAt > rowB.CreatedAt,
  },
  {
    name: 'Customer',
    selector: 'customer',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <Link href={`/customer/${row.customer.ID}`} target="_blank">
        {row.customer.first_name + ' ' + row.customer.last_name}
      </Link>
    ),
  },
  {
    name: 'Location',
    selector: 'location',
    sortable: true,
    grow: 2,
    center: true,
    format: row => row.facility,
    sortFunction: (rowA, rowB) => rowA.facility > rowB.facility,
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.balance)}`,
    sortFunction: (rowA, rowB) => rowA.balance > rowB.balance,
  },
])

class InvoicesReport extends React.Component {
  render() {
    const { invoices } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Invoices</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        exportToCSV(buildInvoiceData(invoices), 'Invoices')
                      }
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={invoices}
              highlightOnHover
              defaultSortField="customer.first_name"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              pagination
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              conditionalRowStyles={conditionalRowStyles}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  invoices: state.reporting.outstanding_customers_array,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesReport)
