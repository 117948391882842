import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CreateSlotForm from './forms/CreateSlot'

import { createSlot } from './../actions'
import { actions } from './../redux'

class SlotDialog extends React.Component {
  render() {
    const { open, handleClose, createSlot } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Add new slot
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateSlotForm />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              createSlot()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.soccernaut.create_slot.dialog_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleCreateSlotModal({ open: false })),
  createSlot: () => dispatch(createSlot()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SlotDialog)
