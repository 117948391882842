import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import FastTextField from 'common/components/FastTextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { searchCustomers } from 'customer/actions'
import { actions } from './../../redux'
const { changeEventDetails, changeEventCustomerDetails, changeTabCustomer } =
  actions

class CustomerSection extends React.Component {
  render() {
    const { customers } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        {!this.props.hide_header && (
          <Grid item xs={12}>
            <Tabs
              value={this.props.tab}
              onChange={(e, val) => this.props.changeTabCustomer(val)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab value="existing" label="Existing customer" />
              {!this.props.no_create && (
                <Tab value="new" label="New customer" />
              )}
            </Tabs>
          </Grid>
        )}
        {!this.props.hide_header && this.props.tab === 'existing' && (
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={customers}
              getOptionLabel={option =>
                `${option.first_name} ${option.last_name} (${option.email})`
              }
              renderOption={option => (
                <p>
                  {option.first_name} {option.last_name}{' '}
                  {option.hubspot && option.hubspot.gender && (
                    <span>({option.hubspot.gender})</span>
                  )}
                  <br />
                  <span>
                    email=
                    {option.email}
                  </span>
                  {option.hubspot && option.hubspot.phone && (
                    <span>
                      <br />
                      phone=
                      {option.hubspot.phone}
                    </span>
                  )}
                  {option.hubspot && option.hubspot.state && (
                    <span>
                      <br />
                      state=
                      {option.hubspot.state}
                    </span>
                  )}
                </p>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Customer"
                  variant="outlined"
                  fullWidth
                  onChange={e => this.props.searchCustomers(e.target.value)}
                />
              )}
              value={this.props.customer}
              onChange={(e, c) => this.props.changeEventDetails('customer', c)}
            />
          </Grid>
        )}
        {(this.props.tab === 'new' || this.props.customer) && (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              style={{ textAlign: 'center' }}
              spacing={2}
            >
              <Grid item xs={6}>
                <FastTextField
                  label="First name"
                  fullWidth
                  value={this.props.customer && this.props.customer.first_name}
                  onChange={e =>
                    this.props.changeEventCustomerDetails(
                      'first_name',
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FastTextField
                  label="Last name"
                  fullWidth
                  value={this.props.customer && this.props.customer.last_name}
                  onChange={e =>
                    this.props.changeEventCustomerDetails(
                      'last_name',
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FastTextField
                  label="Email"
                  fullWidth
                  value={this.props.customer && this.props.customer.email}
                  onChange={e =>
                    this.props.changeEventCustomerDetails(
                      'email',
                      e.target.value
                    )
                  }
                  disabled={
                    this.props.no_create || this.props.tab === 'existing'
                  }
                />
                {this.props.customer?.ID && (
                  <Link
                    href={`/customer/${this.props.customer.ID}`}
                    target="_blank"
                  >
                    edit email address from profile
                  </Link>
                )}
              </Grid>
              <Grid item xs={6}>
                <FastTextField
                  label="Phone"
                  fullWidth
                  value={
                    this.props.customer &&
                    this.props.customer.hubspot &&
                    this.props.customer.hubspot.phone
                  }
                  onChange={e =>
                    this.props.changeEventCustomerDetails(
                      'hubspot.phone',
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  customers: state.customer.customers,
  ...state.calendar.event_details,
  tab: state.calendar.tab_customer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchCustomers: q => dispatch(searchCustomers(q)),
  changeEventDetails: (k, v) =>
    dispatch(changeEventDetails({ field: k, value: v })),
  changeEventCustomerDetails: (k, v) =>
    dispatch(changeEventCustomerDetails({ field: k, value: v })),
  toggleNewCustomer: v =>
    dispatch(changeEventDetails({ field: 'new_customer', value: v })),
  changeTabCustomer: tab => dispatch(changeTabCustomer({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSection)
