import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filters'
import ClassesTable from './../components/ClassesTable'
import CreateClassDialog from '../components/CreateClassDialog'

import { getClasses } from './../actions'

const SearchClasses = ({ getClasses }) => {
  useEffect(() => {
    getClasses()
  }, [getClasses])

  return (
    <div>
      <CreateClassDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ paddingTop: '2%' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={12}>
          <ClassesTable />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getClasses: () => dispatch(getClasses()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchClasses)
