import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import CreateSessionDialog from 'pickup/components/CreateSessionDialog'
import PresenceTable from 'pickup/components/PresenceTable'
import SessionsTable from 'pickup/components/SessionsTable'
import Filters from 'pickup/components/Filter'

class Manage extends React.Component {
  render() {
    return (
      <div>
        <CreateSessionDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            {this.props.tab === 'presence' && <PresenceTable />}
            {this.props.tab === 'slot' && <SessionsTable />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.pickup.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
