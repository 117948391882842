import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import { actions } from './../redux'

class DiscountFilters extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={2} style={{ width: '100%' }}>
            <ToggleButtonGroup
              value={this.props.tab}
              exclusive
              aria-label="text alignment"
            >
              <ToggleButton
                value="discounts"
                aria-label="left aligned"
                onClick={() => this.props.updateTab('discounts')}
              >
                Discounts
              </ToggleButton>
              <ToggleButton
                value="rules"
                aria-label="centered"
                onClick={() => this.props.updateTab('rules')}
              >
                Rules
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={8} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Filter..."
              value={this.props.query}
              onChange={e => this.props.editQuery(e.target.value)}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button variant="outlined" color="primary" component="span">
              Add new
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  query: state.discount.query,
  tab: state.discount.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  editQuery: q => dispatch(actions.editSearchQuery({ query: q })),
  updateTab: t => dispatch(actions.updateTab(t)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscountFilters)
