import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

import { unixToDateTime, momentDateToUnix } from 'helper'
import { day_of_week } from 'consts'

const initialState = {
  sessions: [],
  filter: {
    facility_id: null,
    start_date: momentDateToUnix(moment()),
    end_date: momentDateToUnix(moment()),
    query: '',
    day_of_week: null,
    facility_name: '',
  },
  fee_id: null,
  creation_modal_open: false,
  players: [],
  tab: 'presence',
}

const pickupSlice = createSlice({
    name: 'pickup',
    initialState,
    reducers: {
      getSlots: (state, { payload }) => {
        state.sessions = payload.sessions.map(session => {
          let slot = null
          if (session.events.length) {
            const event = session.events[0],
              start_date = unixToDateTime(event.start_date),
              end_date = unixToDateTime(event.end_date),
              sday = start_date.day() + 1,
              stime = start_date.format('h:mmA'),
              etime = end_date.format('h:mmA'),
              day = day_of_week.find(d => d.value === sday).label
            slot = {
              details: `${day} ${stime}-${etime}`,
              field: `${event.field.number}-${event.field.name}`,
              day: sday,
            }
          }
          return { ...session, slot }
        })
      },
      changeFilterDetails: (state, { payload }) => {
        state.filter = {
          ...state.filter,
          [payload.key]: payload.value,
        }
      },
      toggleCreationModal: (state, { payload }) => {
        state.creation_modal_open = payload.open
      },
      updateTab: (state, { payload }) => {
        state.tab = payload.tab
      },
      setPickupPlayers: (state, { payload }) => {
        state.players = payload.players.map(p => {
          const parsed_start = unixToDateTime(p.start_date),
            parsed_end = unixToDateTime(p.end_date)
          return {
            ...p,
            day: parsed_start.format('dddd'),
            date: parsed_start.format('M/D/YYYY'),
            start_time: parsed_start.format('h:mmA'),
            end_time: parsed_end.format('h:mmA'),
          }
        })
      },
      checkIn: (state, { payload }) => {
        state.players = state.players.map(player => {
          if (player.ID === payload.id) {
            return { ...player, checked_in: payload.checked_in }
          }
          return player
        })
      },
      updateFee: (state, { payload }) => {
        state.fee_id = payload.fee_id
      },
    },
  }),
  actions = pickupSlice.actions

export { pickupSlice, actions }
