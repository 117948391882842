import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'

import Select from 'react-select'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import InvoiceDetails from './InvoiceDetails'
import PaymentDialog from './NewPaymentDialog'
import DiscountDialog from './DiscountDialog'
import InvoiceLogs from './../components/InvoiceChangelog'

import {
  getFees,
  addInvoiceItem,
  updateInvoiceItem,
  sendInvoiceToCustomer,
} from 'invoice/actions'
import { actions } from 'invoice/redux'
import { openConfirmModal } from 'common/actions'

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.floatValue,
        },
      })
    }}
    thousandSeparator
    isNumericString
    prefix="$"
  />
)

const Invoice = ({
  invoice,
  fees,
  event_id,
  customer_id,
  fee_id,
  invoice_item,
  edit,
  is_not_reverse,
  product_type_id,
  facility_id,
  is_contract,
  read_only,
  getFees,
  updateFee,
  addInvoiceItem,
  openPaymentModal,
  openDiscountModal,
  updateInvoiceItem,
  sendInvoiceToCustomer,
  quantity,
  include_manual,
  manual_fee,
  updateManualFee,
}) => {
  const [showAddInvoiceItem, editShowAddInvoiceItem] = useState(false)
  useEffect(() => {
    getFees(product_type_id, facility_id)
  }, [getFees, product_type_id, facility_id])

  if (include_manual) fees = fees.concat({ label: 'Manual fee', value: -1 })

  return (
    <div>
      <PaymentDialog is_contract={is_contract} />
      <DiscountDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        {invoice && (
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="h2" style={{ color: 'red' }}>
              {invoice.reversed ? '[REVERSED]' : ''}
              {invoice.reversal ? '[REVERSAL]' : ''}
            </Typography>
            <Typography variant="h5">Invoice #{invoice.ID}</Typography>
            <Link
              target="_blank"
              href={`https://webapp.sofive.com/invoice.html?invoice_id=${btoa(
                '1:' + invoice.ID
              )}`}
            >
              Open customer invoice
            </Link>
            <br />
            <Link style={{ cursor: 'pointer' }} onClick={sendInvoiceToCustomer}>
              Email invoice to customer
            </Link>
          </Grid>
        )}
        <Grid item xs={12}>
          <InvoiceDetails read_only={read_only} is_contract={is_contract} />
        </Grid>
        {!is_contract && showAddInvoiceItem && (
          <>
            <Grid item xs={fee_id === -1 && include_manual ? 6 : 8}>
              <Select
                label="Product fee"
                variant="outlined"
                fullWidth
                options={fees}
                placeholder="Select fee"
                value={fee_id && fees.find(p => p.value === fee_id)}
                onChange={e => updateFee(e.value)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            {fee_id === -1 && (
              <Grid item xs={4}>
                <TextField
                  id="standard-adornment-amount"
                  value={manual_fee}
                  onChange={e => updateManualFee(e.target.value)}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  label="Fee amount"
                />
              </Grid>
            )}
          </>
        )}
        {!is_contract && !read_only && (!invoice || is_not_reverse) && (
          <Grid item xs={include_manual && showAddInvoiceItem ? 2 : 4}>
            <Button
              className="save-btn"
              onClick={() => {
                if (!showAddInvoiceItem) {
                  return editShowAddInvoiceItem(true)
                }
                if (edit) {
                  updateInvoiceItem(invoice_item, fee_id, quantity)
                } else {
                  addInvoiceItem(
                    event_id,
                    customer_id,
                    facility_id,
                    product_type_id,
                    quantity
                  )
                }
                editShowAddInvoiceItem(false)
              }}
            >
              {edit ? 'Edit' : 'Add'}
              {` `}
              {!showAddInvoiceItem && 'invoice item'}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {invoice?.ID &&
        invoice.invoice_items.length &&
        !read_only &&
        is_not_reverse ? (
          <Grid item xs={4}>
            <Button className="save-btn" onClick={openPaymentModal}>
              Add payment
            </Button>
          </Grid>
        ) : null}
        {invoice?.ID &&
        invoice.invoice_items.length &&
        is_not_reverse &&
        !read_only ? (
          <Grid item xs={4}>
            <Button className="save-btn" onClick={openDiscountModal}>
              Add discount
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <InvoiceLogs />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  invoice: state.invoice.invoice,
  fees: state.invoice.fees
    .map(fee => ({
      label: `${fee.product.name} - $${fee.invoice_price}`,
      value: fee.id,
    }))
    .sort((a, b) => {
      if (a.label > b.label) return 1
      if (a.label < b.label) return -1
      return 0
    }),
  event_id: state.calendar.event_details.ID,
  customer_id: ownProps.is_contract
    ? state.contract.contract && state.contract.contract.customer_id
    : state.calendar.event_details && state.calendar.event_details.customer_id,
  fee_id: state.invoice.update_fee.fee_id,
  manual_fee: state.invoice.update_fee.manual_fee,
  invoice_item: state.invoice.invoice && state.invoice.invoice.invoice_items[0],
  edit: state.invoice.invoice && state.invoice.invoice.invoice_items.length,
  is_not_reverse:
    state.invoice.invoice &&
    !state.invoice.invoice.reversed &&
    !state.invoice.invoice.reversal,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFees: (product_type_id, facility_id) =>
    dispatch(getFees(product_type_id, facility_id)),
  updateFee: fee_id => dispatch(actions.updateFee({ fee_id })),
  updateManualFee: manual_fee => dispatch(actions.updateFee({ manual_fee })),
  addInvoiceItem: (
    event_id,
    customer_id,
    facility_id,
    product_type_id,
    quantity
  ) =>
    dispatch(
      addInvoiceItem(
        event_id,
        customer_id,
        facility_id,
        product_type_id,
        quantity
      )
    ),
  openPaymentModal: () => dispatch(actions.togglePaymentModal({ open: true })),
  openDiscountModal: () =>
    dispatch(actions.toggleDiscountModal({ open: true })),
  updateInvoiceItem: (item, value, quantity) =>
    dispatch(updateInvoiceItem(item, value, quantity)),
  sendInvoiceToCustomer: () => {
    dispatch(
      openConfirmModal('Invoice', `send the customer their invoice`, () =>
        sendInvoiceToCustomer()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
