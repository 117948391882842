import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'

import { actions } from 'teams/redux'

const age_groups = [
  { label: 'Youth', value: 'Youth' },
  { label: 'Adult', value: 'Adult' },
]

class ResourceDropdown extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={6} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Filter team..."
              value={this.props.query}
              onChange={e =>
                this.props.changeFilterDetails('query', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={this.props.facilities}
              placeholder="Facility"
              value={
                this.props.facility_id &&
                this.props.facilities.find(
                  p => p.value === this.props.facility_id
                )
              }
              onChange={v =>
                this.props.changeFilterDetails('facility_id', v && v.value)
              }
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={age_groups}
              placeholder="Age Group"
              value={
                this.props.age_group &&
                age_groups.find(p => p.value === this.props.age_group)
              }
              onChange={v =>
                this.props.changeFilterDetails('age_group', v && v.value)
              }
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              onClick={() => this.props.toggleTeamModal()}
              className="save-btn"
            >
              Add new
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.teams.filter,
  facilities: state.common.filter_facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilterDetails: (key, value) =>
    dispatch(actions.changeFilterDetails({ key, value })),
  toggleTeamModal: q => dispatch(actions.toggleTeamModal({ open: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
