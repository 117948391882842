import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CreateLeagueForm from './../forms/CreateLeague'

import { setCurrentLeagueDetails, updateLeague } from 'league/actions'

class Calendar extends React.Component {
  componentDidMount() {
    this.props.setLeagueDetails()
  }

  render() {
    const { is_tournament } = this.props
    return (
      <Paper elevation={0} style={{ padding: '3%' }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
            <CreateLeagueForm no_facility is_tournament={is_tournament} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              onClick={() => this.props.updateLeague(is_tournament)}
              className="save-btn"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setLeagueDetails: () => dispatch(setCurrentLeagueDetails()),
  updateLeague: is_tournament => dispatch(updateLeague(is_tournament)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
