import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'

import { getStaff } from './../actions'
import { actions } from './../redux'
import { colors, user_types } from './../../consts'

class ResourceDropdown extends React.Component {
  componentDidMount() {
    this.props.changeFilterDetails('facility_id', this.props.user_facility_id)
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.facility_id !== this.props.facility_id) {
      console.log('Next', nextProps.facility_id, this.props.facility_id)
      this.props.getStaff()
    }
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={6} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Filter staff..."
              value={this.props.query}
              onChange={e =>
                this.props.changeFilterDetails('query', e.target.value)
              }
            />
          </Grid>
          {this.props.is_admin && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Select
                options={this.props.facilities}
                placeholder="Facility"
                value={
                  this.props.facility_id &&
                  this.props.facilities.find(
                    p => p.value === this.props.facility_id
                  )
                }
                onChange={v =>
                  this.props.changeFilterDetails('facility_id', v && v.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
          )}
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={user_types}
              placeholder="User type"
              value={
                this.props.user_type &&
                user_types.find(p => p.value === this.props.user_type)
              }
              onChange={v =>
                this.props.changeFilterDetails('user_type', v && v.value)
              }
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              style={{
                color: colors.blue_strong,
                backgroundColor: colors.blue_mild,
              }}
              component="span"
              onClick={() => this.props.openCreateStaffModal()}
            >
              Add new
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.staff.filter,
  facilities: state.common.filter_facilities,
  is_admin: state.user.user_type === 'admin',
  user_facility_id: state.user.facility_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilterDetails: (k, v) =>
    dispatch(actions.changeFilterDetails({ key: k, value: v })),
  openCreateStaffModal: () =>
    dispatch(actions.toggleCreateStaffModal({ open: true })),
  getStaff: () => dispatch(getStaff()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
