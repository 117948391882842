import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from '../components/Filters'
import GameList from '../components/GameList'
import AssignRefereeDialog from './../components/AssignRefereeDialog'

import { getMatches } from './../actions'

const Manage = ({ getMatches }) => {
  useEffect(() => {
    getMatches()
  }, [getMatches])

  return (
    <div>
      <AssignRefereeDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={12}>
          <GameList />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMatches: () => dispatch(getMatches()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
