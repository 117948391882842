import React, { useState } from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import FastTextField from 'common/components/FastTextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { searchCustomers } from 'customer/actions'
import { actions } from 'customer/redux'
const { selectCustomer, changeNewCustomerDetails } = actions

const CustomerSection = ({
  customers,
  hide_header,
  no_create,
  searchCustomers,
  customer,
  selectCustomer,
  changeNewCustomerDetails,
  newCustomer,
  name,
}) => {
  const [tab, updateTab] = useState('existing')
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ textAlign: 'center' }}
      spacing={4}
    >
      {!hide_header && (
        <Grid item xs={12}>
          <Tabs
            value={tab}
            onChange={(e, val) => updateTab(val)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              value="existing"
              label={`Existing ${name ? name : 'customer'}`}
            />
            {!no_create && (
              <Tab value="new" label={`New ${name ? name : 'customer'}`} />
            )}
          </Tabs>
        </Grid>
      )}
      {!hide_header && tab === 'existing' && (
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            options={customers}
            getOptionLabel={option =>
              `${option.first_name} ${option.last_name} (${option.email})`
            }
            renderOption={option => (
              <p>
                {option.first_name} {option.last_name}{' '}
                {option.hubspot && option.hubspot.gender && (
                  <span>({option.hubspot.gender})</span>
                )}
                <br />
                <span>
                  email=
                  {option.email}
                </span>
                {option.hubspot && option.hubspot.phone && (
                  <span>
                    <br />
                    phone=
                    {option.hubspot.phone}
                  </span>
                )}
                {option.hubspot && option.hubspot.state && (
                  <span>
                    <br />
                    state=
                    {option.hubspot.state}
                  </span>
                )}
              </p>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Customer"
                variant="outlined"
                fullWidth
                onChange={e => searchCustomers(e.target.value)}
              />
            )}
            value={customer}
            onChange={(e, c) => selectCustomer(c)}
          />
        </Grid>
      )}
      {(tab === 'new' || customer) && (
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={2}
          >
            <Grid item xs={6}>
              <FastTextField
                label="First name"
                fullWidth
                value={
                  customer && customer.first_name
                    ? customer.first_name
                    : newCustomer.first_name
                }
                onChange={e =>
                  changeNewCustomerDetails('first_name', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FastTextField
                label="Last name"
                fullWidth
                value={
                  customer && customer.last_name
                    ? customer.last_name
                    : newCustomer.last_name
                }
                onChange={e =>
                  changeNewCustomerDetails('last_name', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FastTextField
                label="Email"
                fullWidth
                value={
                  customer && customer.email
                    ? customer.email
                    : newCustomer.email
                }
                onChange={e =>
                  changeNewCustomerDetails('email', e.target.value)
                }
                disabled={no_create || tab === 'existing'}
              />
              {customer?.ID && (
                <Link href={`/customer/${customer.ID}`} target="_blank">
                  edit email address from profile
                </Link>
              )}
            </Grid>
            <Grid item xs={6}>
              <FastTextField
                label="Phone"
                fullWidth
                value={
                  customer && customer.hubspot && customer.hubspot.phone
                    ? customer.hubspot.phone
                    : newCustomer.phone
                }
                onChange={e =>
                  changeNewCustomerDetails('phone', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  customers: state.customer.customers,
  ...state.calendar.event_details,
  tab: state.calendar.tab_customer,
  newCustomer: state.customer.creation,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchCustomers: q => dispatch(searchCustomers(q)),
  selectCustomer: value => dispatch(selectCustomer(value)),
  changeNewCustomerDetails: (k, v) =>
    dispatch(changeNewCustomerDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSection)
