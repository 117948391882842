import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import DailyReport from './../reports/Daily'
import Select from 'react-select'

import { getDailyReport } from './../actions'
import { actions } from './../redux'
import { unixToDateTime, momentDateTimeSecondsToUnix } from 'helper'

const DailyReportScreen = ({
  report,
  start_daily,
  end_daily,
  facilities,
  facility_id,
  updateFilter,
  changeReportType,
  getDailyReport,
}) => {
  React.useEffect(() => changeReportType('daily'), [])

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            variant="inline"
            label="Start"
            value={
              start_daily &&
              unixToDateTime(start_daily).format('YYYY-MM-DDTHH:mm')
            }
            onChange={a =>
              updateFilter(
                'start_daily',
                momentDateTimeSecondsToUnix(moment(a))
              )
            }
            autoOk
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            variant="inline"
            label="End"
            value={
              end_daily && unixToDateTime(end_daily).format('YYYY-MM-DDTHH:mm')
            }
            onChange={a =>
              updateFilter('end_daily', momentDateTimeSecondsToUnix(moment(a)))
            }
            autoOk
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Select
          options={facilities}
          placeholder="Facility"
          value={
            facility_id.length &&
            facilities.filter(f => facility_id.indexOf(f.value) !== -1)
          }
          onChange={v =>
            updateFilter('facility_id', v ? v.map(f => f.value) : [])
          }
          isMulti
        />
      </Grid>
      <Grid item style={{ textAlign: 'center' }}>
        <Button
          className="save-btn"
          style={{ width: '100%' }}
          onClick={() => getDailyReport()}
        >
          Run
        </Button>
      </Grid>
      {report && (
        <Grid item xs={12}>
          <DailyReport />
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  report: state.reporting.report,
  facilities: state.common.filter_facilities,
  ...state.reporting.filter,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeReportType: report => dispatch(actions.changeReportType(report)),
  updateFilter: (k, v) =>
    dispatch(actions.updateFilter({ field: k, value: v })),
  getDailyReport: () => dispatch(getDailyReport()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyReportScreen)
