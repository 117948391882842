import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import TextField from 'common/components/FastTextField'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { CirclePicker } from 'react-color'

import { changeLeagueDetails } from './../../actions'
import { genders } from './../../../consts'
import { jsDateToUnix, unixToJsDate } from 'helper'

class LeagueForm extends React.Component {
  componentDidMount() {
    if (!this.props.is_admin) {
      this.props.changeLeagueDetails('facility_id', this.props.user_facility_id)
    }
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center', paddingTop: 20 }}
        spacing={4}
      >
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            {!this.props.no_facility && (
              <Grid item xs={12}>
                <Select
                  options={this.props.facilities}
                  placeholder="Select facility *"
                  value={
                    this.props.facility_id &&
                    this.props.facilities.find(
                      f => f.value === this.props.facility_id
                    )
                  }
                  onChange={e =>
                    this.props.changeLeagueDetails('facility_id', e.value)
                  }
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  isDisabled={!this.props.is_admin}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                options={this.props.products}
                placeholder="Select product *"
                value={
                  this.props.product_id &&
                  this.props.products.find(
                    f => f.value === this.props.product_id
                  )
                }
                onChange={e =>
                  this.props.changeLeagueDetails('product_id', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={this.props.age_groups}
                placeholder="Select age group *"
                value={
                  this.props.age_group_id &&
                  this.props.age_groups.find(
                    f => f.value === this.props.age_group_id
                  )
                }
                onChange={e =>
                  this.props.changeLeagueDetails('age_group_id', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={this.props.genders}
                placeholder="Select gender *"
                value={
                  this.props.gender &&
                  this.props.genders.find(f => f.value === this.props.gender)
                }
                onChange={e =>
                  this.props.changeLeagueDetails('gender', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name *"
                fullWidth
                variant="outlined"
                value={this.props.name}
                onChange={e =>
                  this.props.changeLeagueDetails('name', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Online description *"
                fullWidth
                multiline
                rows="3"
                variant="outlined"
                value={this.props.description}
                onChange={e =>
                  this.props.changeLeagueDetails('description', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              Color{' '}
              <CirclePicker
                color={this.props.color}
                onChangeComplete={e =>
                  this.props.changeLeagueDetails('color', e.hex)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={6}>
              Start date
              <DatePickerComponent
                id="league_start_date"
                placeholder="Start date *"
                value={unixToJsDate(this.props.start_date)}
                onChange={e =>
                  this.props.changeLeagueDetails(
                    'start_date',
                    jsDateToUnix(e.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              End date
              <DatePickerComponent
                id="league_end_date"
                placeholder="End date *"
                value={unixToJsDate(this.props.end_date)}
                onChange={e =>
                  this.props.changeLeagueDetails(
                    'end_date',
                    jsDateToUnix(e.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              Registration start
              <DatePickerComponent
                id="reg_start_date"
                placeholder="Registration start date *"
                value={unixToJsDate(this.props.registration_start_date)}
                onChange={e =>
                  this.props.changeLeagueDetails(
                    'registration_start_date',
                    jsDateToUnix(e.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              Registration end
              <DatePickerComponent
                id="reg_end_date"
                placeholder="Registration end date *"
                value={unixToJsDate(this.props.registration_end_date)}
                onChange={e =>
                  this.props.changeLeagueDetails(
                    'registration_end_date',
                    jsDateToUnix(e.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              Early bird start
              <DatePickerComponent
                id="eb_start_date"
                placeholder="Early bird start date"
                value={
                  this.props.early_bird_start_date > 0
                    ? unixToJsDate(this.props.early_bird_start_date)
                    : null
                }
                onChange={e =>
                  this.props.changeLeagueDetails(
                    'early_bird_start_date',
                    jsDateToUnix(e.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              Early bird end
              <DatePickerComponent
                id="eb_end_date"
                placeholder="Early bird end date"
                value={
                  this.props.early_bird_end_date > 0
                    ? unixToJsDate(this.props.early_bird_end_date)
                    : null
                }
                onChange={e =>
                  this.props.changeLeagueDetails(
                    'early_bird_end_date',
                    jsDateToUnix(e.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Max number of teams *"
                fullWidth
                variant="outlined"
                value={this.props.max_teams}
                onChange={e =>
                  this.props.changeLeagueDetails('max_teams', e.target.value)
                }
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Match duration *"
                fullWidth
                variant="outlined"
                value={this.props.duration}
                onChange={e =>
                  this.props.changeLeagueDetails('duration', e.target.value)
                }
                type="number"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'left' }}
            >
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.enable_deposit}
                        onChange={e =>
                          this.props.changeLeagueDetails(
                            'enable_deposit',
                            e.target.checked
                          )
                        }
                        name="enable_deposit"
                      />
                    }
                    label="Enable deposit"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'left' }}
            >
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.enable_individual}
                        onChange={e =>
                          this.props.changeLeagueDetails(
                            'enable_individual',
                            e.target.checked
                          )
                        }
                        name="enable_individual"
                      />
                    }
                    label="Enable individual registration"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'left' }}
            >
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.display_schedule}
                        onChange={e =>
                          this.props.changeLeagueDetails(
                            'display_schedule',
                            e.target.checked
                          )
                        }
                        name="display_schedule"
                      />
                    }
                    label="Display standings/schedules online"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0, textAlign: 'left' }}>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.available_online}
                        onChange={e =>
                          this.props.changeLeagueDetails(
                            'available_online',
                            e.target.checked
                          )
                        }
                        name="available_online"
                      />
                    }
                    label="Available for online registration"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0, textAlign: 'left' }}>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.enable_early_bird}
                        onChange={e =>
                          this.props.changeLeagueDetails(
                            'enable_early_bird',
                            e.target.checked
                          )
                        }
                        name="enable_early_bird"
                      />
                    }
                    label="Enable early bird"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.create_league,
  products: state.product.products
    .filter(p => {
      if (ownProps.is_tournament) {
        return p.gl.code.slice(0, 2) === '03' || p.gl.code.slice(0, 2) === '13'
      }
      return p.gl.code.slice(0, 2) === '02' || p.gl.code.slice(0, 2) === '12'
    })
    .filter(p => {
      if (state.user.is_admin) return p
      // Sofive
      if (state.user.facility_id <= 5) {
        return (
          p.facility_id === state.user.facility_id ||
          p.facility_id === 997 || // any sofive
          p.facility_id === 999
        ) // any all centers
      }
      return (
        p.facility_id === state.user.facility_id ||
        p.facility_id === 998 || // any goals
        p.facility_id === 999
      ) // any all centers
    })
    .map(p => ({
      label: `${p.name} (full price = $${p.full_price} / deposit = $${p.deposit_price} / early bird = $${p.early_bird_price})`,
      value: p.ID,
    })),
  facilities: state.common.facilities.map(f => ({
    label: f.name,
    value: f.ID,
  })),
  age_groups: state.league.age_groups.map(a => ({
    label: a.name,
    value: a.ID,
  })),
  genders: genders.map(g => ({ label: g, value: g })),
  is_admin: state.user.is_admin,
  user_facility_id: state.user.facility_id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLeagueDetails: (k, v) => dispatch(changeLeagueDetails(k, v)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueForm)
