import React from 'react'
import { connect } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { editEPlayerDetails } from './../../actions'

class EditPlayer extends React.Component {
  render() {
    return (
      <div>
        {this.props.eplayer.customer && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <TextField
                label="Customer Name *"
                fullWidth
                variant="outlined"
                value={
                  this.props.eplayer.customer.first_name +
                  ' ' +
                  this.props.eplayer.customer.last_name
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Console ID *"
                fullWidth
                variant="outlined"
                value={this.props.eplayer.console_id}
                onChange={e =>
                  this.props.editEPlayerDetails('console_id', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Discord ID *"
                fullWidth
                variant="outlined"
                value={this.props.eplayer.discord_username}
                onChange={e =>
                  this.props.editEPlayerDetails(
                    'discord_username',
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  eplayer: state.league.eplayer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  editEPlayerDetails: (k, v) => dispatch(editEPlayerDetails(k, v)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditPlayer)
