import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import PhotoCard from './PhotoCard'

import { filterPhotos } from './../helper'
import { actions } from './../redux'

const Gallery = ({ photos, selectPhoto }) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      style={{ paddingTop: '2%' }}
      spacing={4}
    >
      {photos.map(photo => (
        <Grid item xs={2} key={photo.ID}>
          <PhotoCard
            src={photo.thumbnail}
            tags={photo.tags}
            onClick={() => selectPhoto(photo)}
            deleted={!photo.to_keep}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  photos: filterPhotos(state.media.filters, state.media.medias),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectPhoto: photo => dispatch(actions.selectPhoto({ photo })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
