import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import LeagueSummary from './../league/LeagueSummary'
import TeamTable from './../league/TeamTable'
import PlayerTable from './../eleague/PlayerTable'
import ELeagueActions from './../eleague/ActionForm'
import PlayerDialog from './../PlayerDialog'

import { toggleAddTeamModal } from './../../actions'
import { dispatchError } from './../../../common/actions'

class Teams extends React.Component {
  render() {
    return (
      <div>
        <PlayerDialog />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={8} style={{ textAlign: 'center', width: '100%' }}>
            {this.props.is_e_league ? <PlayerTable /> : <TeamTable />}
            <Button
              style={{ margin: 10, textAlign: 'right' }}
              onClick={() => this.props.openAddTeamModal(this.props.team_delta)}
              className="save-btn"
            >
              Add new
            </Button>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', width: '100%' }}>
            {this.props.is_e_league && <ELeagueActions />}
            <LeagueSummary />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_e_league: state.league.league.is_e_league,
  team_delta: state.league.league.max_teams - state.league.league.teams.length,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddTeamModal: team_delta => {
    if (team_delta <= 0) {
      return dispatch(
        dispatchError({ message: 'Max numbers of teams reached' })
      )
    }
    dispatch(toggleAddTeamModal(true))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
