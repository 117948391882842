import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import NewGLDialog from 'resources/components/NewGLDialog'
import { Button } from '@material-ui/core'

import { getGLs } from 'product/actions'
import { actions } from 'resources/redux'

const sortIcon = <ArrowDownward />

const columns = setGL =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: gl => gl.ID,
    },
    {
      name: 'Code',
      selector: 'code',
      center: true,
      cell: gl => gl.code,
    },
    {
      name: 'Product type',
      selector: 'product_type',
      center: true,
      cell: gl => gl.product_type.name,
    },
    {
      name: 'Season',
      selector: 'season',
      center: true,
      cell: gl => gl.season.name,
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: gl => (
        <>
          <IconButton onClick={() => setGL(gl)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = ({ setGL }) => {
  const facilities = useSelector(state => state.product.gls),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(actions.updateGL({ key: 'open', value: true, reset: true }))
  useEffect(() => {
    dispatch(getGLs())
  }, [])
  return (
    <div>
      <NewGLDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setGL)()}
              data={facilities}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setGL: gl => {
    dispatch(actions.updateGL({ key: 'id', value: gl.ID }))
    dispatch(actions.updateGL({ key: 'code', value: gl.code }))
    dispatch(
      actions.updateGL({ key: 'product_type_id', value: gl.product_type_id })
    )
    dispatch(actions.updateGL({ key: 'season_id', value: gl.season_id }))
    dispatch(actions.updateGL({ key: 'open', value: true }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)
