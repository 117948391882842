import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'

import {
  changeFilterDetails,
  toggleModal,
  toggleTournamentModal,
  getLeagues,
} from './../actions'
import { colors } from './../../consts'
import { unixToDateTime, momentDateTimeSecondsToUnix } from 'helper'

const sub_product = [
    { label: 'Adult Leagues', value: 3 },
    { label: 'Adult Tournament', value: 4 },
    { label: 'Youth League', value: 8 },
    { label: 'Youth Tournament', value: 9 },
  ],
  yes_no = [
    { label: 'Active: Yes', value: 1 },
    { label: 'Active: No', value: 0 },
  ]

class ResourceDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: null,
    }
  }
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={2} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Filter leagues..."
              value={this.props.query}
              onChange={e =>
                this.props.changeFilterDetails('query', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={this.props.facilities}
              placeholder="Facility"
              onChange={v => this.props.changeFilterDetails('facility_id', v)}
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              isMulti
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={yes_no}
              placeholder="Active"
              value={yes_no.find(p => p.value === this.props.active)}
              onChange={v =>
                this.props.changeFilterDetails('active', v && v.value)
              }
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2}>
            <DateRangePicker
              small
              isOutsideRange={date => false}
              startDate={
                this.props.start_date
                  ? unixToDateTime(this.props.start_date)
                  : null
              }
              startDateId="your_unique_start_date_id"
              endDate={
                this.props.end_date ? unixToDateTime(this.props.end_date) : null
              }
              endDateId="your_unique_end_date_id"
              onDatesChange={({ startDate, endDate }) => {
                if (!startDate && !endDate) {
                  // dates cleared
                  this.props.changeFilterDetails('start_date', null)
                  this.props.changeFilterDetails('end_date', null)
                }
                if (startDate) {
                  this.props.changeFilterDetails(
                    'start_date',
                    momentDateTimeSecondsToUnix(
                      startDate.hour(0).minute(0).second(0)
                    )
                  )
                }
                if (endDate) {
                  this.props.changeFilterDetails(
                    'end_date',
                    momentDateTimeSecondsToUnix(
                      endDate.hours(23).minutes(59).seconds(59)
                    )
                  )
                }
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              showClearDates={true}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={sub_product}
              placeholder="Product"
              value={
                this.props.product_type_id &&
                sub_product.find(p => p.value === this.props.product_type_id)
              }
              onChange={v =>
                this.props.changeFilterDetails('product_type_id', v && v.value)
              }
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              style={{
                color: colors.blue_strong,
                backgroundColor: colors.blue_mild,
              }}
              component="span"
              onClick={() => this.props.getLeagues()}
            >
              Search
            </Button>
          </Grid>
          {this.props.is_admin && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
                component="span"
                onClick={() => this.props.openLeagueModal()}
                size="small"
              >
                Add new league
              </Button>
            </Grid>
          )}
          {this.props.is_admin && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
                component="span"
                onClick={() => this.props.openTournamentModal()}
                size="small"
              >
                Add new tournament
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.filter,
  facilities: state.common.filter_facilities,
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilterDetails: (k, v) => dispatch(changeFilterDetails(k, v)),
  openLeagueModal: () => dispatch(toggleModal(true)),
  openTournamentModal: () => dispatch(toggleTournamentModal(true)),
  getLeagues: () => dispatch(getLeagues()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
