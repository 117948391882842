import React from 'react'
import { connect } from 'react-redux'

import TextField from 'common/components/FastTextField'
import Grid from '@material-ui/core/Grid'

import { actions } from './../../redux'

class CreateCustomer extends React.Component {
  render() {
    return (
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ textAlign: 'center' }}
          spacing={2}
        >
          <Grid item xs={6}>
            <TextField
              label="First name"
              fullWidth
              value={this.props.first_name && this.props.first_name}
              onChange={e =>
                this.props.changeNewCustomerDetails(
                  'first_name',
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last name"
              fullWidth
              value={this.props.last_name && this.props.last_name}
              onChange={e =>
                this.props.changeNewCustomerDetails('last_name', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              fullWidth
              value={this.props.email && this.props.email}
              onChange={e =>
                this.props.changeNewCustomerDetails('email', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone"
              fullWidth
              value={this.props.phone && this.props.phone}
              onChange={e =>
                this.props.changeNewCustomerDetails('phone', e.target.value)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.customer.creation,
  is_admin: state.user.is_admin,
  facilities: state.common.filter_facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeNewCustomerDetails: (k, v) =>
    dispatch(actions.changeNewCustomerDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer)
