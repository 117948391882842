import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { invoiceSlice } from './../redux'
import { exportToCSV } from './../helper'
import { niceNumber } from 'helper'
const { toggleEventSelectionModal } = invoiceSlice.actions
const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'Invoice',
    selector: 'invoice',
    sortable: true,
    center: true,
    cell: row => (
      <Link href={`/invoice/${row.ID}`} target="_blank">
        {row.ID}
      </Link>
    ),
    defaultSortField: 'ID',
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.invoice_type.name}
        <br />
        <small>{row.facility.name}</small>
      </div>
    ),
  },
  {
    name: 'Date',
    selector: 'pretty_date',
    sortable: true,
    center: true,
    defaultSortField: 'date',
  },
  {
    name: 'Customer',
    selector: 'customer',
    sortable: true,
    center: true,
    grow: 3,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.customer.first_name} {row.customer.last_name}
        <Link href={`/customer/${row.customer.ID}`} target="_blank">
          <OpenInNewIcon style={{ fontSize: 15 }} />
        </Link>
        <br />
        <small>{row.customer.email}</small>
      </div>
    ),
  },
  {
    name: 'Product',
    selector: 'product',
    sortable: true,
    grow: 3,
    center: true,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.reversal ? '[REVERSAL] ' : ''}
        {row.reversed ? '[REVERSED] ' : ''}
        {row.product_type.name}
      </div>
    ),
  },
  {
    name: 'Invoiced',
    selector: 'invoiced',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoiced)}`,
  },
  {
    name: 'Discounted',
    selector: 'discounted',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.discounted)}`,
  },
  {
    name: 'Paid',
    selector: 'paid',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.paid)}`,
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.balance)}`,
  },
])

class InvoiceList extends React.Component {
  render() {
    return (
      <div>
        <Paper elevation={0}>
          <DataTable
            title={
              <div>
                <span>Invoices</span>
                {this.props.is_admin && (
                  <Button
                    className="save-btn"
                    style={{ marginLeft: 10 }}
                    onClick={() => exportToCSV(this.props.invoices, 'Invoices')}
                  >
                    Export
                  </Button>
                )}
              </div>
            }
            columns={columns()}
            data={this.props.invoices}
            highlightOnHover
            defaultSortField="date"
            defaultSortAsc={false}
            sortIcon={sortIcon}
            pagination
            striped
            fixedHeader
            paginationPerPage={50}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  invoices: state.invoice.invoices,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openEventModal: id => dispatch(toggleEventSelectionModal({ id, open: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
