import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'

import { refundPayment } from './../actions'
import { actions } from './../redux'
import { payment_options } from 'consts'

const DeletePaymentDialog = ({
  open,
  handleClose,
  editPType,
  amount,
  method,
  refundPayment,
}) => {
  const [refundAmount, setRefundAmount] = useState(amount)
  useEffect(() => {
    setRefundAmount(amount)
  }, [amount])
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Refund confirmation (for ${amount})
      </DialogTitle>
      <DialogContent>
        Please select the refund method:
        <Select
          options={payment_options}
          placeholder="Payment method"
          value={method && payment_options.find(f => f.value === method)}
          onChange={e => editPType(e.value)}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
        <br />
        <TextField
          label="Select the amount you wish to refund"
          variant="outlined"
          fullWidth
          size="small"
          value={refundAmount}
          onChange={e => setRefundAmount(e.target.value)}
          style={{ mrginTop: 10 }}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={() => refundPayment(refundAmount)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  open: state.invoice.delete_payment_modal_open,
  ...state.invoice.delete_payment,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.toggleDeletePaymentModal({ open: false })),
  editPType: p_type => dispatch(actions.editPType({ p_type })),
  refundPayment: amount => dispatch(refundPayment(amount)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeletePaymentDialog)
