import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { getPickupParticipants, checkIn } from './../../actions'

class PickupSection extends React.Component {
  componentDidMount() {
    this.props.getPickupParticipants()
  }
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center', marginTop: 20 }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    Customer
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Checked in
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.participants.map((participant, i) => (
                  <TableRow key={`${participant.ID}`}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'center' }}
                    >
                      {participant.customer.first_name}{' '}
                      {participant.customer.last_name}
                      <Link
                        href={`/customer/${participant.customer_id}`}
                        target="_blank"
                      >
                        <IconButton aria-label="delete">
                          <ExitToAppIcon fontSize="small" />
                        </IconButton>
                      </Link>
                      <small>{participant.customer.email}</small>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Checkbox
                        checked={participant.checked_in}
                        onChange={e =>
                          this.props.checkIn(participant.ID, e.target.checked)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  participants: state.calendar.pickup_players,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPickupParticipants: () => dispatch(getPickupParticipants()),
  checkIn: (id, checked_in) => dispatch(checkIn(id, checked_in)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PickupSection)
