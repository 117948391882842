import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'

const _getDiscounts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/discount`)
    .then(resp => {
      dispatch(
        actions.getDiscounts({
          discounts: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getDiscounts = () => (dispatch, getState) => {
  dispatch(_getDiscounts())
}

export const getDiscountRules = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/discount/rule`)
    .then(resp => {
      dispatch(
        actions.getDiscountRules({
          rules: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const editQuery = query => (dispatch, getState) =>
  dispatch(actions.editSearchQuery({ query }))

export const updateExpiry = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .put(ERP_BACKEND_URL + `/discount/${id}?expired=1`)
    .then(resp => {
      dispatch(_getDiscounts())
    })
    .catch(e => dispatch(dispatchError(e)))
}
