export const filterResources = (resources, filters) => {
  let filtered_resources = resources
  if (filters.centers && filters.centers.length) {
    const array_val = filters.centers.map(c => c.value)
    filtered_resources = filtered_resources.filter(
      r => array_val.indexOf(r.centerid) !== -1
    )
  }
  if (filters.search_query && filters.search_query.trim() !== '') {
    const sq = filters.search_query.trim().toLowerCase()
    filtered_resources = filtered_resources.filter(r => {
      const obj = JSON.stringify(Object.keys(r).map(k => r[k]))
      return obj.indexOf(sq) !== -1
    })
  }
  return filtered_resources
}

export const getFieldTypeNameById = (fieldTypes, id) =>
  fieldTypes?.find(fieldType => fieldType.ID === id)?.name
