import axios from 'axios'
import moment from 'moment'

import { unixToDate } from 'helper'

const getPaymentDetails = p_details => {
  if (!p_details.order_id) {
    return Promise.resolve(p_details)
  }
  return axios
    .get(
      `https://erp.sofive.com/api/payment/details?order_id=${p_details.order_id}`
    )
    .then(resp => {
      return {
        ...p_details,
        details: `${resp.data.card_brand} ${resp.data.last_4}`,
      }
    })
}

export const buildCustomerHistory = invoices => {
  let rows = []
  for (let i = 0; i < invoices.length; i++) {
    const invoice = invoices[i]
    for (let j = 0; j < invoice.payments.length; j++) {
      const payment = invoice.payments[j]
      if (payment.type !== 'card') {
        rows.push({
          id: invoice.ID,
          product: invoice.product_type.name,
          invoiced: invoice.invoiced,
          date: unixToDate(invoice.date).format('M/D/YYYY'),
          payment_amount: payment.amount,
          total_due: 0,
          payment_date: moment(payment.CreatedAt).format('M/D/YYYY'),
          details: 'CASH',
          card: false,
        })
        continue
      }
      if (payment.details.indexOf('in_store') !== -1) {
        rows.push({
          id: invoice.ID,
          product: invoice.product_type.name,
          invoiced: invoice.invoiced,
          date: unixToDate(invoice.date).format('M/D/YYYY'),
          payment_amount: 'Payment did not go through',
          total_due: payment.amount,
          payment_date: moment(payment.CreatedAt).format('M/D/YYYY'),
          details: 0,
          card: false,
        })
        continue
      }
      let parsed = {}
      try {
        parsed = JSON.parse(payment.details)
      } catch (e) {
        console.error(e)
      }
      let order_id = null
      if (parsed.square_transaction_id) {
        order_id = parsed.square_transaction_id
      }
      if (parsed.square_id) {
        order_id = parsed.square_id
      }
      rows.push({
        id: invoice.ID,
        product: invoice.product_type.name,
        invoiced: invoice.invoiced,
        date: unixToDate(invoice.date).format('M/D/YYYY'),
        payment_amount: payment.amount,
        total_due: 0,
        payment_date: moment(payment.CreatedAt).format('M/D/YYYY'),
        details: null,
        order_id,
      })
    }
  }
  return Promise.all(rows.map(getPaymentDetails))
}
