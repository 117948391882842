import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getFields, getEvents } from './../actions'

import EventDialog from './../components/EventDialog'
import Calendar from './../components/Calendar'

class ManageCalendar extends React.Component {
  componentDidMount() {
    this.props.getFields()
    this.props.getEvents(moment().startOf('day'))
  }

  render() {
    return (
      <div>
        <EventDialog />
        <Calendar search={this.props.location.search} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFields: () => dispatch(getFields()),
  getEvents: day => dispatch(getEvents(day)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageCalendar)
