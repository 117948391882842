import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import ContractsTable from './../../../contract/components/ContractsTable'

const Contracts = props => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={12}>
        <ContractsTable data={props.contracts} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  contracts: state.customer.contracts,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Contracts)
