import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'

import FastTextField from 'common/components/FastTextField'

import { saveTax } from 'resources/actions'
import { actions } from 'resources/redux'

const NewTaxFeeDialog = ({ type = 'tax' }) => {
  const { open, name, value } = useSelector(state => state.resources.tax),
    dispatch = useDispatch(),
    updateTax = (k, v) => dispatch(actions.updateTax({ key: k, value: v })),
    handleClose = () => updateTax('open', false),
    _saveTax = () => dispatch(saveTax(type))
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create {type === 'tax' ? 'tax' : 'fee'}
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={7}>
            <FastTextField
              label="Name *"
              variant="outlined"
              value={name}
              onChange={e => updateTax('name', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={7}>
            <FastTextField
              label="Value (in %) *"
              variant="outlined"
              value={value}
              onChange={e => updateTax('value', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => _saveTax()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewTaxFeeDialog
