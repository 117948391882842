import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'

import { addDiscount, getDiscounts } from './../actions'
import { actions } from './../redux'

class DiscountDialog extends React.Component {
  componentDidMount() {
    this.props.getDiscounts()
  }

  render() {
    const { open, handleClose, addDiscount } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Add discount
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item xs={12}>
              <Select
                options={this.props.discounts}
                placeholder="Select discount"
                value={
                  this.props.discount_id &&
                  this.props.discounts.find(
                    f => f.value === this.props.discount_id
                  )
                }
                onChange={e => this.props.updateDiscountID(e.value)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            {this.props.discount_id === -1 && (
              <Grid item xs={12}>
                <TextField
                  label="Custom discount amount"
                  fullWidth
                  dense
                  value={this.props.amount}
                  onChange={e => this.props.editDiscountAmount(e.target.value)}
                  styles={{ marginTop: 50 }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (this.props.addDiscountOverride) {
                this.props.addDiscountOverride()
                handleClose()
                return
              }
              handleClose()
              addDiscount()
            }}
            className="save-btn"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.invoice.discount_modal_open,
  discounts: state.invoice.discounts
    .map(d => ({
      label: d.name,
      value: d.ID,
    }))
    .concat({ label: 'Manual discount', value: -1 }),
  discount_id: state.invoice.discount_id,
  amount: state.invoice.custom_discount_amount,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleDiscountModal({ open: false })),
  getDiscounts: () => dispatch(getDiscounts()),
  updateDiscountID: id => dispatch(actions.updateDiscountID({ id })),
  addDiscount: () => dispatch(addDiscount()),
  editDiscountAmount: amount =>
    dispatch(actions.editDiscountAmount({ amount })),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscountDialog)
