import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { getLeaguesByFacility } from 'league/actions'
import { updateTeamLeague } from 'teams/actions'
import { openConfirmModal } from 'common/actions'
import { actions } from 'teams/redux'

class ChangeLeagueDialog extends React.Component {
  render() {
    const { open, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        onEntering={() =>
          this.props.getLeaguesByFacility(
            this.props.facility_id,
            this.props.is_tournament
          )
        }
      >
        <DialogTitle id="form-dialog-title">
          Change league
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            options={this.props.leagues}
            getOptionLabel={option => `${option.name}`}
            renderInput={params => (
              <TextField
                {...params}
                label="League"
                variant="outlined"
                fullWidth
              />
            )}
            value={this.props.league}
            onChange={(e, c) => this.props.changeTeamDetails('new_league', c)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.updateTeamLeague(this.props.league.name)
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const current_ptype_id =
    state.teams.team &&
    state.teams.team.current_league &&
    state.teams.team.current_league.product.gl.product_type_id
  return {
    open: state.teams.league_dialog_open,
    facility_id: state.teams.team && state.teams.team.facility_id,
    leagues: state.league.leagues.filter(league => {
      if (!current_ptype_id) return true
      const ptype_id = league.product.gl.product_type_id
      return ptype_id === current_ptype_id
    }),
    league: state.teams.team_form.new_league,
    is_tournament: state.teams.team?.current_league?.is_tournament,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleChangeLeagueModal({ open: false })),
  getLeaguesByFacility: (id, is_tournament) =>
    dispatch(getLeaguesByFacility(id, is_tournament)),
  changeTeamDetails: (k, v) =>
    dispatch(actions.editTeamDetails({ key: k, value: v })),
  updateTeamLeague: name => {
    dispatch(
      openConfirmModal(
        'League change',
        `change the team's league to ${name}`,
        () => updateTeamLeague()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLeagueDialog)
