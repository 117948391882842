import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

import { addCredits } from './../actions'
import { actions } from './../redux'
import { niceNumber } from 'helper'

const EditCreditDialog = ({ open, handleClose, addCredits, amount }) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="sm"
    aria-labelledby="confirmation-dialog-title"
    open={open}
  >
    <DialogTitle id="confirmation-dialog-title">
      Customer credit add
    </DialogTitle>
    <DialogContent>
      Do you wish to keep the payment as a ${niceNumber(amount)}{' '}
      &ldquo;credit&rdquo; for the customer?
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={() => addCredits()} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

const mapStateToProps = state => ({
  open: state.invoice.add_credit_modal_open,
  amount: state.invoice.add_credit_amount,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleAddCreditModal({ open: false })),
  addCredits: () => dispatch(addCredits()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCreditDialog)
