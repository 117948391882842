import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import FeeForm from './forms/Fee'

import { updateContractFee } from 'contract/actions'
import { actions } from 'contract/redux'

class FeeUpdateDialog extends React.Component {
  render() {
    const { open, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Update fee
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <FeeForm />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.updateContractFee()}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.contract.fee_dialog_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleFeeModal({ open: false })),
  updateContractFee: () => dispatch(updateContractFee()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeUpdateDialog)
