import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import SearchBar from './../components/SearchBar'
import CustomerTable from './../components/CustomerTable'

import { mergeCustomers } from './../actions'
import { actions } from './../redux'

const MergeDialog = () => {
  const dispatch = useDispatch(),
    { open, customers } = useSelector(state => state.customer.merge),
    handleClose = () =>
      dispatch(actions.updateMerge({ key: 'open', value: false })),
    onSelect = customer =>
      dispatch(
        actions.updateMerge({
          key: 'customers',
          value: customers.concat([customer]),
        })
      ),
    _mergeCustomers = () => dispatch(mergeCustomers())
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      disableBackdropClick={true}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Merge customer
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={4}
        >
          <Grid item xs={12}>
            {customers.map((c, key) => (
              <p key={key}>
                #{c.ID} - {c.first_name} {c.last_name} - {c.email}
              </p>
            ))}
          </Grid>
          <Grid item xs={12}>
            <SearchBar noCreate />
          </Grid>
          <Grid item xs={12}>
            <CustomerTable onSelect={onSelect} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_mergeCustomers}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MergeDialog
