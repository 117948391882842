import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import PlayOffScheduler from './../league/PlayOffScheduler'
import PlayOffs from './../league/PlayOffs'

import { getPlayoffMatches } from './../../actions'

class Schedule extends React.Component {
  componentDidMount() {
    this.props.getPlayoffMatches()
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} style={{ width: '100%' }}>
          {this.props.matches.length === 0 ? <PlayOffScheduler /> : null}
          {this.props.matches.length ? <PlayOffs /> : null}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  matches: state.league.playoff_matches,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPlayoffMatches: () => dispatch(getPlayoffMatches()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
