import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import { getFees, updateClassDetails } from 'classes/actions'
import { actions } from 'classes/redux'
import { jsDateToUnix, unixToJsDate } from 'helper'

const ClassDetails = ({
  fees,
  getFees,
  updateForm,
  is_admin,
  form,
  facilities,
  edit,
  updateClass,
}) => {
  useEffect(() => {
    if (form.facility_id) {
      getFees(form.facility_id)
    }
  }, [form.facility_id, getFees])

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      style={{ textAlign: 'center', paddingTop: 20 }}
      spacing={4}
    >
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={4}
        >
          {is_admin && !edit && (
            <Grid item xs={12}>
              <Select
                options={facilities}
                placeholder="Select facility *"
                value={
                  form.facility_id &&
                  facilities.find(f => f.value === form.facility_id)
                }
                onChange={e => updateForm('facility_id', e.value)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                isDisabled={!is_admin}
              />
            </Grid>
          )}
          {!edit && fees?.length ? (
            <Grid item xs={12}>
              <Select
                label="Product fee"
                variant="outlined"
                fullWidth
                options={fees}
                placeholder="Select fee"
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                onChange={e => updateForm('fee', e.value)}
                menuPortalTarget={document.body}
              />
            </Grid>
          ) : null}
          {!edit ? (
            <Grid item xs={12}>
              <TextField
                label="Name *"
                fullWidth
                variant="outlined"
                value={form.name}
                onChange={e => updateForm('name', e.target.value)}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              label="Online description *"
              fullWidth
              multiline
              rows="3"
              variant="outlined"
              value={form.description}
              onChange={e => updateForm('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Max number of kids *"
              fullWidth
              variant="outlined"
              value={form.max_kids}
              onChange={e => updateForm('max_kids', e.target.value)}
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={4}
        >
          <Grid item xs={6}>
            Start date
            <DatePickerComponent
              id="league_start_date"
              placeholder="Start date *"
              value={unixToJsDate(form.start_date)}
              onChange={e => updateForm('start_date', jsDateToUnix(e.value))}
            />
          </Grid>
          <Grid item xs={6}>
            End date
            <DatePickerComponent
              id="league_end_date"
              placeholder="End date *"
              value={unixToJsDate(form.end_date)}
              onChange={e => updateForm('end_date', jsDateToUnix(e.value))}
            />
          </Grid>
          <Grid item xs={6}>
            Registration start
            <DatePickerComponent
              id="reg_start_date"
              placeholder="Registration start date *"
              value={unixToJsDate(form.registration_start_date)}
              onChange={e =>
                updateForm('registration_start_date', jsDateToUnix(e.value))
              }
            />
          </Grid>
          <Grid item xs={6}>
            Registration end
            <DatePickerComponent
              id="reg_end_date"
              placeholder="Registration end date *"
              value={unixToJsDate(form.registration_end_date)}
              onChange={e =>
                updateForm('registration_end_date', jsDateToUnix(e.value))
              }
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0, textAlign: 'left' }}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.available_online}
                      onChange={e =>
                        updateForm('available_online', e.target.checked)
                      }
                      name="available_online"
                    />
                  }
                  label="Available for online registration"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0, textAlign: 'left' }}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.is_camp}
                      onChange={e => updateForm('is_camp', e.target.checked)}
                      name="is_camp"
                    />
                  }
                  label="Is camp"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {edit ? (
        <Grid item xs={12}>
          <Button className="save-btn" onClick={updateClass}>
            Save
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  fees: state.classes.fees.map(fee => ({
    label: `${fee.product.name} - ${fee.text.split('(')[0]} (${
      fee.product.individual_price
    })`,
    value: fee.id,
  })),
  form: state.classes.form,
  is_admin: state.user.is_admin,
  facilities: state.common.filter_facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFees: facility_id => dispatch(getFees(10, facility_id)),
  updateForm: (key, value) => dispatch(actions.updateForm({ key, value })),
  updateClass: () => dispatch(updateClassDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetails)
