import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { unixToDateTime, applyFilter } from './../../helper'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class GLTable extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Discount
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Facility
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Product Type
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Start Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  End Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '15%' }}
                  className="table-header"
                >
                  Comment
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {this.props.discount_rules.map((discount_rule, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="table-header"
                    >
                      {discount_rule.discount.name}
                      <br />
                      <small>
                        <span style={{ color: 'green' }}>
                          {discount_rule.discount.is_percentage
                            ? 'Percentage'
                            : 'Fixed amount'}
                        </span>
                      </small>
                      <br />
                      <small>
                        Value:{' '}
                        <span style={{ color: 'green' }}>
                          {!discount_rule.discount.is_percentage && '$'}
                          {discount_rule.discount.value}
                          {discount_rule.discount.is_percentage && '%'}
                        </span>
                      </small>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="table-header"
                    >
                      {discount_rule.facility.name}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="table-header"
                    >
                      {discount_rule.product_type.name}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="table-header"
                    >
                      {unixToDateTime(discount_rule.start_date).format(
                        'MM/DD/YYYY h:mmA'
                      )}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="table-header"
                    >
                      {unixToDateTime(discount_rule.end_date).format(
                        'MM/DD/YYYY h:mmA'
                      )}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="table-header"
                    >
                      {discount_rule.comment}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10%' }}
                      className="table-header"
                    >
                      <IconButton className={classes.root}>
                        <DeleteIcon
                          color="secondary"
                          fontSize="small"
                          className="del-btn"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  discount_rules: state.discount.discount_rules.filter(d =>
    applyFilter(d, state.discount.query)
  ),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GLTable))
