import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { getHealthReport } from './../actions'
import { buildHealthData } from './../helper'
import { exportToCSV } from './../../helper'

const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'Date signed',
    selector: 'date',
    sortable: true,
    center: true,
    format: row => moment(row.date).format('MM/DD/YYYY h:mm A'),
  },
  {
    name: 'First Name',
    selector: 'first_name',
    sortable: true,
    center: true,
  },
  {
    name: 'Last Name',
    selector: 'last_name',
    sortable: true,
    center: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
    center: true,
    cell: row => (
      <Link href={`/customer/${row.customer_id}`} target="_blank">
        View customer
      </Link>
    ),
  },
])

const customStyles = {
  rows: {
    style: {
      minHeight: '75px', // override the row height
    },
  },
}

class HealthReport extends React.Component {
  render() {
    const { health_checks } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Check ins</span>
                  <Button
                    className="save-btn"
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      exportToCSV(
                        buildHealthData(health_checks),
                        'Health_Checks'
                      )
                    }
                  >
                    Export
                  </Button>
                </div>
              }
              columns={columns()}
              data={health_checks}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              customStyles={customStyles}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  health_checks: state.reporting.health_checks,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getHealthReport: () => dispatch(getHealthReport()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HealthReport)
