import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { colors } from './../../consts'
import { applyFilter } from './../../helper'
import { deleteUser, resetPassword } from './../actions'
import { actions } from './../redux'
import { openDeleteModal } from './../../common/actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const StaffBadge = ({ staff_type }) => {
  if (staff_type === 'admin') {
    return (
      <Chip
        size="small"
        label={<b>Admin</b>}
        style={{
          color: colors.red_strong,
          backgroundColor: colors.red_mild,
          margin: 5,
        }}
      />
    )
  }
  if (staff_type === 'manager') {
    return (
      <Chip
        size="small"
        label={<b>Manager</b>}
        style={{
          color: colors.purple_strong,
          backgroundColor: colors.purple_mild,
          margin: 5,
        }}
      />
    )
  }
  if (staff_type === 'mod') {
    return (
      <Chip
        size="small"
        label={<b>MOD</b>}
        style={{
          color: colors.green_strong,
          backgroundColor: colors.green_mild,
          margin: 5,
        }}
      />
    )
  }
  if (staff_type === 'coach') {
    return (
      <Chip
        size="small"
        label={<b>Coach</b>}
        style={{
          color: colors.yellow_strong,
          backgroundColor: colors.yellow_mild,
          margin: 5,
        }}
      />
    )
  }
  if (staff_type === 'referee') {
    return (
      <Chip
        size="small"
        label={<b>Referee</b>}
        style={{
          color: colors.blue_strong,
          backgroundColor: colors.blue_mild,
          margin: 5,
        }}
      />
    )
  }
  return (
    <Chip
      size="small"
      label={<b>NONE</b>}
      style={{
        color: colors.red_strong,
        backgroundColor: colors.red_mild,
        margin: 5,
      }}
    />
  )
}

class StaffTable extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Facility
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Email
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Phone
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Type
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Active
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div className={classes.maxheight}>
          <Table>
            <TableBody>
              {this.props.staff.map(user => (
                <TableRow key={user.ID} hover style={{ cursor: 'pointer' }}>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    {user.first_name} {user.last_name}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    {user.facility.name}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    {user.email}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    {user.phone}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    <StaffBadge staff_type={user.user_type} />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    <i>
                      {user.password ? (
                        <span>Yes</span>
                      ) : (
                        <span style={{ color: 'red' }}>No</span>
                      )}
                    </i>
                    <br />
                    <Link onClick={() => this.props.resetPassword(user.email)}>
                      Reset password
                    </Link>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    <IconButton
                      className={classes.root}
                      onClick={() => this.props.editStaff(user)}
                    >
                      <EditIcon
                        fontSize="small"
                        style={{
                          color: colors.blue_strong,
                        }}
                      />
                    </IconButton>
                    <IconButton
                      className={classes.root}
                      onClick={() => this.props.deleteUser(user.ID)}
                    >
                      <DeleteIcon
                        fontSize="small"
                        style={{
                          color: colors.red_strong,
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // route only accessible to admins and managers
  const is_admin = state.user.user_type === 'admin'
  return {
    staff: state.staff.staff
      .filter(staff => {
        if (is_admin) return true
        return staff.user_type !== 'admin'
      })
      .filter(staff =>
        applyFilter(
          {
            first_name: staff.first_name,
            last_name: staff.last_name,
            email: staff.email,
          },
          state.staff.filter.query
        )
      )
      .filter(staff => {
        if (state.staff.filter.user_type) {
          return staff.user_type === state.staff.filter.user_type
        }
        return true
      }),
    is_admin,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteUser: id => dispatch(openDeleteModal('user', () => deleteUser(id))),
  resetPassword: email => dispatch(resetPassword(email)),
  editStaff: s => dispatch(actions.editStaff({ staff: s })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StaffTable))
