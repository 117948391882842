import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import ProductForm from 'product/components/ProductForm'
import ProductTable from 'product/components/NewProductTable'

import { getNewProductByID } from 'product/actions'

const Manager = () => {
  const dispatch = useDispatch(),
    url = useSelector(state => state.router.location.pathname),
    productID = url.replace('/newproduct/', ''),
    product = useSelector(state => state.product.new_product_details),
    [tab, setTab] = useState('base'),
    qParam = useSelector(state => state.router.location.query)

  useEffect(() => {
    productID && tab === 'base' && dispatch(getNewProductByID(productID))
  }, [productID, tab])

  useEffect(() => {
    qParam?.tab && setTab(qParam?.tab)
  }, [qParam])
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Link href="/newproduct">Back</Link>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup value={tab} exclusive aria-label="text alignment">
          <ToggleButton
            value="base"
            aria-label="left aligned"
            onClick={() => (window.location = `${url}?tab=base`)}
          >
            Base product
          </ToggleButton>
          <ToggleButton
            value="facility"
            aria-label="centered"
            onClick={() => (window.location = `${url}?tab=facility`)}
          >
            Facility settings
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {tab === 'base' && (
        <Grid item xs={12}>
          <ProductForm no_gl is_edit />
        </Grid>
      )}
      {tab === 'facility' && (
        <>
          <Grid item xs={12}>
            <ProductTable products={product?.variations} show_facility />
          </Grid>
          <Grid item xs={12}>
            <ProductForm no_gl show_facilities no_duration no_name />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default withRouter(Manager)
