import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { ERP_BACKEND_URL } from './../../../consts'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Select from 'react-select'

import {
  getSimpleDailyMatches,
  sendDailySchedule,
  setNoShows,
} from './../../actions'
import { momentDateTimeToUnix, momentDateToUnix } from './../../../helper'

class ELeagueActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      level: null,
      sendEmailButtonOK: false,
      time: null,
      setNoShowButtonOK: false,
    }
  }

  componentDidMount() {
    this.props.getSimpleDailyMatches()
  }

  sendMatchEmails() {
    this.props.sendDailySchedule(this.state.level.value)
    this.setState({ sendEmailButtonOK: false })
  }

  onMatchDropdownChange(a) {
    axios
      .post(ERP_BACKEND_URL + '/log/check', {
        log_type: 'email_send',
        key: `email_schedule_${this.props.league_id}_${a.value}_${this.props.start}`,
      })
      .then(resp => {
        if (resp.data) {
          // means action already sent
          this.setState({ level: a, sendEmailButtonOK: false })
          return
        }
        this.setState({ level: a, sendEmailButtonOK: true })
      })
  }

  setNoShow() {
    this.props.setNoShows(this.state.time.value)
    this.setState({ setNoShowButtonOK: false })
  }

  onTimeDropdownChange(a) {
    axios
      .post(ERP_BACKEND_URL + '/log/check', {
        log_type: 'set_no_shows',
        key: `league_${this.props.league_id}_no_shows_home_${a.value}`,
      })
      .then(resp => {
        if (resp.data) {
          // means action already sent
          this.setState({ time: a, setNoShowButtonOK: false })
          return
        }
        this.setState({ time: a, setNoShowButtonOK: true })
      })
  }

  render() {
    return (
      <Paper elevation={0} style={{ marginBottom: '2em' }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12} style={{ padding: 20 }}>
            <Select
              options={this.props.matches_dropdown}
              value={this.state.level}
              onChange={a => this.onMatchDropdownChange(a)}
              placeholder="Match type *"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className="save-btn"
              onClick={() => this.sendMatchEmails()}
              disabled={!this.state.sendEmailButtonOK}
            >
              Send match list to players
            </Button>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12} style={{ padding: 20 }}>
            <Select
              options={this.props.times_dropdown}
              value={this.state.time}
              onChange={a => this.onTimeDropdownChange(a)}
              placeholder="Time slot *"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className="save-btn"
              onClick={() => this.setNoShow()}
              disabled={!this.state.setNoShowButtonOK}
            >
              Set 3-0 against no-shows
            </Button>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const today = moment().format('M/D/YYYY')
  let champ_matches = false,
    times = {},
    levels = {}
  state.league.league_matches.forEach(m => {
    if (m.e_league_date.format('M/D/YYYY') === today) {
      if (m.level === 0) {
        champ_matches = true
      }
      levels[m.level] = true
      times[m.e_league_time.unix()] = m.e_league_time.format('h:mmA')
    }
  })
  let matches_dropdown = []
  if (champ_matches) {
    matches_dropdown.push({ label: "Today's championship", value: 0 })
  }
  Object.keys(levels)
    .map(p => parseInt(p))
    .sort((a, b) => b - a)
    .forEach(l => {
      if (l > 0) {
        matches_dropdown.push({ label: `Round of ${l}`, value: l })
      }
    })
  let times_dropdown = []
  Object.keys(times)
    .sort()
    .forEach(t => {
      times_dropdown.push({
        label: times[t],
        value: momentDateTimeToUnix(
          moment(today + ' ' + times[t], 'M/D/YYYY h:mmA')
        ),
      })
    })
  return {
    matches_dropdown,
    times_dropdown,
    start: momentDateToUnix(moment(today, 'M/D/YYYY')),
    league_id: state.league.league.ID,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSimpleDailyMatches: () => dispatch(getSimpleDailyMatches(moment())),
  sendDailySchedule: level => dispatch(sendDailySchedule(moment(), level)),
  setNoShows: time => dispatch(setNoShows(time)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ELeagueActions)
