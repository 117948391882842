import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { getUpcomingClasses, changeClass } from 'classes/actions'
import { openConfirmModal } from 'common/actions'
import { actions } from 'classes/redux'

const ChangeClassDialog = ({
  open,
  new_class_id,
  classes,
  handleClose,
  getUpcomingClasses,
  changeClass,
  saveNewClass,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      onEntering={getUpcomingClasses}
    >
      <DialogTitle id="form-dialog-title">
        Change class
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={classes}
          getOptionLabel={option => `${option.name}`}
          renderInput={params => (
            <TextField
              {...params}
              label="Select class"
              variant="outlined"
              fullWidth
            />
          )}
          value={new_class_id && classes.find(c => c.ID === new_class_id)}
          onChange={(e, c) => changeClass(c.ID)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveNewClass} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.classes.change_class,
  classes: state.classes.classes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.editChangeClass({ key: 'open', value: false })),
  getUpcomingClasses: () => dispatch(getUpcomingClasses()),
  changeClass: new_class_id =>
    dispatch(
      actions.editChangeClass({ key: 'new_class_id', value: new_class_id })
    ),
  saveNewClass: () => {
    dispatch(
      openConfirmModal('Player class change', `change the player's class`, () =>
        changeClass()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeClassDialog)
