import { unixToDateTime } from 'helper'

export const buildEventData = events => {
  return events.map(event => {
    const mom_date = unixToDateTime(event.start_date)
    return {
      Date: mom_date.format('MM/DD/YYYY'),
      'Start time': mom_date.format('h:mmA'),
      'End time': unixToDateTime(event.end_date).format('h:mmA'),
      'Duration (min)': (event.end_date - event.start_date) / 60,
      Customer: event.customer.first_name + ' ' + event.customer.last_name,
      Email: event.customer.email,
      Facility: event.field.facility.name,
      Field: `${event.field.number} - ${event.field.name}`,
      Type: `${event.field.exterior ? 'Outdoor' : 'Indoor'} ${
        event.field.field_type
      }`,
      Invoiced: event.invoice?.invoiced,
      Balance: event.invoice?.balance,
    }
  })
}
