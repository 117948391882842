import React from 'react'
import moment from 'moment'

import Select from 'react-select'
import {
  DatePickerComponent,
  TimePickerComponent,
} from '@syncfusion/ej2-react-calendars'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'

import { unixToDateTime, momentDateTimeToUnix } from './../../../helper'
import { colors } from './../../../consts'

const RepeatTableEdit = ({
  i,
  event,
  fields,
  updateRecurringEvent,
  is_edit,
  deleteEvent,
  checkFunc,
  setEdit,
}) => (
  <TableRow
    key={`${event.ID || 0}_${event.field_id || 0}_${event.start_date}`}
    style={{
      backgroundColor: event.is_ok ? colors.green_mild : colors.red_mild,
    }}
  >
    <TableCell style={{ textAlign: 'center', width: '25%' }}>
      <Select
        options={fields}
        placeholder="Select field..."
        value={event.field_id && fields.find(f => f.value === event.field_id)}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        onChange={e => updateRecurringEvent(i, 'field_id', e.value)}
      />
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '25%' }}>
      <DatePickerComponent
        id="start_date"
        placeholder="Start date"
        value={unixToDateTime(event.start_date).toDate()}
        onChange={e =>
          updateRecurringEvent(
            i,
            'start_date',
            momentDateTimeToUnix(moment(e.value))
          )
        }
      />
      <TimePickerComponent
        id="start_time"
        placeholder="Start time"
        value={unixToDateTime(event.start_date).toDate()}
        step={15}
        onChange={e =>
          updateRecurringEvent(
            i,
            'start_date',
            momentDateTimeToUnix(moment(e.value))
          )
        }
      />
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '25%' }}>
      <DatePickerComponent
        id="end_date"
        placeholder="End date"
        value={unixToDateTime(event.end_date).toDate()}
        onChange={e =>
          updateRecurringEvent(
            i,
            'end_date',
            momentDateTimeToUnix(moment(e.value))
          )
        }
      />
      <TimePickerComponent
        id="end_time"
        placeholder="End time"
        value={unixToDateTime(event.end_date).toDate()}
        onChange={e =>
          updateRecurringEvent(
            i,
            'end_date',
            momentDateTimeToUnix(moment(e.value))
          )
        }
        step={15}
      />
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '10%' }}>
      {event.is_ok ? 'OK' : 'Taken'}
      {event.to_check && (
        <div>
          <br />
          <Button onClick={() => checkFunc(event.ID)} className="save-btn">
            Check {is_edit && ' & Save'}
          </Button>
        </div>
      )}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '15%' }}>
      <IconButton onClick={() => setEdit()}>
        <SaveIcon color="primary" fontSize="small" />
      </IconButton>
      <IconButton onClick={() => deleteEvent(i)}>
        <DeleteIcon style={{ color: colors.red_strong }} fontSize="small" />
      </IconButton>
    </TableCell>
  </TableRow>
)

export default RepeatTableEdit
