import React, { useState } from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { broadcastMessage } from 'classes/actions'
import { actions } from 'classes/redux'
import { openConfirmModal } from 'common/actions'

const styles = () => ({
  paper: {
    height: '100%',
  },
})

const BroadcastMessage = ({
  broadcastMessage,
  classes,
  handleClose,
  open,
  teams,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [subject, setSubject] = useState(null)

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
  }

  const sendMessage = () => {
    const html = stateToHTML(editorState.getCurrentContent())
    let recipients = teams.map(team => team.parent.email)
    broadcastMessage(subject, html, recipients)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id="form-dialog-title">
        Broadcast message to class
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <TextField
              label="Subject *"
              fullWidth
              variant="outlined"
              value={subject}
              onChange={e => setSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ height: '50vh' }}>
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              wrapperStyle={{
                height: '100%',
              }}
              editorStyle={{
                border: '1px solid #F1F1F1',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={sendMessage} className="save-btn">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  open: state.classes.broadcast_message_open,
  teams: state.classes.classInfo.attendees,
})

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(actions.updateBroadcastMessage({ open: false })),
  broadcastMessage: (subject, html, recipients) => {
    dispatch(
      openConfirmModal(
        'Broadcast',
        `send this message to all participants`,
        () => broadcastMessage(subject, html, recipients)
      )
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BroadcastMessage))
