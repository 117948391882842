import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { getCamerasReport } from 'reporting/actions'

const DailyReport = () => {
  const dispatch = useDispatch(),
    cameras = useSelector(state => state.reporting.cameras)
  useEffect(() => dispatch(getCamerasReport()), [])
  return (
    <div>
      <h2>
        <b>Cameras report</b>
      </h2>
      <small>
        <u>Note</u>: can be a false positive if there were no events on that
        field yesterday
      </small>
      {cameras &&
        Object.keys(cameras).map(center => (
          <ul key={center}>
            <b>{center}</b>
            {cameras[center].map(field => (
              <li key={field}>{field}</li>
            ))}
          </ul>
        ))}
    </div>
  )
}

export default DailyReport
