import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  medias: [],
  selected_media: null,
  filters: {
    facility_id: null,
    product: null,
    season: null,
    age_group: null,
    deleted: null,
  },
  details_open: false,
}

const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
      getMedias: (state, { payload }) => {
        state.medias = payload.medias
      },
      updateFilter: (state, { payload }) => {
        state.filters = {
          ...state.filters,
          [payload.key]: payload.value,
        }
      },
      toggleDetails: (state, { payload }) => {
        state.details_open = payload.open
      },
      selectPhoto: (state, { payload }) => {
        state.details_open = true
        state.selected_media = payload.photo
      },
    },
  }),
  actions = mediaSlice.actions

export { mediaSlice, actions }
