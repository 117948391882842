import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import { unixToDate } from 'helper'
import { momentToUnix } from '../helpers'

import { DateRangePicker } from 'react-dates'

import { updateSearch, getMatches, toggleModal, getReferees } from '../actions'
import { colors } from './../../consts'

const styles = theme => ({
  paper: {
    maxHeight: 650,
    overflow: 'scroll',
  },
})

const assigned_options = [
  { label: 'Ref assigned', value: 'yes' },
  { label: 'Ref unassigned', value: 'no' },
]

const Filters = ({
  start_date,
  end_date,
  updateSearch,
  facilities,
  facility_id,
  matchIds,
  getMatches,
  getReferees,
  toggleModal,
  assigned_type,
  field_number,
  fields,
  is_admin,
}) => {
  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <Grid container spacing={2} justify="flex-start">
      <Grid item xs={3}>
        <DateRangePicker
          small
          isOutsideRange={() => false}
          startDate={unixToDate(start_date)}
          startDateId="your_unique_start_date_id"
          endDate={unixToDate(end_date)}
          endDateId="your_unique_end_date_id"
          onDatesChange={({ startDate, endDate }) => {
            if (!startDate && !endDate) {
              updateSearch('start_date', null)
              updateSearch('end_date', null)
            }
            if (startDate) {
              updateSearch('start_date', momentToUnix({ date: startDate }))
            }
            if (endDate) {
              updateSearch(
                'end_date',
                momentToUnix({ date: endDate, end: true })
              )
            }
          }}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          minimumNights={0}
          showClearDates={true}
        />
      </Grid>
      {is_admin ? (
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={facilities}
            placeholder="Facility"
            value={facility_id && facilities.find(f => f.value === facility_id)}
            onChange={v => updateSearch('facility_id', v && v.value)}
            isClearable={true}
          />
        </Grid>
      ) : null}
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Select
          options={fields}
          placeholder="Field"
          value={field_number && fields.find(f => f.value === field_number)}
          onChange={v => updateSearch('field_number', v && v.value)}
          isClearable={true}
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Select
          options={assigned_options}
          placeholder="Game assigned"
          value={
            assigned_type &&
            assigned_options.find(a => a.value === assigned_type)
          }
          onChange={v => updateSearch('assigned_type', v && v.value)}
          isClearable={true}
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          className="save-btn"
          style={{ width: '100%' }}
          onClick={() => getMatches()}
        >
          Search
        </Button>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Button
          style={{
            color: colors.blue_strong,
            backgroundColor: colors.blue_mild,
          }}
          component="span"
          onClick={() => {
            toggleModal()
            getReferees()
          }}
          disabled={matchIds && matchIds.length ? false : true}
        >
          Assign Referee
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.referee.search,
  matchIds: state.referee.matchIds,
  facilities: state.common.facilities.map(f => ({
    label: f.name,
    value: f.ID,
  })),
  fields: _.uniqBy(
    state.referee.matches
      .map(m => m.event?.field)
      .map(f => ({
        value: f.number,
        label: `${f.number}-${f.name}`,
      })),
    'value'
  ).sort((a, b) => a.value - b.value),
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSearch: (k, v) => dispatch(updateSearch(k, v)),
  getMatches: () => dispatch(getMatches()),
  toggleModal: () => dispatch(toggleModal(true)),
  getReferees: () => dispatch(getReferees()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Filters))
