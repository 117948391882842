import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from 'common/components/FastTextField'

import { createTournamentDivision } from 'league/actions'
import { leagueSlice } from 'league/redux'

class NewDivisionDialog extends React.Component {
  render() {
    const { open, handleClose, name, changeName, createTournamentDivision } =
      this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          New age group
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name *"
            fullWidth
            variant="outlined"
            value={name}
            onChange={e => changeName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={createTournamentDivision}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.new_division,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(
      leagueSlice.actions.updateNewDialog({ key: 'open', value: false })
    ),
  changeName: name =>
    dispatch(leagueSlice.actions.updateNewDialog({ key: 'name', value: name })),
  createTournamentDivision: () => dispatch(createTournamentDivision()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewDivisionDialog)
