import api from 'api'
import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import moment from 'moment'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'
import { momentDateToUnix, momentDateTimeToUnix } from './../helper'
import { actions as commonActions } from 'common/redux'

const _getInvoice = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/invoice/${id}`)
    .then(resp => {
      // Concatenate same invoice items
      let m = {}
      resp.data.invoice_items.forEach(invoice_item => {
        let key = `${invoice_item.product_id}_${invoice_item.event_id}_${invoice_item.price}`
        if (!(key in m)) {
          m[key] = invoice_item
        } else {
          m[key].quantity += invoice_item.quantity
        }
      })
      dispatch(
        actions.getInvoice({
          invoice: {
            ...resp.data,
            invoice_items: Object.keys(m).map(k => m[k]),
          },
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _reloadInvoice = () => (dispatch, getState) => {
  const { ID } = getState().invoice.invoice
  dispatch(_getInvoice(ID))
}

export const getInvoice = id => (dispatch, getState) =>
  dispatch(_getInvoice(id))

export const getInvoiceByEventID = event_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/invoice/event?event_id=${event_id}`)
    .then(resp => {
      // Concatenate same invoice items
      if (!resp.data) return dispatch(changeLoading(false))
      let m = {}
      resp.data.invoice_items.forEach(invoice_item => {
        let key = `${invoice_item.product_id}_${invoice_item.event_id}_${invoice_item.price}`
        if (!(key in m)) {
          m[key] = invoice_item
        } else {
          m[key].quantity += invoice_item.quantity
        }
      })
      dispatch(
        actions.getInvoice({
          invoice: {
            ...resp.data,
            invoice_items: Object.keys(m).map(k => m[k]),
          },
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getInvoiceByContractID = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.invoice
    .getFromContract(id)
    .then(invoice => {
      dispatch(actions.getInvoice({ invoice }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const searchInvoices = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const search_details = getState().invoice.search
  let query = {}
  if (search_details.start_date) {
    query.start_date = search_details.start_date
  }
  if (search_details.end_date) {
    query.end_date = search_details.end_date
  }
  if (search_details.facility_id) {
    query.facility_id = search_details.facility_id
  }
  if (search_details.product_type_id) {
    query.product_type_id = search_details.product_type_id
  }
  if (search_details.payment_type) {
    query.payment_type = search_details.payment_type
  }
  if (search_details.square_id) {
    query.square_id = search_details.square_id
  }
  if (search_details.customer?.ID) {
    query.customer_ids = [search_details.customer?.ID]
  }
  return axios
    .post(ERP_BACKEND_URL + `/invoice/search/new`, { query })
    .then(resp => {
      dispatch(
        actions.getAllInvoices({
          invoices: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateSearch = (field, value) => (dispatch, getState) => {
  dispatch(
    actions.updateSearch({
      field,
      value,
    })
  )
}

export const addPayment = square_transaction_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { invoice, payment_form } = getState().invoice
  let body = {
    invoice_id: invoice.ID,
    amount: parseFloat(payment_form.amount),
    type: payment_form.method,
  }
  if (payment_form.player_id) {
    body.player_customer_id = payment_form.player_id
  }
  let details = null
  if (payment_form.method === 'check') {
    details = JSON.stringify({ number: payment_form.check_number })
  }
  if (payment_form.method === 'wire') {
    details = JSON.stringify({
      transaction_number: payment_form.transaction_number,
    })
  }
  if (payment_form.method === 'credit') {
    if (body.amount > payment_form.customer.credits) {
      return dispatch(
        dispatchError({
          message: `Not enough credits, customer has $${payment_form.customer.credits} in credit.`,
        })
      )
    }
    details = JSON.stringify({ customer_id: payment_form.customer.ID })
  }
  if (payment_form.method === 'card') {
    if (square_transaction_id) {
      details = JSON.stringify({
        method: 'manual_card_entry',
        square_transaction_id,
      })
    } else {
      return dispatch(
        dispatchError({ message: 'Card must be charged on square' })
      )
    }
  }
  if (details) {
    body.details = details
  }
  return axios
    .post(ERP_BACKEND_URL + `/payment`, body)
    .then(resp => {
      dispatch(commonActions.openInfoModal({ message: 'Payment successfull' }))
      dispatch(_reloadInvoice())
      if (payment_form.method === 'credit') {
        dispatch(updateCustomerCredits(payment_form.customer, body.amount))
      }
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateCustomerCredits = (customer, amount) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .put(ERP_BACKEND_URL + `/customer/${customer.ID}`, {
      credits: customer.credits - amount,
    })
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const createInvoice = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const invoice_details = getState().invoice.invoice_form
  const body = {
    date: momentDateToUnix(moment(invoice_details.date)),
    customer_id: invoice_details.customer.ID,
    invoice_type_id: 1,
    facility_id: invoice_details.facility_id,
    product_type_id: invoice_details.ptype_id,
    comment: invoice_details.comment,
    invoice_items: [
      {
        product_id: invoice_details.product_id,
        price: parseFloat(invoice_details.amount),
      },
    ],
  }
  return axios
    .post(ERP_BACKEND_URL + `/invoice`, body)
    .then(resp => {
      window.location = `/invoice/${resp.data.ID}`
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateInvoice = date => (dispatch, getState) => {
  const { ID } = getState().invoice.invoice
  return axios
    .put(ERP_BACKEND_URL + `/invoice/${ID}`, { date })
    .then(resp => {
      window.location = `/invoice/${ID}`
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getFees =
  (product_type_id, facility_id) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    let actual_product_type = product_type_id
    if (product_type_id === 2) {
      // block booking and on-demand share the same products
      actual_product_type = 1
    }
    if (!actual_product_type) {
      return dispatch(changeLoading(true))
    }
    return api.product
      .getPricesByID(actual_product_type, facility_id)
      .then(prices => {
        dispatch(
          actions.getFees({
            fees: prices.map(fee => ({
              ...fee,
              id: `${fee.product_id};${fee.invoice_price}`,
            })),
          })
        )
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const deleteInvoiceItem = invoice_item_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/invoice/item/` + invoice_item_id.toString())
    .then(resp => {
      dispatch(_reloadInvoice())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const addCredits = (add_credits, payment_id) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let { customer, team_id, payments } = getState().invoice.invoice
  if (team_id) {
    customer = payments.find(payment => payment.ID === payment_id)?.customer
  }
  if (!customer) {
    return dispatch(dispatchError({ message: 'No customer in invoice' }))
  }
  if (!customer.ID) {
    return dispatch(dispatchError({ message: 'No customer in invoice' }))
  }
  const new_credits = parseFloat(customer.credits) + parseFloat(add_credits)
  return axios
    .put(ERP_BACKEND_URL + `/customer/${customer.ID}`, {
      credits: new_credits,
    })
    .then(resp => {
      dispatch(actions.toggleAddCreditModal({ open: false }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const revertPayment = (payment_id, amount) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/payment/${payment_id}?refund=0&p_type=credit`)
    .then(resp => {
      dispatch(_reloadInvoice())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const refundPayment = refund_amount => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { p_type, id, amount } = getState().invoice.delete_payment
  if (refund_amount > amount) {
    return dispatch(
      dispatchError({
        message: 'Refund amount cant be greater than payment amount',
      })
    )
  }
  if (!p_type) {
    return dispatch(
      dispatchError({
        message: 'Please select a valid payment type',
      })
    )
  }
  return axios
    .delete(
      ERP_BACKEND_URL +
        `/payment/${id}?refund=1&p_type=${p_type}&amount=${refund_amount}`
    )
    .then(resp => {
      dispatch(actions.toggleDeletePaymentModal({ open: false }))
      dispatch(_reloadInvoice())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const addInvoice =
  (
    event_id,
    customer_id,
    product_id,
    price,
    facility_id,
    product_type_id,
    quantity
  ) =>
  (dispatch, getState) => {
    dispatch(changeLoading(true))
    let invoice_item = {
      event_id,
      product_id,
      price,
    }
    if (quantity) invoice_item.quantity = quantity
    const body = {
      facility_id: facility_id || 0,
      product_type_id: product_type_id || 0,
      date: momentDateTimeToUnix(moment()),
      customer_id,
      invoice_type_id: 1,
      invoice_items: [invoice_item],
    }
    return axios
      .post(ERP_BACKEND_URL + `/invoice`, body)
      .then(resp => {
        dispatch(getInvoice(resp.data.ID))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const addInvoiceItem =
  (event_id, customer_id, facility_id, product_type_id, quantity) =>
  (dispatch, getState) => {
    dispatch(changeLoading(true))
    if (!event_id) {
      return dispatch(dispatchError({ message: "EventID can't be empty" }))
    }
    const invoice_details = getState().invoice
    let product_id = 0,
      price = 0
    if (invoice_details.update_fee.fee_id !== -1) {
      const spl = invoice_details.update_fee.fee_id.split(';')
      product_id = parseInt(spl[0])
      price = parseFloat(spl[1])
    } else {
      price = parseFloat(invoice_details.update_fee.manual_fee)
    }
    if (!invoice_details.invoice || !invoice_details.invoice.ID) {
      return dispatch(
        addInvoice(
          event_id,
          customer_id,
          product_id,
          price,
          facility_id,
          product_type_id,
          quantity
        )
      )
    }
    let body = {
      invoice_id: invoice_details.invoice.ID,
      event_id,
      product_id,
      price,
    }
    if (quantity) body.quantity = quantity
    return axios
      .post(ERP_BACKEND_URL + `/invoice/item`, body)
      .then(resp => {
        dispatch(_reloadInvoice())
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const updateInvoiceItem =
  (invoice_item, fee_id, quantity) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    const spl = fee_id.split(';'),
      product_id = parseInt(spl[0]),
      price = parseFloat(spl[1])
    let body = {
      invoice_id: invoice_item.invoice_id,
      event_id: invoice_item.event_id,
      product_id,
      price,
    }
    if (quantity) body.quantity = quantity
    return api.invoice
      .deleteInvoiceItem(invoice_item.ID)
      .then(() => {
        return axios.post(ERP_BACKEND_URL + `/invoice/item`, body)
      })
      .then(resp => {
        dispatch(commonActions.openInfoModal({ message: 'Edit successfull' }))
        dispatch(_reloadInvoice())
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const getDiscounts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/discount`)
    .then(resp => {
      dispatch(
        actions.getDiscounts({ discounts: resp.data.filter(d => !d.expired) })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const addDiscount = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { invoice, discount_id, custom_discount_amount } = getState().invoice
  let discount = {
    invoice_id: invoice.ID,
  }
  if (discount_id !== -1) {
    discount['discount_id'] = discount_id
  } else {
    discount['amount'] = parseFloat(custom_discount_amount)
    discount['comment'] = 'Manual discount'
  }
  return axios
    .post(ERP_BACKEND_URL + `/discount/apply`, discount)
    .then(resp => {
      dispatch(_reloadInvoice())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteDiscount = discount_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/discount/apply/` + discount_id.toString())
    .then(resp => {
      dispatch(_reloadInvoice())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteInvoice = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/invoice/` + id.toString())
    .then(() => {
      window.location = `/invoice`
    })
}

export const sendInvoiceToCustomer = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().invoice.invoice.customer
  if (!customer) {
    return dispatch(dispatchError({ message: 'No customer in invoice' }))
  }
  if (!customer.ID) {
    return dispatch(dispatchError({ message: 'No customer in invoice' }))
  }
  const invoiceID = getState().invoice.invoice.ID,
    invoiceURL = `https://webapp.sofive.com/invoice.html?invoice_id=${btoa(
      '1:' + invoiceID
    )}`
  return axios
    .post(
      ERP_BACKEND_URL + `/invoice/send/${customer.ID}?invoice=${invoiceURL}`
    )
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateInvoiceItemFee = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().invoice.invoice,
    { invoice_item_id, price } = getState().invoice.update_fee
  return axios
    .put(ERP_BACKEND_URL + `/invoice/item/${invoice_item_id}`, {
      invoice_id: ID,
      price,
    })
    .then(() => {
      dispatch(_getInvoice(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}
