import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  discounts: [],
  discount_rules: [],
  query: '',
  new_discount: {
    name: '',
    is_percentage: false,
    value: null,
  },
  new_discount_modal_open: false,
  new_discount_rule: {
    discount_id: null,
    facility_id: null,
    product_type_id: null,
    start_date: null,
    end_date: null,
    comment: null,
  },
  new_discount_rule_modal_open: false,
  tab: 'rules',
  show_expired_discounts: false,
}

const discountSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
      getDiscounts: (state, { payload }) => {
        state.discounts = payload.discounts
      },
      getDiscountRules: (state, { payload }) => {
        state.discount_rules = payload.rules
      },
      updateTab: (state, { payload }) => {
        state.tab = payload
      },
      editSearchQuery: (state, { payload }) => {
        state.query = payload.query
      },
      updateExpired: (state, { payload }) => {
        state.show_expired_discounts = payload.expired
      },
    },
  }),
  actions = discountSlice.actions

export { discountSlice, actions }
