import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/Description'
import GroupIcon from '@material-ui/icons/Group'

import { deleteTeamSignup } from './../../actions'
import { openDeleteModal } from './../../../common/actions'
import { colors } from './../../../consts'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class TeamTable extends React.Component {
  render() {
    const { classes, is_tournament } = this.props

    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                # of players
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Amount billed
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Amount paid
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Balance
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...this.props.teams]
              .sort((a, b) => a.team.ID - b.team.ID)
              .map(team => (
                <TableRow key={team.ID} style={{ cursor: 'pointer' }}>
                  <TableCell
                    style={{ textAlign: 'center', width: '25% !important' }}
                    onClick={() => this.props.gotoTeam(team.team.ID)}
                  >
                    {team.team.name}{' '}
                    {is_tournament ? (
                      <small>
                        {' '}
                        - Group <b>{team.team.group}</b>
                      </small>
                    ) : null}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20% !important' }}
                    onClick={() => this.props.gotoTeam(team.team.ID)}
                  >
                    {team.team?.roster?.length}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20% !important' }}
                    onClick={() => this.props.gotoTeam(team.team.ID)}
                  >
                    {team.invoice
                      ? `$${team.invoice.invoiced - team.invoice.discounted}`
                      : '$0'}
                    {team.invoice ? (
                      <Link
                        href={`/invoice/${team.invoice.ID}`}
                        target="_blank"
                      >
                        <IconButton style={{ padding: 1 }}>
                          <FileCopyIcon
                            fontSize="small"
                            style={{
                              color: colors.blue_strong,
                            }}
                          />
                        </IconButton>
                      </Link>
                    ) : (
                      <IconButton style={{ padding: 1 }}>
                        <FileCopyIcon
                          fontSize="small"
                          style={{
                            color: colors.red_strong,
                          }}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '25% !important' }}
                    onClick={() => this.props.gotoTeam(team.team.ID)}
                  >
                    {team.invoice ? `$${team.invoice.paid}` : '$0'}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '25% !important' }}
                    onClick={() => this.props.gotoTeam(team.team.ID)}
                  >
                    {team.invoice
                      ? `$${
                          team.invoice.invoiced -
                          team.invoice.discounted -
                          team.invoice.paid
                        }`
                      : '$0'}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    <Link href={`/team/${team.team.ID}`} target="_blank">
                      <IconButton className={classes.root}>
                        <GroupIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Link>
                    {(!team.invoice ||
                      (team.invoice && team.invoice.paid === 0)) && (
                      <IconButton
                        className={classes.root}
                        onClick={() => this.props.deleteTeam(team.ID)}
                      >
                        <DeleteIcon
                          color="secondary"
                          fontSize="small"
                          style={{ color: colors.red_strong }}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  teams: state.league.league?.teams,
  is_tournament: state.league.league?.is_tournament,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoTeam: id => dispatch(push('/team/' + id)),
  deleteTeam: id =>
    dispatch(openDeleteModal('team', () => deleteTeamSignup(id))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamTable))
