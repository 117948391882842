import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ProductFieldTimeForm from '../../calendar/components/forms/ProductFieldTimeSection'

import { actions } from 'classes/redux'
import { getFields } from 'calendar/actions'
import { editClassEvent } from 'classes/actions'

const CreateEventDialog = ({
  classInfo,
  editClassEvent,
  getFields,
  handleClose,
  open,
}) => {
  useEffect(() => {
    getFields(classInfo.facility_id)
  }, [classInfo.facility_id, getFields])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      disableBackdropClick={true}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Edit event
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ProductFieldTimeForm hide_comment={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => editClassEvent()}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  open: state.classes.new_event_modal_open,
  edit: state.classes.new_event_modal_edit,
  classInfo: state.classes.classInfo,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.toggleEventCreationModal({ open: false })),
  getFields: id => dispatch(getFields(id)),
  editClassEvent: () => dispatch(editClassEvent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventDialog)
