import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import moment from 'moment'
import { changeLoading, dispatchError } from './../common/actions'
import { getLeagueDetails } from 'league/actions'
import { actions } from 'teams/redux'
import { actions as commonActions } from '../common/redux'
import { createOrUpdateCustomerReturn } from 'customer/actions'
import { actions as customerActions } from 'customer/redux'
import api from 'api'

const _getTeams = forced_facility_id => (dispatch, getState) => {
  let url = `/team/all`
  const { facility_id, user_type } = getState().user
  if (forced_facility_id) {
    url = url + '?facility_id=' + forced_facility_id
  } else {
    if (user_type !== 'admin') {
      url = url + '?facility_id=' + facility_id
    }
  }
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(
        actions.getAllTeams({
          teams: resp.data.map(team => {
            let captain = team.captain
            if (!captain) {
              captain = team.roster.length && team.roster[0].customer
            }
            const age_group =
              team.leagues.length && team.leagues[0].age_group.name
            return { ...team, captain, age_group }
          }),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getTeams = forced_facility_id => (dispatch, getState) => {
  dispatch(_getTeams(forced_facility_id))
}

export const createTeam = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const team_details = getState().teams.team_form,
    body = {
      name: team_details.name,
      color: team_details.color,
      facility_id: team_details.facility_id,
    }
  return axios
    .post(ERP_BACKEND_URL + '/team', body)
    .then(resp => {
      dispatch(_getTeams())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _getTeamDetails = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/team/` + id)
    .then(resp => {
      const team_data = resp.data,
        current_league = team_data.current_league,
        payments = current_league?.invoice?.payments || []
      let p_map = {}
      payments.forEach(p => {
        p_map[p.player_customer_id] = p
      })
      const roster = team_data.roster.map(p => ({
        ...p,
        payment: p_map[p.customer.ID] || null,
      }))
      dispatch(
        actions.getTeam({
          team: {
            ...team_data,
            roster,
          },
        })
      )
      if (current_league?.ID) {
        dispatch(getLeagueDetails(current_league.ID))
      }
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getTeamDetails = id => (dispatch, getState) =>
  dispatch(_getTeamDetails(id))

const _updateCaptain = id => (dispatch, getState) => {
  const { team } = getState().teams
  return axios
    .put(ERP_BACKEND_URL + '/team/' + team.ID, { captain_id: id })
    .then(resp => {
      dispatch(_getTeamDetails(team.ID))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const addPlayer = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { customer, creation } = getState().customer,
    facility_id = getState().user.facility_id,
    team_details = getState().teams,
    body = {
      team_id: team_details.team.ID,
      registration_date: parseInt(moment().format('X')),
    }

  let { first_name, last_name, email, phone } = creation
  let p = Promise.resolve(customer?.ID)
  if (first_name && last_name && email) {
    let customer_body = {
      first_name,
      last_name,
      email,
      facility_id,
      customer_type_id: 1,
    }
    if (phone) customer_body.hubspot = { phone }

    p = createOrUpdateCustomerReturn(customer_body)
  }
  p.then(id => {
    if (!id) return dispatch(dispatchError({ message: 'Error adding player' }))
    body.customer_id = id
    return axios.post(ERP_BACKEND_URL + '/player', body).then(() => {
      if (team_details.team.roster.length === 0) {
        dispatch(_updateCaptain(body.customer_id))
      } else {
        dispatch(_getTeamDetails(team_details.team.ID))
      }
      dispatch(customerActions.clearNewCustomerDetails())
    })
  }).catch(e => dispatch(dispatchError(e)))
}

export const removePlayer = id => (dispatch, getState) => {
  const team_details = getState().teams
  return axios
    .delete(ERP_BACKEND_URL + '/player/' + id)
    .then(resp => {
      dispatch(_getTeamDetails(team_details.team.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateCaptain = id => (dispatch, getState) =>
  dispatch(_updateCaptain(id))

export const searchTeams = (facility_id, query) => (dispatch, getState) => {
  if (!query || query === '') return
  let url = `/team?facility_id=${facility_id}&query=${query}`
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(
        actions.getAllTeams({
          teams: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateTeamLeague = () => (dispatch, getState) => {
  const { team, team_form } = getState().teams,
    league_id = team_form.new_league.ID,
    previous_league_id = team.current_league ? team.current_league.ID : 0,
    team_id = team.ID
  return axios
    .put(ERP_BACKEND_URL + '/team/signup/' + previous_league_id, {
      team_id,
      league_id,
    })
    .then(resp => {
      dispatch(_getTeamDetails(team.ID))
      dispatch(actions.toggleChangeLeagueModal({ open: false }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateTeamName = () => (dispatch, getState) => {
  const { team, team_form } = getState().teams

  return api.team
    .update(team.ID, { name: team_form.name })
    .then(resp => {
      dispatch(_getTeamDetails(team.ID))
      dispatch(
        actions.toggleChangeTeamNameModal({ name: team_form.name, open: false })
      )
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteTeam = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.team
    .delete(id)
    .then(resp => {
      dispatch(changeLoading(false))
      window.location = '/teams'
    })
    .catch(e => {
      dispatch(changeLoading(false))
      dispatch(dispatchError(e))
    })
}

export const confirmDelete = id => (dispatch, getState) => {
  const { team } = getState().teams
  dispatch(
    commonActions.openConfirmModal({
      handleConfirm: () => deleteTeam(id),
      subject: 'Delete team',
      message: `delete ${team.name}`,
    })
  )
}

const _deletePayment = payment_id =>
  axios.delete(
    ERP_BACKEND_URL + `/payment/${payment_id}?refund=0&skip_credit=1`
  )

const _updatePlayer = (player, new_team_id) =>
  axios.put(ERP_BACKEND_URL + `/player/${player.ID}`, {
    team_id: new_team_id,
  })

const _appendPayment = (customer_id, payment, invoice_id) =>
  axios.post(ERP_BACKEND_URL + `/payment`, {
    invoice_id,
    amount: parseFloat(payment.amount),
    type: 'credit',
    player_customer_id: customer_id,
    details: JSON.stringify({ customer_id: customer_id }),
  })

const _getTeam = id =>
  axios.get(ERP_BACKEND_URL + `/team/` + id).then(resp => resp.data)

export const changePlayerTeam = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { player, target_team } = getState().teams.change_team
  return _getTeam(target_team.ID)
    .then(new_team => {
      const invoice = new_team.current_league?.invoice
      return { ...new_team, invoice }
    })
    .then(new_team => {
      let p = Promise.resolve()
      if (player.payment) {
        if (!new_team.invoice?.ID) {
          return dispatch(
            dispatchError({
              message: "Target team has no invoice, can't move player payment",
            })
          )
        }
        p = _appendPayment(
          player.customer.ID,
          player.payment,
          new_team.invoice.ID
        ).then(() => _deletePayment(player.payment.ID))
      }
      return p
        .then(() => {
          return _updatePlayer(player, new_team.ID)
        })
        .then(() => {
          window.location = `/team/${new_team.ID}`
        })
    })
    .catch(e => {
      dispatch(changeLoading(false))
      dispatch(dispatchError(e))
    })
}
