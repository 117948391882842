import axios from 'axios'

import { ERP_BACKEND_URL, ERP_BACKEND_NO_USER_URL } from './../consts'
import { actions } from './redux'
import { actions as commonActions } from './../common/redux'
import { changeLoading, dispatchError } from './../common/actions'

const _getStaff = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let facility_id = getState().staff.filter.facility_id
  if (!facility_id) facility_id = getState().user.facility_id
  return axios
    .get(ERP_BACKEND_URL + `/user?facility_id=${facility_id}`)
    .then(resp => {
      dispatch(actions.getStaff({ staff: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getStaff = () => (dispatch, getState) => dispatch(_getStaff())

export const createStaff = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  if (!getState().user.is_admin_or_manager) {
    return dispatch(
      dispatchError({ message: 'Only admins can create staff members' })
    )
  }
  let body = { ...getState().staff.create_staff }
  delete body.dialog_open
  if (!body.user_type || body.user_type === '') {
    return dispatch(
      dispatchError({ message: 'Please select a valid user type' })
    )
  }
  let p = null
  if (getState().staff.create_staff.dialog_edit) {
    body.id = body.ID
    p = axios.put(ERP_BACKEND_URL + '/user', body)
  } else {
    p = axios.post(ERP_BACKEND_URL + `/user/presignup`, body)
  }
  return p
    .then(() => {
      dispatch(_getStaff())
      dispatch(
        commonActions.openInfoModal({
          message: `Successfully created ${body.user_type}`,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteUser = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/user/` + id)
    .then(resp => {
      dispatch(_getStaff())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const resetPassword = email => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .post(ERP_BACKEND_NO_USER_URL + `/user/reset?email=${email}`)
    .then(resp => {
      dispatch(changeLoading(false))
      dispatch(
        commonActions.openInfoModal({
          message: `Sent password reset link to ${email}`,
        })
      )
    })
    .catch(e => dispatch(dispatchError(e)))
}
