import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import SalesReportWidget from './../components/SalesReportWidget'

import { getInvoiceReport } from './../actions'
import { buildInvoiceData } from './../helper'
import { niceNumber, exportToCSV } from './../../helper'

const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'Invoice',
    selector: 'invoice',
    sortable: true,
    center: true,
    cell: row => (
      <Link href={`/invoice/${row.ID}`} target="_blank">
        {row.ID}
      </Link>
    ),
    sortFunction: (rowA, rowB) => rowA.ID > rowB.ID,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    grow: 2,
    center: true,
    format: row => moment(row.CreatedAt).format('MM/DD/YYYY'),
    sortFunction: (rowA, rowB) => rowA.CreatedAt > rowB.CreatedAt,
  },
  {
    name: 'Customer',
    selector: 'customer',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <Link href={`/customer/${row.customer.ID}`} target="_blank">
        {row.customer.first_name + ' ' + row.customer.last_name}
      </Link>
    ),
  },
  {
    name: 'Product',
    selector: 'product',
    sortable: true,
    grow: 3,
    center: true,
    format: row => row.product,
    sortFunction: (rowA, rowB) => rowA.product > rowB.product,
  },
  {
    name: 'Location',
    selector: 'location',
    sortable: true,
    grow: 2,
    center: true,
    format: row => row.facility,
    sortFunction: (rowA, rowB) => rowA.facility > rowB.facility,
  },
  {
    name: 'Invoiced',
    selector: 'invoiced',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoiced)}`,
    sortFunction: (rowA, rowB) => rowA.invoiced > rowB.invoiced,
  },
  {
    name: 'Discounted',
    selector: 'discounted',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.discounted)}`,
    sortFunction: (rowA, rowB) => rowA.discounted > rowB.discounted,
  },
  {
    name: 'Paid',
    selector: 'paid',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.paid)}`,
    sortFunction: (rowA, rowB) => rowA.paid > rowB.paid,
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.balance)}`,
    sortFunction: (rowA, rowB) => rowA.balance > rowB.balance,
  },
])

class InvoicesReport extends React.Component {
  componentDidMount() {
    this.props.getInvoiceReport()
  }

  render() {
    const {
      invoices,
      payments_by_type,
      payments_by_product,
      payments_by_location,
    } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={4}>
          <SalesReportWidget
            data={payments_by_type}
            title="By status"
            hide_count={true}
          />
        </Grid>
        <Grid item xs={4}>
          <SalesReportWidget data={payments_by_product} title="By product" />
        </Grid>
        <Grid item xs={4}>
          <SalesReportWidget data={payments_by_location} title="By location" />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Invoices</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        exportToCSV(buildInvoiceData(invoices), 'Invoices')
                      }
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={invoices}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  invoices: state.reporting.invoices,
  payments_by_type: state.reporting.payments_by_type,
  payments_by_product: state.reporting.payments_by_product,
  payments_by_location: state.reporting.payments_by_location,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInvoiceReport: () => dispatch(getInvoiceReport()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesReport)
