import React from 'react'
import { connect } from 'react-redux'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import LineTo from 'react-lineto'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'

import {
  updatePlayoffScore,
  deletePlayoffMatches,
  resetPlayoffs,
} from 'league/actions'
import { openConfirmModal } from 'common/actions'

class PlayOffsTable extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{ textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <Button
              className="save-btn"
              onClick={() => this.props.resetPlayoffs()}
            >
              Rebuild playoffs
            </Button>
            <Button
              className="del-btn"
              style={{ marginLeft: 30 }}
              onClick={() => this.props.deletePlayoffMatches()}
            >
              Delete playoffs
            </Button>
            <ReactToPrint content={() => this.componentRef}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button
                    style={{ marginLeft: 30 }}
                    color="secondary"
                    onClick={handlePrint}
                  >
                    Print Schedule
                  </Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </Grid>
          <Grid
            ref={el => (this.componentRef = el)}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            style={{ textAlign: 'center' }}
          >
            {this.props.levels.map((level, index) => (
              <Grid
                item
                xs={2}
                style={{ width: '100%', height: '100%' }}
                key={index}
              >
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    Round of {level}
                  </Grid>
                  {this.props.matches
                    .filter(m => m.level === level)
                    .map(m => (
                      <Grid
                        item
                        xs={12}
                        style={{ width: '100%', border: '1px solid black' }}
                        className={m.index.toString()}
                        key={m.ID}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-end"
                            >
                              <Grid item xs={8}>
                                <Link
                                  href={'/team/' + m.home_team.ID.toString()}
                                  target="_blank"
                                >
                                  {m.home_team.name}
                                </Link>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  label="HScore"
                                  fullWidth
                                  dense
                                  value={m.home_score}
                                  onChange={e =>
                                    this.props.updatePlayoffScore(
                                      m.ID,
                                      'home_score',
                                      e.target.value
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="flex-end"
                            >
                              <Grid item xs={8}>
                                <Link
                                  href={'/team/' + m.away_team.ID.toString()}
                                  target="_blank"
                                >
                                  {m.away_team.name}
                                </Link>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  label="AScore"
                                  fullWidth
                                  dense
                                  value={m.away_score}
                                  onChange={e =>
                                    this.props.updatePlayoffScore(
                                      m.ID,
                                      'away_score',
                                      e.target.value
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {m.event.ID ? (
                            <>
                              <Grid item xs={12}>
                                {m.event.field.number}-{m.event.field.name}
                              </Grid>
                              <Grid item xs={12}>
                                {m.start_parsed}
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              BYE
                            </Grid>
                          )}
                        </Grid>
                        <div>
                          <LineTo
                            from={m.index.toString()}
                            to={(
                              Math.round(m.index / 2) +
                              Math.round(this.props.maxLevel / 2)
                            ).toString()}
                            fromAnchor="right"
                            toAnchor="left"
                          />
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const levels = [...new Set(state.league.playoff_matches.map(m => m.level))],
    maxLevel = Math.max.apply(Math, levels)
  return {
    matches: state.league.playoff_matches,
    levels,
    maxLevel,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePlayoffScore: (id, side, score) =>
    dispatch(updatePlayoffScore(id, side, score)),
  deletePlayoffMatches: () => {
    dispatch(
      openConfirmModal('Playoffs deletion', `delete all playoffs`, () =>
        deletePlayoffMatches()
      )
    )
  },
  resetPlayoffs: () => {
    dispatch(
      openConfirmModal(
        'Playoffs rebuild',
        `rebuild playoffs with the latest results`,
        () => resetPlayoffs()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayOffsTable)
