import moment from 'moment'
import * as FileSaver from 'file-saver'

const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  fileExtension = '.xlsx'

const buildExportInvoiceData = invoice_data => {
  let rows = []
  invoice_data.forEach(invoice => {
    invoice.payments.forEach(payment => {
      const parsed_date = moment(payment.created_at),
        year = parseInt(parsed_date.format('YY')),
        invoice_item = invoice.invoice_items[0]
      if (invoice_item) {
        rows.push({
          invoice_id: invoice.ID,
          invoice_amount: invoice.invoice_items.reduce(
            (a, b) => a + b.price,
            0
          ),
          amount: payment.amount,
          day: parsed_date.format('MM/DD/YYYY'),
          product: invoice.product_type.name,
          season: invoice_item.product.gl.season.name,
          gl: invoice_item.product.gl.code,
          year: `20${year}-${year + 1}`,
          type: payment.type,
          customer: `${invoice.customer.first_name} ${invoice.customer.last_name}`,
          location: invoice.facility.name,
        })
      }
    })
  })
  return rows
}

export const exportToCSV = (invoice_data, fileName) => {
  const final_data = buildExportInvoiceData(invoice_data)
  import('xlsx').then(XLSX => {
    const ws = XLSX.utils.json_to_sheet(final_data),
      wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Hydra')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  })
}
