import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Filters from './../components/Filters'
import Gallery from './../components/Gallery'
import PhotoDetails from './../components/PhotoDetails'

import { getMedias } from './../actions'

const Manage = ({ getMedias }) => {
  useEffect(getMedias, [])
  return (
    <div>
      <PhotoDetails />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ paddingTop: '2%' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={12}>
          <Gallery />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMedias: () => dispatch(getMedias()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
