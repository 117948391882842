import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  staff: [],
  create_staff: {
    ID: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    user_type: null,
    facility: null,
    dialog_open: false,
    dialog_edit: false,
  },
  filter: {
    query: '',
    facility_id: null,
    user_type: null,
  },
}

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
      getStaff: (state, { payload }) => {
        state.staff = payload.staff
      },
      toggleCreateStaffModal: (state, { payload }) => {
        state.create_staff = {
          ...state.create_staff,
          dialog_open: payload.open,
          dialog_edit: false,
        }
      },
      changeStaffDetails: (state, { payload }) => {
        state.create_staff = {
          ...state.create_staff,
          [payload.key]: payload.value,
        }
      },
      editStaff: (state, { payload }) => {
        state.create_staff = {
          ...state.create_staff,
          ...payload.staff,
          dialog_open: true,
          dialog_edit: true,
        }
      },
      changeFilterDetails: (state, { payload }) => {
        state.filter = {
          ...state.filter,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = staffSlice.actions

export { staffSlice, actions }
