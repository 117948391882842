import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filters'

import ReconciliationReport from './../reports/Reconciliation'

import { getReconciliations } from 'reporting/actions'
import { actions } from 'reporting/redux'

class Report extends React.Component {
  componentDidMount() {
    this.props.changeReportType('reconciliation')
    this.props.getReconciliations()
  }
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Filters hide_payment hide_event hide_facility />
        </Grid>
        <Grid item xs={12}>
          <ReconciliationReport />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getReconciliations: () => dispatch(getReconciliations()),
  changeReportType: report => dispatch(actions.changeReportType(report)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Report)
