import moment from 'moment'
import { cleanFieldName, cleanString } from './helper'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  unassigned: 0,
  matches: [],
  events: [],
  fields: [],
  refs: [],
  centers: [],
  all_checked: false,
  filters: {
    centers: [],
    events: [],
    fields: [],
    refs: [],
    start_date: null,
  },
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    getMatches: (state, { payload }) => {
      let fields = {},
        events = {},
        centers = {}
      state.matches = payload.matches
        .map(m => {
          const match_moment = moment(m.date, 'YYYY-MM-DD HH:mm:ss'),
            clean_fieldname = cleanFieldName(m.fieldname),
            clean_event = cleanString(m.event),
            clean_center = cleanString(m.center)
          fields[clean_fieldname] = true
          events[clean_event] = true
          centers[clean_center] = true
          return {
            ...m,
            match_date: match_moment.format('MM/DD'),
            match_time: match_moment.format('h:mmA'),
            match_moment,
            fieldname: clean_fieldname,
            checked: false,
            event: clean_event,
            center: clean_center,
          }
        })
        .sort((a, b) => {
          const diff = b.match_moment.diff(a.match_moment, 'seconds')
          if (diff > 0) {
            return -1
          } else if (diff < 0) {
            return 1
          } else {
            return 0
          }
        })
      state.unassigned = payload.matches.filter(m => !m.refid).length
      state.fields = Object.keys(fields).sort()
      state.events = Object.keys(events).sort()
      state.centers = Object.keys(centers).sort()
    },
    getRefs: (state, { payload }) => {
      state.refs = payload.refs
    },
    checkMatch: (state, { payload }) => {
      state.matches = state.matches.map(m => {
        if (m.id === payload) {
          return {
            ...m,
            checked: !m.checked,
          }
        }
        return m
      })
    },
    checkAllMatches: (state, { payload }) => {
      state.matches = state.matches.map(m => {
        if (payload.indexOf(m.id) !== -1) {
          return {
            ...m,
            checked: !state.all_checked,
          }
        }
        return m
      })
      state.all_checked = !state.all_checked
    },
    changeFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.key]: payload.val,
      }
    },
    setRef: (state, { payload }) => {
      state.matches = state.matches.map(m => {
        if (m.checked) {
          return {
            ...m,
            ref: payload.name,
            refid: payload.ref_id,
            checked: false,
          }
        }
        return m
      })
    },
  },
})
