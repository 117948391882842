import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'

import { getEventsToAssign } from './../actions'
import { niceNumber, unixToDateTime, exportToCSV } from './../../helper'
import { actions } from './../redux'
import { buildEventData } from './../helper'

const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true,
    grow: 2,
    format: row => unixToDateTime(row.start_date).format('MM/DD/YYYY'),
    sortFunction: (rowA, rowB) => rowA.start_date - rowB.start_date,
  },
  {
    name: 'Timeslot',
    selector: 'time',
    sortable: true,
    center: true,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {unixToDateTime(row.start_date).format('h:mmA')}
        <br />- {unixToDateTime(row.end_date).format('h:mmA')}
        <br />
        <Link href={`/calendar/event/${row.ID}`} target="_blank">
          Event {row.ID}
        </Link>
      </div>
    ),
    sortFunction: (rowA, rowB) => rowA.start_date > rowB.start_date,
  },
  {
    name: 'Duration',
    selector: 'duration',
    sortable: true,
    center: true,
    format: row => (row.end_date - row.start_date) / 60,
    sortFunction: (rowA, rowB) => rowA.start_date > rowB.start_date,
  },
  {
    name: 'Customer',
    selector: 'customer',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <Link href={`/customer/${row.customer.ID}`} target="_blank">
        {row.customer.first_name + ' ' + row.customer.last_name}
      </Link>
    ),
  },
  {
    name: 'Facility',
    selector: 'facility',
    sortable: true,
    center: true,
    format: row => row.field.facility.name,
  },
  {
    name: 'Resource',
    selector: 'resource',
    sortable: true,
    grow: 2,
    center: true,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.field.number} - {row.field.name}
        <br />
        {row.field.exterior ? 'Outdoor' : 'Indoor'} {row.field.field_type}
      </div>
    ),
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoice && row.invoice.invoiced)}`,
    sortFunction: (rowA, rowB) => rowA.invoice.invoiced > rowB.invoice.invoiced,
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoice && row.invoice.balance)}`,
    sortFunction: (rowA, rowB) => rowA.invoice.balance > rowB.invoice.balance,
  },
])

const customStyles = {
  rows: {
    style: {
      minHeight: '100px', // override the row height
    },
  },
}

class EventsToAssign extends React.Component {
  componentDidMount() {
    this.props.getEventsToAssign()
  }

  render() {
    const { events } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Events</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        exportToCSV(buildEventData(events), 'Soccernaut Events')
                      }
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={events}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              customStyles={customStyles}
              persistTableHead
              selectableRows
              selectableRowsHighlight
              selectableRowSelected={row => row.selected}
              onSelectedRowsChange={({
                allSelected,
                selectedCount,
                selectedRows,
              }) => {
                this.props.setSelected(selectedRows.map(row => row.ID))
              }}
              selectableRowsComponent={Checkbox}
              contextMessage={{
                singular: 'event',
                plural: 'events',
                message: 'selected',
              }}
              contextActions={[
                <Button
                  key={1}
                  color="secondary"
                  variant="contained"
                  onClick={() => this.props.openAssignDialog()}
                >
                  Assign
                </Button>,
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  events: state.soccernaut.events.filter(l => {
    if (state.soccernaut.filter.facility_id) {
      return l.field.facility_id === state.soccernaut.filter.facility_id
    }
    return true
  }),
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getEventsToAssign: () => dispatch(getEventsToAssign()),
  setSelected: selected => dispatch(actions.setSelected({ selected })),
  openAssignDialog: () => dispatch(actions.toggleAssignModal({ open: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventsToAssign)
