import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import StaffForm from './forms/CreateStaff'

import { actions } from './../redux'
import { createStaff } from './../actions'

class CreateStaffDialog extends React.Component {
  render() {
    const { open, handleClose, createStaff, is_edit } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          {is_edit ? 'Edit' : 'Create'} staff member
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <StaffForm />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              createStaff()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.staff.create_staff.dialog_open,
  is_edit: state.staff.create_staff.dialog_edit,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleCreateStaffModal({ open: false })),
  createStaff: () => dispatch(createStaff()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateStaffDialog)
