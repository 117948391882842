import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

const styles = theme => ({
  root: {
    textAlign: 'center',
    display: 'block',
  },
})

const CustomSnackbarContent = withStyles(styles)(SnackbarContent)

class Loader extends React.Component {
  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.props.loading}
      >
        <CustomSnackbarContent
          message={
            <div>
              <Typography
                variant="h6"
                style={{ display: 'inline', marginRight: '20px' }}
              >
                Loading
              </Typography>{' '}
              <CircularProgress
                size={20}
                style={{ color: 'rgb(220, 0, 78)' }}
              />
            </div>
          }
        />
      </Snackbar>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.common.loading,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Loader)
