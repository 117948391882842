import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'

import FastTextField from 'common/components/FastTextField'

import { saveFieldType } from 'resources/actions'
import { actions } from 'resources/redux'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const NewFieldTypeDialog = () => {
  const { open, name, capacity, is_outdoors } = useSelector(
      state => state.resources.field_type
    ),
    dispatch = useDispatch(),
    updateFieldType = (k, v) =>
      dispatch(actions.updateFieldType({ key: k, value: v })),
    handleClose = () => updateFieldType('open', false),
    _saveFieldType = () => dispatch(saveFieldType())
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create field type
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={7}>
            <FastTextField
              label="Name *"
              variant="outlined"
              value={name}
              onChange={e => updateFieldType('name', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={7}>
            <FastTextField
              label="Capacity *"
              variant="outlined"
              value={capacity}
              onChange={e => updateFieldType('capacity', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={7}>
            <FormControlLabel
              control={<Checkbox name="is_outdoors" />}
              label="Outdoors"
              style={{ marginLeft: 20 }}
              checked={is_outdoors}
              onChange={e => updateFieldType('is_outdoors', e.target.checked)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => _saveFieldType()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewFieldTypeDialog
