import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filters'
import TeamsReport from './../reports/Teams'

class Report extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Filters league_only hide_payment show_active />
        </Grid>
        <Grid item xs={12}>
          <TeamsReport />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Report)
