import axios from 'axios'

class HttpClient {
  constructor(options = {}) {
    this._baseURL = options.baseURL || ''
    this._token = null
  }

  _makeRequest(method, endpoint, data) {
    let req = {
      method,
      url: this._baseURL + endpoint,
      headers: {
        Authorization: `Bearer ${this._token}`,
      },
    }
    if (data) {
      req.data = data
    }
    return axios(req).then(resp => resp.data)
  }

  get(endpoint, options = {}) {
    return this._makeRequest('GET', endpoint)
  }

  post(endpoint, body) {
    return this._makeRequest('POST', endpoint, body)
  }

  put(endpoint, id, body) {
    return this._makeRequest('PUT', `${endpoint}/${id}`, body)
  }

  putRaw(endpoint, body) {
    return this._makeRequest('PUT', endpoint, body)
  }

  delete(endpoint, id) {
    return this._makeRequest('DELETE', `${endpoint}/${id}`)
  }
}

export default HttpClient
