import { createSlice } from '@reduxjs/toolkit'

import { facilityMap } from './../consts'

const initialState = {
  form: {
    user_id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    token: null,
    user_type: 'manager',
  },
  user: null,
  facility_id: 1,
  facility_name: 'Sofive Meadowlands',
  user_type: 'manager',
  app_ready: false,
  is_admin: false,
  is_admin_or_manager: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      changeFacility: (state, { payload }) => {
        state.facility_id = parseInt(payload.facility_id)
        state.facility_name = facilityMap[payload.facility_id]
      },
      changeUserType: (state, { payload }) => {
        state.user_type = payload.user_type
        state.is_admin = payload.user_type === 'admin'
        state.is_admin_or_manager =
          payload.user_type === 'admin' || payload.user_type === 'manager'
      },
      updateFormField: (state, { payload }) => {
        state.form = {
          ...state.form,
          [payload.key]: payload.value,
        }
      },
      loginSuccess: (state, { payload }) => {
        state.user = payload.user
      },
      changeAppState: (state, { payload }) => {
        state.app_ready = payload.ready
      },
    },
  }),
  actions = userSlice.actions

export { userSlice, actions }
