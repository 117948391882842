import moment from 'moment'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customers: [],
  search_query: '',
  facilities: [],
  customer: null,
  snackbar_open: false,
  tab: 'profile',
  family: [],
  family_member: null,
  contracts: [],
  invoices: [],
  creation: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  customer_creation_modal_open: false,
  add_credit_amount: 0,
  add_credit_modal_open: false,
  edit_email_modal_open: false,
  merge: {
    customers: [],
    open: false,
  },
}

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
      seatchCustomer: (state, { payload }) => {
        state.customers = payload.customers.map(c => {
          if (!c.hubspot) {
            return c
          }
          let dob = c.hubspot.dob
          if (dob !== '') {
            dob = moment.utc(dob, 'x').format('MM/DD/YYYY')
          }
          return {
            ...c,
            hubspot: {
              ...c.hubspot,
              dob,
            },
          }
        })
      },
      editSearchQuery: (state, { payload }) => {
        state.search_query = payload.search_query
      },
      getCustomer: (state, { payload }) => {
        let dob = null
        if (payload.customer.hubspot && payload.customer.hubspot.dob) {
          dob = payload.customer.hubspot.dob
          if (dob !== '') {
            dob = moment.utc(dob, 'x').format('MM/DD/YYYY')
          }
        }
        state.customer = {
          ...state.customer,
          ...payload.customer,
          hubspot: payload.customer.hubspot
            ? { ...payload.customer.hubspot, dob }
            : {},
        }
      },
      updateCustomerDetails: (state, { payload }) => {
        if (payload.hubspot) {
          state.customer = {
            ...state.customer,
            hubspot: {
              ...state.customer.hubspot,
              [payload.key]: payload.val,
            },
          }
          return
        }
        state.customer = {
          ...state.customer,
          [payload.key]: payload.val,
        }
      },
      getEPlayerDetails: (state, { payload }) => {
        state.customer = {
          ...state.customer,
          eplayer: payload.eplayer,
        }
      },
      getFamily: (state, { payload }) => {
        state.family = payload.family.map(m => {
          let relation = 'child'
          if (m.child_id === state.customer.ID) {
            relation = 'parent'
          }
          return {
            ...m,
            name: `${m[relation].first_name} ${m[relation].last_name}`,
            contact: m[relation].email,
            customer_id: m[relation].ID,
            relation,
          }
        })
      },
      selectFamilyMember: (state, { payload }) => {
        state.family_member = payload.member
      },
      getInvoices: (state, { payload }) => {
        state.invoices = payload.invoices
      },
      getContracts: (state, { payload }) => {
        state.contracts = payload.contracts
      },
      changeTab: (state, { payload }) => {
        state.tab = payload.tab
      },
      toggleWaiver: (state, { payload }) => {
        state.customer = {
          ...state.customer,
          hubspot: {
            ...state.customer.hubspot,
            waiver_signed: payload.on ? '1' : '',
          },
        }
      },
      changeNewCustomerDetails: (state, { payload }) => {
        state.creation = {
          ...state.creation,
          [payload.key]: payload.value,
        }
      },
      toggleCustomerCreationModal: (state, { payload }) => {
        state.customer_creation_modal_open = payload.open
      },
      toggleAddCreditModal: (state, { payload }) => {
        state.add_credit_modal_open = payload.open
      },
      editAddCreditAmount: (state, { payload }) => {
        state.add_credit_amount = payload.amount
      },
      updateCustomerCredits: (state, { payload }) => {
        state.customer = {
          ...state.customer,
          credits: payload.credits,
        }
      },
      toggleEditEmailModal: (state, { payload }) => {
        state.creation = {
          ...state.creation,
          email: payload.email,
        }
        state.edit_email_modal_open = payload.open
      },
      closeEditEmailModal: (state, { payload }) => {
        state.edit_email_modal_open = false
      },
      selectCustomer: (state, { payload }) => {
        state.customer = payload
      },
      clearNewCustomerDetails: (state, { payload }) => {
        state.creation = initialState.creation
        state.customer = initialState.customer
      },
      updateMerge: (state, { payload }) => {
        state.merge = {
          ...state.merge,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = customerSlice.actions

export { customerSlice, actions }
