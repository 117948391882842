import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { commonSlice } from './common/redux'
import { userSlice } from './user/redux'
import { calendarSlice } from './calendar/redux'
import { homeSlice } from './home/redux'
import { leagueSlice } from './league/redux'
import { productSlice } from './product/redux'
import { invoiceSlice } from './invoice/redux'
import { customerSlice } from './customer/redux'
import { reportingSlice } from './reporting/redux'
import { contractSlice } from './contract/redux'
import { discountSlice } from './discount/redux'
import { soccernautSlice } from './soccernaut/redux'
import { staffSlice } from './staff/redux'
import { pickupSlice } from './pickup/redux'
import { teamSlice } from './teams/redux'
import { refereeSlice } from './referee/redux'
import { classesSlice } from './classes/redux'
import { scoreboardSlice } from './scoreboard/redux'
import { mediaSlice } from './media/redux'
import { resourceSlice } from './resources/redux'

export const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    calendar: calendarSlice.reducer,
    common: commonSlice.reducer,
    home: homeSlice.reducer,
    user: userSlice.reducer,
    scoreboards: scoreboardSlice.reducer,
    resources: resourceSlice.reducer,
    customer: customerSlice.reducer,
    product: productSlice.reducer,
    league: leagueSlice.reducer,
    teams: teamSlice.reducer,
    invoice: invoiceSlice.reducer,
    reporting: reportingSlice.reducer,
    contract: contractSlice.reducer,
    discount: discountSlice.reducer,
    soccernaut: soccernautSlice.reducer,
    staff: staffSlice.reducer,
    pickup: pickupSlice.reducer,
    referee: refereeSlice.reducer,
    classes: classesSlice.reducer,
    media: mediaSlice.reducer,
  })
