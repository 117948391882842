import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import SalesReportWidget from './../components/SalesReportWidget'

import { getEventsReport } from './../actions'
import { buildEventData } from './../helper'
import { niceNumber, unixToDateTime, exportToCSV } from './../../helper'
import { actions } from './../redux'

const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true,
    grow: 2,
    format: row => unixToDateTime(row.start_date).format('MM/DD/YYYY'),
    sortFunction: (rowA, rowB) => rowA.start_date > rowB.start_date,
  },
  {
    name: 'Timeslot',
    selector: 'time',
    sortable: true,
    center: true,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {unixToDateTime(row.start_date).format('h:mmA')}
        <br />- {unixToDateTime(row.end_date).format('h:mmA')}
      </div>
    ),
    sortFunction: (rowA, rowB) => rowA.start_date > rowB.start_date,
  },
  {
    name: 'Duration',
    selector: 'duration',
    sortable: true,
    center: true,
    format: row => (row.end_date - row.start_date) / 60,
    sortFunction: (rowA, rowB) => rowA.start_date > rowB.start_date,
  },
  {
    name: 'Yield',
    selector: 'duration',
    sortable: true,
    center: true,
    format: row =>
      `$${row.invoice.amount / ((row.end_date - row.start_date) / 3600)}`,
    sortFunction: (rowA, rowB) => rowA.start_date > rowB.start_date,
  },
  {
    name: 'Customer',
    selector: 'customer',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <Link href={`/customer/${row.customer.ID}`} target="_blank">
        {row.customer.first_name + ' ' + row.customer.last_name}
      </Link>
    ),
  },
  {
    name: 'Event type',
    selector: 'product',
    sortable: true,
    grow: 2,
    wrap: true,
    center: true,
    format: row => row.product_type.name,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.product_type.name}
        <br />
        <Link href={`/calendar/event/${row.ID}`} target="_blank">
          {row.ID}
        </Link>
      </div>
    ),
    sortFunction: (rowA, rowB) =>
      rowA.product_type.name > rowB.product_type.name,
  },
  {
    name: 'Facility',
    selector: 'facility',
    sortable: true,
    grow: 2,
    center: true,
    format: row => row.field.facility.name,
  },
  {
    name: 'Resource',
    selector: 'resource',
    sortable: true,
    grow: 3,
    center: true,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.field.number} - {row.field.name}
        <br />
        {row.field.exterior ? 'Outdoor' : 'Indoor'} {row.field.field_type}
      </div>
    ),
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoice.amount)}`,
    sortFunction: (rowA, rowB) => rowA.invoice.amount > rowB.invoice.amount,
  },
  {
    name: 'Paid',
    selector: 'paid',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoice.paid)}`,
    sortFunction: (rowA, rowB) => rowA.invoice.paid > rowB.invoice.paid,
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.invoice.balance)}`,
    sortFunction: (rowA, rowB) => rowA.invoice.balance > rowB.invoice.balance,
  },
])

const customStyles = {
  rows: {
    style: {
      minHeight: '100px', // override the row height
    },
  },
}

class EventsReport extends React.Component {
  componentDidMount() {
    this.props.getEventsReport()
    this.props.changeReportType('events')
  }

  changeInvoiceFilter(key, value) {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.props.getEventsReport({
          ...this.state,
        })
      }
    )
  }

  render() {
    const {
      events,
      payments_by_type,
      payments_by_product,
      payments_by_location,
    } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12} />
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox name="invoiced_paid" />}
            label="Invoiced paid"
            style={{ marginLeft: 20 }}
            checked={this.props.invoiced_paid}
            onChange={e => {
              this.props.updateFilter('invoiced_paid', e.target.checked)
              this.props.getEventsReport()
            }}
          />
          <FormControlLabel
            control={<Checkbox name="invoiced_unpaid" />}
            label="Invoiced unpaid"
            style={{ marginLeft: 20 }}
            checked={this.props.invoiced_unpaid}
            onChange={e => {
              this.props.updateFilter('invoiced_unpaid', e.target.checked)
              this.props.getEventsReport()
            }}
          />
          <FormControlLabel
            control={<Checkbox name="uninvoiced" />}
            label="Uninvoiced"
            style={{ marginLeft: 20 }}
            checked={this.props.uninvoiced}
            onChange={e => {
              this.props.updateFilter('uninvoiced', e.target.checked)
              this.props.getEventsReport()
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <SalesReportWidget
            data={payments_by_type}
            title="By status"
            hide_amount={true}
          />
        </Grid>
        <Grid item xs={4}>
          <SalesReportWidget
            data={payments_by_product}
            title="By product"
            hide_amount={true}
          />
        </Grid>
        <Grid item xs={4}>
          <SalesReportWidget
            data={payments_by_location}
            title="By location"
            hide_amount={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Events</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        exportToCSV(buildEventData(events), 'Events')
                      }
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={events}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              customStyles={customStyles}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.reporting.filter,
  events: state.reporting.events,
  payments_by_type: state.reporting.payments_by_type,
  payments_by_product: state.reporting.payments_by_product,
  payments_by_location: state.reporting.payments_by_location,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeReportType: report => dispatch(actions.changeReportType(report)),
  getEventsReport: p => dispatch(getEventsReport(p)),
  updateFilter: (k, v) =>
    dispatch(actions.updateFilter({ field: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventsReport)
