import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CreateProductForm from './forms/NewProduct'

import { toggleModal, createProducts, updateProducts } from './../actions'

class NewProductDialog extends React.Component {
  render() {
    const { open, handleClose, edit, createProducts, updateProducts } =
      this.props
    const productFunc = edit ? updateProducts : createProducts
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit' : 'Create new'} product
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateProductForm />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              productFunc()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.product.new_product_modal_open,
  edit: state.product.product_modal_edit,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleModal(false)),
  createProducts: () => dispatch(createProducts()),
  updateProducts: () => dispatch(updateProducts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProductDialog)
