import React from 'react'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import Select from 'react-select'
import {
  DatePickerComponent,
  TimePickerComponent,
} from '@syncfusion/ej2-react-calendars'

import { unixToDate, unixToTime, momentDateTimeToUnix } from './../../../helper'
import { colors } from './../../../consts'

const EditScoreForm = ({
  match,
  fields,
  updateMatch,
  teams,
  hide_column,
  updateMatchScore,
  updateMatchDetails,
  updateMatchEvent,
  deleteMatch,
  setEdit,
}) => (
  <TableRow
    key={match.ID}
    hover
    style={{
      cursor: 'pointer',
      backgroundColor: match.changed ? colors.yellow_mild : null,
    }}
  >
    {!hide_column && (
      <TableCell style={{ textAlign: 'center', width: '5% !important' }}>
        {match.round ? match.round : match.week}
      </TableCell>
    )}
    <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
      {match.is_bye ? null : (
        <Select
          options={fields}
          placeholder="Select field..."
          value={
            match.event.field_id &&
            fields.find(f => f.value === match.event.field_id)
          }
          onChange={e => updateMatch(match.ID, 'field_id', e.value)}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
        />
      )}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
      {match.is_bye ? (
        'BYE'
      ) : (
        <DatePickerComponent
          id="start_date"
          placeholder="Start date *"
          value={unixToDate(match.event?.start_date).toDate()}
          onChange={e =>
            updateMatch(
              match.ID,
              'start_date',
              momentDateTimeToUnix(moment(e.value))
            )
          }
        />
      )}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
      {match.is_bye ? null : (
        <TimePickerComponent
          id="start_time"
          placeholder="Start time*"
          step={15}
          value={unixToTime(match.event?.start_date).toDate()}
          onChange={e => {
            const _moment_date = unixToDate(match.event?.start_date)
                .hours(0)
                .minutes(0),
              _moment = moment(e.value),
              secs =
                parseInt(_moment.format('HH')) * 60 * 60 +
                parseInt(_moment.format('mm')) * 60
            updateMatch(
              match.ID,
              'start_date',
              momentDateTimeToUnix(_moment_date) + secs
            )
          }}
        />
      )}
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '15% !important' }}>
      <Select
        options={teams}
        placeholder="Home team*"
        value={teams.find(t => t.value === match.home_team.ID)}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        onChange={e => updateMatchDetails(match.ID, 'home_team_id', e.value)}
      />
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '15% !important' }}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <TextField
            label="HScore"
            fullWidth
            value={match.home_score}
            onChange={e =>
              updateMatchScore(match.ID, 'home_score', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <TextField
            label="VScore"
            fullWidth
            value={match.away_score}
            onChange={e =>
              updateMatchScore(match.ID, 'away_score', e.target.value)
            }
            onBlur={console.log}
          />
        </Grid>
      </Grid>
    </TableCell>
    <TableCell style={{ textAlign: 'center', width: '15% !important' }}>
      <Select
        options={teams}
        placeholder="Home team*"
        value={teams.find(t => t.value === match.away_team.ID)}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        onChange={e => updateMatchDetails(match.ID, 'away_team_id', e.value)}
      />
    </TableCell>
    {!hide_column && (
      <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
        {match.changed ? (
          <Button className="save-btn" onClick={() => updateMatchEvent(match)}>
            Check & Save
          </Button>
        ) : (
          <>
            <IconButton onClick={() => setEdit()}>
              <SaveIcon color="primary" fontSize="small" />
            </IconButton>
            <IconButton onClick={() => deleteMatch(match.ID)}>
              <DeleteIcon
                color="secondary"
                fontSize="small"
                style={{ color: colors.red_strong }}
              />
            </IconButton>
          </>
        )}
      </TableCell>
    )}
  </TableRow>
)

export default EditScoreForm
