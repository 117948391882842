import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import EventIcon from '@material-ui/icons/Event'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { colors } from 'consts'
import { openDeleteModal } from 'common/actions'
import { unixToDateTime } from 'helper'
import {
  openEventCreationModal,
  openEventEditModal,
  deleteEvent,
} from './../actions'
import { getFields, setContractEventRepeat } from 'calendar/actions'
import { actions } from './../redux'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class EventsTable extends React.Component {
  render() {
    const { classes, facility_id } = this.props

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={6} style={{ textAlign: 'center', width: '100%' }}>
          <Button
            style={{
              color: colors.blue_strong,
              backgroundColor: colors.blue_mild,
            }}
            onClick={() => this.props.toggleEventCreationModal()}
          >
            <b>Add event</b>
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'center', width: '100%' }}>
          <Button
            style={{
              color: colors.blue_strong,
              backgroundColor: colors.blue_mild,
            }}
            onClick={() => this.props.toggleEditEventsModal(facility_id)}
          >
            <b>Edit events</b>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ textAlign: 'center', width: '30%' }}
                    className="table-header"
                  >
                    Field
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '25%' }}
                    className="table-header"
                  >
                    Start
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '25%' }}
                    className="table-header"
                  >
                    End
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', width: '20%' }}
                    className="table-header"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div className={classes.maxheight}>
              <Table aria-label="simple table">
                <TableBody>
                  {this.props.events.map(event => (
                    <TableRow key={event.ID}>
                      <TableCell style={{ textAlign: 'center', width: '30%' }}>
                        {event.field.number} - {event.field.name}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '25%' }}>
                        {unixToDateTime(event.start_date).format(
                          'M/D/YYYY h:mmA'
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '25%' }}>
                        {unixToDateTime(event.end_date).format(
                          'M/D/YYYY h:mmA'
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '20%' }}>
                        <IconButton
                          className={classes.root}
                          onClick={() => this.props.editEvent(event)}
                        >
                          <EditIcon
                            style={{ color: colors.blue_strong }}
                            fontSize="small"
                          />
                        </IconButton>
                        <Link
                          href={`/calendar/event/${event.ID}`}
                          target="_blank"
                        >
                          <IconButton style={{ padding: 5 }}>
                            <EventIcon
                              fontSize="small"
                              style={{
                                color: colors.blue_strong,
                              }}
                            />
                          </IconButton>
                        </Link>
                        <IconButton
                          className={classes.root}
                          onClick={() => this.props.deleteEvent(event)}
                        >
                          <DeleteIcon
                            style={{ color: colors.red_strong }}
                            fontSize="small"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  facility_id: state.contract.contract.facility_id,
  events: state.contract.contract.events,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleEventCreationModal: () => dispatch(openEventCreationModal()),
  toggleEditEventsModal: facility_id => {
    dispatch(getFields(facility_id))
    dispatch(setContractEventRepeat())
    dispatch(actions.toggleEditEventsModal({ open: true }))
  },
  deleteEvent: event =>
    dispatch(openDeleteModal('event', () => deleteEvent(event))),
  editEvent: event => dispatch(openEventEditModal(event)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventsTable))
