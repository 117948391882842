import React from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Select from 'react-select'

import { resetProductDetails, toggleModal } from './../actions'
import { actions } from './../redux'

const ResourceDropdown = ({
  query,
  openModal,
  changeFilterDetails,
  product_type_id,
  product_types,
}) => {
  const dispatch = useDispatch(),
    addProduct = () =>
      dispatch(actions.updateProduct({ key: 'dialog_open', value: true }))
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={8} style={{ width: '100%' }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Filter..."
          value={query}
          onChange={e => changeFilterDetails('query', e.target.value)}
        />
      </Grid>
      <Grid item xs={2} style={{ width: '100%' }}>
        <Select
          options={product_types}
          placeholder="Product type"
          value={
            product_type_id &&
            product_types.find(p => p.value === product_type_id)
          }
          onChange={v => changeFilterDetails('product_type_id', v && v.value)}
          isClearable
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          component="span"
          onClick={addProduct}
        >
          Add new
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.product.filter,
  tab: state.product.tab,
  facilities: state.common.filter_facilities,
  product_types: state.common.product_types
    .map(p => ({
      label: p.name,
      value: p.ID,
    }))
    .filter(p => p.label.length),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openModal: q => {
    dispatch(resetProductDetails())
    dispatch(toggleModal(true))
  },
  updateTab: t => dispatch(actions.updateTab(t)),
  changeFilterDetails: (key, value) =>
    dispatch(actions.changeFilterDetails({ key, value })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
