import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import RepeatTable from 'calendar/components/forms/RepeatTable'

import { getContract } from 'contract/actions'
import { actions } from 'contract/redux'

class EventsEditDialog extends React.Component {
  render() {
    const { open, contract_id, getContract, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Update events</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <RepeatTable is_edit={true} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
              getContract(contract_id)
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  contract_id: state.contract.contract?.ID,
  open: state.contract.events_edit_modal_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getContract: id => dispatch(getContract(id)),
  handleClose: () => dispatch(actions.toggleEditEventsModal({ open: false })),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventsEditDialog)
