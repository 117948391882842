import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { colors } from './../../consts'

const styles = theme => ({
    card: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
  menu = [
    {
      subtitle: 'Report',
      title: 'Sales',
      description: 'Cash & accrual basis',
      url: '/reporting/sales',
    },
    {
      subtitle: 'Report',
      title: 'Event',
      description: 'By payment status and product',
      url: '/reporting/events',
    },
    {
      subtitle: 'Report',
      title: 'Outstanding',
      description: 'Invoices per customer',
      url: '/reporting/outstanding',
    },
    {
      subtitle: 'Report',
      title: 'Square Reconciliation',
      description: 'Square card reconciliation',
      url: '/reporting/payments',
    },
    {
      subtitle: 'Report',
      title: 'Check-in',
      description: 'Display signed check ins',
      url: '/reporting/health',
    },
    {
      subtitle: 'Report',
      title: 'Teams',
      description: 'Display teams payment status and product',
      url: '/reporting/teams',
    },
    {
      subtitle: 'Report',
      title: 'Daily',
      description: 'Daily report for soccer and bar sales',
      url: '/reporting/daily',
    },
    {
      subtitle: 'Report',
      title: 'Cameras',
      description: 'Report on down cameras',
      url: '/reporting/cameras',
    },
  ]

class AdminPanel extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ textAlign: 'center', paddingTop: '2%' }}
        spacing={8}
      >
        {menu.map(m => (
          <Grid item xs={12} md={3} xl={3} key={m.title}>
            <Card className={classes.card} elevation={0}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {m.subtitle}
                </Typography>
                <Typography variant="h5" component="h2">
                  {m.title}
                </Typography>
                <Typography variant="body2" component="p">
                  {m.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  style={{
                    left: '50%',
                    transform: 'translate(-50%)',
                    color: colors.blue_strong,
                    backgroundColor: colors.blue_mild,
                  }}
                  onClick={() => (window.location = m.url)}
                >
                  <b>View</b>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminPanel))
