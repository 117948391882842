import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import ClassDetails from 'classes/components/ClassDetails'
import Menu from 'classes/components/Menu'
import EventsTable from 'classes/components/EventsTable'
import ParticipantsTable from 'classes/components/ParticipantsTable'
import FeeUpdateDialog from 'classes/components/FeeUpdateDialog'
import ManualClassEvent from 'classes/components/ManualClassDialog'
import BroadcastMessage from './../components/BroadcastMessage'
import EditEventDialog from '../components/EditEventDialog'
import ChangeClassDialog from '../components/ChangeClassDialog'
import AddParticipantsDialog from '../components/AddParticipantsDialog'
import ClassDetailsForm from 'classes/components/forms/ClassDetails'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import HubspotListDialog from 'common/components/HubspotListDialog'

import { getClassDetails } from 'classes/actions'
import { actions } from 'classes/redux'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Manage = ({
  alert_open,
  alert_message,
  closeAlert,
  classInfo,
  getClassDetails,
  match,
  tab,
  openAddClassEventModal,
  openAddParticipantModal,
}) => {
  useEffect(() => {
    const { id } = match.params
    getClassDetails(id)
  }, [getClassDetails, match.params])
  return (
    <div>
      <Snackbar
        open={alert_open}
        autoHideDuration={3000}
        onClose={() => closeAlert()}
      >
        <Alert severity="success" onClose={() => closeAlert()}>
          {alert_message}
        </Alert>
      </Snackbar>
      {classInfo && (
        <>
          <FeeUpdateDialog />
          <BroadcastMessage />
          <EditEventDialog />
          <ManualClassEvent />
          <AddParticipantsDialog />
          <ChangeClassDialog />
          <HubspotListDialog />
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ paddingTop: '2%' }}
            spacing={4}
          >
            <Grid item xs={4}>
              <ClassDetails />
            </Grid>
            <Grid item xs={8}>
              <Menu />
              {tab !== 'settings' ? (
                <Grid justify="center" container>
                  <Button
                    className="save-btn"
                    style={{ marginBottom: 20 }}
                    onClick={
                      tab === 'events'
                        ? openAddClassEventModal
                        : openAddParticipantModal
                    }
                  >
                    Add new
                  </Button>
                </Grid>
              ) : null}
              {tab === 'events' && <EventsTable />}
              {tab === 'participants' && <ParticipantsTable />}
              {tab === 'settings' && <ClassDetailsForm edit />}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  classInfo: state.classes.classInfo,
  tab: state.classes.tab,
  alert_open: state.classes.alert_message_open,
  alert_message: state.classes.alert_message,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getClassDetails: id => dispatch(getClassDetails(id)),
  closeAlert: () => dispatch(actions.closeAlert()),
  openAddClassEventModal: () => dispatch(actions.toggleClassEventModal(true)),
  openAddParticipantModal: () =>
    dispatch(actions.toggleAddParticipantModal(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
