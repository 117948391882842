import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Filters from './../components/Filter'
import TeamsTable from './../components/TeamsTable'
import TeamDialog from './../components/TeamDialog'

import { getFacilities } from './../../common/actions'
import { getTeams } from './../actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getTeams()
    this.props.getFacilities()
  }

  render() {
    return (
      <div>
        <TeamDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            <TeamsTable />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  getFacilities: () => dispatch(getFacilities()),
  getTeams: () => dispatch(getTeams()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
