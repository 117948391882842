import React from 'react'
import { connect } from 'react-redux'

import {
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Button,
} from '@material-ui/core'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'

import { openInNewTab } from './../../../helper'
import { colors } from './../../../consts'

const consoles = ['PS4', 'XBOX'].map(v => ({
  label: v,
  value: v.toLowerCase(),
}))

const CustomerEPlayer = ({ eplayer }) => {
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          E-Player
        </Typography>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Gamertag"
                margin="dense"
                required
                value={eplayer.console_id}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
            <Grid item md={4} xs={12} style={{ paddingTop: '20px' }}>
              <Select
                options={consoles}
                placeholder="Console *"
                value={
                  eplayer.console &&
                  consoles.find(c => c.value === eplayer.console)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                isDisabled
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Discord Username"
                margin="dense"
                required
                value={eplayer.discord_username}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button
          style={{
            color: colors.blue_strong,
            backgroundColor: colors.blue_mild,
          }}
          onClick={() => openInNewTab(`/eplayer?query=${eplayer.console_id}`)}
        >
          <b>View</b>
        </Button>
      </CardActions>
    </Card>
  )
}

const mapStateToProps = (state, ownProps) => ({
  eplayer: state.customer.customer.eplayer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEPlayer)
