import HttpClient from './http-client'

class ApiClient extends HttpClient {
  constructor(baseURL) {
    super({ baseURL })
    this._user_type = null
    this._facility_id = null
  }

  setToken(token) {
    this._token = token
  }

  setUserDetails(user_type, facility_id) {
    this._user_type = user_type
    this._facility_id = facility_id
  }

  getWithFacilityURL(endpoint) {
    if (this._user_type === 'admin') return this.get(endpoint)
    return this.get(`${endpoint}?facility_id=${this._facility_id}`)
  }

  getWithID(endpoint, id) {
    return this.get(`${endpoint}/${id}`)
  }

  get pickup() {
    return {
      get: () => this.getWithFacilityURL('/pickup'),
      create: session => this.post('/contract', session),
      update: (id, session) => this.put('/contract', id, session),
      getPlayers: (facility_id, start, end) =>
        this.get(
          `/slot/sessions?facility_id=${facility_id}&start=${start}&end=${end}`
        ),
    }
  }

  get contract() {
    return {
      get: () => this.getWithFacilityURL('/contract'),
      getSingle: id => this.getWithID('/contract', id),
      update: (id, contract) => this.put('/contract', id, contract),
      create: contract => this.post('/contract', contract),
      search: query => this.post('/contract/search', query),
      updateFee: (id, fee) => this.put('/contract/fee', id, fee),
      generateDoc: id => this.post(`/contract/doc/${id}`),
      sendDoc: id => this.post(`/contract/doc/send/${id}`),
      voidDoc: id => this.post(`/contract/doc/void/${id}`),
      delete: id => this.delete('/contract', id),
    }
  }

  get event() {
    return {
      get: id => this.getWithID('/event', id),
      create: event => this.post('/event', event),
      update: (id, event) => this.put('/event', id, event),
      video: id => this.get(`/video/event/${id}`),
      emailConfirm: id => this.post(`/event/email/${id}`),
      getPickupPlayers: id => this.getWithID(`/pickup/players`, id),
      checkInPickupPlayers: (id, checked_in) =>
        this.putRaw(`/pickup/checkin/${id}?present=${checked_in ? 1 : 0}`),
      getHealthChecks: id => this.getWithID('/event/health', id),
      delete: id => this.delete('/event', id),
    }
  }

  get invoice() {
    return {
      get: () => this.get('/invoice'),
      getFromContract: id => this.get(`/invoice/contract/${id}`),
      create: invoice => this.post('/invoice', invoice),
      createInvoiceItem: invoice_item =>
        this.post('/invoice/item', invoice_item),
      deleteInvoiceItem: id => this.delete('/invoice/item', id),
      createPayment: payment => this.post('/payment', payment),
      applyDiscount: (discount_id, invoice_id) =>
        this.post('/discount/apply', { discount_id, invoice_id }),
    }
  }

  get product() {
    return {
      getByID: (id, facility_id) =>
        this.get(`/products/${id}?facility_id=${facility_id}`),
      getPricesByID: (id, facility_id) =>
        this.get(
          `/product/prices/${id}?facility_id=${facility_id}&booking_date=1`
        ),
    }
  }

  get referee() {
    return {
      getMatches: url => this.get(url),
      getReferees: ({ facility_id }) =>
        this.get(`/ref/all?facility_id=${facility_id}`),
      assignReferee: body => this.post(`/ref/assign`, body),
    }
  }

  get classes() {
    return {
      create: body => this.post(`/class`, body),
      get: () => this.getWithFacilityURL(`/class`),
      getByID: id => this.getWithID(`/class`, id),
      update: (id, body) => this.put(`/class`, id, body),
      delete: id => this.delete(`/class`, id),
      addParticipant: body => this.post(`/class/registration`, body),
    }
  }

  get media() {
    return {
      get: () => this.get(`/media`),
    }
  }

  get team() {
    return {
      update: (id, body) => this.put('/team', id, body),
      delete: (id, body) => this.delete('/team', id),
    }
  }

  get soccernaut() {
    return {
      get: url => this.get(url),
    }
  }

  get broadcast() {
    const base_url = `/broadcast`
    return {
      send: payload => this.post(base_url, payload),
    }
  }
}

export default ApiClient
