import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'

import { colors, day_of_week } from './../../consts'
import { actions } from './../redux'
import { unixToDateTime, unixToDate } from './../../helper'
import { deleteSlot } from './../actions'
import { openDeleteModal } from './../../common/actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class LeaguesTable extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Facility
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '30%' }}
                className="table-header"
              >
                Slot
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                From
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                To
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                Fields
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.slots.map(slot => (
              <TableRow key={slot.ID} hover style={{ cursor: 'pointer' }}>
                <TableCell
                  style={{ textAlign: 'center', width: '15% !important' }}
                >
                  {slot.facility.name}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '30% !important' }}
                >
                  {slot.days.map(d => (
                    <p key={d}>
                      {d} {slot.start_time_parsed} - {slot.end_time_parsed}
                      <br />
                    </p>
                  ))}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {slot.start_date_parsed}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {slot.end_date_parsed}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '25% !important' }}
                >
                  {slot.fields.map(f => (
                    <div key={f}>
                      {f}
                      <br />
                    </div>
                  ))}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  <IconButton onClick={e => this.props.duplicateSlot(slot)}>
                    <CopyIcon color="primary" fontSize="small" />
                  </IconButton>
                  <IconButton
                    className={classes.root}
                    onClick={() => this.props.deleteSlot(slot.group_id)}
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{
                        color: colors.red_strong,
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  slots: state.soccernaut.slots
    .map(s => ({
      ...s,
      start_time_parsed: unixToDateTime(s.start_time).format('h:mmA'),
      end_time_parsed: unixToDateTime(s.end_time).format('h:mmA'),
      start_date_parsed: unixToDate(s.start_date).format('M/D/YYYY'),
      end_date_parsed: unixToDate(s.end_date).format('M/D/YYYY'),
      days: s.sel_days_of_week.map(
        day => day_of_week.find(d => d.value === day).label
      ),
      sort_key: s.group_id,
    }))
    .filter(l => {
      if (state.soccernaut.filter.facility_id) {
        return l.field.facility_id === state.soccernaut.filter.facility_id
      }
      return true
    })
    .filter(l => {
      if (state.soccernaut.filter.day_of_week) {
        return l.day_of_week === state.soccernaut.filter.day_of_week
      }
      return true
    })
    .sort((a, b) => {
      if (a.sort_key > b.sort_key) {
        return 1
      }
      if (a.sort_key < b.sort_key) {
        return -1
      }
      return 0
    }),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  duplicateSlot: slot => {
    dispatch(actions.setSlotDetails(slot))
    dispatch(actions.toggleCreateSlotModal({ open: true }))
  },
  deleteSlot: id => dispatch(openDeleteModal('slot', () => deleteSlot(id))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LeaguesTable))
