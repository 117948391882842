import React from 'react'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { niceNumber } from './../../helper'
import { colors } from './../../consts'

const red_invert = ['balance', 'uninvoiced', 'invoiced_unpaid']

const columns = memoize((hide_count, hide_amount, hide_wow) => [
  {
    name: 'Description',
    selector: 'description',
    grow: 2,
    wrap: true,
    cell: row => (
      <div
        style={{
          textAlign: 'left',
          fontWeight: row.strong ? 900 : '',
          paddingLeft: row.soft ? 20 : 0,
          color: row.soft ? 'grey' : null,
        }}
      >
        {row.description}
      </div>
    ),
  },
  {
    name: 'Count',
    selector: 'quantity',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {row.quantity}
        <br />
        {!hide_wow && (
          <span
            style={{
              color:
                (red_invert.indexOf(row.key) === -1 ? 1 : -1) * row.wow_count <
                0
                  ? colors.red_strong
                  : colors.green_strong,
            }}
          >
            ({niceNumber(row.wow_count)}
            %)
          </span>
        )}
      </div>
    ),
    omit: hide_count,
  },
  {
    name: 'Amount',
    selector: 'amount',
    center: true,
    grow: 2,
    wrap: true,
    cell: row => (
      <div
        style={{
          textAlign: 'center',
          fontWeight: row.strong ? 900 : '',
          color: row.soft ? 'grey' : null,
        }}
      >
        $
        {row.amount >= 0
          ? `${niceNumber(row.amount)}`
          : `(${niceNumber(-row.amount)})`}
        <br />
        {!hide_wow && (
          <span
            style={{
              color:
                (red_invert.indexOf(row.key) === -1 ? 1 : -1) * row.wow_amount <
                0
                  ? colors.red_strong
                  : colors.green_strong,
            }}
          >
            ({niceNumber(row.wow_amount)}
            %)
          </span>
        )}
      </div>
    ),
    omit: hide_amount,
  },
])

class SalesReportWidget extends React.Component {
  render() {
    const { title, data } = this.props,
      hide_count = this.props.hide_count ? this.props.hide_count : false,
      hide_amount = this.props.hide_amount ? this.props.hide_amount : false,
      hide_wow = this.props.hide_wow ? this.props.hide_wow : false
    return (
      <>
        <Paper elevation={0} style={{ padding: '1%' }}>
          <DataTable
            title={title}
            columns={columns(hide_count, hide_amount, hide_wow)}
            data={data}
            highlightOnHover
            defaultSortField="name"
            striped
            fixedHeader
            noTableHead={this.props.hide_header}
          />
        </Paper>
      </>
    )
  }
}

export default SalesReportWidget
