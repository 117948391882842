import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CalendarIcon from '@material-ui/icons/DateRange'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'

import { actions } from 'classes/redux'

const Details = ({ changeTab, tab }) => {
  return (
    <Paper square style={{ marginBottom: '2em' }} elevation={0}>
      <Tabs
        value={tab}
        onChange={(a, b) => changeTab(b)}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        centered
      >
        <Tab icon={<CalendarIcon />} label="Events" value="events" />
        <Tab icon={<PeopleIcon />} label="Participants" value="participants" />
        <Tab icon={<SettingsIcon />} label="Settings" value="settings" />
      </Tabs>
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.classes.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTab: tab => dispatch(actions.changeTab({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
