import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import { resetPassword } from './../actions'
import { actions } from './../redux'

const styles = theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
})

class ForgotForm extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={12}>
          <TextField
            label="Email"
            fullWidth
            value={this.props.email}
            onChange={e => this.props.updateLoginField('email', e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => {
              this.props.resetPassword()
            }}
            className="save-btn"
          >
            <b>Save</b>
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  ...state.user.form,
})

const mapDispatchToProps = dispatch => ({
  updateLoginField: (k, v) =>
    dispatch(actions.updateFormField({ key: k, value: v })),
  resetPassword: () => dispatch(resetPassword()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotForm))
