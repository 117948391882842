export const applyFilter = (products, filters) => {
  if (!filters.query && !filters.facility_id && !filters.product_type_id)
    return products
  if (filters.facility_id)
    products = products.filter(
      ({ facility_id }) => filters.facility_id === facility_id
    )
  if (filters.product_type_id)
    products = products.filter(
      ({ gl }) => filters.product_type_id === gl.product_type_id
    )
  if (filters.query) {
    const s_query = filters.query.split(' ').map(w => w.trim().toLowerCase())
    products = products.filter(p => {
      const p_str = (p.name + p.facility?.name).toLowerCase()
      for (let i = 0; i < s_query.length; i++) {
        if (p_str.indexOf(s_query[i]) !== -1) {
          return true
        }
      }
      return false
    })
  }
  return products
}
