import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Roster from './Roster'
import TeamSummary from './TeamSummary'
import Button from '@material-ui/core/Button'

import { actions } from 'teams/redux'
import { actions as customerActions } from 'customer/redux'

class Players extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={9} style={{ textAlign: 'center', width: '100%' }}>
            <Roster />
            <Button
              style={{ margin: 10, textAlign: 'right' }}
              onClick={() => this.props.toggleNewPlayerModal()}
              className="save-btn"
            >
              Add player
            </Button>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', width: '100%' }}>
            <TeamSummary />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleNewPlayerModal: () => {
    dispatch(customerActions.clearNewCustomerDetails())
    dispatch(actions.togglePlayerModal({ open: true }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Players)
