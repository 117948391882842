import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import SalesReportWidget from './../components/SalesReportWidget'

import { getSalesReport, getBarData } from './../actions'
import { buildPaymentData } from './../helper'
import {
  niceNumber,
  exportToCSV,
  momentDateTimeToUnix,
  capitalizeFirst,
} from 'helper'
import { actions } from './../redux'

const sortIcon = <ArrowDownward />

const toUnix = created_at => momentDateTimeToUnix(moment(created_at))

const columns = memoize(() => [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    grow: 2,
    center: true,
    format: row => moment(row.CreatedAt).format('MM/DD/YYYY hh:mm A'),
    sortFunction: (rowA, rowB) =>
      toUnix(rowA.CreatedAt) - toUnix(rowB.CreatedAt),
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    center: true,
    format: row => `$${niceNumber(row.amount)}`,
    sortFunction: (rowA, rowB) => rowA.amount - rowB.amount,
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
    center: true,
    format: row => row.type,
    sortFunction: (rowA, rowB) => (rowA.type > rowB.type ? 1 : -1),
  },
  {
    name: 'Product',
    selector: 'product',
    sortable: true,
    grow: 2,
    center: true,
    format: row => row.product_type.name,
    sortFunction: (rowA, rowB) =>
      rowA.product_type.name > rowB.product_type.name ? 1 : -1,
  },
  {
    name: 'Invoice',
    selector: 'invoice',
    sortable: true,
    center: true,
    cell: row => (
      <Link href={`/invoice/${row.invoice_id}`} target="_blank">
        {row.invoice_id}
      </Link>
    ),
    sortFunction: (rowA, rowB) => rowA.invoice_id > rowB.invoice_id,
  },
  {
    name: 'Customer',
    selector: 'customer',
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <Link href={`/customer/${row.customer.ID}`} target="_blank">
        {row.customer.first_name + ' ' + row.customer.last_name}
      </Link>
    ),
  },
  {
    name: 'Location',
    selector: 'location',
    sortable: true,
    center: true,
    format: row => row.facility.name,
    sortFunction: (rowA, rowB) => rowA.facility.name > rowB.facility.name,
  },
])

class SalesReport extends React.Component {
  componentDidMount() {
    this.props.getSalesReport()
  }

  render() {
    const { payments_by_product, payments_by_location, reporting } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox name="exclude_tax" />}
            label="Exclude taxes"
            style={{ marginLeft: 20 }}
            checked={this.props.exclude_tax}
            onChange={e => {
              this.props.updateFilter('exclude_tax', e.target.checked)
              this.props.getSalesReport()
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SalesReportWidget
            data={[
              {
                description: 'Gross sales',
                amount: reporting.gross_sales,
                strong: true,
              },
              { description: 'Refunds', amount: -reporting.refunds },
              { description: 'Discounts', amount: -reporting.discounts },
              {
                description: 'Net sales',
                amount: reporting.net_sales,
                strong: true,
              },
              { description: 'Taxes', amount: reporting.taxes },
              {
                description: 'Total sales',
                amount: reporting.net_sales + reporting.taxes,
                strong: true,
              },
            ]}
            title="Sales"
            hide_count
            hide_wow
            hide_header
          />
        </Grid>
        <Grid item xs={6}>
          <SalesReportWidget
            data={[
              {
                description: 'Total collected',
                amount: reporting.net_sales + reporting.taxes,
                strong: true,
              },
            ]
              .concat(
                Object.keys(reporting.payments_by_type || [])
                  .sort()
                  .map(ptype => ({
                    description: capitalizeFirst(ptype),
                    amount: reporting.payments_by_type[ptype],
                    soft: true,
                  }))
              )
              .concat([
                { description: 'Fees', amount: -reporting.square_fees },
                {
                  description: 'Net total',
                  amount: reporting.net_sales - reporting.square_fees,
                  strong: true,
                },
              ])}
            title="Payments"
            hide_count
            hide_wow
            hide_header
          />
        </Grid>
        <Grid item xs={6}>
          <SalesReportWidget
            data={payments_by_product}
            title="Net sales by product"
          />
        </Grid>
        <Grid item xs={6}>
          <SalesReportWidget
            data={payments_by_location}
            title="Net sales by location"
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
            <DataTable
              title={
                <div>
                  <span>Transactions</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        exportToCSV(
                          buildPaymentData(reporting.payments),
                          'Transactions'
                        )
                      }
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={reporting.payments}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  reporting: state.reporting.sales,
  payments_by_product: state.reporting.payments_by_product,
  payments_by_location: state.reporting.payments_by_location,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSalesReport: () => dispatch(getSalesReport()),
  getBarData: () => dispatch(getBarData()),
  updateFilter: (k, v) =>
    dispatch(actions.updateFilter({ field: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport)
