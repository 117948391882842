import React, { Component } from 'react'
import { connect } from 'react-redux'

import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

import { actions } from './../redux'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

class InfoDialog extends Component {
  render() {
    const { open, message, handleClose } = this.props
    return (
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={() => handleClose()}
      >
        <Alert severity="success" onClose={() => handleClose()}>
          {message}
        </Alert>
      </Snackbar>
    )
  }
}

const mapStateToProps = state => ({
  ...state.common.info_dialog,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.closeInfoModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoDialog)
