import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import queryString from 'query-string'
import { momentDateTimeToUnix, unixToDateTime } from './../../helper'
import { isEmpty, isEqual, xorWith } from 'lodash'

import {
  Day,
  Week,
  Month,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule'

import '@syncfusion/ej2-base/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import '@syncfusion/ej2-calendars/styles/material.css'
import '@syncfusion/ej2-dropdowns/styles/material.css'
import '@syncfusion/ej2-inputs/styles/material.css'
import '@syncfusion/ej2-lists/styles/material.css'
import '@syncfusion/ej2-navigations/styles/material.css'
import '@syncfusion/ej2-popups/styles/material.css'
import '@syncfusion/ej2-react-schedule/styles/material.css'
import './../calendar.css'

import { openConfirmModal } from 'common/actions'
import { getEvents, updateEventTimes } from './../actions'
import { actions } from './../redux'
const { changeEventDetails, toggleEventModal, selectEvent, openNewEvent } =
  actions

const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual))

class Calendar extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.fields.length !== this.props.fields.length) return true
    return !isArrayEqual(nextProps.events, this.props.events)
  }

  onEventRendered(args) {
    this.applyCategoryColor(args)
  }

  applyCategoryColor(args) {
    try {
      let categoryColor = args.data.color
      if (!args.element || !categoryColor) {
        return
      }
      args.element.style.cursor = 'pointer'
      args.element.style.backgroundColor = categoryColor
      args.element.style.color = 'black'
      if (args.data.product_type_id !== 5) {
        let img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/2714-fe0f@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
        width: 20px;">`
        if (!args.data.invoice) {
          img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/274c@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
        width: 20px;">`
        }
        if (args.data.invoice && args.data.balance > 0) {
          img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/2757@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
        width: 20px;">`
        }
        if (
          args.data.product_type_id === 3 ||
          args.data.product_type_id === 8
        ) {
          if (
            args.data.match?.home_score !== '' &&
            args.data.match?.away_score !== ''
          ) {
            img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/2714-fe0f@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
              width: 20px;">`
          } else {
            img = null
          }
        }
        if (img) {
          args.element.querySelector('.e-appointment-details').innerHTML += img
        }
        args.element.querySelector(
          '.e-appointment-details'
        ).innerHTML += `<span style="font-size: 10px">${args.data.Description.split(
          '-'
        ).join('<br/>')}</span><br/>`
        if (args.data.balance > 0) {
          args.element.querySelector(
            '.e-appointment-details'
          ).innerHTML += `<span style="color: rgb(179, 43, 0); font-size: 13px">Balance: $${args.data.balance}</span>`
        }
      }
      if (args.data.small) {
        args.element.style.width = '50%'
      } else {
        args.element.style.minWidth = '100%'
        args.element.style.left = 0
      }
    } catch (e) {
      console.error(e)
    }
  }

  emptyCellClick(args) {
    args.cancel = true
    this.props.openNewEvent({
      start_time: momentDateTimeToUnix(moment(args.startTime)),
      field_id: this.props.fields[args.groupIndex].ID,
      end_time: momentDateTimeToUnix(moment(args.endTime).add(30, 'm')),
    })
    this.props.openEventModal()
  }

  eventClick(args) {
    const win = window.open(`/calendar/event/${args.event.ID}`, '_blank')
    if (win != null) {
      win.focus()
    }
  }

  onDataBound(args) {
    const currentHour = moment().format('HH') + ':00'
    this.scheduleObj.scrollTo(currentHour)
  }

  onRenderCell(args) {
    if (args.elementType === 'resourceHeader') {
      const spl = args.element.innerText.split('|')
      args.element.innerHTML = `<div class="e-text-ellipsis">${spl[0]}<br/><small>${spl[1]}</small></div>`
    }
  }

  onDragStart(args) {
    args.interval = 5
  }

  onDragStop(args) {
    let cellData = this.scheduleObj.getCellDetails(args.event.target)
    const field = this.props.fields[cellData.groupIndex],
      field_id = field.ID,
      start = cellData.startTime,
      durationUnix = args.data.end_date - args.data.start_date,
      prev_field = `${args.data.field.number} - ${args.data.field.name}`,
      new_field = field.name.split('|')[0]
    let changes = []
    if (prev_field !== new_field) {
      changes.push(`field ${prev_field} -> ${new_field}`)
    }
    const start_date = unixToDateTime(args.data.start_date).format(
        'MM/DD h:mmA'
      ),
      new_start_date = moment(start).format('MM/DD h:mmA')
    if (start_date !== new_start_date) {
      changes.push(`time ${start_date} -> ${new_start_date}`)
    }
    if (!changes.length) return
    this.props.updateEventTimes(
      changes,
      args.data.ID,
      field_id,
      start,
      durationUnix
    )
  }

  render() {
    const { fields, events, search } = this.props
    console.log(events)
    return (
      <div>
        <ScheduleComponent
          ref={schedule => (this.scheduleObj = schedule)}
          group={{ resources: ['Fields'] }}
          currentView="Day"
          eventSettings={{ dataSource: events }}
          renderCell={this.onRenderCell.bind(this)}
          eventRendered={this.onEventRendered.bind(this)}
          showQuickInfo={false}
          eventClick={this.eventClick.bind(this)}
          cellClick={this.emptyCellClick.bind(this)}
          cssClass="schedule-cell-dimension"
          navigating={d => {
            if (d.currentView === 'Week') {
              const start = moment(d.currentDate).startOf('week'),
                end = start.clone().add(7, 'd')
              return this.props.getEvents(start, end)
            }
            if (d.currentView === 'Month') {
              const start = moment(d.currentDate).startOf('month'),
                end = start.clone().add(30, 'd')
              return this.props.getEvents(start, end)
            }
            d.cancel = true
            if (d.action === 'date') {
              this.scheduleObj.selectedDate = d.currentDate
            }
            this.props.getEvents(moment(d.currentDate).startOf('day'))
          }}
          created={() => {
            if (search) {
              const query = queryString.parse(search)
              if (query.date) {
                const parsed = moment(query.date, 'M/D/YYYY')
                this.scheduleObj.selectedDate = parsed.toDate()
                this.props.getEvents(parsed.startOf('day'))
              }
            }
          }}
          startHour="00:00"
          endHour="24:00"
          width="100%"
          height="800px"
          dataBound={this.onDataBound.bind(this)}
          dragStart={this.onDragStart.bind(this)}
          dragStop={this.onDragStop.bind(this)}
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <ResourcesDirective>
            <ResourceDirective
              field="field_id"
              title="Field"
              name="Fields"
              allowMultiple={false}
              dataSource={fields}
              textField="name"
              idField="ID"
            />
          </ResourcesDirective>
          <Inject services={[Day, Week, Month, DragAndDrop]} />
        </ScheduleComponent>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  fields: state.calendar.fields.map(f => ({
    ...f,
    name: `${f.number} - ${f.name}|${f.field_type} ${
      f.exterior ? 'outdoor' : 'indoor'
    }`,
  })),
  events: state.calendar.events.map(e => ({
    ...e,
    StartTime: unixToDateTime(e.start_date).toDate(),
    EndTime: unixToDateTime(e.end_date).toDate(),
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openEventModal: () => dispatch(toggleEventModal(true)),
  changeEventDetails: (k, v) =>
    dispatch(changeEventDetails({ field: k, value: v })),
  getEvents: (start, end) => dispatch(getEvents(start, end)),
  selectEvent: event => dispatch(selectEvent({ event })),
  openNewEvent: event => dispatch(openNewEvent({ event })),
  updateEventTimes: (changes, ev, field, start, duration) => {
    dispatch(
      openConfirmModal(
        'Event update',
        `update this event, changes: ${changes.join(' / ')}`,
        () => updateEventTimes(ev, field, start, duration)
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
