import React from 'react'
import { connect } from 'react-redux'

import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Button,
  TextField,
  Chip,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'

import { us_states, countries, interests } from './../../../consts'
import { updateCustomerDetails, saveCustomer } from './../../actions'
import { colors } from './../../../consts'

const AccountDetails = props => {
  const { className, customer, facilities } = props

  return (
    <Card className={className} elevation={0}>
      <form autoComplete="off" noValidate>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First name"
                margin="dense"
                onChange={e =>
                  props.updateCustomerDetails('first_name', e.target.value)
                }
                required
                value={customer.first_name}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                onChange={e =>
                  props.updateCustomerDetails('last_name', e.target.value)
                }
                required
                value={customer.last_name}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={e =>
                  props.updateCustomerDetails('email', e.target.value)
                }
                required
                value={customer.email}
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Facility"
                margin="dense"
                onChange={e =>
                  props.updateCustomerDetails('facility_id', e.target.value)
                }
                required
                select
                value={customer.facility_id}
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {facilities.map(k => (
                  <option key={k.ID} value={k.ID}>
                    {k.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('phone', e.target.value)
                }
                value={customer.hubspot.phone}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Date of birth (MM/DD/YYYY)"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('dob', e.target.value)
                }
                value={customer.hubspot.dob}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Gender"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('gender', e.target.value)
                }
                value={customer.hubspot.gender}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {['Male', 'Female'].map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Address"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('address', e.target.value)
                }
                value={customer.hubspot.address}
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="City"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('city', e.target.value)
                }
                value={customer.hubspot.city}
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="State"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('state', e.target.value)
                }
                value={customer.hubspot.state}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {us_states.map(k => (
                  <option key={k} value={k}>
                    {k}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Country"
                margin="dense"
                onChange={e =>
                  props.updateCustomerHubspotDetails('country', e.target.value)
                }
                value={customer.hubspot.country}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {countries.map(k => (
                  <option key={k} value={k}>
                    {k}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={interests}
                value={
                  customer.hubspot.interest ? customer.hubspot.interest : []
                }
                onChange={(a, b) =>
                  props.updateCustomerHubspotDetails('interest', b)
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Customer interest"
                    style={{ width: '100%' }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={index}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingTop: '20px' }}>
              <TextField
                fullWidth
                label="Is kid"
                margin="dense"
                onChange={e =>
                  props.updateCustomerDetails(
                    'is_kid',
                    e.target.value === 'yes'
                  )
                }
                value={customer.is_kid ? 'yes' : 'no'}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {['Yes', 'No'].map(option => (
                  <option key={option} value={option.toLowerCase()}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingTop: '20px' }}>
              <TextField
                fullWidth
                label="Is organization"
                margin="dense"
                onChange={e =>
                  props.updateCustomerDetails(
                    'organization',
                    e.target.value === 'yes'
                  )
                }
                value={customer.organization ? 'yes' : 'no'}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {['Yes', 'No'].map(option => (
                  <option key={option} value={option.toLowerCase()}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            {customer.organization && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Tax ID"
                  margin="dense"
                  onChange={e =>
                    props.updateCustomerDetails('tax', e.target.value)
                  }
                  required
                  value={customer.tax}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardActions style={{ float: 'right' }}>
          <Button
            style={{
              color: colors.blue_strong,
              backgroundColor: colors.blue_mild,
            }}
            onClick={() => props.saveCustomer()}
          >
            <b>Save</b>
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

const mapStateToProps = (state, ownProps) => ({
  customer: state.customer.customer,
  facilities: state.common.facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCustomerDetails: (k, v) => dispatch(updateCustomerDetails(k, v, false)),
  updateCustomerHubspotDetails: (k, v) =>
    dispatch(updateCustomerDetails(k, v, true)),
  saveCustomer: () => dispatch(saveCustomer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
