import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import EventIcon from '@material-ui/icons/Event'

import { unixToDateTime } from './../../helper'
import { colors } from './../../consts'
import { invoiceSlice } from './../redux'
const { toggleEventSelectionModal } = invoiceSlice.actions

class EventSelectionDialog extends React.Component {
  render() {
    const { open, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Invoice events
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {this.props.invoice && (
            <Paper elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ textAlign: 'center', width: '40%' }}
                      className="table-header"
                    >
                      Event
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '25%' }}
                      className="table-header"
                    >
                      Start
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '25%' }}
                      className="table-header"
                    >
                      End
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'center', width: '10%' }}
                      className="table-header"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.invoice.invoice_items.map(event => (
                    <TableRow key={event.ID}>
                      <TableCell style={{ textAlign: 'center', width: '40%' }}>
                        {event.product.name}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '25%' }}>
                        {unixToDateTime(event.event.start_date).format(
                          'M/D/YYYY h:mmA'
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '25%' }}>
                        {unixToDateTime(event.event.end_date).format(
                          'M/D/YYYY h:mmA'
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '10%' }}>
                        <Link
                          href={`/calendar/event/${event.event.ID}`}
                          target="_blank"
                        >
                          <IconButton style={{ padding: 5 }}>
                            <EventIcon
                              fontSize="small"
                              style={{
                                color: colors.blue_strong,
                              }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.invoice.event_modal_open,
  invoice: state.invoice.invoices.find(
    invoice => invoice.ID === state.invoice.selected_invoice_id
  ),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleEventSelectionModal({ open: false })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventSelectionDialog)
