import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ProductFieldTimeForm from './../../calendar/components/forms/ProductFieldTimeSection'
import RepeatForm from './../../calendar/components/forms/RepeatSection'

import { actions } from 'contract/redux'
import { getFields } from 'calendar/actions'
import { saveContractEvents, editContractEvent } from 'contract/actions'

class CreateEventDialog extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.props.getFields(
        this.props.contract && this.props.contract.facility_id
      )
    }
  }

  render() {
    const { edit, open, handleClose, editContractEvent, saveContractEvents } =
        this.props,
      cb = edit ? editContractEvent : saveContractEvents
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit' : 'Add new'} event
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProductFieldTimeForm hide_comment={true} />
          {!edit && <RepeatForm />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cb()}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.contract.new_event_modal_open,
  edit: state.contract.new_event_modal_edit,
  contract: state.contract.contract,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.toggleEventCreationModal({ open: false })),
  getFields: id => dispatch(getFields(id)),
  saveContractEvents: () => dispatch(saveContractEvents()),
  editContractEvent: () => dispatch(editContractEvent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventDialog)
