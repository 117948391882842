import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import NewProductDialog from './../components/NewProductDialog'
import Filters from './../components/Filters'
import ProductTable from './../components/ProductTable'
import GLTable from './../components/GLTable'

import { getProducts, getGLs } from './../actions'
import { getFacilities } from './../../common/actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getProducts()
    this.props.getFacilities()
    this.props.getGLs()
  }

  render() {
    return (
      <div>
        <NewProductDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            {this.props.tab === 'products' && <ProductTable />}
            {this.props.tab === 'gls' && <GLTable />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.product.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getProducts: () => dispatch(getProducts()),
  getFacilities: () => dispatch(getFacilities()),
  getGLs: () => dispatch(getGLs()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
