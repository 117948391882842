import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import GroupIcon from '@material-ui/icons/Group'

import { deleteLeague } from './../../actions'
import { openDeleteModal } from './../../../common/actions'
import { colors } from './../../../consts'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class TeamTable extends React.Component {
  render() {
    const { classes, leagues, deleteLeague } = this.props
    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '50%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                # of teams
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leagues.map(league => (
              <TableRow key={league.ID} style={{ cursor: 'pointer' }}>
                <TableCell
                  style={{ textAlign: 'center', width: '25% !important' }}
                >
                  {league.name}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {league.teams?.length}
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '20%' }}>
                  <Link href={`/league/${league.ID}`} target="_blank">
                    <IconButton className={classes.root}>
                      <GroupIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                  {!league.teams?.length && (
                    <IconButton className={classes.root}>
                      <DeleteIcon
                        color="secondary"
                        fontSize="small"
                        style={{ color: colors.red_strong }}
                        onClick={() =>
                          deleteLeague(league.ID, league.is_tournament)
                        }
                      />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  leagues: state.league.league.leagues,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoTeam: id => dispatch(push('/team/' + id)),
  deleteLeague: (id, is_tournament) =>
    dispatch(openDeleteModal('league', () => deleteLeague(id, is_tournament))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamTable))
