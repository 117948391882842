import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Link from '@material-ui/core/Link'
import CSVReader from 'react-csv-reader'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'

import CreateCustomerForm from './forms/CreateCustomer'

import { createOrUpdateCustomer, bulkCreateCustomers } from './../actions'
import { actions } from './../redux'

class CreateCustomerDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      in_bulk: false,
      facility_id: null,
      bulk_customers: [],
    }
  }

  render() {
    const { open, handleClose, createOrUpdateCustomer, bulkCreateCustomers } =
      this.props
    const cb = this.state.in_bulk ? bulkCreateCustomers : createOrUpdateCustomer
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Create new customer
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateCustomerForm />
          {this.props.is_admin && (
            <>
              <Grid item xs={12} style={{ textAlign: 'left', marginTop: 20 }}>
                <Typography
                  variant="subtitle1"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  Bulk creation
                  <Checkbox
                    checked={this.state.in_bulk}
                    name="closed_registration"
                    onChange={e => this.setState({ in_bulk: e.target.checked })}
                  />
                </Typography>
              </Grid>
              {this.state.in_bulk && (
                <Grid item xs={6} style={{ textAlign: 'left', marginTop: 20 }}>
                  <Select
                    options={this.props.facilities}
                    placeholder="Select facility..."
                    value={
                      this.state.facility_id &&
                      this.props.facilities.find(
                        f => f.value === this.state.facility_id
                      )
                    }
                    onChange={e => this.setState({ facility_id: e.value })}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              )}
              {this.state.in_bulk && (
                <Grid item xs={12} style={{ textAlign: 'left', marginTop: 20 }}>
                  <CSVReader
                    onFileLoaded={data => {
                      this.setState({
                        bulk_customers: data
                          .filter(d => d.length > 1)
                          .filter(d => d[0] !== 'First Name')
                          .map(d => ({
                            first_name: d[0],
                            last_name: d[1],
                            email: d[2],
                            phone: d[3],
                          })),
                      })
                    }}
                    inputStyle={{
                      color: '#2F313F',
                    }}
                  />
                  <Link
                    href={'https://sofive-assets.s3.amazonaws.com/customer.csv'}
                  >
                    Download example
                  </Link>
                </Grid>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              cb(this.state.facility_id, this.state.bulk_customers)
            }
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.customer.customer_creation_modal_open,
  is_admin: state.user.is_admin,
  facilities: state.common.filter_facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.toggleCustomerCreationModal({ open: false })),
  createOrUpdateCustomer: () => dispatch(createOrUpdateCustomer()),
  bulkCreateCustomers: (f, c) => dispatch(bulkCreateCustomers(f, c)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomerDialog)
