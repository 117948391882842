import React from 'react'
import { connect } from 'react-redux'

import memoize from 'memoize-one'
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import DataTable from 'react-data-table-component'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import FileCopyIcon from '@material-ui/icons/Description'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Tooltip from '@material-ui/core/Tooltip'

import { deleteClassRegistration } from './../actions'
import { openDeleteModal } from 'common/actions'
import { colors } from 'consts'
import { niceNumber, getHubspot } from 'helper'
import { actions } from 'classes/redux'

const sortIcon = <ArrowDownward />

const columns = memoize((deleteClassRegistration, openChangeClass) => [
  {
    name: 'Firstname',
    selector: 'Firstname',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>{row.customer.first_name}</div>
    ),
  },
  {
    name: 'Lastname',
    selector: 'Lastname',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>{row.customer.last_name}</div>
    ),
  },
  {
    name: 'Contact',
    selector: 'Contact',
    center: true,
    grow: 5,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://ps.w.org/leadin/assets/icon-256x256.png?rev=2785153"
          onClick={() => getHubspot(row.parent?.ID || row.customer?.ID)}
          width="15"
          style={{ marginLeft: 10, cursor: 'pointer' }}
        />
      </div>
    ),
  },
  {
    name: 'Balance',
    selector: 'Balance',
    center: true,
    grow: 1,
    cell: row => (
      <Chip
        size="small"
        label={<b>${niceNumber(row.invoice.balance)}</b>}
        style={{
          color:
            row.invoice.balance > 0 ? colors.red_strong : colors.green_strong,
          backgroundColor:
            row.invoice.balance > 0 ? colors.red_mild : colors.green_mild,
        }}
      />
    ),
    sortFunction: (rowA, rowB) => rowA.invoice.balance - rowB.invoice.balance,
  },
  {
    name: 'Action',
    selector: 'Action',
    center: true,
    grow: 1,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {(!row.invoice || row.invoice.paid === 0) && (
          <IconButton onClick={() => deleteClassRegistration(row.ID)}>
            <DeleteIcon fontSize="small" style={{ color: colors.red_strong }} />
          </IconButton>
        )}
        <Link href={`/invoice/${row.invoice_id}`} target="_blank">
          <IconButton style={{ padding: 5 }}>
            <FileCopyIcon
              fontSize="small"
              style={{
                color: colors.blue_strong,
              }}
            />
          </IconButton>
        </Link>
        <Tooltip title="Move to other class">
          <IconButton
            style={{ padding: 5 }}
            onClick={() => openChangeClass(row.ID)}
          >
            <ImportExportIcon
              fontSize="small"
              style={{
                color: colors.yellow_strong,
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
])

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const ParticipantsTable = ({
  participants,
  deleteClassRegistration,
  openChangeClass,
}) => (
  <Paper elevation={0}>
    <DataTable
      columns={columns(deleteClassRegistration, openChangeClass)}
      data={participants}
      highlightOnHover
      defaultSortField="date"
      defaultSortAsc={false}
      sortIcon={sortIcon}
      pagination
      striped
      fixedHeader
      paginationPerPage={50}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
    />
  </Paper>
)

const mapStateToProps = (state, ownProps) => ({
  participants: state.classes.classInfo.attendees,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteClassRegistration: id =>
    dispatch(
      openDeleteModal('class registration', () => deleteClassRegistration(id))
    ),
  openChangeClass: attendee_id => {
    dispatch(actions.editChangeClass({ key: 'open', value: true }))
    dispatch(
      actions.editChangeClass({ key: 'attendee_id', value: attendee_id })
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ParticipantsTable))
