import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars'
import SchedulerSlotsTable from './SlotsTable'
import TeamOrderTable from './TeamOrderTable'

import { changeScheduleDetails, addSchedulerSlot } from './../../actions'
import { weekdays } from './../../../consts'

class Scheduler extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={8}
      >
        <Grid item xs={4} style={{ textAlign: 'center', width: '100%' }}>
          <TeamOrderTable />
        </Grid>
        <Grid item xs={8} style={{ textAlign: 'center', width: '100%' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Add a new slot</Typography>
            </Grid>
            <Grid item xs={6}>
              <Select
                options={this.props.fields}
                placeholder="Field*"
                value={this.props.field}
                onChange={e => this.props.changeScheduleDetails('field', e)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={this.props.weekdays}
                placeholder="Day of week*"
                value={
                  this.props.weekday &&
                  this.props.weekdays[this.props.weekday - 1]
                }
                onChange={e =>
                  this.props.changeScheduleDetails('weekday', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePickerComponent
                id="start_time"
                placeholder="Start time*"
                value={this.props.start_time}
                step={15}
                onChange={e =>
                  this.props.changeScheduleDetails('start_time', e.value)
                }
              />
            </Grid>
            {!this.props.is_tournament ? (
              <Grid item xs={6}>
                <TimePickerComponent
                  id="end_time"
                  placeholder="End time*"
                  value={this.props.end_time}
                  step={15}
                  onChange={e =>
                    this.props.changeScheduleDetails('end_time', e.value)
                  }
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                style={{ margin: 10, textAlign: 'right' }}
                onClick={() => this.props.addSchedulerSlot()}
                className="save-btn"
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={12}>
              <SchedulerSlotsTable />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.scheduler,
  fields: state.calendar.fields.map(f => ({
    value: f.ID,
    label: f.number.toString() + ' - ' + f.name,
  })),
  weekdays: weekdays.map((w, i) => ({
    value: i + 1,
    label: w,
  })),
  scheduler_matches: state.league.scheduler_matches,
  is_tournament: state.league.league.is_tournament,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeScheduleDetails: (k, v) => dispatch(changeScheduleDetails(k, v)),
  addSchedulerSlot: () => dispatch(addSchedulerSlot()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler)
