import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/Description'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import { unixToDateTime } from './../../helper'
import { colors, GLOBAL_DATE_FORMAT } from './../../consts'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class InvoiceList extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  No
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  League
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Product
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Invoiced
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Paid
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Balance
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {this.props.leagues.map(league => (
                  <TableRow key={league.invoice.ID}>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {league.invoice.ID}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {unixToDateTime(league.invoice.date).format(
                        GLOBAL_DATE_FORMAT
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      {league.name}
                      <Link href={`/league/${league.ID}`} target="_blank">
                        <OpenInNewIcon style={{ fontSize: 15 }} />
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '20%' }}>
                      {league.product.name}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      ${league.invoice.invoiced}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      ${league.invoice.paid}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      {league.invoice.balance <= 0 ? (
                        <Chip
                          size="small"
                          label={<b>${league.invoice.balance}</b>}
                          style={{
                            color: colors.green_strong,
                            backgroundColor: colors.green_mild,
                            margin: 5,
                          }}
                        />
                      ) : (
                        <Chip
                          size="small"
                          label={<b>${league.invoice.balance}</b>}
                          style={{
                            color: colors.red_strong,
                            backgroundColor: colors.red_mild,
                            margin: 5,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: '10%' }}>
                      <Link href={`/invoice/${league.invoice.ID}`}>
                        <IconButton style={{ padding: 5 }}>
                          <FileCopyIcon
                            fontSize="small"
                            style={{
                              color: colors.blue_strong,
                            }}
                          />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  leagues: state.teams.team.leagues.filter(l => l.invoice),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InvoiceList))
