import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'

import { colors } from './../../consts'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
})

class CustomerTable extends React.Component {
  render() {
    const { classes } = this.props,
      cb = customer => {
        if (this.props.onSelect) return this.props.onSelect(customer)
        this.props.gotoProfile(customer.ID)
      }

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Location
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '10%' }}
                className="table-header"
              >
                Birth date
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Phone
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Email
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '15%' }}
                className="table-header"
              >
                Address
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div className={classes.maxheight}>
          <Table aria-label="simple table">
            <TableBody>
              {this.props.customers.map(customer => (
                <TableRow
                  key={customer.ID}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => cb(customer)}
                >
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    {customer.first_name} {customer.last_name}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    <Chip
                      size="small"
                      label={<b>{customer.facility.name}</b>}
                      style={{
                        color: colors.purple_strong,
                        backgroundColor: colors.purple_mild,
                        margin: 5,
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '10%' }}>
                    {customer.hubspot.dob ? customer.hubspot.dob : null}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    <b>{customer.hubspot.phone}</b>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '20%' }}>
                    <u>{customer.email}</u>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', width: '15%' }}>
                    {customer.hubspot.address}
                    <br />
                    {customer.hubspot.state}
                    <br />
                    {customer.hubspot.country}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  customers: state.customer.customers,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoProfile: id => (window.location = '/customer/' + id),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomerTable))
