import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { removeSchedulerSlot } from './../../actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class SchedulerSlotsTable extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Priority
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Field
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Weekday
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Time range
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '20%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.scheduler_slots.map((slot, i) => (
              <TableRow
                key={JSON.stringify(slot)}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  <b>{i + 1}</b>
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {slot.field.label}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {slot.weekday}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {moment(slot.start_time).format('h:mma')} -{' '}
                  {!this.props.is_tournament &&
                    moment(slot.end_time).format('h:mma')}
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '20%' }}>
                  <IconButton
                    className={classes.root}
                    onClick={() => this.props.removeSchedulerSlot(slot.token)}
                  >
                    <DeleteIcon
                      color="secondary"
                      fontSize="small"
                      className="del-btn"
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  scheduler_slots: state.league.scheduler_slots,
  is_tournament: state.league.league.is_tournament,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeSchedulerSlot: token => dispatch(removeSchedulerSlot(token)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SchedulerSlotsTable))
