import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'

import { applyFilter } from './../helper'
import {
  setProductDetails,
  toggleModal,
  setEditProductDetails,
  deleteProduct,
} from './../actions'
import { openDeleteModal } from './../../common/actions'
const sortIcon = <ArrowDownward />

const columns = ({ openProductEditModal, openProductModal, deleteProduct }) =>
  memoize(() => [
    {
      name: 'Name',
      selector: 'name',
      center: true,
      grow: 3,
      cell: product => product.name,
      sortFunction: (productA, productB) => productA.name < productB.name,
    },
    {
      name: 'Facility',
      selector: 'facility',
      center: true,
      cell: product => product.facility.name,
      sortFunction: (productA, productB) =>
        productA.facility.name < productB.facility.name,
    },
    {
      name: 'GL',
      selector: 'gl',
      center: true,
      grow: 2,
      cell: product => (
        <div>
          {product.gl.code}
          <br />
          <small>{product.gl.product_type.name}</small>
        </div>
      ),
    },
    {
      name: 'Deposit',
      selector: 'deposit',
      center: true,
      format: product =>
        product.deposit_price && product.deposit_price > 0
          ? '$' + product.deposit_price.toString()
          : '',
      sortFunction: (productA, productB) =>
        productA.deposit_price - productB.deposit_price,
    },
    {
      name: 'Early bird',
      selector: 'early-bird',
      center: true,
      format: product =>
        product.early_bird_price && product.early_bird_price > 0
          ? '$' + product.early_bird_price.toString()
          : '',
      sortFunction: (productA, productB) =>
        productA.early_bird_price - productB.early_bird_price,
    },
    {
      name: 'Individual',
      selector: 'individual',
      center: true,
      format: product =>
        product.individual_price && product.individual_price > 0
          ? '$' + product.individual_price.toString()
          : '',
      sortFunction: (productA, productB) =>
        productA.individual_price - productB.individual_price,
    },
    {
      name: 'Full',
      selector: 'full',
      center: true,
      format: product =>
        product.full_price && product.full_price > 0
          ? '$' + product.full_price.toString()
          : '',
      sortFunction: (productA, productB) =>
        productA.full_price - productB.full_price,
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: product => (
        <>
          <IconButton onClick={() => openProductEditModal(product)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => openProductModal(product)}>
            <CopyIcon color="primary" fontSize="small" />
          </IconButton>
          <IconButton onClick={() => deleteProduct(product.ID)}>
            <DeleteIcon
              color="secondary"
              fontSize="small"
              className="del-btn"
            />
          </IconButton>
        </>
      ),
    },
  ])

const RefereeList = ({ products, ...props }) => {
  return (
    <div>
      <Paper elevation={0}>
        <DataTable
          columns={columns(props)()}
          data={products}
          highlightOnHover
          defaultSortField="facility"
          defaultSortAsc={false}
          sortIcon={sortIcon}
          fixedHeader
          customStyles={{
            rows: {
              style: {
                textAlign: 'center',
                paddingTop: 30,
                paddingBottom: 30,
              },
            },
          }}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  products: applyFilter(state.product.products, state.product.filter),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openProductEditModal: product => {
    dispatch(
      setProductDetails({
        ...product,
        facility_id: product.facility.ID,
        gl_code: product.gl.code,
      })
    )
    dispatch(setEditProductDetails())
    dispatch(toggleModal(true))
  },
  openProductModal: product => {
    dispatch(
      setProductDetails({
        ...product,
        facility_id: product.facility.ID,
        gl_code: product.gl.code,
      })
    )
    dispatch(toggleModal(true))
  },
  deleteProduct: id =>
    dispatch(openDeleteModal('product', () => deleteProduct(id))),
})

export default connect(mapStateToProps, mapDispatchToProps)(RefereeList)
