import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Scoreboard from './../components/Scoreboard'
import Filters from './../components/Filters'

import { getScoreboards, setupWebsocket } from './../actions'
import { filterScoreboards } from './../helper'

class Scoreboards extends React.Component {
  componentDidMount() {
    this.props.getScoreboards().then(this.props.setupWebsocket)
  }

  render() {
    const { scoreboards } = this.props
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={4}
            >
              {scoreboards.map(s => (
                <Grid item xs={3} key={s.ID}>
                  <Scoreboard data={s} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  scoreboards: filterScoreboards(
    state.scoreboards.scoreboards,
    state.scoreboards.filters
  ),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getScoreboards: () => dispatch(getScoreboards()),
  setupWebsocket: () => dispatch(setupWebsocket()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Scoreboards)
