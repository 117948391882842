import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import FastTextField from 'common/components/FastTextField'
import CustomerSection from 'customer/components/CustomerSection'

import { addParticipant } from 'classes/actions'
import { getFields } from 'calendar/actions'
import { actions } from 'classes/redux'

const AddParticipantsDialog = ({
  facility_id,
  open,
  handleClose,
  handleSubmit,
  getFields,
  class_price,
}) => {
  useEffect(() => {
    getFields(facility_id)
  })
  const [child, updateChild] = useState({
    first_name: '',
    last_name: '',
    price: class_price,
  })
  const updateField = ({ name, value }) =>
    updateChild({
      ...child,
      [name]: value,
    })
  const { first_name, last_name, price } = child
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        Add participant
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <CustomerSection name="Parent" />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                flexGrow: 1,
                minHeight: '100%',
                justifyContent: 'space-around',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Enter participant&apos;s details
              </Typography>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={6}>
                  <FastTextField
                    name="first_name"
                    label="First name"
                    fullWidth
                    value={first_name}
                    onChange={e => updateField(e.target)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FastTextField
                    name="last_name"
                    label="Last name"
                    fullWidth
                    value={last_name}
                    onChange={e => updateField(e.target)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FastTextField
                    name="price"
                    label="Price"
                    fullWidth
                    value={price}
                    onChange={e => updateField(e.target)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit(child, updateChild)}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  open: state.classes.participant_modal,
  facility_id: state.classes.classInfo.facility_id,
  class_price: state.classes.classInfo?.product?.individual_price,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.toggleAddParticipantModal(false)),
  handleSubmit: (child, clearState) =>
    dispatch(addParticipant(child, clearState)),
  getFields: id => dispatch(getFields(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddParticipantsDialog)
