import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { getTeams, changePlayerTeam } from 'teams/actions'
import { openConfirmModal } from 'common/actions'
import { actions } from 'teams/redux'

class ChangeTeamDialog extends React.Component {
  render() {
    const { open, handleClose } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        onEntering={() => this.props.getTeams(this.props.facility_id)}
      >
        <DialogTitle id="form-dialog-title">
          Change player team
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            options={this.props.teams}
            getOptionLabel={option =>
              `${option.name} / league ${option.league.name}`
            }
            renderOption={option => (
              <div>
                <b>{option.name}</b> / League: <u>{option.league.name}</u>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="New team"
                variant="outlined"
                fullWidth
              />
            )}
            fullWidth
            onChange={(e, l) =>
              this.props.updateChangeTeamInfo('target_team', l)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.changePlayerTeam(this.props.target_team)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  facility_id: state.teams.team?.facility_id,
  teams: state.teams.teams.map(t => {
    let league = { name: 'None' }
    if (t.current_league?.ID) {
      league = t.current_league
    }
    return { ...t, league }
  }),
  open: state.teams.change_team_dialog_open,
  target_team: state.teams.change_team.target_team?.name,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTeams: facility_id => dispatch(getTeams(facility_id)),
  handleClose: () => dispatch(actions.toggleChangeTeamModal({ open: false })),
  updateChangeTeamInfo: (k, v) =>
    dispatch(actions.updateChangeTeamInfo({ key: k, value: v })),
  changePlayerTeam: name => {
    dispatch(
      openConfirmModal(
        'Player team change',
        `change the player's team to ${name}`,
        () => changePlayerTeam()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTeamDialog)
