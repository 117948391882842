import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Filters from './../components/Filters'
import SlotsTable from './../components/SlotsTable'
import SlotDialog from './../components/SlotDialog'
import AssignDialog from './../components/AssignDialog'
import EventsToAssign from './../components/EvTable'

import { getSlots } from './../actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getSlots()
  }

  render() {
    return (
      <Paper elevation={0}>
        <SlotDialog />
        <AssignDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          {this.props.tab === 'slot' && (
            <Grid item xs={12}>
              <SlotsTable />
            </Grid>
          )}
          {this.props.tab === 'assign' && (
            <Grid item xs={12}>
              <EventsToAssign />
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.soccernaut.tab,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSlots: () => dispatch(getSlots()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
