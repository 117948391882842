import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import { actions } from 'classes/redux'

const FeeForm = ({ changeContractDetails, fees, fee }) => {
  return (
    <Grid item xs={12}>
      <Select
        label="Product fee"
        variant="outlined"
        fullWidth
        options={fees}
        placeholder="Select fee"
        value={fee && fees.find(p => p.value === fee)}
        onChange={e => {
          changeContractDetails('fee', e.value)
        }}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  ...state.classes.form,
  fees: state.classes.fees.map(fee => ({
    label: `${fee.product.name} - ${fee.text.split('(')[0]} (${
      fee.product.individual_price
    })`,
    value: fee.id,
  })),
})

const mapDispatchToProps = dispatch => ({
  changeContractDetails: (k, v) =>
    dispatch(actions.updateForm({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeForm)
