import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Select from 'react-select'
import {
  DatePickerComponent,
  TimePickerComponent,
} from '@syncfusion/ej2-react-calendars'

import {
  getComputedSchedule,
  getComputedTournamentSchedule,
  getComputedELeagueSchedule,
  changeScheduledMatchDetails,
  runNewCheck,
} from './../../actions'
import { colors } from './../../../consts'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class SchedulerMatchesTable extends React.Component {
  componentDidMount() {
    if (this.props.is_e_league) {
      return this.props.getComputedELeagueSchedule()
    }
    if (this.props.is_tournament) {
      return this.props.getComputedTournamentSchedule()
    }
    this.props.getComputedSchedule()
  }

  render() {
    console.log('GOT', this.props.scheduler_matches.length, 'scheduler matches')
    let team_week_map = {},
      double_slot = {}
    this.props.scheduler_matches.forEach(m => {
      // Double headers
      let key_h = `${m.week}_${m.home}`,
        key_a = `${m.week}_${m.away}`
      if (!(key_h in team_week_map)) team_week_map[key_h] = 0
      if (!(key_a in team_week_map)) team_week_map[key_a] = 0
      team_week_map[key_h] += 1
      team_week_map[key_a] += 1
      // Time conflict
      key_h = `${m.date}_${m.time}_${m.home}`
      key_a = `${m.date}_${m.time}_${m.away}`
      if (!(key_h in double_slot)) double_slot[key_h] = 0
      if (!(key_a in double_slot)) double_slot[key_a] = 0
      double_slot[key_h] += 1
      double_slot[key_a] += 1
    })
    let previous_modulo = 0
    let matches = this.props.scheduler_matches.map(m => {
      let double_header = false,
        conflict = false
      let key_h = `${m.week}_${m.home}`,
        key_a = `${m.week}_${m.away}`
      if (team_week_map[key_h] > 1 || team_week_map[key_a] > 1) {
        double_header = true
      }
      key_h = `${m.date}_${m.time}_${m.home}`
      key_a = `${m.date}_${m.time}_${m.away}`
      if (
        m.type === 'regular' &&
        (double_slot[key_h] > 1 || double_slot[key_a] > 1)
      ) {
        conflict = true
      }
      let change = false
      if (m.week % 2 !== previous_modulo) {
        change = true
        previous_modulo = m.week % 2
      }
      return { ...m, double_header, conflict, change }
    })

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Week
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                Field
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Date
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Time
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                Home
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }}>
                Away
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '10%' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.map(match => (
              <TableRow
                key={`${match.date}_${match.time}_${match.home}_${match.away}_${match.match_type}_${match.field}`}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    !match.is_ok || match.conflict
                      ? colors.red_mild
                      : match.double_header
                      ? 'aliceblue'
                      : '',
                  borderTop: match.change ? '3px solid black' : 0,
                }}
              >
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  {match.week}
                  <br />
                  {match.match_type}
                  <br />
                  {match.level > 0
                    ? 'playoff'
                    : match.home !== ''
                    ? 'regular'
                    : 'break'}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  <Select
                    options={this.props.fields}
                    placeholder="Field*"
                    value={this.props.fields.find(
                      f => f.value.toString() === match.field
                    )}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    onChange={e =>
                      this.props.changeScheduledMatchDetails(
                        match.token,
                        'field',
                        e.value.toString()
                      )
                    }
                  />
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  <DatePickerComponent
                    id="start_date"
                    placeholder="Start date *"
                    value={moment(match.date, 'M/D/YYYY').toDate()}
                    onChange={e =>
                      this.props.changeScheduledMatchDetails(
                        match.token,
                        'date',
                        moment(e.value).format('M/D/YYYY')
                      )
                    }
                  />
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10% !important' }}
                >
                  <TimePickerComponent
                    id="start_time"
                    placeholder="Start time*"
                    value={moment(match.time, 'HH:mm').toDate()}
                    onChange={e =>
                      this.props.changeScheduledMatchDetails(
                        match.token,
                        'time',
                        moment(e.value).format('HH:mm')
                      )
                    }
                    step={15}
                  />
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {!this.props.is_e_league && (
                    <Select
                      options={this.props.teams}
                      placeholder="Home team*"
                      value={this.props.teams.find(
                        t => t.value.toString() === match.home
                      )}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={e =>
                        this.props.changeScheduledMatchDetails(
                          match.token,
                          'home',
                          e.value.toString()
                        )
                      }
                    />
                  )}
                  {this.props.is_e_league && match.home}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {!this.props.is_e_league && (
                    <Select
                      options={this.props.teams}
                      placeholder="Away team*"
                      value={this.props.teams.find(
                        t => t.value.toString() === match.away
                      )}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={e =>
                        this.props.changeScheduledMatchDetails(
                          match.token,
                          'away',
                          e.value.toString()
                        )
                      }
                    />
                  )}
                  {this.props.is_e_league && match.away}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {match.is_ok ? 'OK' : 'Taken'}
                  {match.to_check && (
                    <Button
                      className="save-btn"
                      onClick={() => this.props.runNewCheck(match)}
                    >
                      Check
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_e_league: state.league.league.is_e_league,
  is_tournament: state.league.league.is_tournament,
  scheduler_matches: state.league.scheduler_matches,
  fields: state.calendar.fields.map(f => ({
    value: f.ID,
    label: f.number.toString() + ' - ' + f.name,
  })),
  teams: state.league.league.teams.map(t => ({
    label: t.team.name,
    value: t.team.ID,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getComputedSchedule: () => dispatch(getComputedSchedule()),
  getComputedTournamentSchedule: () =>
    dispatch(getComputedTournamentSchedule()),
  getComputedELeagueSchedule: () => dispatch(getComputedELeagueSchedule()),
  changeScheduledMatchDetails: (id, key, value) =>
    dispatch(changeScheduledMatchDetails(id, key, value)),
  runNewCheck: m => dispatch(runNewCheck(m)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SchedulerMatchesTable))
