import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import FastTextField from 'common/components/FastTextField'
import Select from 'react-select'

import FeeForm from './Fee'
import CustomerSection from 'customer/components/CustomerSection'

import { searchCustomers } from 'calendar/actions'
import { getFees } from 'contract/actions'
import { actions } from 'contract/redux'

class ContractForm extends React.Component {
  render() {
    return (
      <Grid container spacing={4}>
        <Grid sm={6} item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Select
                label="Product type"
                variant="outlined"
                fullWidth
                options={this.props.product_types}
                placeholder="Select product type"
                value={
                  this.props.product_type_id &&
                  this.props.product_types.find(
                    p => p.value === this.props.product_type_id
                  )
                }
                onChange={e => {
                  this.props.changeContractDetails('product_type_id', e.value)
                  this.props.getFees(e.value)
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Facility"
                variant="outlined"
                fullWidth
                options={this.props.facilities}
                placeholder="Select facility"
                value={
                  this.props.facility_id &&
                  this.props.facilities.find(
                    p => p.value === this.props.facility_id
                  )
                }
                onChange={e => {
                  this.props.changeContractDetails('facility_id', e.value)
                  this.props.getFees(this.props.product_type_id, e.value)
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <FeeForm />
            <Grid item xs={12}>
              <FastTextField
                id="standard-multiline-static"
                label="Deposit percentage"
                fullWidth
                variant="outlined"
                value={this.props.percentage}
                onChange={e =>
                  this.props.changeContractDetails('percentage', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FastTextField
                id="standard-multiline-static"
                label="Name"
                fullWidth
                required
                variant="outlined"
                value={this.props.name}
                onChange={e =>
                  this.props.changeContractDetails('name', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FastTextField
                id="standard-multiline-static"
                label="Comment"
                multiline
                rows="3"
                fullWidth
                variant="outlined"
                value={this.props.comment}
                onChange={e =>
                  this.props.changeContractDetails('comment', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={6} item>
          <CustomerSection />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.contract.new_contract,
  product_types: state.common.product_types
    .map(p => ({
      label: p.name,
      value: p.ID,
    }))
    .filter(
      p =>
        p.label.indexOf('Block-') !== -1 ||
        p.label.indexOf('Birthday') !== -1 ||
        p.label.indexOf('Contract') !== -1 ||
        p.label.indexOf('Event') !== -1 ||
        p.label.indexOf('Soccernaut') !== -1 ||
        (p.label.indexOf('Closed') !== -1 && state.user.user_type === 'admin')
    ),
  customers: state.calendar.customers, // can have contact of other facility
  facilities: state.common.filter_facilities,
  fees: state.contract.fees
    .map(fee => ({
      label: `${fee.product.name} - ${fee.text}`,
      value: fee.id,
    }))
    .concat({ label: 'Manual fee', value: -1 }),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchCustomers: q => dispatch(searchCustomers(q)),
  changeContractDetails: (k, v) =>
    dispatch(actions.changeContractDetails({ field: k, value: v })),
  getFees: (product_type_id, facility_id) =>
    dispatch(getFees(product_type_id, facility_id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractForm)
