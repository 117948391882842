import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'

import Filters from 'contract/components/Filters'
import ContractsTable from 'contract/components/ContractsTable'
import CreateContractDialog from 'contract/components/CreateContractDialog'

import { searchContracts } from 'contract/actions'
import { actions } from 'contract/redux'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getContracts()
  }

  render() {
    return (
      <div>
        <CreateContractDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            Displaying <b>{this.props.contracts}</b> contract. Hide paid{' '}
            <Switch
              checked={this.props.hide_paid}
              onChange={e => this.props.togglePaid(e.target.checked)}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={12}>
            <ContractsTable />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  contracts: state.contract.contracts.length,
  hide_paid: state.contract.search.hide_paid,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getContracts: () => dispatch(searchContracts()),
  togglePaid: paid =>
    dispatch(actions.updateSearch({ key: 'hide_paid', value: paid })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
